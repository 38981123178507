import {
  React,
  Component,
  connect,
  Actions,
  Portlet,
  PortletHeader,
  PortletBody,
  BootstrapTable,
  paginationFactory,
  filterFactory,
  Utils,
  FILTER_TYPES,
  textFilter,
  Filters,
  customFilter,
  Routes,
  Link,
  Button,
  classNames,
  ActionTypes,
  CSVLink,
  Input,
  Select,
  DateFilter,
  _,
} from '../../../Imports'
import { Helmet } from 'react-helmet'
import { Auth } from 'aws-amplify'
import moment from 'moment-timezone'

const headers = [
  { label: 'Invoice #', key: 'invoiceNumber' },
  { label: 'Customer Name', key: 'customerFullName' },
  { label: 'Purchace Date', key: 'invoiceDate' },
  { label: 'Subtotal', key: 'subtotal' },
  { label: 'Tax', key: 'tax' },
  { label: 'Shipping', key: 'shippingPrice' },
  { label: 'Grand Total', key: 'total' },
  { label: 'Order status', key: 'status' },
]

class Orders extends Component {
  constructor(props) {
    super(props)

    this.state = {
      orders: [],
      settings: {},
      isOpen: false,
      btnLoading: false,
      id: null,
      filters: [
        {
          id: 1,
          name: 'Customer Name',
          filter: null,
          value: '',
          type: FILTER_TYPES.TEXT,
        },
        {
          id: 2,
          name: 'Invoice Date',
          filter: null,
          value: '',
          type: FILTER_TYPES.DATE,
        },
      ],
      isFilterOpen: false,
      refresh: true,

      selection: [],

      userGroup: '',
      userId: '',
    }

    this.storeId = Utils.search(this.props.location).store
  }

  UNSAFE_componentWillMount = async () => {
    Auth.currentAuthenticatedUser()
      .then(user => {
        this.setState({
          userGroup: user.signInUserSession.accessToken.payload['cognito:groups'][0],
          userId: user.signInUserSession.accessToken.payload.sub,
        })
      })
      .catch(err => console.log(err))

    if (this.props.settings.data === null) {
      await this.props.storePost(
        { id: this.storeId },
        'get-store',
        ActionTypes.FETCH_SETTINGS_SUCCESS,
        ActionTypes.FETCH_SETTINGS_FAILURE,
      )
    }

    this.onRefresh()
  }

  getOrdersToExport = () => {
    const { orders: stateOrders, settings, selection } = this.state
    const timeZone = _.get(this.state.settings, 'timeZone.value', 'America/New_York')
    const orders = selection.length
      ? _.filter(stateOrders, order => _.includes(selection, order.id))
      : stateOrders

    return orders.map(order => {
      const tax = Object.values(order.tax).reduce((a, b) => a + b, 0)
      return {
        ...order,
        invoiceDate: moment(order.invoiceDate)
          .tz(timeZone)
          .format('MM/DD/YYYY HH:mm'),
        customerFullName: `${order.billToFirstName} ${order.billToLastName} ${
          order.asGuest ? '(Guest)' : ''
        }`,
        subtotal: Utils.baseSubtotal(order.products),
        total: (Utils.baseSubtotal(order.products) + tax + order.shippingPrice).toFixed(2),
        tax,
      }
    })
  }

  onSelectRow = (row, isSelect, rowIndex, e) => {
    let selection = []

    if (isSelect) {
      selection = [...this.state.selection, row.id]
    } else {
      selection = this.state.selection.filter(s => s != row.id)
    }

    this.setState({
      selection,
    })
  }

  onSelectAllRows = (isSelect, rows) => {
    let selection = []

    if (isSelect) {
      rows.map((r, index) => {
        selection.push(r.id)
      })
    }

    this.setState({
      selection,
    })
  }

  onAction = e => {
    this.onPrint(e.value)
  }

  onPrint = async option => {
    if (this.state.selection.length > 0) {
      // Get Select Orders
      let orders = _.map(this.state.selection, id =>
        _.find(this.getFilteredOrders(), order => order.id === id))

      if (parseInt(option, 10) === 1) {
        let pdf = await Utils.printInvoice(
          orders,
          this.state.settings,
          this.props.country,
          this.props.states,
        )
        pdf.open()
      } else {
        if (parseInt(option, 10) === 2) {
          let or = _.map(orders, order => {
            let packages = []
            packages = _.get(order, 'shippingPackages', [])
            packages = packages === null ? [] : packages

            if (packages.length === 0) {
              let ite = _.map(order.products, p => ({
                id: p.id,
                title: p.title,
                qtyInPackage: p.qty,
                sku: p.sku,
              }))

              packages.push({
                items: ite,
              })
            }

            order.shippingPackages = packages
            return order
          })

          let pdf = await Utils.printSlip(
            or,
            this.state.settings,
            this.props.country,
            this.props.states,
          )
          pdf.open()
        }
      }
    }
  }

  onRefresh = () => {
    this.setState({ orders: [], refresh: true })

    this.props
      .storePost(
        {
          storeId: this.storeId,
          status: ['PENDING', 'PROCESSING', 'SHIPPED', 'DECLINED', 'VOID'],
        },
        'search-order',
        ActionTypes.FETCH_ORDERS_SUCCESS,
        ActionTypes.FETCH_ORDERS_FAILURE,
      )
      .then(() => {
        this.setState({
          orders: this.props.orders.data,
          settings: this.props.settings.data,
          refresh: false,
        })
      })
  }

  toggleFilter = () => {
    this.setState({
      isFilterOpen: !this.state.isFilterOpen,
    })
  }

  clearFilter = () => {
    _.forEach(this.state.filters, filter => {
      this.removeFilter(filter)
    })
  }

  removeFilter = filter => {
    if (filter.filter) filter.filter('')
    filter.value = ''
    const filters = this.state.filters
    this.setState({ filters })
  }

  once = false
  onceTimeout = null
  setFiltersStateOnce = filters => {
    if (!this.once) {
      this.setState({ filters })
    }
    this.once = true
    this.onceTimeout = window.setTimeout(() => {
      this.once = false
      window.clearTimeout(this.onceTimeout)
    }, 500)
  }

  onDataSizeChange = ({ dataSize }) => {
    console.log('Table size changed', dataSize)
    const filters = this.state.filters
    this.setState({ filters })
  }

  onFilterToday = () => {
    const timeZone = _.get(this.props.settings, 'data', {}).timeZone.value
    const onFilterDate = this.state.filters[1].filter
    onFilterDate({
      date: moment()
        .tz(timeZone)
        .startOf('day'),
      endDate: moment()
        .tz(timeZone)
        .endOf('day'),
    })
  }

  // onDateFilterApply = data => {
  //     this.toggleFilter();
  //     console.log(data);
  // };

  getFilteredOrders = () => {
    const { userGroup, userId } = this.state
    const { SALES } = Utils.GROUPS
    if (userGroup === SALES) {
      return this.state.orders?.filter(({ primaryRepId, secondaryRepId }) => (
        primaryRepId === userId || secondaryRepId === userId
      ))
    }
    return this.state.orders
  }

  render = () => {
    const orders = this.getFilteredOrders()
    const headerFormatter = (column, colIndex, { sortElement, filterElement }) => (
      <div className="d-flex justify-content-end flex-column">
        <div className="d-flex justify-content-between">
          {column.text}
          {sortElement}
        </div>
        <div
          className={this.state.isFilterOpen ? '' : 'd-none'}
          style={{
            margin: '0 -.75rem',
            marginTop: '.75rem',
            padding: '1rem .75rem .25rem',
            borderTop: '2px solid #f7f8fa',
          }}
        >
          {filterElement}
        </div>
      </div>
    )

    const selectRow = {
      mode: 'checkbox',
      classes: 'table-secondary',
      clickToSelect: true,
      onSelect: this.onSelectRow,
      onSelectAll: this.onSelectAllRows,
      selected: this.state.selection,
      selectionHeaderRenderer: ({ indeterminate, ...rest }) => (
        <label className="k-checkbox k-checkbox--brand">
          <Input
            type="checkbox"
            disabled
            ref={input => {
              if (input) input.indeterminate = indeterminate
            }}
            {...rest}
          />{' '}
          <span />
        </label>
      ),
      selectionRenderer: ({ mode, ...rest }) => (
        <label className="k-checkbox k-checkbox--brand">
          <Input type={mode} {...rest} disabled /> <span />
        </label>
      ),
    }

    const rowEvents = {
      onClick: (e, column, columnIndex, row) => {
        let url = `/order/detail/${row.id}?store=${this.storeId}`
        this.props.history.push(url)
      },
    }

    const timeZone = _.get(this.state.settings, 'timeZone.value', 'America/New_York')

    let columns = [
      {
        dataField: 'id',
        hidden: true,
      },
      {
        dataField: 'invoiceNumber',
        text: 'Invoice #',
        headerFormatter,
        headerClasses: 'align-top',
        events: rowEvents,
        sort: true,
        headerStyle: (colum, colIndex) => ({ width: '110px' }),
      },
      {
        dataField: 'billToFirstName',
        text: 'Customer Name',
        sort: true,
        headerFormatter,
        headerClasses: 'align-top',
        events: rowEvents,
        headerStyle: (colum, colIndex) => ({ width: '120px' }),
        filter: textFilter({
          defaultValue: this.state.filters[0].value,
          getFilter: filter => {
            this.state.filters[0].filter = filter
          },
          onFilter: filterVal => {
            let filters = this.state.filters
            filters[0].value = filterVal
            this.setFiltersStateOnce(filters)
          },
        }),
        formatter: (cell, row) => (
          <span>
            {`${_.upperFirst(_.lowerCase(row.billToFirstName))} ${_.upperFirst(
              _.lowerCase(row.billToLastName),
            )} ${row.asGuest ? '(Guest)' : ''}`}
          </span>
        ),
      },
      {
        dataField: 'shippToAddress',
        text: 'Shipping Address',
        sort: true,
        headerFormatter,
        headerClasses: 'align-top',
        events: rowEvents,
        headerStyle: (colum, colIndex) => ({ width: '100px' }),
        formatter: (cell, row) => (
          <span>
            {`${_.startCase(_.lowerCase(row.shippToAddress))} ${_.startCase(
              _.lowerCase(row.shippToCity),
            )}, ${row.shippToState} ${row.shippToZipcode}`}
          </span>
        ),
      },
      {
        dataField: 'total',
        text: 'Total',
        align: 'right',
        sort: true,
        headerFormatter,
        headerClasses: 'align-top',
        events: rowEvents,
        headerStyle: (colum, colIndex) => ({ width: '100px' }),
        formatter: (cell, row, rowIndex, formatExtraData) => {
          const subtotal = Utils.baseSubtotal(row.products)
          const shipping = row.shippingPrice
          const tax = Object.values(row.tax).reduce((a, b) => a + b, 0)
          const discount = _.get(row, 'discount', 0)
          const total = (subtotal + tax + shipping) - discount

          return <strong>${total.toFixed(2)}</strong>
        },
      },
      {
        dataField: 'tax',
        text: 'Tax',
        align: 'right',
        sort: true,
        headerFormatter,
        headerClasses: 'align-top',
        events: rowEvents,
        headerStyle: (colum, colIndex) => ({ width: '100px' }),
        formatter: (cell, row, rowIndex, formatExtraData) => {
          const tax = Object.values(row.tax).reduce((a, b) => a + b, 0)
          return <span>{tax === 0 ? <span> - </span> : <strong>${tax.toFixed(2)}</strong>}</span>
        },
      },
      {
        dataField: 'invoiceDate',
        text: 'Date',
        headerFormatter,
        headerClasses: 'align-top',
        events: rowEvents,
        sort: true,
        headerStyle: (colum, colIndex) => ({ width: '140px' }),
        formatter: (cell, row, rowIndex, formatExtraData) => (
          <span>
            {moment(cell)
              .tz(timeZone)
              .format('MM/DD/YYYY HH:mm')}
          </span>
        ),
        filter: customFilter({
          type: FILTER_TYPES.DATE,
          onFilter: (filterVal, data) => {
            let filters = this.state.filters

            if (filterVal.date) {
              // Custom filtering by (start)date and endDate
              const mdate = moment(filterVal.date).tz(timeZone)
              const mendDate = moment(filterVal.endDate).tz(timeZone)

              filters[1].value =
                mdate.format('MM/DD/YYYY') +
                (filterVal.date === filterVal.endDate ||
                (filterVal.endDate &&
                  moment.duration(mendDate.diff(mdate))._milliseconds <= 86399999)
                  ? ''
                  : ' - ' + mendDate.format('MM/DD/YYYY'))
              this.setFiltersStateOnce(filters, true)

              return _.filter(
                data,
                s =>
                  moment(s.invoiceDate).tz(timeZone) > mdate &&
                  moment(s.invoiceDate).tz(timeZone) < mendDate,
              )
            } else {
              filters[1].value = ''
              this.setFiltersStateOnce(filters)
              return true
            }
          },
        }),
        filterRenderer: (onFilter, column) => {
          this.state.filters[1].filter = onFilter

          return (
            <DateFilter
              onFilter={onFilter}
              timeZone={timeZone}
              column={column}
              onApply={this.toggleFilter}
            />
          )
        },
      },
      {
        dataField: 'status',
        text: 'Status',
        headerFormatter,
        headerClasses: 'align-top',
        events: rowEvents,
        sort: true,
        headerStyle: (colum, colIndex) => ({ width: '130px' }),
        formatter: (cell, row, rowIndex, formatExtraData) => <span>{_.capitalize(cell)}</span>,
      },
      {
        dataField: 'actions',
        text: 'Actions',
        headerAlign: 'right',
        headerFormatter,
        headerClasses: 'align-top',
        headerStyle: (colum, colIndex) => ({ width: '100px' }),
        align: 'right',
        formatter: (cell, row, rowIndex, formatExtraData) => {
          let url = _.replace(Routes.ORDER_DETAIL, ':id', row.id)
          url = url + '?store=' + this.storeId
          return (
            <div>
              <Link to={url} className="btn btn-outline-secondary btn-sm btn-icon" title="Edit">
                <i className="la la-search" />
              </Link>
            </div>
          )
        },
      },
    ]

    if (this.state.userGroup == Utils.GROUPS.SUPER_ADMIN) {
      columns = [
        ..._.slice(columns, 0, 6),
        {
          dataField: 'source',
          text: 'Source',
          sort: true,
          headerFormatter,
          headerClasses: 'align-top',
          events: rowEvents,
          headerStyle: (colum, colIndex) => ({ width: '100px' }),
          formatter: (cell, row, rowIndex, formatExtraData) => <span>{cell}</span>,
        },
        ..._.slice(columns, 6),
      ]
    }

    const options = {
      showTotal: true,
      sizePerPageList: [
        {
          text: '20',
          value: 20,
        },
        {
          text: '100',
          value: 100,
        },
        {
          text: '200',
          value: 200,
        },
        {
          text: '300',
          value: 300,
        },
        {
          text: 'All',
          value: orders.length,
        },
      ],
    }

    const defaultSorted = [
      {
        dataField: 'invoiceNumber',
        order: 'desc',
      },
    ]

    return (
      <Portlet>
        <Helmet>
          <title>Orders | Admin Panel - Max Ecommerce</title>
        </Helmet>
        <PortletHeader sticky title="Orders List">
          <Button
            label="Refresh"
            icon="la la-refresh"
            className="mr-3"
            color="secondary"
            onClick={this.onRefresh}
          />
          <Button
            label="Filters"
            icon="la la-filter"
            color="secondary"
            onClick={this.toggleFilter}
            className={classNames('mr-3', {
              active: this.state.isFilterOpen,
            })}
          />
          <Button
            label="Today"
            icon="la la-calendar"
            color="secondary"
            onClick={this.onFilterToday}
            className="mr-3"
          />
          <CSVLink
            data={this.getOrdersToExport()}
            filename={'orders.csv'}
            className="btn btn-primary"
            headers={headers}
          >
            <i className="la la-download" />
            Export
          </CSVLink>

          {/* <Link to={Routes.ORDER_CREATE} className="btn btn-primary">
                        <i className="la la-plus" />
                        Add new
                    </Link>*/}
        </PortletHeader>
        <PortletBody>
          <Filters
            removeFilter={this.removeFilter}
            clearAll={this.clearFilter}
            filters={this.state.filters}
          />
          <div className="row">
            <div className="col-4">
              <Select
                options={[
                  { value: 1, label: 'Print Invoice' },
                  { value: 2, label: 'Print Packing Slips' },
                ]}
                value={null}
                placeholder={'Actions'}
                onChange={e => this.onAction(e)}
                className="mb-4"
              />
            </div>
            <div className="col-4" />
            <div className="col-4" />
          </div>
          {!!orders.length && (
            <BootstrapTable
              pagination={paginationFactory(options)}
              filter={filterFactory()}
              bootstrap4
              data={orders}
              columns={columns}
              defaultSorted={defaultSorted}
              keyField="id"
              classes="mb-0"
              wrapperClasses="mx-n3 mb-2 overflow-auto"
              hover
              noDataIndication={() => (
                <div className="mt-2">{this.state.refresh ? 'Loading...' : 'No Orders'}</div>
              )}
              selectRow={selectRow}
              onDataSizeChange={this.onDataSizeChange}
            />
          )}
        </PortletBody>
      </Portlet>
    )
  }
}

export const mapStateToProps = state => ({
  orders: state.orders,
  country: state.country,
  states: state.states,
  currentStore: state.currentStore,
  settings: state.settings,
})

export const mapDispatchToProps = dispatch => ({
  storePost: function(payload, actionEvent, typeSuccess, typeError) {
    return dispatch(Actions.storePost(payload, actionEvent, typeSuccess, typeError))
  },
  storeGet: function(actionEvent, typeSuccess, typeError) {
    return dispatch(Actions.storeGet(actionEvent, typeSuccess, typeError))
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Orders)
