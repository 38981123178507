import {
  React,
  Component,
  connect,
  Actions,
  ActionTypes,
  Portlet,
  PortletHeader,
  PortletBody,
  Section,
  FormGroup,
  Label,
  FormFeedback,
  Utils,
  Notification,
  // Routes,
  // Link,
  FormValidator,
  Button,
  _,
} from '../../../Imports'
import { Helmet } from 'react-helmet'
import MaskedInput from 'react-text-mask'

const entity = 'Tax'

class OrderSettings extends Component {
  constructor(props) {
    super(props)

    this.validator = new FormValidator([
      {
        field: 'minOrderAmount',
        method: 'isEmpty',
        validWhen: false,
        message: 'The Minimum Order Amount is required.',
      },
    ])

    this.state = {
      setting: null,
      submited: false,
      isCreate: false,
      btnLoading: false,
    }
  }

  UNSAFE_componentWillMount = () => {
    let storeId = Utils.search(this.props.location).store

    if (!this.props.settings.data) {
      this.props.post(
        { id: storeId },
        'get-store',
        ActionTypes.FETCH_SETTINGS_SUCCESS,
        ActionTypes.FETCH_SETTINGS_FAILURE,
      ).then(resp => {
        this.setState({
          setting: resp.data,
        })
      })
    } else {
      this.setState({
        setting: this.props.settings.data,
      })
    }
  }

  onChangeInput = e => {
    let setting = this.state.setting
    setting[e.target.name] = e.target.value

    this.setState({ setting })
  }

  update = () => {
    this.setState({
      submited: !this.state.submited,
    })

    let isValid = this.validator.validate(this.state.setting).isValid

    console.log(this.validator.validate(this.state.setting))
    console.log(this.state.setting)

    if (isValid) {
      this.setState({ btnLoading: true })
      this.props.post(this.state.setting, 'update-store').then(resp => {
        this.response(resp)
      })
    }
  }

  response = resp => {
    this.setState({
      btnLoading: false,
    })

    let msn = _.replace(
      _.replace(process.env.REACT_APP_SUCCESS_ACTION, '%entity%', entity),
      '%action%',
      'Updated',
    )

    if (resp.status === 'success') {
      Notification.success(msn)
    } else {
      Notification.error(process.env.REACT_APP_ERROR_ACTION)
    }
  }

  render = () => {
    const validator = this.state.submited
      ? this.validator.validate(this.state.setting)
      : this.validator.valid()

    return (
      <Portlet>
        <Helmet>
          <title>Order Settings | Admin Panel - Max Ecommerce</title>
        </Helmet>
        <PortletHeader
          title={`Order Settings - ${_.get(this.state.setting, 'name', '')}`}
          sticky
        >
          {/*
					<Link to={Routes.STORES} className="btn btn-secondary mr-3">
						<i className="la la-arrow-left" />
						Back
					</Link>
					*/}
          <Button
            label={'Update'}
            color="primary"
            icon={`la ${this.state.btnLoading ? '' : ' la-check'}`}
            isLoading={this.state.btnLoading}
            onClick={this.update}
          />
        </PortletHeader>
        <PortletBody>
          {this.state.setting === null && (
            <div className="row">
              <div className="col-12">
                <div>Loading...</div>
              </div>
            </div>
          )}

          {this.state.setting !== null && (
            <div className="row">
              <div className="col-xl-1" />
              <div className="col-xl-10">
                <Section title="Minimum Order Amount">
                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">Amount * </Label>
                    <div className="col-9">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">$</span>
                        </div>
                        <MaskedInput
                          value={this.state.setting.minOrderAmount}
                          onChange={this.onChangeInput}
                          name="minOrderAmount"
                          mask={Utils.currencyMask}
                          className={`form-control ${
                            validator.minOrderAmount.isInvalid
                              ? 'is-invalid'
                              : ''
                          }
                                                    }`}
                        />
                      </div>
                      <FormFeedback>
                        {validator.minOrderAmount.message}
                      </FormFeedback>
                    </div>
                  </FormGroup>
                </Section>
              </div>
              <div className="col-xl-1" />
            </div>
          )}
        </PortletBody>
      </Portlet>
    )
  }
}

export const mapStateToProps = state => ({
  settings: state.settings,
  taxType: state.taxType,
})

export const mapDispatchToProps = dispatch => ({
  post: function(payload, actionEvent) {
    return dispatch(Actions.post(payload, actionEvent))
  },
  storeGet: function(actionEvent, typeSuccess, typeError) {
    return dispatch(Actions.storeGet(actionEvent, typeSuccess, typeError))
  },
  storePost: function(payload, actionEvent, typeSuccess, typeError) {
    return dispatch(
      Actions.storePost(payload, actionEvent, typeSuccess, typeError),
    )
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(OrderSettings)
