import React, { Component } from 'react'

import classNames from 'classnames'

class Portlet extends Component {
  render = () => {
    const { className } = this.props

    return (
      <div className={classNames('k-portlet', { [className]: !!className })}>
        {this.props.children}
      </div>
    )
  }
}

export default Portlet
