import React, { Component } from 'react'
import Layout from './components/layout/Layout'
import Amplify from 'aws-amplify'
import aws_exports from './aws-exports'
import { withAuthenticator } from 'aws-amplify-react'

let name = process.env.REACT_APP_API_GATEWAY_NAME
let endpoint = process.env.REACT_APP_API_URL

aws_exports.API = {
  endpoints: [
    {
      name: name,
      endpoint: endpoint,
    },
  ],
}

Amplify.configure(aws_exports)

class App extends Component {
  render = () => <Layout />
}

export default withAuthenticator(App)
