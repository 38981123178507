import {
  React,
  Component,
  connect,
  Actions,
  Portlet,
  PortletHeader,
  PortletBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  BootstrapTable,
  paginationFactory,
  filterFactory,
  Filters,
  textFilter,
  numberFilter,
  FILTER_TYPES,
  Comparator,
  Utils,
  Notification,
  Routes,
  CSVLink,
  Link,
  Button,
  ActionTypes,
  classNames,
  _,
} from '../../../Imports'
import { Helmet } from 'react-helmet'

const entity = 'Customer'

const headers = [
  { label: 'Customer first name', key: 'firstName' },
  { label: 'Customer last name', key: 'lastName' },
  { label: 'Email address', key: 'email' },
  { label: 'Phone number', key: 'phoneNumber' },
  { label: 'Address', key: 'address' },
  { label: 'City', key: 'city' },
  { label: 'Country', key: 'country' },
  { label: 'State', key: 'state' },
  { label: 'ZipCode', key: 'zip' },
  { label: 'Total Orders', key: 'totalOrders' },
  { label: 'Total sales ($)', key: 'totalSalesAmount' },
]

class Customers extends Component {
  constructor(props) {
    super(props)

    this.state = {
      customers: [],
      filters: [
        {
          id: 1,
          name: 'Name',
          filter: null,
          value: '',
          type: FILTER_TYPES.TEXT,
        },
        {
          id: 2,
          name: 'Phone',
          filter: null,
          value: '',
          type: FILTER_TYPES.TEXT,
        },
        {
          id: 3,
          name: 'Email',
          filter: null,
          value: '',
          type: FILTER_TYPES.TEXT,
        },
        {
          id: 4,
          name: 'Sales',
          filter: null,
          value: '',
          type: FILTER_TYPES.NUMBER,
        },
        {
          id: 5,
          name: 'Orders',
          filter: null,
          value: '',
          type: FILTER_TYPES.NUMBER,
        },
      ],
      isOpen: false,
      isFilterOpen: false,
      btnLoading: false,
      refresh: true,
      id: null,
    }

    this.storeId = Utils.search(this.props.location).store
  }

  UNSAFE_componentWillMount = async () => {
    this.onRefresh()
  }

  onRefresh = () => {
    this.setState({ customers: [], refresh: true })

    let storeId = Utils.search(this.props.location).store

    this.props
      .storePost(
        {
          storeId,
        },
        'list-customer',
        ActionTypes.FETCH_CUSTOMERS_SUCCESS,
        ActionTypes.FETCH_CUSTOMERS_FAILURE,
      )
      .then(() => {
        this.setState({
          customers: this.props.customers.data,
          refresh: false,
        })
      })
  }

  onPublish = () => {
    console.log('Publish')
  }

  onClean = () => {
    this.setState({ customers: [], refresh: true })
  }

  delete = id => {
    this.setState({ isOpen: true, id: id })
  }

  close = () => {
    this.setState({ isOpen: false })
  }

  confirm = () => {
    this.setState({ btnLoading: true })

    this.props
      .post(
        {
          storeId: this.storeId,
          email: this.state.email,
        },
        'delete-customer',
      )
      .then(resp => {
        this.response(resp)
      })
  }

  response = resp => {
    this.setState({
      btnLoading: false,
      isOpen: false,
    })

    let msn = _.replace(
      _.replace(process.env.REACT_APP_SUCCESS_ACTION, '%entity%', entity),
      '%action%',
      'Deleted',
    )

    if (resp !== 'error') {
      Notification.success(msn)
    } else {
      Notification.error(process.env.REACT_APP_ERROR_ACTION)
    }

    this.onRefresh()
  }

  toggleFilter = () => {
    this.setState({
      isFilterOpen: !this.state.isFilterOpen,
    })
  }

  clearFilter = () => {
    _.forEach(this.state.filters, filter => {
      this.removeFilter(filter)
    })
  }

  removeFilter = filter => {
    let filters = this.state.filters
    filter.filter('')
    filter.value = ''
    this.setState({ filters })
  }

  setFilterState = ({ dataSize }) => {
    console.log('Table size changed', dataSize)
    let filters = this.state.filters
    this.setState({ filters })
  }

  render = () => {
    const { customers } = this.state
    const headerFormatter = (
      column,
      colIndex,
      { sortElement, filterElement },
    ) => (
      <div className="d-flex justify-content-end flex-column">
        <div className="d-flex justify-content-between">
          {column.text}
          {sortElement}
        </div>
        {this.state.isFilterOpen && (
          <div
            style={{
              margin: '0 -.75rem',
              marginTop: '.75rem',
              padding: '1rem .75rem .25rem',
              borderTop: '2px solid #f7f8fa',
            }}
          >
            {filterElement}
          </div>
        )}
      </div>
    )

    const defaultSorted = [
      {
        dataField: 'created',
        order: 'desc',
      },
    ]

    const rowEvents = {
      onClick: (e, column, columnIndex, row) => {
        let url = `/customer/detail/${row.email}?store=${this.storeId}`
        this.props.history.push(url)
      },
    }

    const columns = [
      {
        dataField: 'id',
        text: 'Id',
        headerFormatter,
        headerClasses: 'align-top',
        hidden: true,
      },
      {
        dataField: 'firstName',
        text: 'Customer Name',
        events: rowEvents,
        headerFormatter,
        headerClasses: 'align-top',
        filter: textFilter({
          defaultValue: this.state.filters[0].value,
          getFilter: filter => {
            this.state.filters[0].filter = filter
          },
          onFilter: filterVal => {
            let filters = this.state.filters
            filters[0].value = filterVal

            // this.setState({ filters });
          },
        }),
        formatter: (cell, row) => {
          let fullname = _.startCase(_.lowerCase(`${cell} ${row.lastName}`))
          return fullname
        },
        sort: true,
      },
      {
        dataField: 'email',
        text: 'Email',
        sort: true,
        headerFormatter,
        events: rowEvents,
        headerClasses: 'align-top',
        filter: textFilter({
          defaultValue: this.state.filters[2].value,
          getFilter: filter => {
            this.state.filters[2].filter = filter
          },
          onFilter: filterVal => {
            let filters = this.state.filters
            filters[2].value = filterVal

            // this.setState({ filters });
          },
        }),
        formatter: (cell) => {
          let email = _.toLower(`${cell}`)
          return <span>{email}</span>
        },
      },
      {
        dataField: 'phoneNumber',
        text: 'Phone',
        events: rowEvents,
        headerFormatter,
        headerStyle: () => ({ width: '110px' }),
        headerClasses: 'align-top',
        filter: textFilter({
          defaultValue: this.state.filters[1].value,
          getFilter: filter => {
            this.state.filters[1].filter = filter
          },
          onFilter: filterVal => {
            let filters = this.state.filters
            filters[1].value = filterVal

            // this.setState({ filters });
          },
        }),
        sort: true,
      },

      // {
      //   dataField: 'address',
      //   text: 'Address',
      //   events: rowEvents,
      //   headerFormatter,
      //   headerClasses: 'align-top',
      //   sort: true,
      //   formatter: (cell, row, rowIndex, formatExtraData) => {
      //     let address = `${_.startCase(_.lowerCase(cell))} ${_.startCase(
      //       _.lowerCase(row.city),
      //     )}, ${row.state}, ${row.zip} ${row.country}`
      //
      //     return <span>{address}</span>
      //   },
      // },
      {
        dataField: 'totalOrders',
        text: 'Total Orders',
        events: rowEvents,
        headerFormatter,
        headerClasses: 'align-top',
        filter: numberFilter({
          defaultValue: { comparator: Comparator.GT },
          getFilter: filter => {
            this.state.filters[4].filter = filter
          },
          onFilter: filterVal => {
            let filters = this.state.filters
            if (filterVal.number) {
              filters[4].value = `${filterVal.comparator} ${filterVal.number}`
            } else {
              filters[4].value = ''
            }

            // this.setState({ filters });
          },
        }),
        headerStyle: () => ({ width: '80px' }),
        sort: true,
        align: 'right',
        formatter: (cell) => (
          <span>{cell}</span>
        ),
      },
      {
        dataField: 'totalSalesAmount',
        text: 'Total Sales',
        events: rowEvents,
        headerFormatter,
        headerClasses: 'align-top',
        filter: numberFilter({
          defaultValue: { comparator: Comparator.GT },
          getFilter: filter => {
            this.state.filters[3].filter = filter
          },
          onFilter: filterVal => {
            let filters = this.state.filters
            if (filterVal.number) {
              filters[3].value = `${filterVal.comparator} ${filterVal.number}`
            } else {
              filters[3].value = ''
            }

            // this.setState({ filters });
          },
        }),
        headerStyle: () => ({ width: '100px' }),
        sort: true,
        align: 'right',
        formatter: (cell) => {
          let price = cell

          if (price === null || price === undefined) {
            price = 0
          }

          return (
            <span>
              {price !== 0 ? (
                <span className="k-label-font-color-3 k-font-bold">
                  {'$' + price.toFixed(2)}
                </span>
              ) : (
                <span className="k-label-font-color-3 k-font-bold">{'-'}</span>
              )}
            </span>
          )
        },
      },
      {
        dataField: 'isRegistered',
        text: 'Registered',
        headerFormatter,
        headerClasses: 'align-top',
        events: rowEvents,
        headerStyle: () => ({ width: '100px' }),
        formatter: (cell) => (
          <span
            className={classNames(
              'badge',
              `badge-${cell ? 'success' : 'secondary'}`,
            )}
          >
            {cell ? 'Yes' : 'No'}
          </span>
        ),
      },
      {
        dataField: 'actions',
        text: 'Actions',
        headerAlign: 'right',
        align: 'right',
        headerFormatter,
        headerClasses: 'align-top',
        headerStyle: () => ({ width: '80px' }),
        formatter: (cell, row) => {
          let url = `/customer/detail/${row.email}?store=${this.storeId}`
          return (
            <div>
              {/* <button
                                className="btn btn-outline-secondary btn-sm btn-icon mr-3"
                                title="Delete"
                                onClick={() => this.delete(row.pagesId)}
                            >
                                <i className="la la-trash" />
                            </button> */}

              <Link
                to={url}
                className="btn btn-outline-secondary btn-sm btn-icon"
                title="Edit"
              >
                <i className="la la-search" />
              </Link>
            </div>
          )
        },
      },
    ]

    const options = {
      showTotal: true,
      sizePerPageList: [
        {
          text: '20',
          value: 20,
        },
        {
          text: '100',
          value: 100,
        },
        {
          text: '200',
          value: 200,
        },
        {
          text: '300',
          value: 300,
        },
        {
          text: 'All',
          value: customers.length,
        },
      ],
    }

    return (
      <Portlet>
        <Helmet>
          <title>Customers | Admin Panel - Max Ecommerce</title>
        </Helmet>

        <PortletHeader sticky title="Customers List">
          <Button
            label="Refresh"
            icon="la la-refresh"
            color="secondary"
            className="mr-3"
            onClick={this.onRefresh}
          />
          <Button
            label="Filters"
            icon="la la-filter"
            color="secondary"
            onClick={this.toggleFilter}
            className={classNames('mr-0', {
              active: this.state.isFilterOpen,
            })}
          />
          <CSVLink
            data={customers}
            filename={'customers.csv'}
            className="btn btn-secondary mx-3"
            headers={headers}
          >
            <i className="la la-download" />
            Export
          </CSVLink>
          <Link
            to={`${Routes.CUSTOMER_CREATE}?store=${
              Utils.search(this.props.location).store
            }`}
            className="btn btn-primary"
          >
            <i className="la la-plus" />
            Add new
          </Link>
          {/*
                        <Button
                            label="Publish"
                            icon="la la-sellsy"
                            color="secondary"
                            className="mr-3"
                            onClick={this.onPublish}
                        />
                    */}
        </PortletHeader>
        <PortletBody>
          <Filters
            removeFilter={this.removeFilter}
            clearAll={this.clearFilter}
            filters={this.state.filters}
          />
          {!!customers.length && (
            <BootstrapTable
              bootstrap4
              keyField="contactEmail"
              data={this.state.customers}
              columns={columns}
              hover
              noDataIndication={() => (
                <div className="mt-2">
                  {this.state.refresh ? 'Loading...' : `No ${entity + 's'}`}
                </div>
              )}
              pagination={paginationFactory(options)}
              filter={filterFactory()}
              defaultSorted={defaultSorted}
              onDataSizeChange={this.setFilterState}
            />
          )}
        </PortletBody>
        <Modal isOpen={this.state.isOpen}>
          <ModalHeader>Delete confirmation</ModalHeader>
          <ModalBody>Are you sure want to Delete this Customer?</ModalBody>
          <ModalFooter>
            <Button
              isLoading={this.state.btnLoading}
              label="Confirm"
              color="danger"
              onClick={this.confirm}
            />
            <Button label="Cancel" color="secondary" onClick={this.close} />
          </ModalFooter>
        </Modal>
      </Portlet>
    )
  }
}

export const mapStateToProps = state => ({
  customers: state.customers,
  currentStore: state.currentStore,
})

export const mapDispatchToProps = dispatch => ({
  post: function(path, payload, actionEvent) {
    return dispatch(Actions.post(path, payload, actionEvent))
  },
  storePost: function(path, payload, actionEvent, typeSuccess, typeError) {
    return dispatch(
      Actions.storePost(path, payload, actionEvent, typeSuccess, typeError),
    )
  },
  storeGet: function(path, actionEvent, typeSuccess, typeError) {
    return dispatch(
      Actions.storeGet(path, actionEvent, typeSuccess, typeError),
    )
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Customers)
