import React, { Component } from 'react'

import classNames from 'classnames'

class PortletHeader extends Component {
  render = () => (
    <div
      className={classNames(
        'k-portlet__head',
        { 'k-portlet__head--lg': this.props.lg },
        { 'k-portlet__header--sticky': this.props.sticky },
      )}
    >
      {this.props.title && (
        <div className="k-portlet__head-label">
          {this.props.icon && (
            <span className="k-portlet__head-icon">
              <i className={this.props.icon} />
            </span>
          )}
          <h3 className="k-portlet__head-title">
            {this.props.title}
            {this.props.subtitle && <small>{this.props.subtitle}</small>}
          </h3>
        </div>
      )}
      {this.props.children && (
        <div className="k-portlet__head-toolbar">
          <div className="k-portlet__head-group">{this.props.children}</div>
        </div>
      )}
    </div>
  )
}

export default PortletHeader
