import ActionTypes from './ActionTypes'

export const onHoverAside = hovered => ({
  type: ActionTypes.HOVER_ASIDE,
  payload: hovered,
})

export const onMinimizeAside = minimized => ({
  type: ActionTypes.MINIMIZE_ASIDE,
  payload: minimized,
})

export const onToggleAside = expanded => ({
  type: ActionTypes.TOGGLE_ASIDE,
  payload: expanded,
})

export const onToggleHeader = expanded => ({
  type: ActionTypes.TOGGLE_HEADER,
  payload: expanded,
})

export const onSetBreadcrumb = path => ({
  type: ActionTypes.BREADCRUMB_PATH,
  payload: path,
})

export const onSetLoading = payload => ({
  type: ActionTypes.SET_LOADING,
  payload,
})
