import React, { Component } from 'react'
import ShipmentAsideItem from './ShipmentAsideItem'
import * as Routes from '../../layout/Routes'
import _ from 'lodash'

class ShipmentAside extends Component {
  getItems = () =>
    _.map(Routes.SHIPMENT_OPTIONS, value => {
      if (value.url !== Routes.PACKAGE_SLIPS || this.props.packageslips)
        return (
          <ShipmentAsideItem
            key={value.url}
            url={value.url}
            label={value.label}
            icon={value.icon}
          />
        )
    })

  render = () => {
    let items = this.getItems()

    return (
      <div className="k-portlet settings-aside">
        <div className="k-portlet__body k-portlet__body--fit">
          <ul className="k-nav k-nav--bold k-nav--md-space k-nav--v3 k-margin-t-20 k-margin-b-20 nav nav-tabs">
            {items}
          </ul>
        </div>
      </div>
    )
  }
}

export default ShipmentAside
