import React, { useMemo, useState } from 'react'
import { connect } from 'react-redux'
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js'
import _ from 'lodash'
import { Button, Notification } from '../../../../../Imports'
import * as DataActions from '../../../../../actions/DataActions'

const useOptions = () => {
  const fontSize = '16px'
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: '#424770',
          letterSpacing: '0.025em',
          fontFamily: 'Source Code Pro, monospace',
          '::placeholder': {},
        },
        invalid: {
          color: '#9e2146',
        },
      },
    }),
    [fontSize],
  )

  return options
}

const SplitForm = ({ orderId, post, storeId, fetchOrder, modalToggle }) => {
  const [numberComplete, setNumberComplete] = useState(false)
  const [dateComplete, setDateComplete] = useState(false)
  const [cvvComplete, setCvvComplete] = useState(false)
  const [loading, setLoading] = useState(false)
  const stripe = useStripe()
  const elements = useElements()
  const options = useOptions()

  const handleSubmit = async event => {
    event.preventDefault()
    if (!stripe || !elements) return
    if (!isNotValid()) {
      setLoading(true)
      const card = elements.getElement(CardNumberElement)
      const result = await stripe.createToken(card)
      const token = result.token && result.token.id
  
      if (token) {
        const data = {
          orderId,
          storeId,
          token,
        }
  
        const response = await post(data, 'stripe', '/api/payment')
  
        if (response?.data?.success) {
          Notification.success('Order submitted successfully')
        } else {
          Notification.error(response?.data?.data || 'Unexpected error')
        }
  
        fetchOrder()
        modalToggle()
      }
      setLoading(false)
    }
  }

  const isNotValid = () => !numberComplete || !dateComplete || !cvvComplete || loading

  return (
    <form onSubmit={handleSubmit} className="card-form stripe">
      <label className="card-item card-number">
        <CardNumberElement
          onChange={event => setNumberComplete(event.complete)}
          options={options}
        />
      </label>
      <div className="expiry-fields">
        <label className="card-item">
          <CardExpiryElement
            onChange={event => setDateComplete(event.complete)}
            options={options}
          />
        </label>
        <label className="card-item">
          <CardCvcElement
            onChange={event => setCvvComplete(event.complete)}
            options={options}
          />
        </label>
      </div>
      <Button
        label="Submit"
        color="primary"
        type="submit"
        icon={`la ${loading ? '' : ' la-check'}`}
        disabled={isNotValid()}
        isLoading={loading}
      />
    </form>
  )
}

export const mapStateToProps = state => ({})

export const mapDispatchToProps = dispatch => ({
  post: (payload, actionEvent, pathname) =>
    dispatch(
      DataActions.post(payload, actionEvent, pathname),
    ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SplitForm)
