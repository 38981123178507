import React, { Component } from 'react'
import classNames from 'classnames'

class Widget extends Component {
  render = () => (
    <div
      className={classNames('k-portlet', {
        'k-portlet--height-fluid': this.props.fluid,
      })}
    >
      <div className="k-portlet__body">
        <div className="d-flex mb-5 flex-wrap align-items-center">
          {this.props.title && <h5 className="mb-0">{this.props.title}</h5>}
          {this.props.tools && (
            <div className="ml-auto">{this.props.tools}</div>
          )}
        </div>
        {this.props.children}
      </div>
    </div>
  )
}

export default Widget
