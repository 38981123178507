import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'

class AsideItem extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
    }
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  getItem = (item = null) => {
    if (!item) {
      item = {
        url: this.props.url,
        label: this.props.label,
        icon: this.props.icon,
      }
    }

    return (
      <NavLink
        to={{
          pathname: item.url,
          search: `?store=${this.props.store}`,
        }}
        className="k-menu__link k-menu__toggle"
        activeClassName="k-menu__link--active"
        exact={this.props.exact}
      >
        <i
          className={
            item.icon
              ? `k-menu__link-icon ${item.icon}`
              : 'k-menu__link-bullet k-menu__link-bullet--dot'
          }
        >
          <span />
        </i>
        <span className="k-menu__link-text">{item.label}</span>
      </NavLink>
    )
  }

  render = () =>
    this.props.options ? (
      <li
        className={classNames('k-menu__item k-menu__item--submenu', {
          'k-menu__item--open': this.state.isOpen,
        })}
      >
        <a className="k-menu__link k-menu__toggle" onClick={this.toggle}>
          <i className={`k-menu__link-icon ${this.props.icon}`} />
          <span className="k-menu__link-text">{this.props.label}</span>
          <i className="k-menu__ver-arrow la la-angle-right" />
        </a>
        <div className="k-menu__submenu">
          <ul className="k-menu__subnav">
            {this.props.options.map(o => (
              <li className="k-menu__item" key={o.label}>
                {this.getItem(o)}
              </li>
            ))}
          </ul>
        </div>
      </li>
    ) : (
      <li className="k-menu__item">{this.getItem()}</li>
    )
}

export default AsideItem
