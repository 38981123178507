import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import * as Utils from '../../../utils/Utils'

class ShipmentAsideItem extends Component {
  render = () => {
    let search = Utils.search(this.props.location)

    let storeId = search.store
    let id = search.id

    return (
      <li className="k-nav__item">
        <NavLink
          to={{
            pathname: this.props.url,
            search: `?id=${id}&store=${storeId}`,
          }}
          className="k-nav__link"
          activeClassName="active"
          exact
        >
          <span className="k-nav__link-icon">
            <i className={this.props.icon} />
          </span>
          <span className="k-nav__link-text">{this.props.label}</span>
        </NavLink>
      </li>
    )
  }
}

export default ShipmentAsideItem
