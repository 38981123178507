import {
  React,
  Component,
  Input,
  Comparator,
  DateRangePicker,
} from '../../../Imports'

import moment from 'moment-timezone'

class DateFilter extends Component {
  constructor(props) {
    super(props)

    this.options = [
      { value: Comparator.LE, label: '<=' },
      { value: Comparator.EQ, label: '=' },
      { value: Comparator.GE, label: '>=' },
    ]

    this.state = {
      date: moment().tz(props.timeZone).toDate(),
      endDate: moment().tz(props.timeZone).toDate(),
      comparator: this.options[0],
    }
  }

  comparator = comparator => {
    this.setState({
      comparator,
    })

    this.props.onFilter({
      date: this.state.date,
      endDate: this.state.endDate,
      comparator: comparator.value,
    })
  }

  apply = (e, picker) => {
    const date = picker.startDate.toDate()
    const endDate = picker.endDate.toDate()

    this.setState({
      date,
      endDate,
    })

    this.props.onFilter({
      date,
      endDate,
      comparator: this.state.comparator.value,
    })

    if (this.props.onApply) this.props.onApply({ date })
  }

  render = () => {
    const { timeZone } = this.props

    return (
      <>
        <DateRangePicker
          autoUpdateInput
          opens="left"
          showDropdowns
          linkedCalendars={false}
          onApply={this.apply}
          containerClass="d-block react-bootstrap-daterangepicker-container"
          startDate={moment(this.state.date).tz(timeZone).format('MM/DD/YYYY')}
          endDate={moment(this.state.endDate).tz(timeZone).format('MM/DD/YYYY')}
        >
          <div className="input-group date">
            <Input
              value={
                moment(this.state.date).tz(timeZone).format('MM/DD/YYYY') +
                '-' +
                moment(this.state.endDate).tz(timeZone).format('MM/DD/YYYY')
              }
              placeholder="Select date or range"
              readOnly={true}
              className="px-1"
              style={{ minWidth: '80px', fontSize: 'smaller' }}
            />
          </div>
        </DateRangePicker>
      </>
    )
  }
}

export default DateFilter
