// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
const REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID =
  process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID
const REACT_APP_AWS_USER_POOLS_ID = process.env.REACT_APP_AWS_USER_POOLS_ID
const REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID =
  process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID

const awsmobile = {
  aws_project_region: 'us-west-2',
  aws_cognito_region: 'us-west-2',
  aws_user_pools_id: REACT_APP_AWS_USER_POOLS_ID,
  aws_cognito_identity_pool_id: REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  aws_user_pools_web_client_id: REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
}

export default awsmobile
