import React, { Component } from 'react'
import { components } from 'react-select'

class ProductSearchOption extends Component {
  constructor(props) {
    super(props)
  }

  render = () => {
    let p = this.props.data.object || this.props.data
    let price = p.showSalePrice ? p.salePrice : p.retailPrice

    if (price == null || price == undefined) {
      price = 0
    }

    return (
      <components.Option {...this.props} className="d-flex align-items-center">
        <div className="k-user-card-v2">
          <div>
            <img
              width="25px"
              src={p.thumbnail}
              className="mr-2"
              alt=""
            />
          </div>
          <div className="k-user-card-v2__details">
            <span className="">{`${p.name} $${price.toFixed(2)}`}</span>
            {/* <p className="k-user-card-v2__email mb-0">
                            {`${p.length || 0}'L x ${p.width || 0}'W x ${p.height || 0}'H`}
                        </p> */}
          </div>
        </div>
      </components.Option>
    )
  }
}

export default ProductSearchOption
