import React, { Component } from 'react'

import classNames from 'classnames'

class Button extends Component {
  render = () => (
    <button
      className={classNames(
        this.props.className,
        'btn',
        `btn-${this.props.color}`,
        { 'k-spinner k-spinner--sm k-spinner--light': this.props.isLoading },
      )}
      disabled={this.props.disabled}
      onClick={e => {
        if (this.props.onClick) this.props.onClick(e)
      }}
    >
      {this.props.icon && <i className={this.props.icon} />}
      {this.props.label}
    </button>
  )
}

export default Button
