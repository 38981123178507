import React, {useState} from 'react'
import { Input } from '../../../Imports'

function CustomTextFilter ({onFilter, column, previousFilter, updateFilterBy}) {
  const columnName = column.dataField
  const [filter, setFilter] = useState(previousFilter)

    const onSearchClick = async ()  => {
      await updateFilterBy(columnName)
      onFilter(filter)
    }

    return (<div className="d-flex" onClick={(e) => e.stopPropagation()}>
      <Input onChange={(e)=>setFilter( e.target.value)} value={filter} />
      <button
        className="btn btn-outline-secondary btn-icon"
        title="Search"
        onClick={onSearchClick}
      >
        <i className="la la-search" />
      </button>
    </div>)

}

export default CustomTextFilter
