import {
  React,
  Component,
  connect,
  Actions,
  ActionTypes,
  Portlet,
  PortletHeader,
  PortletBody,
  Section,
  Select,
  FormGroup,
  Input,
  Label,
  FormFeedback,
  Utils,
  Notification,
  // Routes,
  // Link,
  FormValidator,
  Separator,
  Button,
  classNames,
  _,
} from '../../../Imports'
import { Helmet } from 'react-helmet'
import Dropzone from 'react-dropzone'
import axios from 'axios'
import moment from 'moment-timezone'

const entity = 'Profile'
const allowedFiles = 'image/jpeg, image/png, .svg'

class Profile extends Component {
  constructor(props) {
    super(props)

    this.validator = new FormValidator([
      {
        field: 'name',
        method: 'isEmpty',
        validWhen: false,
        message: 'The name is required.',
      },
      {
        field: 'slogan',
        method: 'isEmpty',
        validWhen: false,
        message: 'The Slogan is required.',
      },
      {
        field: 'businessPrimaryPhone',
        method: 'isEmpty',
        validWhen: false,
        message: 'The Phone is required.',
      },
      {
        field: 'businessPrimaryEmailId',
        method: 'isEmpty',
        validWhen: false,
        message: 'The Email is required.',
      },
      {
        field: 'description',
        method: 'isEmpty',
        validWhen: false,
        message: 'The Description is required.',
      },
      {
        field: 'address',
        method: 'isEmpty',
        validWhen: false,
        message: 'The Address is required.',
      },
      {
        field: 'zipcode',
        method: 'isEmpty',
        validWhen: false,
        message: 'The Zip code is required.',
      },
      {
        field: 'city',
        method: 'isEmpty',
        validWhen: false,
        message: 'The City code is required.',
      },
      {
        field: 'state',
        method: 'isEmpty',
        validWhen: false,
        message: 'The State code is required.',
      },
    ])

    this.state = {
      setting: null,
      submited: false,
      isCreate: false,
      btnLoading: false,
      loadingImgcompany: false,
      bccEmail: '',
    }
  }

  UNSAFE_componentWillMount = () => {
    let storeId = Utils.search(this.props.location).store

    if (!this.props.settings.data) {
      this.props.storePost(
        { id: storeId },
        'get-store',
        ActionTypes.FETCH_SETTINGS_SUCCESS,
        ActionTypes.FETCH_SETTINGS_FAILURE,
      ).then(resp => {
        this.setState({
          setting: resp.data,
        })
      })
    } else {
      this.setState({
        setting: this.props.settings.data,
      })
    }
  }

  onChangeBcc = e => this.setState({ bccEmail: e.target.value })

  addBcc = event => {
    if ((!event.keyCode || event.keyCode === 13) && this.state.bccEmail) {
      this.setState(({ setting, bccEmail }) => ({
        bccEmail: '',
        setting: {
          ...setting,
          emailConf: {
            ...setting.emailConf,
            bcc: [...(setting?.emailConf?.bcc || []), bccEmail],
          },
        },
      }))
    }
  }

  deleteBccEmail = email => () => this.setState(({ setting }) => ({
    setting: {
      ...setting,
      emailConf: {
        ...setting.emailConf,
        bcc: (setting?.emailConf?.bcc || []).filter(bccEmail => bccEmail !== email),
      },
    },
  }))

  onChangeInput = e => {
    let setting = this.state.setting
    setting[e.target.name] = e.target.value

    this.setState({ setting })
  }

  onChangeSelect = (e, name) => {
    let setting = this.state.setting
    setting[name] = e.value

    this.setState({ setting })
  }

  update = () => {
    this.setState({
      submited: !this.state.submited,
    })
    let isValid = this.validator.validate(this.state.setting).isValid

    if (isValid) {
      this.setState({ btnLoading: true })
      this.props.post(this.state.setting, 'update-store').then(resp => {
        this.response(resp)
      })
    }
  }

  onDrop = async (accepted, rejected, e) => {
    const field = e.target.name
    const loadingImg = 'loadingImg' + field
    this.setState({
      [loadingImg]: true,
    })

    if (rejected.length > 0) {
      this.setState({
        [loadingImg]: false,
      })

      return
    }

    if (accepted.length > 0) {
      let file = accepted[0]

      let fileName = `${
        this.state.setting.id
      }/images/${moment().valueOf()}-${_.toLower(file.name)}`
      let fileType = file.type

      let config = {
        headers: {
          'Content-Type': fileType,
        },
      }

      let payload = {
        fileName,
      }

      let resp = await this.props.post(payload, 'generate-url')
      let url = resp.data

      axios
        .put(url, file, config)
        .then(async response => {
          const setting = {
            ...this.state.setting,
            logoUrl: {
              ...this.state.setting.logoUrl,
              [field]: 'https://d2d8dl1lrybgkf.cloudfront.net/' + fileName,
            },
          }
          this.setState({ setting, [loadingImg]: false })
        })
        .catch(error => {
          this.setState({
            [loadingImg]: false,
          })

          console.log('ERROR: ', error)
          Notification.error('File failed to upload.')
        })
    }
  }

  response = resp => {
    this.setState({
      btnLoading: false,
    })

    let msn = _.replace(
      _.replace(process.env.REACT_APP_SUCCESS_ACTION, '%entity%', entity),
      '%action%',
      'Updated',
    )

    if (resp.status === 'success') {
      Notification.success(msn)
    } else {
      Notification.error(process.env.REACT_APP_ERROR_ACTION)
    }
  }

  render = () => {
    const { bccEmail } = this.state
    const validator = this.state.submited
      ? this.validator.validate(this.state.setting)
      : this.validator.valid()

    return (
      <Portlet>
        <Helmet>
          <title>Profile | Admin Panel - Max Ecommerce</title>
        </Helmet>
        <PortletHeader
          title={`Profile - ${_.get(this.state.setting, 'name', '')}`}
          sticky
        >
          {/*
					<Link to={Routes.STORES} className="btn btn-secondary mr-3">
						<i className="la la-arrow-left" />
						Back
					</Link>
					*/}
          <Button
            label={'Update'}
            color="primary"
            icon={`la ${this.state.btnLoading ? '' : ' la-check'}`}
            isLoading={this.state.btnLoading}
            onClick={this.update}
          />
        </PortletHeader>
        <PortletBody>
          {this.state.setting === null && (
            <div className="row">
              <div className="col-12">
                <div>Loading...</div>
              </div>
            </div>
          )}

          {this.state.setting !== null && (
            <div className="row">
              <div className="col-xl-1" />
              <div className="col-xl-10">
                <Section title="General Information" size="lg">
                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">Name * </Label>
                    <div className="col-9">
                      <Input
                        value={this.state.setting.name}
                        onChange={this.onChangeInput}
                        name="name"
                        invalid={validator.name.isInvalid}
                      />
                      <FormFeedback>{validator.name.message}</FormFeedback>
                    </div>
                  </FormGroup>

                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">Slogan * </Label>
                    <div className="col-9">
                      <Input
                        value={this.state.setting.slogan}
                        onChange={this.onChangeInput}
                        name="slogan"
                        invalid={validator.slogan.isInvalid}
                      />
                      <FormFeedback>{validator.slogan.message}</FormFeedback>
                    </div>
                  </FormGroup>

                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">
                      Description *{' '}
                    </Label>
                    <div className="col-9">
                      <Input
                        value={this.state.setting.description}
                        onChange={this.onChangeInput}
                        name="description"
                        type="textarea"
                        invalid={validator.description.isInvalid}
                      />
                      <FormFeedback>
                        {validator.description.message}
                      </FormFeedback>
                    </div>
                  </FormGroup>
                </Section>

                <Separator size="lg" border={'dashed'} />
                <Section title="Contact Information" size="lg">
                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">Phone * </Label>
                    <div className="col-9">
                      <Input
                        value={this.state.setting.businessPrimaryPhone}
                        onChange={this.onChangeInput}
                        name="businessPrimaryPhone"
                        invalid={validator.businessPrimaryPhone.isInvalid}
                      />
                      <FormFeedback>
                        {validator.businessPrimaryPhone.message}
                      </FormFeedback>
                    </div>
                  </FormGroup>

                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">Email * </Label>
                    <div className="col-9">
                      <Input
                        value={this.state.setting.businessPrimaryEmailId}
                        onChange={this.onChangeInput}
                        name="businessPrimaryEmailId"
                        invalid={validator.businessPrimaryEmailId.isInvalid}
                      />
                      <FormFeedback>
                        {validator.businessPrimaryEmailId.message}
                      </FormFeedback>
                    </div>
                  </FormGroup>
                </Section>
                <Section title="Email Settings" size="lg">
                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">BCC Email </Label>
                    <div className="col-9 values-section">
                      <div>
                        <Input
                          value={bccEmail}
                          onChange={this.onChangeBcc}
                          onKeyDown={this.addBcc}
                        />
                        <Button label="Add" color="primary" onClick={this.addBcc} />
                      </div>
                      <div>
                        {this.state.setting?.emailConf?.bcc?.map((email, index) => (
                          <div key={index}>
                            <span>{email}</span>
                            <div onClick={this.deleteBccEmail(email)} />
                          </div>
                        ))}
                      </div>
                    </div>
                  </FormGroup>
                </Section>
                <Separator size="lg" border={'dashed'} />
                <Section title="Location" size="lg">
                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">Address * </Label>
                    <div className="col-9">
                      <Input
                        value={this.state.setting.address}
                        onChange={this.onChangeInput}
                        name="address"
                        invalid={validator.address.isInvalid}
                      />
                      <FormFeedback>{validator.address.message}</FormFeedback>
                    </div>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">Zipcode *</Label>
                    <div className="col-9">
                      <Input
                        value={this.state.setting.zipcode}
                        onChange={this.onChangeInput}
                        name="zipcode"
                        invalid={validator.zipcode.isInvalid}
                      />
                      <FormFeedback>{validator.zipcode.message}</FormFeedback>
                    </div>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">City * </Label>
                    <div className="col-9">
                      <Input
                        value={this.state.setting.city}
                        onChange={this.onChangeInput}
                        name="city"
                        invalid={validator.city.isInvalid}
                      />
                      <FormFeedback>{validator.city.message}</FormFeedback>
                    </div>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">State * </Label>
                    <div className="col-9">
                      <Select
                        options={this.props.states}
                        value={Utils.object(
                          this.props.states,
                          this.state.setting.state,
                        )}
                        onChange={e => this.onChangeSelect(e, 'state')}
                        className={
                          validator.state.isInvalid ? 'is-invalid' : ''
                        }
                        isSearchable={true}
                      />

                      <FormFeedback>{validator.state.message}</FormFeedback>
                    </div>
                  </FormGroup>
                </Section>
                <Separator size="lg" border={'dashed'} />
                <Section title="Upload Logos" size="lg">
                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">Website Logo</Label>
                    <div className="product-img">
                      <img
                        className="img"
                        src={this.state.setting.logoUrl.company}
                      ></img>
                    </div>
                    <div>
                      {this.state.loadingImgcompany && (
                        <i className="product-img-placeholder" />
                      )}
                    </div>
                  </FormGroup>
                  <div
                    className={classNames('dropZone', {
                      'dropZone-invalid': false,
                    })}
                  >
                    <Dropzone
                      disabled={this.state.loadingImgcompany}
                      onDrop={this.onDrop}
                      accept={allowedFiles}
                      multiple={false}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <div {...getRootProps()}>
                            <input name="company" {...getInputProps()} />
                            <p className="dropZone-msg">
                              {allowedFiles} (BROWSE)
                            </p>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  </div>

                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">Email Logo</Label>
                    <div className="product-img">
                      <img
                        className="img"
                        src={this.state.setting.logoUrl.email}
                      ></img>
                    </div>
                    <div>
                      {this.state.loadingImgemail && (
                        <i className="product-img-placeholder" />
                      )}
                    </div>
                  </FormGroup>
                  <div
                    className={classNames('dropZone', {
                      'dropZone-invalid': false,
                    })}
                  >
                    <Dropzone
                      disabled={this.state.loadingImgemail}
                      onDrop={this.onDrop}
                      accept={allowedFiles}
                      multiple={false}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <div {...getRootProps()}>
                            <input name="email" {...getInputProps()} />
                            <p className="dropZone-msg">
                              {allowedFiles} (BROWSE)
                            </p>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  </div>
                </Section>
              </div>
              <div className="col-xl-1" />
            </div>
          )}
        </PortletBody>
      </Portlet>
    )
  }
}

export const mapStateToProps = state => ({
  settings: state.settings,
  states: state.states,
})

export const mapDispatchToProps = dispatch => ({
  post: function(payload, actionEvent) {
    return dispatch(Actions.post(payload, actionEvent))
  },
  storeGet: function(actionEvent, typeSuccess, typeError) {
    return dispatch(Actions.storeGet(actionEvent, typeSuccess, typeError))
  },
  storePost: function(payload, actionEvent, typeSuccess, typeError) {
    return dispatch(
      Actions.storePost(payload, actionEvent, typeSuccess, typeError),
    )
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
