import _ from 'lodash'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import MaskedInput from 'react-text-mask'
import {
  Section,
  Portlet,
  PortletHeader,
  PortletBody,
  FormGroup,
  Input,
  Select,
  Label,
  Utils,
  Button,
} from '../../../Imports'
import DropZone from '../dropzone/DropZone'
import UnitSelect from '../select/UnitSelect'

const EditForm = ({ editVariant, setVariant, variant, loading, onClose }) => {
  // Selectors
  const categories = useSelector(state => (
    (state?.productCategories?.data || []).map(({ id, label }) => ({
      value: id,
      label: _.capitalize(label),
    }))
  ))
  // State
  const [imgLoading, setImgLoading] = useState(false)

  const onChangeHandle = event => {
    setVariant(prevVariant => ({
      ...prevVariant,
      [event.target.name]: event.target.value,
    }))
  }

  const onChangeCheckbox = event => {
    setVariant(prevVariant => ({
      ...prevVariant,
      [event.target.name]: !prevVariant[event.target.name],
    }))
  }

  const onImageChange = thumbnail => {
    setVariant(prevVariant => ({
      ...prevVariant,
      thumbnail,
    }))
  }

  const onDimentionChange = (event, value) => {
    setVariant(prevVariant => ({
      ...prevVariant,
      dimension: {
        ...(prevVariant.dimension || {}),
        [event.target.name]: +event.target.value || value,
      },
    }))
  }

  const onChangeMultiSelect = name => event => setVariant(prevVariant => ({
    ...prevVariant,
    [name]: event.map(({ value }) => value),
  }))

  return (
    <Portlet>
      <PortletHeader title="Edit">
        <Button
          label="Back"
          className="btn btn-secondary mr-3"
          icon="la la-arrow-left"
          onClick={onClose}
          disabled={loading || imgLoading}
        />
        <Button
          label="Save"
          color="primary"
          icon={`la ${loading ? '' : ' la-check'}`}
          isLoading={loading}
          disabled={loading || imgLoading}
          onClick={editVariant}
        />
      </PortletHeader>
      <PortletBody>
        <Section>
          <FormGroup className="row mb-3">
            <Label className="col-3 col-form-label">Complex Variant</Label>
            <div className="col-9">
              <span className="k-switch k-switch--md k-switch--icon">
                <label>
                  <Input
                    checked={variant?.isComplexVariant ? 'checked' : ''}
                    onChange={event => {
                      onChangeCheckbox(event)
                      if (variant?.isComplexVariant) {
                        setVariant(prevVariant => ({
                          ...prevVariant,
                          url: '',
                        }))
                      }
                    }}
                    name="isComplexVariant"
                    type="checkbox"
                  />
                  <span />
                </label>
              </span>
            </div>
          </FormGroup>
          <FormGroup className="row mb-3">
            <Label className="col-3 col-form-label">Name</Label>
            <div className="col-9">
              <Input
                value={variant?.name || ''}
                onChange={onChangeHandle}
                name="name"
              />
            </div>
          </FormGroup>
          <DropZone
            image={variant?.thumbnail}
            onChange={onImageChange}
            onLoading={setImgLoading}
          />
          <FormGroup className="row">
            <Label className="col-3 col-form-label">
              Categories
            </Label>
            <div className="col-9">
              <Select
                options={categories}
                value={Utils.multiObject(categories, variant?.categories || [])}
                onChange={onChangeMultiSelect('categories')}
                closeMenuOnSelect={false}
                isMulti
              />
            </div>
          </FormGroup>
          <FormGroup className="row mb-3">
            <Label className="col-3 col-form-label">SKU</Label>
            <div className="col-9">
              <Input
                value={variant?.sku || ''}
                onChange={onChangeHandle}
                name="sku"
              />
            </div>
          </FormGroup>
          <FormGroup className="row mb-3">
            <Label className="col-3 col-form-label">Show Price</Label>
            <div className="col-9">
              <span className="k-switch k-switch--md k-switch--icon">
                <label>
                  <Input
                    checked={variant?.showRetailPrice ? 'checked' : ''}
                    onChange={onChangeCheckbox}
                    name="showRetailPrice"
                    type="checkbox"
                  />
                  <span />
                </label>
              </span>
            </div>
          </FormGroup>
          <FormGroup className="row mb-3">
            <Label className="col-3 col-form-label">Price</Label>
            <div className="col-9">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">$</span>
                </div>
                <MaskedInput
                  name="retailPrice"
                  value={variant?.retailPrice || ''}
                  onChange={onChangeHandle}
                  mask={Utils.currencyMask}
                  className="form-control"
                />
              </div>
            </div>
          </FormGroup>
          <FormGroup className="row mb-3">
            <Label className="col-3 col-form-label">Show Sale Price</Label>
            <div className="col-9">
              <span className="k-switch k-switch--md k-switch--icon">
                <label>
                  <Input
                    checked={variant?.showSalePrice ? 'checked' : ''}
                    onChange={onChangeCheckbox}
                    name="showSalePrice"
                    type="checkbox"
                  />
                  <span />
                </label>
              </span>
            </div>
          </FormGroup>
          <FormGroup className="row mb-3">
            <Label className="col-3 col-form-label">Sale Price</Label>
            <div className="col-9">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">$</span>
                </div>
                <MaskedInput
                  name="salePrice"
                  value={variant?.salePrice || ''}
                  onChange={onChangeHandle}
                  mask={Utils.currencyMask}
                  className="form-control"
                />
              </div>
            </div>
          </FormGroup>
          <FormGroup className="row mb-3">
            <Label className="col-3 col-form-label">Weight</Label>
            <div className="col-9">
              <div className="input-group">
                <UnitSelect
                  onChange={onDimentionChange}
                  value={variant?.dimension?.weightUnit}
                  name="weightUnit"
                  isWeight
                />
                <MaskedInput
                  name="weight"
                  value={variant?.dimension?.weight || ''}
                  onChange={onDimentionChange}
                  mask={Utils.currencyMask}
                  className="form-control"
                />
              </div>
            </div>
          </FormGroup>
          <FormGroup className="row mb-3">
            <Label className="col-3 col-form-label">Inventory</Label>
            <div className="col-9">
              <Input
                name="inventory"
                value={variant?.inventory || ''}
                onChange={onChangeHandle}
                name="inventory"
              />
            </div>
          </FormGroup>
          <FormGroup className="row mb-3">
            <Label className="col-3 col-form-label">URL</Label>
            <div className="col-9">
              <Input
                value={variant?.url || ''}
                onChange={onChangeHandle}
                disabled={!variant?.isComplexVariant}
                name="url"
              />
            </div>
          </FormGroup>
          <FormGroup className="row mb-3">
            <Label className="col-3 col-form-label">Stock</Label>
            <div className="col-9">
              <span className="k-switch k-switch--md k-switch--icon">
                <label>
                  <Input
                    checked={variant?.inStock ? 'checked' : ''}
                    onChange={onChangeCheckbox}
                    name="inStock"
                    type="checkbox"
                  />
                  <span />
                </label>
              </span>
            </div>
          </FormGroup>
          <FormGroup className="row mb-3">
            <Label className="col-3 col-form-label">Status</Label>
            <div className="col-9">
              <span className="k-switch k-switch--md k-switch--icon">
                <label>
                  <Input
                    checked={variant?.status ? 'checked' : ''}
                    onChange={onChangeCheckbox}
                    name="status"
                    type="checkbox"
                  />
                  <span />
                </label>
              </span>
            </div>
          </FormGroup>
        </Section>
      </PortletBody>
    </Portlet>
  )
}

export default EditForm
