import {
  React,
  Component,
  connect,
  Actions,
  Portlet,
  PortletHeader,
  PortletBody,
  Section,
  Select,
  FormGroup,
  Input,
  Label,
  FormFeedback,
  Utils,
  Notification,
  Link,
  FormValidator,
  Separator,
  Button,
  ActionTypes,
  UiActions,
  _,
} from '../../../Imports'
import { Helmet } from 'react-helmet'
import ReactSummernote from 'react-summernote'
import 'react-summernote/dist/react-summernote.css'
import 'bootstrap'
const entity = 'Page'

class PageForm extends Component {
  constructor(props) {
    super(props)

    this.validator = new FormValidator([
      {
        field: 'name',
        method: 'isEmpty',
        validWhen: false,
        message: 'The name is required.',
      },
    ])

    this.storeId = Utils.search(this.props.location).store

    this.state = {
      page: null,
      submited: false,
      isCreate: false,
      btnLoading: false,
      categoryOptions: [],
    }
  }

  UNSAFE_componentWillMount = async () => {
    let storeId = Utils.search(this.props.location).store

    if (this.props.pages.data.length === 0) {
      await this.props.storePost(
        {
          storeId: storeId,
        },
        'list-pages',
        ActionTypes.FETCH_PAGES_SUCCESS,
        ActionTypes.FETCH_PAGES_FAILURE,
      )
    }

    // Get categories
    if (this.props.categories.data.length === 0) {
      await this.props.storePost(
        {
          storeId: storeId,
        },
        'list-categories',
        ActionTypes.FETCH_CATEGORIES_SUCCESS,
        ActionTypes.FETCH_CATEGORIES_FAILURE,
      )
    }

    let categoryOptions = _.map(this.props.categories.data, c => ({
      value: c.id,
      id: c.id,
      label: c.label,
    }))

    this.setState({ categoryOptions })

    this.setState(
      { isCreate: Utils.isCreate(this.props.location.pathname) },
      () => {
        if (this.state.isCreate) {
          this.setState({ page: {} })
        } else {
          this.props
            .post(
              {
                storeId: storeId,
                id: this.props.match.params.id,
              },
              'get-page',
            )
            .then(resp => {
              this.setState({
                page: resp.data,
              })
            })
        }
      },
    )
  }

  onChangeInput = e => {
    let page = this.state.page
    page[e.target.name] = e.target.value

    this.setState({ page })
  }

  onChangeSelect = (e, name) => {
    let page = this.state.page
    page[name] = e.value

    this.setState({ page })
  }

  onChangeSelectObject = (e, name) => {
    let page = this.state.page
    page[name] = e
    this.setState({ page })
  }

  onChangeCheckbox = e => {
    let page = this.state.page
    page[e.target.name] = !_.get(page, e.target.name, false)

    this.setState({ page })
  }

  onChangeLinkActive = () => {
    this.setState(({ page }) => ({
      page: {
        ...page,
        linkActive: !page.url ? false : !page.linkActive,
      },
    }))
  }

  onChangeFeatured = () => {
    this.setState(({ page }) => ({
      page: {
        ...page,
        featured: !page.featured,
      },
    }))
  }

  onChangeUrl = e => {
    const { name, value } = e.target
    this.setState(({ page }) => ({
      page: {
        ...page,
        linkActive: !value && page.linkActive ? false : page.linkActive,
        [name]: value,
      },
    }))
  }

  onChangePosition = e => {
    let page = this.state.page
    let positions =
      _.get(page, 'positions', null) === null ? [] : page.positions

    if (_.indexOf(positions, e.target.value) !== -1) {
      positions = _.filter(positions, function(value) {
        return value !== e.target.value
      })
    } else {
      positions.push(e.target.value)
    }

    page.positions = positions
    this.setState({ page })
  }

  onChangeHtml = value => {
    let page = this.state.page
    page.htmlBody = value
    this.setState({ page })
  }

  action = () => {
    this.setState({
      submited: !this.state.submited,
    })
    let isValid = this.validator.validate(this.state.page).isValid
    let action = this.state.isCreate ? 'create-page' : 'update-page'
    let storeId = Utils.search(this.props.location).store

    if (isValid) {
      this.props.onSetLoading(true)
      this.setState({ btnLoading: true })
      this.props
        .post(
          {
            ...this.state.page,
            storeId,
          },
          action,
        )
        .then(resp => {
          this.response(resp)
        })
    }
  }

  response = resp => {
    this.props.onSetLoading(false)
    this.setState({ btnLoading: false })

    let msn = _.replace(
      _.replace(process.env.REACT_APP_SUCCESS_ACTION, '%entity%', entity),
      '%action%',
      this.state.isCreate ? 'Created' : 'Updated',
    )

    if (resp.status === 'success') {
      Notification.success(msn)

      if (this.state.isCreate) {
        this.props.history.push('/page/detail/' + resp.data.id)
      }
    } else {
      Notification.error(process.env.REACT_APP_ERROR_ACTION)
    }
  }

  close = () => {
    this.setState({ isOpen: false })
  }

  getPageOption = () =>
    _.map(this.props.pages.data, function(p) {
      return { value: p.id, label: p.name }
    })

  render = () => {
    const validator = this.state.submited
      ? this.validator.validate(this.state.page)
      : this.validator.valid()

    let toolbar = [
      ['style', ['style']],
      ['font', ['bold', 'underline', 'clear']],
      ['fontname', ['fontname']],
      ['para', ['ul', 'ol', 'paragraph']],
      ['table', ['table']],
      ['insert', ['link', 'picture', 'video']],
      ['view', ['fullscreen', 'codeview']],
    ]

    let pageOptions = this.getPageOption()

    return (
      <Portlet>
        <Helmet>
          <title>Page | Admin Panel - Max Ecommerce</title>
        </Helmet>
        <PortletHeader
          title={this.state.isCreate ? 'New Page' : 'Update Page'}
          sticky
        >
          <Link
            to={`/pages?store=${Utils.search(this.props.location).store}`}
            className="btn btn-secondary mr-3"
          >
            <i className="la la-arrow-left" />
            Back
          </Link>
          <Button
            label={this.state.isCreate ? 'Create' : 'Update'}
            color="primary"
            icon={`la ${this.state.btnLoading ? '' : ' la-check'}`}
            isLoading={this.state.btnLoading}
            onClick={this.action}
          />
        </PortletHeader>
        <PortletBody>
          {this.state.page === null && (
            <div className="row">
              <div className="col-12">
                <div>Loading...</div>
              </div>
            </div>
          )}

          {this.state.page !== null && (
            <div>
              <div className="row">
                <div className="col-xl-2" />
                <div className="col-xl-8">
                  <Section title="Page Information">
                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">
                        Page active
                      </Label>
                      <div className="col-9">
                        <span className="k-switch k-switch--md k-switch--icon">
                          <label>
                            <Input
                              checked={this.state.page.active ? 'checked' : ''}
                              onChange={this.onChangeCheckbox}
                              name="active"
                              type="checkbox"
                            />
                            <span />
                          </label>
                        </span>
                      </div>
                    </FormGroup>
                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">Name * </Label>
                      <div className="col-9">
                        <Input
                          value={this.state.page.name || ''}
                          onChange={this.onChangeInput}
                          name="name"
                          invalid={validator.name.isInvalid}
                        />
                        <FormFeedback>{validator.name.message}</FormFeedback>
                      </div>
                    </FormGroup>
                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">Body Title</Label>
                      <div className="col-9">
                        <Input
                          value={this.state.page.urlTitle || ''}
                          onChange={this.onChangeInput}
                          name="urlTitle"
                        />
                      </div>
                    </FormGroup>
                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">Template</Label>
                      <div className="col-9">
                        <Select
                          options={this.props.pageCategories}
                          value={Utils.object(
                            this.props.pageCategories,
                            this.state.page.category,
                          )}
                          onChange={e => this.onChangeSelect(e, 'category')}
                          className={
                            validator.name.isInvalid ? 'is-invalid' : ''
                          }
                          isSearchable={true}
                        />
                        <FormFeedback>{validator.name.message}</FormFeedback>
                      </div>
                    </FormGroup>

                    {_.get(this.state.page, 'category', '') === 'CATALOG' && (
                      <>
                        <FormGroup className="row">
                          <Label className="col-3 col-form-label">
                            Category
                          </Label>
                          <div className="col-9">
                            <Select
                              options={this.state.categoryOptions}
                              value={this.state.page.productCategory}
                              onChange={e =>
                                this.onChangeSelectObject(e, 'productCategory')
                              }
                              isSearchable={true}
                            />
                            {/*
                                                    <FormFeedback>
                                                        {validator.name.message}
                                                    </FormFeedback>*/}
                          </div>
                        </FormGroup>
                        <FormGroup className="row">
                          <Label className="col-3 col-form-label">
                            Featured
                          </Label>
                          <div className="col-9">
                            <span className="k-switch k-switch--md k-switch--icon">
                              <label>
                                <Input
                                  checked={
                                    this.state.page.featured ? 'checked' : ''
                                  }
                                  onChange={this.onChangeFeatured}
                                  name="featured"
                                  type="checkbox"
                                />
                                <span />
                              </label>
                            </span>
                          </div>
                        </FormGroup>
                        {this.state.page.featured && (
                          <FormGroup className="row">
                            <Label className="col-3 col-form-label">
                              Featured Image Url
                            </Label>
                            <div className="col-9">
                              <Input
                                value={this.state.page.featuredImageUrl || ''}
                                onChange={this.onChangeInput}
                                name="featuredImageUrl"
                              />
                            </div>
                          </FormGroup>
                        )}
                      </>
                    )}

                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">Parent</Label>
                      <div className="col-9">
                        <Select
                          options={[
                            { value: null, label: 'NONE' },
                            ...pageOptions,
                          ]}
                          value={Utils.object(
                            pageOptions,
                            this.state.page.parent,
                          )}
                          onChange={e => this.onChangeSelect(e, 'parent')}
                          className={
                            validator.name.isInvalid ? 'is-invalid' : ''
                          }
                          isSearchable={true}
                        />
                        <FormFeedback>{validator.name.message}</FormFeedback>
                      </div>
                    </FormGroup>
                  </Section>
                  <Separator size="lg" border={'dashed'} />
                  <Section title="Navigation" size="lg">
                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">Sort</Label>
                      <div className="col-9">
                        <Input
                          value={this.state.page.sort || ''}
                          type="number"
                          onChange={this.onChangeInput}
                          name="sort"
                        />
                      </div>
                    </FormGroup>
                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">Position</Label>
                      <div className="col-9">
                        <div className="k-checkbox-list">
                          <label className="k-checkbox">
                            <Input
                              checked={
                                _.indexOf(this.state.page.positions, 'TOP') !==
                                -1
                                  ? 'checked'
                                  : ''
                              }
                              type="checkbox"
                              onChange={this.onChangePosition}
                              name="position"
                              value="TOP"
                            />
                            Top
                            <span />
                          </label>
                          <label className="k-checkbox">
                            <Input
                              type="checkbox"
                              checked={
                                _.indexOf(this.state.page.positions, 'LEFT') !==
                                -1
                                  ? 'checked'
                                  : ''
                              }
                              onChange={this.onChangePosition}
                              name="position"
                              value="LEFT"
                            />
                            Left
                            <span />
                          </label>
                          <label className="k-checkbox">
                            <Input
                              type="checkbox"
                              checked={
                                _.indexOf(
                                  this.state.page.positions,
                                  'BOTTOM',
                                ) !== -1
                                  ? 'checked'
                                  : ''
                              }
                              onChange={this.onChangePosition}
                              name="position"
                              value="BOTTOM"
                            />
                            Bottom
                            <span />
                          </label>
                        </div>
                        <FormFeedback>{validator.name.message}</FormFeedback>
                      </div>
                    </FormGroup>
                  </Section>

                  <Separator size="lg" border={'dashed'} />

                  <Section title="Search engine" size="lg">
                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">URL</Label>
                      <div className="col-9">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">Http</span>
                          </div>
                          <Input
                            value={this.state.page.url || ''}
                            onChange={this.onChangeUrl}
                            name="url"
                          />
                        </div>
                      </div>
                    </FormGroup>
                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">
                        Link active
                      </Label>
                      <div className="col-9">
                        <span className="k-switch k-switch--md k-switch--icon">
                          <label>
                            <Input
                              checked={
                                this.state.page.linkActive ? 'checked' : ''
                              }
                              onChange={this.onChangeLinkActive}
                              name="linkActive"
                              type="checkbox"
                            />
                            <span />
                          </label>
                        </span>
                      </div>
                    </FormGroup>
                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">Title</Label>
                      <div className="col-9">
                        <Input
                          value={this.state.page.title || ''}
                          onChange={this.onChangeInput}
                          name="title"
                        />
                      </div>
                    </FormGroup>
                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">
                        Meta Keywords
                      </Label>
                      <div className="col-9">
                        <Input
                          value={this.state.page.metaKeywords || ''}
                          onChange={this.onChangeInput}
                          name="metaKeywords"
                        />
                      </div>
                    </FormGroup>
                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">
                        Meta Description
                      </Label>
                      <div className="col-9">
                        <Input
                          value={this.state.page.metaDescription || ''}
                          onChange={this.onChangeInput}
                          name="metaDescription"
                        />
                      </div>
                    </FormGroup>
                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">Canonical</Label>
                      <div className="col-9">
                        <Input
                          value={this.state.page.canonical || ''}
                          onChange={this.onChangeInput}
                          name="canonical"
                        />
                      </div>
                    </FormGroup>
                  </Section>

                  <Separator size="lg" border={'dashed'} />

                  <Section title="Description">
                    <FormGroup>
                      <ReactSummernote
                        children={
                          (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: this.state.page.htmlBody,
                              }}
                            ></div>
                          ) || ''
                        }
                        onChange={this.onChangeHtml}
                        options={{
                          height: 150,
                          dialogsInBody: true,
                          popover: {
                            image: [],
                            link: [],
                            air: [],
                          },
                          toolbar: toolbar,
                        }}
                      />
                    </FormGroup>
                  </Section>
                </div>
                <div className="col-xl-2" />
              </div>
            </div>
          )}
        </PortletBody>
      </Portlet>
    )
  }
}

export const mapStateToProps = state => ({
  pages: state.pages,
  categories: state.productCategories,
  pageCategories: state.pageCategories,
})

export const mapDispatchToProps = dispatch => ({
  post: function(payload, actionEvent) {
    return dispatch(Actions.post(payload, actionEvent))
  },
  storePost: function(payload, actionEvent, typeSuccess, typeError) {
    return dispatch(
      Actions.storePost(payload, actionEvent, typeSuccess, typeError),
    )
  },
  onSetLoading: payload => dispatch(UiActions.onSetLoading(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PageForm)
