import React from 'react'
import { connect } from 'react-redux'
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'

const WeightTypeSelect = ({ onChange, fedexWeightType, fedexDimensionType, value, name, isWeight }) => (
  <div className="input-group-prepend">
    <UncontrolledDropdown>
      <DropdownToggle
        caret
        style={{
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          minWidth: '75px',
          color: '#a1a8c3',
          backgroundColor: '#f7f8fa',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {`${value || 'Unit'} `}
      </DropdownToggle>
      <DropdownMenu>
        {(isWeight ? fedexWeightType : fedexDimensionType)?.map(w => (
          <DropdownItem
            key={w.value}
            name={name}
            onClick={e => {
              onChange(
                e,
                w.value,
              )
            }}
          >
            {w.label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  </div>
)

const mapStateToProps = ({ fedexWeightType, fedexDimensionType }) => ({
  fedexWeightType,
  fedexDimensionType,
})

export default connect(mapStateToProps)(WeightTypeSelect)
