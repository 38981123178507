import {
  React,
  Component,
  connect,
  Actions,
  Portlet,
  PortletHeader,
  PortletBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  BootstrapTable,
  paginationFactory,
  filterFactory,
  overlayFactory,
  CustomTextFilter,
  customFilter,
  FILTER_TYPES,
  Utils,
  Notification,
  Routes,
  Link,
  Button,
  ActionTypes,
  classNames,
  _,
} from '../../../Imports'
import { Helmet } from 'react-helmet'

const entity = 'Product'

// the attribute names and column dataFields are different, this is for backend
const orderAttributes = {
  sku: 'sku',
  name: 'name',
  retailPrice: 'price',
  salePrice: 'sale',
  inStock: 'stock',
  status: 'active'
}

class Products extends Component {
  constructor(props) {
    super(props)

    this.state = {
      filterBy: '',
      filters: {},
      isOpen: false,
      isFilterOpen: false,
      btnLoading: false,
      id: null,
      publishBtnLoading: false
    }

    this.storeId = Utils.search(this.props.location).store
  }

  UNSAFE_componentWillMount = () => {
    // Get Products
    this.getProducts()
    if (this.props.productCategories?.length === 0) {
      this.props.storePost(
        {
          storeId: this.storeId,
        },
        'list-categories',
        ActionTypes.FETCH_CATEGORIES_SUCCESS,
        ActionTypes.FETCH_CATEGORIES_FAILURE,
      )
    }
  }


  getProducts = async ({pageNumber = 0, pageSize = 20, filterValue, filterBy , order} = {}) => {
    const payload = {
      clientId: this.storeId,
      paging: {
        pageNumber,
        pageSize,
      },
    }

    let filterAttribute = ''
    let filterType = ''
    if(filterBy === 'sku') {
      filterType = 'Equal'
      filterAttribute = 'sku'
    } else if ( filterBy === 'name') {
      filterType = 'Contains'
      filterAttribute = 'productName'
    }

    if(filterValue) {
      payload.filter = {
        '@type': filterType,
        attribute: filterAttribute,
        value: filterValue,
      }
    }

    if(order) {
      payload.order = [{'attribute':orderAttributes[order.sortField], 'asc': order.sortOrder === 'asc' }]
    }

    await this.props.storePostProductsTable(
      payload,
      'list-products',
      ActionTypes.FETCH_PRODUCTS_LOADING,
      ActionTypes.FETCH_PRODUCTS_SUCCESS,
      ActionTypes.FETCH_PRODUCTS_FAILURE,
    )
  }

  delete = id => {
    this.setState({ isOpen: true, id: id })
  }

  close = () => {
    this.setState({ isOpen: false })
  }

  confirm = () => {
    this.setState({ btnLoading: true })

    let payload = {
      storeId: this.storeId,
      id: this.state.id,
    }

    this.props.post(payload, 'delete-product').then(resp => {
      this.response(resp)
    })
  }

  response = resp => {
    this.setState({
      btnLoading: false,
      isOpen: false,
    })

    let msn = _.replace(
      _.replace(process.env.REACT_APP_SUCCESS_ACTION, '%entity%', entity),
      '%action%',
      'Deleted',
    )

    if (resp !== 'error') {
      Notification.success(msn)
    } else {
      Notification.error(process.env.REACT_APP_ERROR_ACTION)
    }

    this.getProducts()
  }

  toggleFilter = () => {
    this.setState({
      isFilterOpen: !this.state.isFilterOpen,
    })
  }

  updateFilterBy = (filterBy) => {
    this.setState({
      filterBy: filterBy
    })
  }

  handleTableChange = (type, { page, sizePerPage, filters, sortField, sortOrder }) => {
    this.setState({filters: filters})
    this.getProducts({
      pageNumber: page -1,
      pageSize: sizePerPage,
      filterValue: filters?.[this.state.filterBy]?.filterVal || '',
      filterBy: this.state.filterBy,
      order: sortField && sortOrder ? {sortField, sortOrder} : null,
    })
  }

  render = () => {

    const products = this.props.products.data && this.props.products?.data?.filter(({ parentId }) => !parentId) || []

    const onRefresh = () => {
      this.setState({filters: {}  })
      this.getProducts();
    }


    const headerFormatter = (
      column,
      colIndex,
      { sortElement, filterElement },
    ) => (
      <div className="d-flex justify-content-end flex-column">
        <div className="d-flex justify-content-between">
          {column.text}
          {sortElement}
        </div>
        {this.state.isFilterOpen && (
          <div
            style={{
              margin: '0 -.75rem',
              marginTop: '.75rem',
              padding: '1rem .75rem .25rem',
              borderTop: '2px solid #f7f8fa',
            }}
          >
            {filterElement}
          </div>
        )}
      </div>
    )

    const rowEvents = {
      onClick: (e, column, columnIndex, row, rowIndex) => {
        let url = '/product/detail/' + row.id + '?store=' + this.storeId
        this.props.history.push(url)
      },
    }

    const columns = [
      {
        dataField: 'id',
        text: 'Id',
        hidden: true,
      },
      {
        dataField: 'sku',
        text: 'SKU',
        sort: true,
        events: rowEvents,
        filter: customFilter({
          type: FILTER_TYPES.TEXT
        }),
        filterRenderer: (onFilter, column) =>
          <CustomTextFilter
            onFilter={onFilter}
            column={column}
            previousFilter={this.state.filters?.sku?.filterVal || ''}
            updateFilterBy={this.updateFilterBy}
          />,
        headerFormatter,
        headerClasses: 'align-top',
        headerStyle: (colum, colIndex) => ({ width: '200px' }),
      },
      {
        dataField: 'name',
        sort: true,
        text: 'Name',
        headerFormatter,
        headerClasses: 'align-top',
        events: rowEvents,
        filter: customFilter({
          type: FILTER_TYPES.TEXT
        }),
        filterRenderer: (onFilter, column) =>
          <CustomTextFilter
            onFilter={onFilter}
            column={column}
            previousFilter={this.state.filters?.name?.filterVal || ''}
            updateFilterBy={this.updateFilterBy}
          />,
        formatter: (cell, row, rowIndex, formatExtraData) => {
          let length = row.length ? row.length : 0
          let width = row.width ? row.width : 0
          let height = row.height ? row.height : 0
          const categories = [...(row?.categories?.map(id => this.props?.productCategories?.find(category => id === category.id)?.label || '') || [])].filter(value => value).join('/')
          const attributes = [...(row?.attributes?.filter(({ value }) => value !== row.brand).map(({ value }) => value) || [])].filter(value => value).join('/')
          const price = `$${(row.showSalePrice ? row.salePrice : row.retailPrice).toFixed(2)}`
          return (
            <div className="k-user-card-v2">
              <div className="k-user-card-v2__pic">
                <img
                  src={row.thumbnail}
                  className="k-img-rounded k-marginless"
                  alt="photo"
                />
              </div>
              <div className="k-user-card-v2__details">
                <span className="k-user-card-v2__name">
                  {_.capitalize(cell)}
                  {attributes || categories ? `\n(${categories ? `${categories}${attributes ? ' - ' : `, ${price}`}` : ''}${attributes ? `${attributes ? `${attributes}, ${price}` : ''}` : ''})` : ''}
                </span>
              </div>
            </div>
          )
        },
      },
      {
        dataField: 'retailPrice',
        text: 'Price',
        headerFormatter,
        headerClasses: 'align-top',
        events: rowEvents,
        headerStyle: (colum, colIndex) => ({ width: '100px' }),
        sort: true,
        align: 'right',
        formatter: (cell, row, rowIndex, formatExtraData) => {
          let price = cell ? cell : 0

          return (
            <span>
              {price !== 0 ? (
                <span className={'k-font-bold'}>{'$' + price.toFixed(2)}</span>
              ) : (
                <span className="k-label-font-color-3 k-font-bold">{'-'}</span>
              )}
            </span>
          )
        },
      },
      {
        dataField: 'salePrice',
        text: 'Sale',
        headerFormatter,
        headerClasses: 'align-top',
        events: rowEvents,
        headerStyle: (colum, colIndex) => ({ width: '100px' }),
        sort: true,
        align: 'right',
        formatter: (cell, row, rowIndex, formatExtraData) => {
          let price = cell ? cell : 0

          return (
            <span>
              {price !== 0 ? (
                <span style={{ color: 'red' }} className={'k-font-bold'}>
                  {'$' + price.toFixed(2)}
                </span>
              ) : (
                <span className="k-label-font-color-3 k-font-bold">{''}</span>
              )}
            </span>
          )
        },
      },
      {
        dataField: 'inStock',
        text: 'Stock',
        sort: true,
        events: rowEvents,
        headerFormatter,
        headerClasses: 'align-top',
        headerStyle: (colum, colIndex) => ({ width: '90px' }),
        formatter: (cell, row, rowIndex, formatExtraData) => (
          <span
            className={classNames(
              'badge',
              `${!cell && 'gray-out-of-stock'}`,
              `badge-${cell ? 'success' : 'native'}`,
            )}
          >
            {cell ? 'In-Stock' : 'Out'}
          </span>
        ),
      },
      {
        dataField: 'status',
        text: 'Active',
        sort: true,
        events: rowEvents,
        headerFormatter,
        headerClasses: 'align-top',
        headerStyle: (colum, colIndex) => ({ width: '90px' }),
        formatter: (cell, row, rowIndex, formatExtraData) => (
          <span
            className={classNames(
              'badge',
              `badge-${cell ? 'success' : 'danger'}`,
            )}
          >
            {cell ? 'Active' : 'No Active'}
          </span>
        ),
      },
      {
        dataField: 'actions',
        text: 'Actions',
        headerAlign: 'right',
        align: 'right',
        headerFormatter,
        headerClasses: 'align-top',
        headerStyle: (colum, colIndex) => ({ width: '100px' }),
        formatter: (cell, row, rowIndex, formatExtraData) => {
          let url = `/product/detail/${row.id}?store=${this.storeId}`

          return (
            <div>
              <button
                className="btn btn-outline-secondary btn-sm btn-icon mr-3"
                title="Delete"
                onClick={() => this.delete(row.id)}
              >
                <i className="la la-trash" />
              </button>
              <Link
                to={url}
                className="btn btn-outline-secondary btn-sm btn-icon"
                title="Edit"
              >
                <i className="la la-search" />
              </Link>
            </div>
          )
        },
      },
    ]

    const options = {
      showTotal: true,
      totalSize: this.props.products.totalProducts,
      sizePerPageList: [
        {
          text: '20',
          value: 20,
        },
        {
          text: '100',
          value: 100,
        },
        {
          text: '200',
          value: 200,
        },
        {
          text: '300',
          value: 300,
        },
      ],
    }

    return (
      <Portlet>
        <Helmet>
          <title>Products | Admin Panel - Max Ecommerce</title>
        </Helmet>
        <PortletHeader title="Products List">
          <Button
            label="Refresh"
            icon="la la-refresh"
            color="secondary"
            className="mr-3"
            onClick={onRefresh}
          />
          <Button
            label="Filters"
            icon="la la-filter"
            color="secondary"
            onClick={this.toggleFilter}
            className={classNames('mr-3', {
              active: this.state.isFilterOpen,
            })}
          />
          <Link
            to={`${Routes.PRODUCT_CREATE}?store=${
              Utils.search(this.props.location).store
            }`}
            className="btn btn-primary"
          >
            <i className="la la-plus" />
            Add new
          </Link>
        </PortletHeader>
        <PortletBody>
          <BootstrapTable
            bootstrap4
            remote
            keyField="id"
            data={products}
            columns={columns}
            loading={this.props.products.loading}
            overlay={overlayFactory({ spinner: true, background: 'rgba(0,0,0,0.5)' })}
            noDataIndication={() => (
              <div className="mt-2">
                {this.props.products.loading ? 'Loading...' : `No ${entity + 's'}`}
              </div>
            )}
            pagination={paginationFactory(options)}
            filter={filterFactory()}
            onTableChange={this.handleTableChange}
          />
        </PortletBody>
        <Modal isOpen={this.state.isOpen}>
          <ModalHeader>Delete confirmation</ModalHeader>
          <ModalBody>Are you sure want to Delete this Product?</ModalBody>
          <ModalFooter>
            <Button
              isLoading={this.state.btnLoading}
              label="Confirm"
              color="danger"
              onClick={this.confirm}
            />
            <Button label="Cancel" color="secondary" onClick={this.close} />
          </ModalFooter>
        </Modal>
      </Portlet>
    )
  }
}

export const mapStateToProps = state => ({
  products: state.products,
  productType: state.productType,
  currentStore: state.currentStore,
  productCategories: state?.productCategories?.data || [],
})

export const mapDispatchToProps = dispatch => ({
  post: function(payload, actionEvent) {
    return dispatch(Actions.post(payload, actionEvent))
  },
  storePost: function(payload, actionEvent, typeSuccess, typeError) {
    return dispatch(
      Actions.storePost(payload, actionEvent, typeSuccess, typeError),
    )
  },
  storePostProductsTable: function(payload, actionEvent, typeSuccess, typeError) {
    return dispatch(
      Actions.storePostProductsTable(payload, actionEvent, typeSuccess, typeError),
    )
  },
  get: actionEvent => dispatch(Actions.get(actionEvent)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Products)
