import React, { Component } from 'react'
import HeaderMenu from './HeaderMenu'
import HeaderTopbar from './HeaderTopbar'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

class Header extends Component {
  render = () => (
    <div className="k-header k-grid__item  k-header--fixed ">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        pauseOnHover
      />
      <HeaderMenu />
      <HeaderTopbar />
    </div>
  )
}

export default Header
