import {
  React,
  Component,
  connect,
  Actions,
  BootstrapTable,
  Select,
  Utils,
  classNames,
  ActionTypes,
  _,
} from '../../../Imports'
import { Helmet } from 'react-helmet'
import moment from 'moment-timezone'
import { Auth } from 'aws-amplify'

import Widget from '../../base/Widget'
import { Bar, Line } from 'react-chartjs-2'

const PAST_TIME = {
  TODAY: { value: 'TODAY', label: 'Today' },
  YESTERDAY: { value: 'YESTERDAY', label: 'Yesterday' },
  THIS_WEEK: { value: 'THIS_WEEK', label: 'This Week' },
  LAST_WEEK: { value: 'LAST_WEEK', label: 'Last Week' },
  THIS_MONTH: { value: 'THIS_MONTH', label: 'This Month' },
  LAST_MONTH: { value: 'LAST_MONTH', label: 'Last Month' },
  LAST_SEVEN_DAYS: { value: 'LAST_SEVEN_DAYS', label: 'Last 7 days' },
  LAST_FOURTEEN_DAYS: { value: 'LAST_FOURTEEN_DAYS', label: 'Last 14 days' },
  LAST_THIRTY_DAYS: { value: 'LAST_THIRTY_DAYS', label: 'Last 30 days' },
  YEAR_TO_DATE: { value: 'YEAR_TO_DATE', label: 'Year to Date' },
  ALL_TIME: { value: 'ALL_TIME', label: 'All Time' },
}

const format = 'YYYY-MM-DD'

class Home extends Component {
  state = {
    totalSalesReport: null,
    daily: null,
    topProducts: null,
    customer: null,
    order: null,
    revenue: null,
    growth: null,
    aOrderSubtotal: null,
    aOrderTotal: null,
    userGroup: '',
    userId: '',
  }

  UNSAFE_componentWillMount = () => {
    this.storeId = Utils.search(this.props.location).store
    if (this.props.settings.data === null) {
      this.props.storePost(
        { id: this.storeId },
        'get-store',
        ActionTypes.FETCH_SETTINGS_SUCCESS,
        ActionTypes.FETCH_SETTINGS_FAILURE,
      )
    }
    Auth.currentAuthenticatedUser()
      .then(user => {
        this.setState({
          userGroup: user.signInUserSession.accessToken.payload['cognito:groups'][0],
          userId: user.signInUserSession.accessToken.payload.sub,
        })
      })
      .catch(err => console.log(err))
    this.firstLoad()
  }

  firstLoad = async () => {
    let dashboard = await this.getStats(
      this.getPastTime(PAST_TIME.TODAY.value),
    )
    this.setState({
      totalSalesReport: {
        labels: this.getLabels(PAST_TIME.YEAR_TO_DATE.value),
        data: dashboard.anual,
      },
      daily: {
        labels: this.getLabels(PAST_TIME.TODAY.value),
        data: dashboard.daily,
      },
      topProducts: {
        labels: this.getLabels(PAST_TIME.ALL_TIME.value),
        data: dashboard.topProducts,
      },
      customer: _.find(dashboard.growth, g => g.type === 'CUSTOMER'),
      order: _.find(dashboard.growth, g => g.type === 'ORDER'),
      revenue: _.find(dashboard.growth, g => g.type === 'REVENUE'),
      growth: _.find(dashboard.growth, g => g.type === 'GROWTH'),
      aOrderSubtotal: _.find(
        dashboard.growth,
        g => g.type === 'AVERAGE_ORDER_SUBTOTAL',
      ),
      aOrderTotal: _.find(
        dashboard.growth,
        g => g.type === 'AVERAGE_ORDER_TOTAL',
      ),
    })
  }

  getStats = async range => {
    const settings = _.get(this.props.settings, 'data', {})
    const payload = {
      storeId: this.storeId,
      days: Math.abs(
        moment(range.end, format).diff(moment(range.start, format), 'days') + 1,
      ),
      allTime: true,
    }

    if (settings) {
      const tz = settings.timeZone

      payload.days = Math.abs(
        moment(range.end, format)
          .tz(tz.value)
          .diff(moment(range.start, format).tz(tz.value), 'days') + 1,
      )
    }

    const stats = await this.props.post(payload, 'dashboard')

    return stats.data
  }

  getLabels = value => {
    const settings = _.get(this.props.settings, 'data', {})
    const timeZone = settings && settings.timeZone && (settings.timeZone.value || settings.timeZone)

    const p = PAST_TIME
    let labels = []

    switch (value) {
    case p.TODAY.value:
      labels = ['Today']

      break
    case p.YESTERDAY.value:
      labels = ['Yesterday', 'Today']

      break
    case p.THIS_WEEK.value:
      labels = _.range(moment().tz(timeZone).isoWeekday() + 1)
      labels = _.map(labels, l =>
        moment().tz(timeZone).weekday(l).format('ddd'))

      break
    case p.LAST_WEEK.value:
      labels = _.range(7)
      labels = _.map(labels, l =>
        moment().tz(timeZone).weekday(l).format('ddd'))

      break
    case p.THIS_MONTH.value:
      labels = _.range(moment().tz(timeZone).date())
      labels = _.map(labels, l =>
        moment()
          .tz(timeZone)
          .date(l + 1)
          .format('ddd-D'))

      break
    case p.LAST_MONTH.value:
      labels = moment().tz(timeZone).subtract(1, 'months')
      const range = _.range(labels.daysInMonth())
      labels = _.map(range, l => labels.date(l + 1).format('ddd-D'))

      break
    case p.LAST_SEVEN_DAYS.value:
      labels = _.range(7)
      labels = _.map(labels, l =>
        moment().tz(timeZone).subtract(l, 'days').format('MMM-D'))
      labels = _.reverse(labels)

      break
    case p.LAST_FOURTEEN_DAYS.value:
      labels = _.range(14)
      labels = _.map(labels, l =>
        moment().tz(timeZone).subtract(l, 'days').format('MMM-D'))
      labels = _.reverse(labels)

      break
    case p.LAST_THIRTY_DAYS.value:
      labels = _.range(30)
      labels = _.map(labels, l =>
        moment().tz(timeZone).subtract(l, 'days').format('MMM-D'))
      labels = _.reverse(labels)

      break
    case p.YEAR_TO_DATE.value:
      labels = _.range(12)
      labels = _.map(labels, l =>
        moment().tz(timeZone)?.month(l).format('MMM'))

      break
    case p.ALL_TIME.value:
      break
    }

    return labels
  }

  getPastTime = value => {
    const p = PAST_TIME
    const settings = _.get(this.props.settings, 'data', {})
    const timeZone =
      (settings && settings.timeZone && settings.timeZone.value) ||
      'America/New_York'

    let range = {
      start: moment().tz(timeZone),
      end: moment().tz(timeZone),
    }

    switch (value) {
    case p.TODAY.value:
      break
    case p.YESTERDAY.value:
      range.start = moment().tz(timeZone).subtract(1, 'days')

      break
    case p.THIS_WEEK.value:
      range.start = moment().tz(timeZone).startOf('week')

      break
    case p.LAST_WEEK.value:
      let lastweek = moment().tz(timeZone).subtract(1, 'weeks')

      range.start = lastweek.startOf('week')
      range.end = lastweek.endOf('week')

      break
    case p.THIS_MONTH.value:
      range.start = moment().tz(timeZone).startOf('month')
      // console.log("start,end", range.start);

      break
    case p.LAST_MONTH.value:
      let lastmonth = moment().tz(timeZone).subtract(1, 'months')

      range.start = moment(lastmonth).tz(timeZone).date(1)
      range.end = lastmonth.endOf('month')

      break
    case p.LAST_SEVEN_DAYS.value:
      range.start = moment().tz(timeZone).subtract(6, 'days')

      break
    case p.LAST_FOURTEEN_DAYS.value:
      range.start = moment().tz(timeZone).subtract(13, 'days')

      break
    case p.LAST_THIRTY_DAYS.value:
      range.start = moment().tz(timeZone).subtract(29, 'days')

      break
    case p.YEAR_TO_DATE.value:
      range.start = moment().tz(timeZone).startOf('year')

      break
    case p.ALL_TIME.value:
      range.start = moment(0).tz(timeZone)

      break
    }

    return range
  }

  onChangeSelect = async (e, name) => {
    this.setState({
      [name]: null,
    })

    const data = await this.getStats(this.getPastTime(e.value))

    this.setState({
      [name]: {
        labels: this.getLabels(e.value),
        data: data[name],
      },
    })
  }

  render = () => {
    return (
      <div className="row">
        <Helmet>
          <title>Dashboard | Admin Panel - Max Ecommerce</title>
        </Helmet>
        {this.state.userGroup !== Utils.GROUPS.SALES && this.state.userGroup !== Utils.GROUPS.MANAGER && (
          <div className="col-md-6">
            <Widget title="Total Sales Report View" fluid>
              {this.state.totalSalesReport == null ? (
                <div className="content-placeholder d-flex align-items-end h-100">
                  <div className="cp-w-xs cp-h-lg mr-3" />
                  <div className="cp-w-xs cp-h-md mr-3" />
                  <div className="cp-w-xs cp-h-sm mr-3" />
                </div>
              ) : (
                <Bar
                  data={{
                    labels: this.state.totalSalesReport.labels,
                    datasets: [
                      {
                        label: 'Product Sales',
                        data: _.map(
                          this.state.totalSalesReport.data,
                          a => a.subtotal,
                        ),
                        backgroundColor: '#1dc9b7',
                      },
                      {
                        label: 'Products Sales, Tax and Shipping',
                        data: _.map(
                          this.state.totalSalesReport.data,
                          a => a.total,
                        ),
                        backgroundColor: '#e1e1ef',
                      },
                    ],
                  }}
                  options={{
                    legend: false,
                    scales: {
                      xAxes: [
                        {
                          stacked: true,
                          categoryPercentage: 0.35,
                          barPercentage: 0.7,
                          gridLines: false,
                          ticks: {
                            fontColor: '#BBBFDA',
                            fontSize: 13,
                            padding: 10,
                          },
                        },
                      ],
                      yAxes: [
                        {
                          categoryPercentage: 0.35,
                          barPercentage: 0.7,
                          gridLines: {
                            color: '#EBEEFC',
                            drawBorder: false,
                            lineWidth: 1.9,
                            borderDash: [3, 4],
                            zeroLineWidth: 1.9,
                            zeroLineColor: '#EBEEFC',
                            zeroLineBorderDash: [3, 4],
                          },
                          ticks: {
                            // max: 100,
                            min: 0,
                            // stepSize: 20,
                            fontColor: '#BBBFDA',
                            fontSize: 13,
                            padding: 10,
                          },
                        },
                      ],
                    },
                    tooltips: {
                      mode: 'index',
                      intersect: false,
                    },
                  }}
                />
              )}
            </Widget>
          </div>
        )}
        {this.state.userGroup !== Utils.GROUPS.SALES && this.state.userGroup !== Utils.GROUPS.MANAGER && (
          <div className="col-md-6">
            <Widget
              fluid
              title="Sales Snapshot View"
              tools={
                <Select
                  defaultValue={PAST_TIME.TODAY}
                  options={[
                    PAST_TIME.TODAY,
                    PAST_TIME.YESTERDAY,
                    PAST_TIME.THIS_WEEK,
                    // PAST_TIME.LAST_WEEK,
                    PAST_TIME.LAST_SEVEN_DAYS,
                    PAST_TIME.LAST_FOURTEEN_DAYS,
                    PAST_TIME.LAST_THIRTY_DAYS,
                    PAST_TIME.THIS_MONTH,
                    PAST_TIME.LAST_MONTH,
                  ]}
                  onChange={e => {
                    this.onChangeSelect(e, 'daily')
                  }}
                  isSearchable={false}
                  className="dashboard-select--width"
                />
              }
            >
              {this.state.daily == null ? (
                <div className="content-placeholder d-flex align-items-end h-100">
                  <div className="cp-w-xs cp-h-lg mr-3" />
                  <div className="cp-w-xs cp-h-md mr-3" />
                  <div className="cp-w-xs cp-h-sm mr-3" />
                </div>
              ) : (
                <Line
                  data={{
                    labels: this.state.daily.labels,
                    datasets: [
                      {
                        label: 'Product Sales',
                        data: _.map(this.state.daily.data, a => a.subtotal),
                        backgroundColor: 'rgba(29,201,183,.3)',
                        borderWidth: 3,
                        borderColor: '#1dc9b7',
                      },
                      {
                        label: 'Product Sales, Tax and Shipping',
                        data: _.map(this.state.daily.data, a => a.total),
                        borderWidth: 3,
                        borderDash: [5, 5],
                        borderColor: '#e1e1ef',
                        fill: false,
                      },
                    ],
                  }}
                  options={{
                    legend: false,
                    scales: {
                      xAxes: [
                        {
                          categoryPercentage: 0.35,
                          barPercentage: 0.7,
                          gridLines: {
                            color: '#EBEEFC',
                            drawBorder: false,
                            lineWidth: 1.9,
                            borderDash: [3, 4],
                            zeroLineWidth: 1.9,
                            zeroLineColor: '#EBEEFC',
                            zeroLineBorderDash: [3, 4],
                          },
                          ticks: {
                            fontColor: '#BBBFDA',
                            fontSize: 13,
                            padding: 10,
                          },
                        },
                      ],
                      yAxes: [
                        {
                          categoryPercentage: 0.35,
                          barPercentage: 0.7,
                          gridLines: false,
                          ticks: {
                            // max: 100,
                            min: 0,
                            // stepSize: 20,
                            fontColor: '#BBBFDA',
                            fontSize: 13,
                            padding: 10,
                          },
                        },
                      ],
                    },
                    tooltips: {
                      mode: 'index',
                      intersect: false,
                    },
                  }}
                />
              )}
            </Widget>
          </div>
        )}
        {this.state.userGroup !== Utils.GROUPS.SALES && this.state.userGroup !== Utils.GROUPS.MANAGER && (
          <div className="col-md-6 col-lg-4">
            <Widget
              fluid
              title="Customers"
              tools={
                <span
                  className="badge badge-light d-flex ml-auto"
                  style={{
                    height: '40px',
                    width: '40px',
                  }}
                >
                  <i className="flaticon-users-1 text-success m-auto h5" />
                </span>
              }
            >
              {this.state.customer == null ? (
                <div className="content-placeholder">
                  <div className="cp-w-sm cp-h-md mb-3" />
                  <div className="cp-w-md cp-h-sm" />
                </div>
              ) : (
                <React.Fragment>
                  <p className="h3 text-muted mb-3">{this.state.customer.total}</p>
                  <p className="mb-0">
                    <span
                      className={classNames(
                        'mr-3',
                        'h6',
                        {
                          'text-success': this.state.customer.percentage >= 0,
                        },
                        {
                          'text-danger': this.state.customer.percentage < 0,
                        },
                      )}
                    >
                      <i
                        className={classNames(
                          'la',
                          'font-weight-bold',
                          'mr-2',
                          {
                            'la-arrow-up': this.state.customer.percentage >= 0,
                          },
                          {
                            'la-arrow-down': this.state.customer.percentage < 0,
                          },
                        )}
                      />
                      {this.state.customer.percentage}%
                    </span>
                    Since last month
                  </p>
                </React.Fragment>
              )}
            </Widget>
          </div>
        )}
        {this.state.userGroup !== Utils.GROUPS.MANAGER && (
          <div className="col-md-6 col-lg-4">
            <Widget
              fluid
              title="Orders"
              tools={
                <span
                  className="badge badge-light d-flex ml-auto"
                  style={{
                    height: '40px',
                    width: '40px',
                  }}
                >
                  <i className="flaticon2-supermarket text-success m-auto h5" />
                </span>
              }
            >
              {this.state.order == null ? (
                <div className="content-placeholder">
                  <div className="cp-w-sm cp-h-md mb-3" />
                  <div className="cp-w-md cp-h-sm" />
                </div>
              ) : (
                <React.Fragment>
                  <p className="h3 text-muted mb-3">{this.state.order.total}</p>
                  <p className="mb-0">
                    <span
                      className={classNames(
                        'mr-3',
                        'h6',
                        {
                          'text-success': this.state.order.percentage >= 0,
                        },
                        {
                          'text-danger': this.state.order.percentage < 0,
                        },
                      )}
                    >
                      <i
                        className={classNames(
                          'la',
                          'font-weight-bold',
                          'mr-2',
                          {
                            'la-arrow-up': this.state.order.percentage >= 0,
                          },
                          {
                            'la-arrow-down': this.state.order.percentage < 0,
                          },
                        )}
                      />
                      {this.state.order.percentage}%
                    </span>
                    Since last month
                  </p>
                </React.Fragment>
              )}
            </Widget>
          </div>
        )}
        {this.state.userGroup !== Utils.GROUPS.SALES && this.state.userGroup !== Utils.GROUPS.MANAGER && (
          <div className="col-md-6 col-lg-4">
            <Widget
              fluid
              title="Revenue"
              tools={
                <span
                  className="badge badge-light d-flex ml-auto"
                  style={{
                    height: '40px',
                    width: '40px',
                  }}
                >
                  <i className="flaticon-price-tag text-success m-auto h5" />
                </span>
              }
            >
              {this.state.revenue == null ? (
                <div className="content-placeholder">
                  <div className="cp-w-sm cp-h-md mb-3" />
                  <div className="cp-w-md cp-h-sm" />
                </div>
              ) : (
                <React.Fragment>
                  <p className="h3 text-muted mb-3">
                    ${(this.state.revenue.revenue || 0).toFixed(2)}
                  </p>
                  <p className="mb-0">
                    <span
                      className={classNames(
                        'mr-3',
                        'h6',
                        {
                          'text-success': this.state.revenue.percentage >= 0,
                        },
                        {
                          'text-danger': this.state.revenue.percentage < 0,
                        },
                      )}
                    >
                      <i
                        className={classNames(
                          'la',
                          'font-weight-bold',
                          'mr-2',
                          {
                            'la-arrow-up': this.state.revenue.percentage >= 0,
                          },
                          {
                            'la-arrow-down': this.state.revenue.percentage < 0,
                          },
                        )}
                      />
                      {this.state.revenue.percentage}%
                    </span>
                    Since last month
                  </p>
                </React.Fragment>
              )}
            </Widget>
          </div>
        )}
        {this.state.userGroup !== Utils.GROUPS.MANAGER && (
          <div className="col-md-6 col-lg-4">
            <Widget
              title="Growth"
              tools={
                <span
                  className="badge badge-light d-flex ml-auto"
                  style={{
                    height: '40px',
                    width: '40px',
                  }}
                >
                  <i className="flaticon2-chart text-success m-auto h5" />
                </span>
              }
            >
              {this.state.growth == null ? (
                <div className="content-placeholder">
                  <div className="cp-w-sm cp-h-md mb-3" />
                  <div className="cp-w-md cp-h-sm" />
                </div>
              ) : (
                <React.Fragment>
                  <p className="h3 text-muted mb-3">
                    {`${(this.state.growth.revenue || 0).toFixed(2)}%`}
                  </p>
                  <p className="mb-0">
                    <span
                      className={classNames(
                        'mr-3',
                        'h6',
                        {
                          'text-success': this.state.growth.percentage >= 0,
                        },
                        {
                          'text-danger': this.state.growth.percentage < 0,
                        },
                      )}
                    >
                      <i
                        className={classNames(
                          'la',
                          'font-weight-bold',
                          'mr-2',
                          {
                            'la-arrow-up': this.state.growth.percentage >= 0,
                          },
                          {
                            'la-arrow-down': this.state.growth.percentage < 0,
                          },
                        )}
                      />
                      {this.state.growth.percentage}%
                    </span>
                    Since last month
                  </p>
                </React.Fragment>
              )}
            </Widget>
          </div>
        )}
        {this.state.userGroup !== Utils.GROUPS.SALES && this.state.userGroup !== Utils.GROUPS.MANAGER && (
          <div className="col-md-6 col-lg-4">
            <Widget
              title="Avg Order Subtotal"
              tools={
                <span
                  className="badge badge-light d-flex ml-auto"
                  style={{
                    height: '40px',
                    width: '40px',
                  }}
                >
                  <i className="flaticon2-chart2 text-success m-auto h5" />
                </span>
              }
            >
              {this.state.aOrderSubtotal == null ? (
                <div className="content-placeholder">
                  <div className="cp-w-sm cp-h-md mb-3" />
                  <div className="cp-w-md cp-h-sm" />
                </div>
              ) : (
                <React.Fragment>
                  <p className="h3 text-muted mb-3">
                    ${(this.state.aOrderSubtotal.revenue || 0).toFixed(2)}
                  </p>
                  <p className="mb-0">
                    <span
                      className={classNames(
                        'mr-3',
                        'h6',
                        {
                          'text-success': this.state.aOrderSubtotal.percentage >= 0,
                        },
                        {
                          'text-danger': this.state.aOrderSubtotal.percentage < 0,
                        },
                      )}
                    >
                      <i
                        className={classNames(
                          'la',
                          'font-weight-bold',
                          'mr-2',
                          {
                            'la-arrow-up':
                              this.state.aOrderSubtotal.percentage >= 0,
                          },
                          {
                            'la-arrow-down':
                              this.state.aOrderSubtotal.percentage < 0,
                          },
                        )}
                      />
                      {this.state.aOrderSubtotal.percentage}%
                    </span>
                    Since last month
                  </p>
                </React.Fragment>
              )}
            </Widget>
          </div>
        )}
        {this.state.userGroup !== Utils.GROUPS.SALES && this.state.userGroup !== Utils.GROUPS.MANAGER && (
          <div className="col-md-6 col-lg-4">
            <Widget
              title="Avg Order Total"
              tools={
                <span
                  className="badge badge-light d-flex ml-auto"
                  style={{
                    height: '40px',
                    width: '40px',
                  }}
                >
                  <i className="flaticon2-chart2 text-success m-auto h5" />
                </span>
              }
            >
              {this.state.aOrderTotal == null ? (
                <div className="content-placeholder">
                  <div className="cp-w-sm cp-h-md mb-3" />
                  <div className="cp-w-md cp-h-sm" />
                </div>
              ) : (
                <React.Fragment>
                  <p className="h3 text-muted mb-3">
                    ${(this.state.aOrderTotal.revenue || 0).toFixed(2)}
                  </p>
                  <p className="mb-0">
                    <span
                      className={classNames(
                        'mr-3',
                        'h6',
                        {
                          'text-success': this.state.aOrderTotal.percentage >= 0,
                        },
                        {
                          'text-danger': this.state.aOrderTotal.percentage < 0,
                        },
                      )}
                    >
                      <i
                        className={classNames(
                          'la',
                          'font-weight-bold',
                          'mr-2',
                          {
                            'la-arrow-up': this.state.aOrderTotal.percentage >= 0,
                          },
                          {
                            'la-arrow-down': this.state.aOrderTotal.percentage < 0,
                          },
                        )}
                      />
                      {this.state.aOrderTotal.percentage}%
                    </span>
                    Since last month
                  </p>
                </React.Fragment>
              )}
            </Widget>
          </div>
        )}
        {this.state.userGroup !== Utils.GROUPS.SALES && this.state.userGroup !== Utils.GROUPS.MANAGER && (
          <div className="col-12">
            <Widget
              title="Top 5 Selling Products"
              tools={
                <Select
                  defaultValue={PAST_TIME.ALL_TIME}
                  options={[
                    // PAST_TIME.THIS_WEEK,
                    // PAST_TIME.LAST_WEEK,
                    // PAST_TIME.THIS_MONTH,
                    // PAST_TIME.LAST_MONTH,
                    // PAST_TIME.YEAR_TO_DATE,
                    PAST_TIME.ALL_TIME,
                  ]}
                  isSearchable={false}
                  className="dashboard-select--width"
                />
              } /* 
                                    onChange={(e) => {
                                        this.onChangeSelect(e, "topProducts");
                                    }}
                                */
            >
              {this.state.topProducts == null ? (
                <div className="content-placeholder">
                  <div className="cp-w-md cp-h-sm mb-3" />
                  <div className="cp-w-sm cp-h-sm mb-3" />
                </div>
              ) : (
                this.state.topProducts &&
                !!this.state.topProducts?.data?.length && (
                  <BootstrapTable
                    keyField="id"
                    bootstrap4
                    bordered={false}
                    headerClasses="d-none"
                    noDataIndication="Loading..."
                    data={this.state.topProducts.data}
                    columns={[
                      {
                        dataField: 'id',
                        hidden: true,
                      },
                      {
                        dataField: 'title',
                        text: '',
                        formatter: (cell, row) => (
                          <div>
                            {row.title}
                            <small className="d-block text-muted mt-1">
                              Weight: {(row.weight || 0).toFixed(2)}
                            </small>
                          </div>
                        ),
                      },
                      {
                        dataField: 'price',
                        text: '',
                        style: () => ({
                          width: '20%',
                        }),
                        formatter: (cell, row) => (
                          <div>
                            {`$${row.price.toFixed(2)}`}
                            <small className="d-block text-muted mt-1">Price</small>
                          </div>
                        ),
                      },
                      {
                        dataField: 'quantity',
                        text: '',
                        style: () => ({
                          width: '20%',
                        }),
                        formatter: (cell, row) => (
                          <div>
                            {row.qty}
                            <small className="d-block text-muted mt-1">
                              Quantity
                            </small>
                          </div>
                        ),
                      },
                    ]}
                  />
                )
              )}
            </Widget>
          </div>
        )}
      </div>
    )
  }
}

export const mapStateToProps = state => ({
  orders: state.orders,
  settings: state.settings,
})

export const mapDispatchToProps = dispatch => ({
  storePost: function(payload, actionEvent, typeSuccess, typeError) {
    return dispatch(
      Actions.storePost(payload, actionEvent, typeSuccess, typeError),
    )
  },
  post: function(payload, actionEvent) {
    return dispatch(Actions.post(payload, actionEvent))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Home)
