import React, { Component } from 'react'
import { Auth } from 'aws-amplify'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import HeaderMobile from './header/HeaderMobile'
import * as Utils from '../../utils/Utils'
import Header from './header/Header'
import Aside from './aside/Aside'
import Content from './content/Content'
import Footer from './footer/Footer'
import { Loader } from '../../Imports'
import classNames from 'classnames'

class Layout extends Component {
  eventListenerAdded = false
  componentDidUpdate(prevProps) {
    const { stores: prevStores } = prevProps
    const { history, stores } = this.props
    if (!!prevStores.length && stores.length && !this.eventListenerAdded) {
      this.eventListenerAdded = true
      Auth.currentAuthenticatedUser()
        .then(user => {
          const store = stores.find(({ id }) => id === Utils.search(this.props.location).store)
          const role = user.signInUserSession.accessToken.payload['cognito:groups'][0]
          this.pageView(store.businessName || store.name, role)
          history.listen(() => this.pageView(store.businessName || store.name, role))
        })
        .catch(err => console.log(err))
    }
  }

  pageView = (store, role) => setTimeout(() => (
    window.dataLayer?.push({
      event: 'pageview',
      title: `${store} - ${role}, ${document.title.split('|')[0].trim()}`,
    })
  ), 1000)

  render = () => {
    const { loading } = this.props.ui

    return (
      <div
        className={classNames(
          'k-header--fixed',
          'k-header-mobile--fixed',
          'k-aside--enabled',
          'k-aside--fixed',
          { 'k-aside--on': this.props.ui.toggleAside },
          { 'k-aside--minimize': this.props.ui.minimizeAside },
          { 'k-aside--minimize-hover': this.props.ui.hoverAside },
          {
            'k-header__topbar--mobile-on': this.props.ui.toggleHeader,
          },
        )}
      >
        <HeaderMobile />
        <div className="k-grid k-grid--hor k-grid--root">
          <div className="k-grid__item k-grid__item--fluid k-grid k-grid--ver k-page">
            <Aside />
            <Loader active={loading} />
            <div
              className="k-grid__item k-grid__item--fluid k-grid k-grid--hor k-wrapper"
              style={{
                height: '100vh',
              }}
            >
              <Header />
              <Content />
              <Footer />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ui: state.ui,
  stores: state.stores?.data || [],
})

export default withRouter(connect(mapStateToProps)(Layout))
