import {
  React,
  Component,
  connect,
  Actions,
  Portlet,
  PortletHeader,
  PortletBody,
  Section,
  Select,
  FormGroup,
  Label,
  FormFeedback,
  Utils,
  Notification,
  FormValidator,
  Button,
  ActionTypes,
  _,
} from '../../../Imports'
import { Helmet } from 'react-helmet'
import moment from 'moment-timezone'

const entity = 'Timezone'

class Timezone extends Component {
  constructor(props) {
    super(props)

    this.state = {
      btnLoading: false,
      data: null,

      countries: [
        {
          label: 'United States',
          value: 'US',
        },
      ],
      timezonesByCountry: {},
    }

    this.state.timezonesByCountry = []
    moment.tz.zonesForCountry(this.state.countries[0].value).map(tz => {
      this.state.timezonesByCountry.push(this.getTimezoneProps(tz))
    })
  }

  UNSAFE_componentWillMount = () => {
    let storeId = Utils.search(this.props.location).store

    if (!this.props.settings.data) {
      this.props
        .storePost(
          { id: storeId },
          'get-store',
          ActionTypes.FETCH_SETTINGS_SUCCESS,
          ActionTypes.FETCH_SETTINGS_FAILURE,
        )
        .then(resp => {
          const data = _.get(resp, 'data', {})
          this.setState({
            data,
          })
        })
    } else {
      this.setState({
        data: this.props.settings.data,
      })
    }
  }

  onChangeSelect = (e, name) => {
    this.setState(prevState => ({
      data: {
        ...prevState.data,

        [name]: e,
      },
    }))
  }

  update = () => {
    this.setState({ btnLoading: true })
    this.props
      .storePost(
        this.state.data,
        'update-store',
        ActionTypes.FETCH_SETTINGS_SUCCESS,
        ActionTypes.FETCH_SETTINGS_FAILURE,
      )
      .then(this.response)
  }

  response = ({ status, data }) => {
    this.setState({
      btnLoading: false,
      data,
    })

    let msn = _.replace(
      _.replace(process.env.REACT_APP_SUCCESS_ACTION, '%entity%', entity),
      '%action%',
      'Updated',
    )

    if (status === 'success') {
      Notification.success(msn)
    } else {
      Notification.error(process.env.REACT_APP_ERROR_ACTION)
    }
  }

  getTimezoneProps = tzString => {
    const tz = moment.tz(tzString)

    const tzStringOffset = tz
      .format('Z')
      .replace(':00', '')
      .replace(':30', '.5')
    let x = tzStringOffset === 0 ? 0 : parseInt(tzStringOffset).toFixed(2)

    return {
      label: this.formatTimezone(tzString),
      value: `${tzString}`,
      time: `${x}`,
      offset: tz._offset,
    }
  }

  formatTimezone = tzString =>
    `(GMT${moment.tz(tzString).format('Z')}) ${tzString
      .split('/')
      .pop()
      .replace('/', ' ')
      .replace('_', ' ')}`

  render = () => {
    const { data, timezonesByCountry, countries } = this.state

    return (
      <Portlet>
        <Helmet>
          <title>Timezone | Admin Panel - Max Ecommerce</title>
        </Helmet>
        <PortletHeader title="Timezone" sticky>
          <Button
            label="Update"
            color="primary"
            icon={`la ${this.state.btnLoading ? '' : ' la-check'}`}
            isLoading={this.state.btnLoading}
            onClick={this.update}
          />
        </PortletHeader>
        <PortletBody>
          {data === null && (
            <div className="row">
              <div className="col-12">
                <div>Loading...</div>
              </div>
            </div>
          )}

          {data !== null && (
            <div className="row">
              <div className="col-xl-1" />
              <div className="col-xl-10">
                <Section title="Local Time">
                  <FormGroup className="row">
                    <div className="col-3">
                      <Select
                        options={countries}
                        value={countries[0]}
                        onChange={e => {}}
                        isSearchable={false}
                        closeMenuOnSelect
                      />
                    </div>
                    <div className="col-9">
                      <Select
                        options={timezonesByCountry}
                        value={data.timeZone}
                        onChange={e => this.onChangeSelect(e, 'timeZone')}
                        isSearchable={false}
                        closeMenuOnSelect
                      />
                    </div>
                  </FormGroup>
                </Section>
              </div>
              <div className="col-xl-1" />
            </div>
          )}
        </PortletBody>
      </Portlet>
    )
  }
}

export const mapStateToProps = state => ({
  settings: state.settings,
  taxType: state.taxType,
})

export const mapDispatchToProps = dispatch => ({
  storePost: function(payload, actionEvent, typeSuccess, typeError) {
    return dispatch(
      Actions.storePost(payload, actionEvent, typeSuccess, typeError),
    )
  },
  storeGet: function(actionEvent, typeSuccess, typeError) {
    return dispatch(Actions.storeGet(actionEvent, typeSuccess, typeError))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Timezone)
