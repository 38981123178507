import { React, Section, _ } from '../../../Imports'

import FilterBalloon from './FilterBalloon'

class Filters extends React.Component {
  render = () => {
    let actives = _.filter(this.props.filters, function(o) {
      return o.value !== ''
    })

    return (
      <div>
        {actives.length > 0 && (
          <Section
            title={
              <div className="mb-n4">
                <span className="small"> Applied filters </span>
                <span className="ml-3 text-secondary font-weight-normal">
                  |
                </span>
                <button onClick={this.props.clearAll} className="btn btn-link">
                  Clear filters
                </button>
              </div>
            }
            className="table-bordered mx-n3 p-3 pb-4"
          >
            <div className="d-flex justify-content-between align-items-center flex-wrap">
              <div className="d-flex flex-wrap mb-3">
                {actives.map(f => (
                  <FilterBalloon
                    key={f.id}
                    filter={f}
                    onRemove={e => this.props.removeFilter(f)}
                  />
                ))}
              </div>
            </div>
          </Section>
        )}
      </div>
    )
  }
}

export default Filters
