import React, { Component } from 'react'

import { Link } from 'react-router-dom'

class Footer extends Component {
  render = () => (
    <footer className="k-footer k-grid__item k-grid k-grid--desktop k-grid--ver-desktop">
      <div className="k-footer__copyright">
        {new Date().getFullYear()}&nbsp;&copy;&nbsp;
        <a href="https://ondreserve.com" className="k-link">
          MaxEcommerce
        </a>
      </div>
      <div className="k-footer__menu">
        <Link to="/" className="k-footer__menu-link k-link">
          Home
        </Link>
      </div>
    </footer>
  )
}

export default Footer
