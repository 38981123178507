import {
  React,
  Component,
  connect,
  Actions,
  Portlet,
  PortletHeader,
  PortletBody,
  Section,
  Select,
  FormGroup,
  Input,
  Label,
  FormFeedback,
  Utils,
  Notification,
  Link,
  FormValidator,
  Button,
  UiActions,
  _,
} from '../../../Imports'
import validator from 'validator'
import { Helmet } from 'react-helmet'

const roles = [
  { value: 'Admin', label: 'Administrator' },
  { value: 'Manager', label: 'Manager' },
  { value: 'Shipper', label: 'Shipper' },
  { value: 'Sales', label: 'Sales' },
  { value: 'SuperAdmin', label: 'Super Admin' },
]

const entity = 'Account'

class UserForm extends Component {
  constructor(props) {
    super(props)

    this.validator = new FormValidator([
      // Conatat information
      {
        field: 'firstName',
        method: 'isEmpty',
        validWhen: false,
        message: 'First Name is required.',
      },
      {
        field: 'lastName',
        method: 'isEmpty',
        validWhen: false,
        message: 'Last Name is required.',
      },
      {
        field: 'email',
        method: 'isEmail',
        validWhen: true,
        message: 'Invalid email or already exist.',
      },
      {
        field: 'role',
        method: 'isEmpty',
        validWhen: false,
        message: 'Country is required.',
      },
    ])

    this.storeId = Utils.search(this.props.location).store

    this.state = {
      user: null,
      submited: false,
      isCreate: false,
      btnLoading: false,
    }
  }

  UNSAFE_componentWillMount = () => {
    let storeId = Utils.search(this.props.location).store

    this.setState(
      { isCreate: Utils.isCreate(this.props.location.pathname) },
      () => {
        if (this.state.isCreate) {
          this.setState({ user: {} })
        } else {
          const email = this.props.match.params.id
          const payload = { storeId, email }

          this.props.post(payload, 'get-user-account').then(resp => {
            this.setState({
              user: resp.data,
            })
          })
        }
      },
    )
  }

  onChangeInput = e => {
    let user = this.state.user
    user[e.target.name] = e.target.value

    this.setState({ user })
  }

  onChangeCheckbox = e => {
    let user = this.state.user
    user[e.target.name] = !_.get(user, e.target.name, false)

    this.setState({ user })
  }

  onChangeSelect = (e, name) => {
    let user = this.state.user
    user[name] = e.value

    this.setState({ user })
  }

  isValidEmail = email => {
    let exist = false

    if (this.state.isCreate) {
      this.props
        .post(
          {
            storeId: this.storeId,
            email,
          },
          'get-user-account',
        )
        .then(rsp => {
          exist = rsp.data != null
          return validator.isEmail(email) && !exist
        })
    } else {
      return validator.isEmail(email)
    }

    //console.log("validator.isEmail(email)", validator.isEmail(email));
    //return false; //validator.isEmail(email) && !exist;
  }

  action = async () => {
    this.props.onSetLoading(true)
    this.setState({
      submited: true,
      btnLoading: true,
    })

    let isValid = this.validator.validate(this.state.user).isValid
    let action = this.state.isCreate
      ? 'create-user-account'
      : 'update-user-account'

    if (isValid) {
      if (this.state.isCreate) {
        let rsp = await this.props.post(
          {
            storeId: this.storeId,
            email: this.state.user.email,
          },
          'get-user-account',
        )

        if (rsp.data != null) {
          // Notification
          this.props.onSetLoading(false)
          this.setState({ btnLoading: false })

          Notification.error(
            'Sorry the User Account with the same email already exist.',
          )
          return
        }
      }
    }

    if (isValid) {
      this.props
        .post(
          {
            ...this.state.user,
            storeId: this.storeId,
          },
          action,
        )
        .then(resp => {
          this.response(resp)
        })
    } else {
      this.props.onSetLoading(false)
      this.setState({ btnLoading: false })
    }
  }

  response = resp => {
    this.props.onSetLoading(false)
    this.setState({ btnLoading: false })

    let msn = _.replace(
      _.replace(process.env.REACT_APP_SUCCESS_ACTION, '%entity%', entity),
      '%action%',
      this.state.isCreate ? 'Created' : 'Updated',
    )

    if (resp.status !== 'error') {
      Notification.success(msn)

      if (this.state.isCreate) {
        this.props.history.push(
          `/user-account/detail/${resp.data.email}?store=${this.storeId}`,
        )
      }
    } else {
      Notification.error(process.env.REACT_APP_ERROR_ACTION)
    }
  }

  render = () => {
    const validator = this.state.submited
      ? this.validator.validate(this.state.user)
      : this.validator.valid()

    return (
      <Portlet>
        <Helmet>
          <title>User | Admin Panel - Max Ecommerce</title>
        </Helmet>
        <PortletHeader
          title={
            this.state.isCreate ? 'New User Account' : 'Update User Account'
          }
          sticky
        >
          <Link
            to={`/user-accounts?store=${this.storeId}`}
            className="btn btn-secondary mr-3"
          >
            <i className="la la-arrow-left" />
            Back
          </Link>
          <Button
            label={this.state.isCreate ? 'Create' : 'Update'}
            color="primary"
            icon={`la ${this.state.btnLoading ? '' : ' la-check'}`}
            isLoading={this.state.btnLoading}
            onClick={this.action}
          />
        </PortletHeader>
        <PortletBody>
          {this.state.user === null && (
            <div className="row">
              <div className="col-12">
                <div>Loading...</div>
              </div>
            </div>
          )}
          {this.state.user !== null && (
            <div className="row">
              <div className="col-xl-2" />
              <div className="col-xl-8">
                <Section title="User Information" size="lg">
                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">Active</Label>
                    <div className="col-9">
                      <span className="k-switch k-switch--md k-switch--icon">
                        <label>
                          <Input
                            checked={this.state.user.status ? 'checked' : ''}
                            onChange={this.onChangeCheckbox}
                            name="status"
                            type="checkbox"
                          />
                          <span />
                        </label>
                      </span>
                    </div>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">First Name </Label>
                    <div className="col-9">
                      <Input
                        value={this.state.user.firstName}
                        onChange={this.onChangeInput}
                        name="firstName"
                        invalid={validator.firstName.isInvalid}
                      />
                      <FormFeedback>{validator.firstName.message}</FormFeedback>
                    </div>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">Last Name </Label>
                    <div className="col-9">
                      <Input
                        value={this.state.user.lastName}
                        onChange={this.onChangeInput}
                        name="lastName"
                        invalid={validator.lastName.isInvalid}
                      />
                      <FormFeedback>{validator.lastName.message}</FormFeedback>
                    </div>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">Email </Label>
                    <div className="col-9">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <i className="la la-at input-group-text" />
                        </div>
                        <Input
                          readOnly={!this.state.isCreate}
                          value={this.state.user.email}
                          onChange={this.onChangeInput}
                          name="email"
                          invalid={validator.email.isInvalid}
                        />
                        <FormFeedback>{validator.email.message}</FormFeedback>
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">Role </Label>
                    <div className="col-9">
                      <Select
                        options={roles}
                        value={Utils.object(roles, this.state.user.role)}
                        onChange={e => this.onChangeSelect(e, 'role')}
                        className={validator.role.isInvalid ? 'is-invalid' : ''}
                      />
                      <FormFeedback>{validator.role.message}</FormFeedback>
                    </div>
                  </FormGroup>
                </Section>
              </div>
              <div className="col-xl-2" />
            </div>
          )}
        </PortletBody>
      </Portlet>
    )
  }
}

export const mapStateToProps = state => ({
  users: state.users,
})

export const mapDispatchToProps = dispatch => ({
  post: function(path, payload, actionEvent) {
    return dispatch(Actions.post(path, payload, actionEvent))
  },
  storeGet: function(path, actionEvent, typeSuccess, typeError) {
    return dispatch(
      Actions.storeGet(path, actionEvent, typeSuccess, typeError),
    )
  },
  storePost: function(payload, actionEvent, typeSuccess, typeError) {
    return dispatch(
      Actions.storePost(payload, actionEvent, typeSuccess, typeError),
    )
  },
  onSetLoading: payload => dispatch(UiActions.onSetLoading(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UserForm)
