import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import ReactModal from 'react-modal'

import './Overlay.scss'
import './Modal.scss'

const Modal = ({ children, className, size, isOpen, onClose, closeOnOverlayClick, closeOnEscape }) => (
  <ReactModal
    className={classNames('Modal', {
      [`Modal_${size}`]: size,
      [className]: className,
    })}
    isOpen={isOpen}
    onRequestClose={onClose}
    shouldCloseOnOverlayClick={closeOnOverlayClick}
    shouldCloseOnEsc={closeOnEscape}
    ariaHideApp={false}
    overlayClassName="Overlay"
    contentLabel="Modal"
    bodyOpenClassName="Body_modalOpen"
  >
    {children}
  </ReactModal>
)

Modal.defaultProps = {
  size: 'md',
  closeOnOverlayClick: true,
  closeOnEscape: true,
}

Modal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  size: PropTypes.oneOf([
    'sm',
    'md',
    'lg',
    'xl',
  ]),
}

export default Modal
