import React, { Component } from 'react'
import classNames from 'classnames'
import { Link, withRouter } from 'react-router-dom'
import { getPath } from '../../../utils/Utils'

class ContentHeader extends Component {
  getPath = () => getPath(this.props.location.pathname)

  getSteps = path => {
    const steps = []
    path.map((p, i) => {
      steps.push(
        <Link key={i} to={p.url} className={classNames('k-content__head-breadcrumb-link', { disabled: i >= 2 })}>
          {p.label}
        </Link>,
      )

      if (i < path.length - 1) {
        steps.push(
          <span
            key={`s${i}`}
            className="k-content__head-breadcrumb-separator"
          />,
        )
      }
    })

    return steps
  }

  render = () => {
    const path = this.getPath()

    return (
      <div className="k-content__head k-grid__item">
        <div className="k-content__head-main">
          {/* <h3 className="k-content__head-title">
                        {path.pop().label}
                    </h3> */}
          <div className="k-content__head-breadcrumbs">
            <Link to="/" className="k-content__head-breadcrumb-link">
              <i className="flaticon2-shelter" />
            </Link>
            <span className="k-content__head-breadcrumb-separator" />

            {this.getSteps(path)}
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(ContentHeader)
