import React, { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Actions, connect, ActionTypes, Utils } from '../../Imports'
import AttributesForm from '../base/attributes/AttributesForm'

const Attributes = ({ storePost }) => {
  const location = useLocation()
  const storeId = useMemo(() => Utils.search(location).store, [])

  useEffect(
    () =>
      storePost(
        { storeId: storeId },
        'list-attributes',
        ActionTypes.FETCH_ATTRIBUTES_SUCCESS,
        ActionTypes.FETCH_ATTRIBUTES_FAILURE,
      ),
    [],
  )

  return (
    <>
      <Helmet>
        <title>Attributes | Admin Panel - Max Ecommerce</title>
      </Helmet>
      <AttributesForm />
    </>
  )
}

const mapDispatchToProps = dispatch => ({
  storePost: (payload, actionEvent, typeSuccess, typeError) =>
    dispatch(Actions.storePost(payload, actionEvent, typeSuccess, typeError)),
})

export default connect(null, mapDispatchToProps)(Attributes)
