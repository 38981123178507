import React, { Component } from 'react'

import classNames from 'classnames'
import { Alert as _Alert } from 'reactstrap'

class Alert extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: this.props.isOpen,
    }
  }

  toggle = e => {
    e.preventDefault()

    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  render = () => {
    const isMain = ['success', 'danger', 'warning', 'info'].includes(
      this.props.type,
    )

    return (
      <_Alert color={`outline-${this.props.type}`} isOpen={this.props.isOpen}>
        <div className="alert-icon">
          <i
            className={classNames(
              { 'flaticon-warning': isMain },
              { 'flaticon-questions-circular-button': !isMain },
            )}
          />
        </div>
        <div className="alert-text">{this.props.label}</div>
        <div className="alert-close">
          <button className="close" onClick={this.props.close}>
            <span>
              <i className="la la-close" />
            </span>
          </button>
        </div>
      </_Alert>
    )
  }
}

export default Alert
