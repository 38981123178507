import {
  React,
  Component,
  connect,
  Actions,
  ActionTypes,
  Portlet,
  PortletHeader,
  PortletBody,
  Section,
  Select,
  FormGroup,
  Label,
  Utils,
  Notification,
  // Routes,
  // Link,
  FormValidator,
  Button,
  _,
} from '../../../Imports'
import { Helmet } from 'react-helmet'

const entity = 'Profile'

class ServiceArea extends Component {
  constructor(props) {
    super(props)

    this.validator = new FormValidator([
      {
        field: 'address1',
        method: 'isEmpty',
        validWhen: false,
        message: 'The Address is required.',
      },
    ])

    this.state = {
      setting: null,
      submited: false,
      isCreate: false,
      btnLoading: false,
      defaultCountry: null,
      activeCountry: null,
      country: null,
    }
  }

  UNSAFE_componentWillMount = () => {
    let storeId = Utils.search(this.props.location).store

    if (!this.props.settings.data) {
      this.props.storePost(
        { id: storeId },
        'get-store',
        ActionTypes.FETCH_SETTINGS_SUCCESS,
        ActionTypes.FETCH_SETTINGS_FAILURE,
      ).then(resp => {
        let defaultCountry = _.get(resp.data, 'shipping.defaultCountry', {})
        let activeCountry = _.get(resp.data, 'shipping.activeCountry', {})
        this.setState({
          setting: resp.data,
          defaultCountry,
          activeCountry,
        })
      })
    } else {
      this.setState({
        setting: this.props.settings.data,
        defaultCountry: _.get(this.props.settings.data, 'shipping.defaultCountry', {}),
        activeCountry: _.get(this.props.settings.data, 'shipping.activeCountry', {}),
      })
    }
  }

  onChangeCountry = e => {
    this.setState({ defaultCountry: e })
  }

  onChangeActiveCountry = e => {
    this.setState({ activeCountry: e })
  }

  update = () => {
    this.setState({
      submited: !this.state.submited,
    })

    let isValid = true //this.validator.validate(this.state.fulfilment).isValid;
    let setting = this.state.setting
    let shipping = setting.shipping === null ? {} : setting.shipping
    shipping.defaultCountry = this.state.defaultCountry
    shipping.activeCountry = this.state.activeCountry
    setting.shipping = shipping

    if (isValid) {
      this.setState({ btnLoading: true })
      this.props.post(this.state.setting, 'update-store').then(resp => {
        this.response(resp)
      })
    }
  }

  response = resp => {
    this.setState({
      btnLoading: false,
    })

    let msn = _.replace(
      _.replace(process.env.REACT_APP_SUCCESS_ACTION, '%entity%', entity),
      '%action%',
      'Updated',
    )

    if (resp.status === 'success') {
      Notification.success(msn)
    } else {
      Notification.error(process.env.REACT_APP_ERROR_ACTION)
    }
  }

  render = () => (
    //const validator = this.state.submited
    //		? this.validator.validate(this.state.fulfilment)
    //		: this.validator.valid();

    <Portlet>
      <Helmet>
        <title>Service Area | Admin Panel - Max Ecommerce</title>
      </Helmet>
      <PortletHeader
        title={`Service Area - ${_.get(this.state.setting, 'name', '')}`}
        sticky
      >
        {/*
					<Link to={Routes.STORES} className="btn btn-secondary mr-3">
						<i className="la la-arrow-left" />
						Back
					</Link>
					*/}
        <Button
          label={'Update'}
          color="primary"
          icon={`la ${this.state.btnLoading ? '' : ' la-check'}`}
          isLoading={this.state.btnLoading}
          onClick={this.update}
        />
      </PortletHeader>
      <PortletBody>
        {this.state.setting === null && (
          <div className="row">
            <div className="col-12">
              <div>Loading...</div>
            </div>
          </div>
        )}

        {this.state.setting !== null && (
          <div className="row">
            <div className="col-xl-1" />
            <div className="col-xl-10">
              <Section title="Location" size="lg">
                <FormGroup className="row">
                  <Label className="col-3 col-form-label">
                    Default Country
                  </Label>
                  <div className="col-9">
                    <Select
                      options={this.props.country}
                      placeholder="Select the a default country"
                      value={this.state.defaultCountry}
                      onChange={e => this.onChangeCountry(e)}
                      isSearchable={true}
                    />
                  </div>
                </FormGroup>

                <FormGroup className="row">
                  <Label className="col-3 col-form-label">
                    Other Countries
                  </Label>
                  <div className="col-9">
                    <Select
                      options={this.props.country}
                      value={this.state.activeCountry}
                      onChange={e => this.onChangeActiveCountry(e)}
                      isSearchable={true}
                      isMulti
                      closeMenuOnSelect={false}
                    />
                  </div>
                </FormGroup>
              </Section>
            </div>
            <div className="col-xl-1" />
          </div>
        )}
      </PortletBody>
    </Portlet>
  )
}

export const mapStateToProps = state => ({
  settings: state.settings,
  country: state.country,
})

export const mapDispatchToProps = dispatch => ({
  post: function(payload, actionEvent) {
    return dispatch(Actions.post(payload, actionEvent))
  },
  storeGet: function(actionEvent, typeSuccess, typeError) {
    return dispatch(Actions.storeGet(actionEvent, typeSuccess, typeError))
  },
  storePost: function(payload, actionEvent, typeSuccess, typeError) {
    return dispatch(
      Actions.storePost(payload, actionEvent, typeSuccess, typeError),
    )
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ServiceArea)
