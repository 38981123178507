import React, { useEffect, useState } from 'react'
import Modal from '../../../layout/modal'
import { Portlet, PortletHeader, PortletBody } from '../../../../Imports'
import StrimpePayment from './strimpePayment'
import AuthorizePayment from './authorizePayment'

import './PaymentForm.scss'

const stripeInvalid = !process.env.REACT_APP_STRIPE_KEY ? 'invalid' : ''
const authorizeNetInvalid = false ? 'invalid' : ''

const PaymentForm = ({ modalToggle, isOpen, orderId, fetchOrder, storeId, payment, ...restProps }) => {
  const [paymentMethod, setPaymentMethod] = useState(0)

  const setPaymentMethodClick = method => () => setPaymentMethod(method)

  useEffect(() => !isOpen && setPaymentMethod(payment || 0), [isOpen])

  return (
    <Modal
      isOpen={isOpen}
      onClose={modalToggle}
      size="xl"
    >
      <Portlet className="payment-form">
        <PortletHeader title={paymentMethod ? 'Payment' : 'Select method'} />
        <PortletBody>
          {!paymentMethod && (
            <div className="select-method">
              <div
                className={stripeInvalid}
                onClick={setPaymentMethodClick(!stripeInvalid ? 1 : 0)}
              />
              <div
                className={authorizeNetInvalid}
                onClick={setPaymentMethodClick(!authorizeNetInvalid ? 2 : 0)}
              />
            </div>
          )}
          {!!paymentMethod && (() => {
            switch (paymentMethod) {
            case 1:
              return (
                <StrimpePayment
                  orderId={orderId}
                  fetchOrder={fetchOrder}
                  storeId={storeId}
                  modalToggle={modalToggle}
                  {...restProps}
                />
              )
              
            case 2:
              return (
                <AuthorizePayment
                  orderId={orderId}
                  fetchOrder={fetchOrder}
                  storeId={storeId}
                  modalToggle={modalToggle}
                  {...restProps}
                />
              )

            default:
              return null
            }
          })()}
        </PortletBody>
      </Portlet>
    </Modal>
  )
}

export default PaymentForm
