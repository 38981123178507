import {
  React,
  Component,
  connect,
  Actions,
  ActionTypes,
  Portlet,
  PortletHeader,
  PortletBody,
  Section,
  Select,
  FormGroup,
  Input,
  Label,
  Utils,
  Notification,
  // Routes,
  // Link,
  FormValidator,
  Separator,
  Button,
  _,
} from '../../../Imports'
import { Helmet } from 'react-helmet'

import MaskedInput from 'react-text-mask'

const entity = 'Profile'

const Type = {
  FREE: 'FREE',
  FLAT: 'FLAT',
  USPS: 'USPS',
  FEDEX: 'FEDEX',
}

const applyType = [
  { label: 'Order', value: 'ORDER' },
  { label: 'Each Item', value: 'EACH_ITEM' },
]

class ShippingMethod extends Component {
  constructor(props) {
    super(props)

    this.validator = new FormValidator([
      {
        field: 'address1',
        method: 'isEmpty',
        validWhen: false,
        message: 'The Address is required.',
      },
    ])

    this.state = {
      setting: null,
      submited: false,
      isCreate: false,
      btnLoading: false,
      activeStape: 1,
      flatRateMethod: null,
      freeShippingMethod: null,
      uspsMethod: null,
      fedexMethod: null,
    }
  }

  UNSAFE_componentWillMount = () => {
    let storeId = Utils.search(this.props.location).store

    if (!this.props.settings.data) {
      this.props.storePost(
        { id: storeId },
        'get-store',
        ActionTypes.FETCH_SETTINGS_SUCCESS,
        ActionTypes.FETCH_SETTINGS_FAILURE,
      ).then(resp => {
        let flatRateMethod = _.get(resp.data, 'shipping.flatRateMethod', {})
        let freeShippingMethod = _.get(resp.data, 'shipping.freeShippingMethod', {})
        let uspsMethod = _.get(resp.data, 'shipping.uspsMethod', {})
        let fedexMethod = _.get(resp.data, 'shipping.fedexMethod', {})
  
        this.setState({
          setting: resp.data,
          flatRateMethod: flatRateMethod === null ? {} : flatRateMethod,
          freeShippingMethod:
            freeShippingMethod === null ? {} : freeShippingMethod,
          uspsMethod: uspsMethod === null ? {} : uspsMethod,
          fedexMethod: fedexMethod === null ? {} : fedexMethod,
        })
      })
    } else {
      this.setState({
        setting: this.props.settings.data,
        flatRateMethod: _.get(this.props.settings.data, 'shipping.flatRateMethod', {}),
        freeShippingMethod: _.get(this.props.settings.data, 'shipping.freeShippingMethod', {}),
        uspsMethod: _.get(this.props.settings.data, 'shipping.uspsMethod', {}),
        fedexMethod: _.get(this.props.settings.data, 'shipping.fedexMethod', {}),
      })
    }
  }

  onChangeInput = (e, type) => {
    let method = this.getMethod(type)
    method[e.target.name] = e.target.value
    this.setMethod(type, method)
  }

  onChangeCheckbox = (e, type) => {
    let method = this.getMethod(type)
    method[e.target.name] = !_.get(method, e.target.name, false)
    this.setMethod(type, method)
  }

  onChangeMultiCheckbox = (e, name, type) => {
    let method = this.getMethod(type)
    method[name] = e
    this.setMethod(type, method)
  }

  onChangeSelect = (e, type, name) => {
    let method = this.getMethod(type)
    method[name] = e.value
    this.setMethod(type, method)
  }

  getMethod = type => {
    switch (String(type)) {
    case Type.FREE:
      return this.state.freeShippingMethod
    case Type.FLAT:
      return this.state.flatRateMethod
    case Type.USPS:
      return this.state.uspsMethod
    case Type.FEDEX:
      return this.state.fedexMethod
    default:
      break
    }
  }

  setMethod = (type, value) => {
    switch (type) {
    case Type.FREE:
      this.setState({ freeShippingMethod: value })
      break
    case Type.FLAT:
      this.setState({ flatRateMethod: value })
      break
    case Type.USPS:
      this.setState({ uspsMethod: value })
      break
    case Type.FEDEX:
      this.setState({ fedexMethod: value })
      break
    default:
      break
    }
  }

  update = () => {
    if (this.state.submited === false) {
      this.setState({
        submited: true,
      })
    }

    let isValid = true //this.validator.validate(this.state.fulfilment).isValid;
    let setting = this.state.setting

    // Shipping
    let shipping = setting.shipping === null ? {} : setting.shipping
    shipping.flatRateMethod = this.state.flatRateMethod
    shipping.freeShippingMethod = this.state.freeShippingMethod
    shipping.uspsMethod = this.state.uspsMethod
    shipping.fedexMethod = this.state.fedexMethod
    setting.shipping = shipping

    if (isValid) {
      this.setState({ btnLoading: true })
      this.props.post(setting, 'update-store').then(resp => {
        this.response(resp)
      })
    }
  }

  response = resp => {
    this.setState({
      btnLoading: false,
    })

    let msn = _.replace(
      _.replace(process.env.REACT_APP_SUCCESS_ACTION, '%entity%', entity),
      '%action%',
      'Updated',
    )

    if (resp.status === 'success') {
      Notification.success(msn)
    } else {
      Notification.error(process.env.REACT_APP_ERROR_ACTION)
    }
  }

  render = () => (
    //const validator = this.state.submited
    //		? this.validator.validate(this.state.fulfilment)
    //		: this.validator.valid();

    <Portlet>
      <Helmet>
        <title>Shipping Method | Admin Panel - Max Ecommerce</title>
      </Helmet>
      <PortletHeader
        title={`Shipping Method - ${_.get(this.state.setting, 'name', '')}`}
        sticky
      >
        {/*
					<Link to={Routes.STORES} className="btn btn-secondary mr-3">
						<i className="la la-arrow-left" />
						Back
					</Link>
					*/}
        <Button
          label={'Update'}
          color="primary"
          icon={`la ${this.state.btnLoading ? '' : ' la-check'}`}
          isLoading={this.state.btnLoading}
          onClick={this.update}
        />
      </PortletHeader>
      <PortletBody>
        {this.state.setting === null && (
          <div className="row">
            <div className="col-12">
              <div>Loading...</div>
            </div>
          </div>
        )}

        {this.state.setting !== null && (
          <div className="row">
            <div className="col-xl-1" />
            <div className="col-xl-10">
              <ul
                className="nav nav-tabs nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-brand"
                role="tablist"
              >
                <li
                  className="nav-item"
                  onClick={() => {
                    this.setState({
                      activeStape: 1,
                    })
                  }}
                >
                  <a
                    className="nav-link active"
                    data-toggle="tab"
                    href="#kt_tabs_19_15c9c30a225194"
                    role="tab"
                    aria-selected="true"
                  >
                    {/*
												<span className="nav-link-icon">
													<i className="flaticon2-graphic" />
												</span>
												*/}

                    <span className="nav-link-title">Free Shipping</span>
                  </a>
                </li>
                <li
                  className="nav-item"
                  onClick={() => {
                    this.setState({
                      activeStape: 2,
                    })
                  }}
                >
                  <a
                    className="nav-link"
                    data-toggle="tab"
                    href="#kt_tabs_19_25c9c30a225194"
                    role="tab"
                    aria-selected="false"
                  >
                    {/*
												<span className="nav-link-icon">
													<i className="flaticon2-position" />
												</span>
												*/}
                    <span className="nav-link-title">Flate Rate</span>
                  </a>
                </li>
                <li
                  className="nav-item"
                  onClick={() => {
                    this.setState({
                      activeStape: 3,
                    })
                  }}
                >
                  <a
                    className="nav-link"
                    data-toggle="tab"
                    href="#kt_tabs_19_25c9c30a225194"
                    role="tab"
                    aria-selected="false"
                  >
                    {/*
												<span className="nav-link-icon">
													<i className="flaticon2-position" />
												</span>
												*/}
                    <span className="nav-link-title">Usps</span>
                  </a>
                </li>
                <li
                  className="nav-item"
                  onClick={() => {
                    this.setState({
                      activeStape: 4,
                    })
                  }}
                >
                  <a
                    className="nav-link"
                    data-toggle="tab"
                    href="#kt_tabs_19_35c9c30a225194"
                    role="tab"
                    aria-selected="false"
                  >
                    {/*
												<span className="nav-link-icon">
													<i className="flaticon2-layers-1" />
												</span>
												*/}
                    <span className="nav-link-title">Fedex</span>
                  </a>
                </li>
              </ul>

              {this.state.activeStape === 1 && (
                <Section title="Settings" size="lg" className="mt-5">
                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">Active</Label>
                    <div className="col-9">
                      <span className="k-switch k-switch--md k-switch--icon">
                        <label>
                          <Input
                            checked={
                              this.state.freeShippingMethod.active
                                ? 'checked'
                                : ''
                            }
                            onChange={e => {
                              this.onChangeCheckbox(e, Type.FREE)
                            }}
                            name="active"
                            type="checkbox"
                          />
                          <span />
                        </label>
                      </span>
                    </div>
                  </FormGroup>

                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">Name</Label>
                    <div className="col-9">
                      <Input
                        value={this.state.freeShippingMethod.name}
                        onChange={e => {
                          this.onChangeInput(e, Type.FREE)
                        }}
                        name="name"
                      />
                    </div>
                  </FormGroup>

                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">
                      Shipping Method
                    </Label>
                    <div className="col-9">
                      <Input
                        value={this.state.freeShippingMethod.method}
                        onChange={e => {
                          this.onChangeInput(e, Type.FREE)
                        }}
                        name="method"
                      />
                    </div>
                  </FormGroup>

                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">
                      Minimum Amount
                    </Label>
                    <div className="col-9">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">$</span>
                        </div>
                        <MaskedInput
                          value={
                            this.state.freeShippingMethod.minimumOrderAmount
                          }
                          onChange={e => {
                            this.onChangeInput(e, Type.FREE)
                          }}
                          name="minimumOrderAmount"
                          mask={Utils.currencyMask}
                          className={'form-control'}
                        />
                      </div>
                    </div>
                  </FormGroup>

                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">All Regions</Label>
                    <div className="col-9">
                      <span className="k-switch k-switch--md k-switch--icon">
                        <label>
                          <Input
                            checked={
                              this.state.freeShippingMethod.allRegion
                                ? 'checked'
                                : ''
                            }
                            onChange={e => {
                              this.onChangeCheckbox(e, Type.FREE)
                            }}
                            name="allRegion"
                            type="checkbox"
                          />
                          <span />
                        </label>
                      </span>
                    </div>
                  </FormGroup>

                  {!_.get(
                    this.state.freeShippingMethod,
                    'allRegion',
                    false,
                  ) && (
                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">
                        Specific Region
                      </Label>
                      <div className="col-9">
                        <Select
                          options={this.props.country}
                          value={this.state.freeShippingMethod.regions}
                          onChange={e =>
                            this.onChangeMultiCheckbox(e, 'regions', Type.FREE)
                          }
                          isSearchable={true}
                          isMulti
                          closeMenuOnSelect={false}
                        />
                      </div>
                    </FormGroup>
                  )}

                  <Separator size="lg" border={'dashed'} />
                  <Section title="Sort" size="lg">
                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">Index</Label>
                      <div className="col-9">
                        <Input
                          value={this.state.freeShippingMethod.sort}
                          onChange={e => {
                            this.onChangeInput(e, Type.FREE)
                          }}
                          name="sort"
                        />
                      </div>
                    </FormGroup>
                  </Section>
                </Section>
              )}

              {this.state.activeStape === 2 && (
                <Section title="Settings" size="lg" className="mt-5">
                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">Active</Label>
                    <div className="col-9">
                      <span className="k-switch k-switch--md k-switch--icon">
                        <label>
                          <Input
                            checked={
                              this.state.flatRateMethod.active ? 'checked' : ''
                            }
                            onChange={e => {
                              this.onChangeCheckbox(e, Type.FLAT)
                            }}
                            name="active"
                            type="checkbox"
                          />
                          <span />
                        </label>
                      </span>
                    </div>
                  </FormGroup>

                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">Name</Label>
                    <div className="col-9">
                      <Input
                        value={this.state.flatRateMethod.name}
                        onChange={e => {
                          this.onChangeInput(e, Type.FLAT)
                        }}
                        name="name"
                      />
                    </div>
                  </FormGroup>

                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">
                      Shipping Method
                    </Label>
                    <div className="col-9">
                      <Input
                        value={this.state.flatRateMethod.method}
                        onChange={e => {
                          this.onChangeInput(e, Type.FLAT)
                        }}
                        name="method"
                      />
                    </div>
                  </FormGroup>

                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">Apply To</Label>
                    <div className="col-9">
                      <Select
                        options={applyType}
                        value={Utils.object(
                          applyType,
                          this.state.flatRateMethod.applyType,
                        )}
                        onChange={e =>
                          this.onChangeSelect(e, Type.FLAT, 'applyType')
                        }
                      />
                    </div>
                  </FormGroup>

                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">Price</Label>
                    <div className="col-9">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">$</span>
                        </div>
                        <MaskedInput
                          name="price"
                          value={this.state.flatRateMethod.price}
                          onChange={e => {
                            this.onChangeInput(e, Type.FLAT)
                          }}
                          mask={Utils.currencyMask}
                          className="form-control"
                        />
                      </div>
                    </div>
                  </FormGroup>

                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">All Regions</Label>
                    <div className="col-9">
                      <span className="k-switch k-switch--md k-switch--icon">
                        <label>
                          <Input
                            checked={
                              this.state.flatRateMethod.allRegion
                                ? 'checked'
                                : ''
                            }
                            onChange={e => {
                              this.onChangeCheckbox(e, Type.FLAT)
                            }}
                            name="allRegion"
                            type="checkbox"
                          />
                          <span />
                        </label>
                      </span>
                    </div>
                  </FormGroup>

                  {!_.get(this.state.flatRateMethod, 'allRegion', false) && (
                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">
                        Specific Region
                      </Label>
                      <div className="col-9">
                        <Select
                          options={this.props.country}
                          value={this.state.flatRateMethod.regions}
                          onChange={e =>
                            this.onChangeMultiCheckbox(e, 'regions', Type.FLAT)
                          }
                          isSearchable={true}
                          isMulti
                          closeMenuOnSelect={false}
                        />
                      </div>
                    </FormGroup>
                  )}
                  <Separator size="lg" border={'dashed'} />
                  <Section title="Sort" size="lg">
                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">Index</Label>
                      <div className="col-9">
                        <Input
                          value={this.state.flatRateMethod.sort}
                          onChange={e => {
                            this.onChangeInput(e, Type.FLAT)
                          }}
                          name="sort"
                        />
                      </div>
                    </FormGroup>
                  </Section>
                </Section>
              )}

              {this.state.activeStape === 3 && (
                <Section title="Settings" size="lg" className="mt-5">
                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">Active</Label>
                    <div className="col-9">
                      <span className="k-switch k-switch--md k-switch--icon">
                        <label>
                          <Input
                            checked={
                              this.state.uspsMethod.active ? 'checked' : ''
                            }
                            onChange={e => {
                              this.onChangeCheckbox(e, Type.USPS)
                            }}
                            name="active"
                            type="checkbox"
                          />
                          <span />
                        </label>
                      </span>
                    </div>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">Name</Label>
                    <div className="col-9">
                      <Input
                        value={this.state.uspsMethod.name}
                        onChange={e => {
                          this.onChangeInput(e, Type.USPS)
                        }}
                        name="name"
                      />
                    </div>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">All Regions</Label>
                    <div className="col-9">
                      <span className="k-switch k-switch--md k-switch--icon">
                        <label>
                          <Input
                            checked={
                              this.state.uspsMethod.allRegion ? 'checked' : ''
                            }
                            onChange={e => {
                              this.onChangeCheckbox(e, Type.USPS)
                            }}
                            name="allRegion"
                            type="checkbox"
                          />
                          <span />
                        </label>
                      </span>
                    </div>
                  </FormGroup>
                  {!_.get(this.state.uspsMethod, 'allRegion', false) && (
                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">
                        Specific Region
                      </Label>
                      <div className="col-9">
                        <Select
                          options={this.props.country}
                          value={this.state.uspsMethod.regions}
                          onChange={e =>
                            this.onChangeMultiCheckbox(e, 'regions', Type.USPS)
                          }
                          isSearchable={true}
                          isMulti
                          closeMenuOnSelect={false}
                        />
                      </div>
                    </FormGroup>
                  )}

                  <Separator size="lg" border={'dashed'} />
                  <Section title="Account" size="lg">
                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">User Id</Label>
                      <div className="col-9">
                        <Input
                          value={this.state.uspsMethod.userId}
                          onChange={e => {
                            this.onChangeInput(e, Type.USPS)
                          }}
                          name="userId"
                        />
                      </div>
                    </FormGroup>

                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">
                        Gateway Url
                      </Label>
                      <div className="col-9">
                        <Input
                          value={this.state.uspsMethod.gatewayUrl}
                          onChange={e => {
                            this.onChangeInput(e, Type.USPS)
                          }}
                          name="gatewayUrl"
                        />
                      </div>
                    </FormGroup>

                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">
                        Secure Gateway Url
                      </Label>
                      <div className="col-9">
                        <Input
                          value={this.state.uspsMethod.secureGatewayURL}
                          onChange={e => {
                            this.onChangeInput(e, Type.USPS)
                          }}
                          name="secureGatewayURL"
                        />
                      </div>
                    </FormGroup>

                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">
                        Allowed Methods
                      </Label>
                      <div className="col-9">
                        <Select
                          options={this.props.uspsServiceType}
                          value={this.state.uspsMethod.allowedMethods}
                          onChange={e =>
                            this.onChangeMultiCheckbox(
                              e,
                              'allowedMethods',
                              Type.USPS,
                            )
                          }
                          isSearchable={true}
                          isMulti
                          closeMenuOnSelect={false}
                        />
                      </div>
                    </FormGroup>

                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">
                        Weight Type
                      </Label>
                      <div className="col-9">
                        <Select
                          options={this.props.fedexWeightType}
                          value={this.state.uspsMethod.weightType}
                          onChange={e =>
                            this.onChangeMultiCheckbox(
                              e,
                              'weightType',
                              Type.USPS,
                            )
                          }
                          isSearchable={true}
                        />
                      </div>
                    </FormGroup>

                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">
                        Maximum Package Weight
                      </Label>
                      <div className="col-9">
                        <div className="input-group">
                          <Input
                            value={this.state.uspsMethod.maxPackageWeight}
                            onChange={e => {
                              this.onChangeInput(e, Type.USPS)
                            }}
                            name="maxPackageWeight"
                          />
                        </div>
                      </div>
                    </FormGroup>
                  </Section>

                  <Separator size="lg" border={'dashed'} />
                  <Section title="Sort" size="lg">
                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">Index</Label>
                      <div className="col-9">
                        <Input
                          value={this.state.uspsMethod.sort}
                          onChange={e => {
                            this.onChangeInput(e, Type.USPS)
                          }}
                          name="sort"
                        />
                      </div>
                    </FormGroup>
                  </Section>

                  <Separator size="lg" border={'dashed'} />
                  <Section title="Tracking" size="lg">
                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">Url</Label>
                      <div className="col-9">
                        <Input
                          value={this.state.uspsMethod.trackingUrl}
                          onChange={e => {
                            this.onChangeInput(e, Type.USPS)
                          }}
                          name="trackingUrl"
                        />
                      </div>
                    </FormGroup>
                  </Section>
                </Section>
              )}

              {this.state.activeStape === 4 && (
                <Section title="Settings" size="lg" className="mt-5">
                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">Active</Label>
                    <div className="col-9">
                      <span className="k-switch k-switch--md k-switch--icon">
                        <label>
                          <Input
                            checked={
                              this.state.fedexMethod.active ? 'checked' : ''
                            }
                            onChange={e => {
                              this.onChangeCheckbox(e, Type.FEDEX)
                            }}
                            name="active"
                            type="checkbox"
                          />
                          <span />
                        </label>
                      </span>
                    </div>
                  </FormGroup>

                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">Name</Label>
                    <div className="col-9">
                      <Input
                        value={this.state.fedexMethod.name}
                        onChange={e => {
                          this.onChangeInput(e, Type.FEDEX)
                        }}
                        name="name"
                      />
                    </div>
                  </FormGroup>
                  <Separator size="lg" border={'dashed'} />

                  <Section title="Account" size="lg">
                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">
                        Sandbox Mode
                      </Label>
                      <div className="col-9">
                        <span className="k-switch k-switch--md k-switch--icon">
                          <label>
                            <Input
                              checked={
                                this.state.fedexMethod.sandboxMode
                                  ? 'checked'
                                  : ''
                              }
                              onChange={e => {
                                this.onChangeCheckbox(e, Type.FEDEX)
                              }}
                              name="sandboxMode"
                              type="checkbox"
                            />
                            <span />
                          </label>
                        </span>
                      </div>
                    </FormGroup>
                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">Account Id</Label>
                      <div className="col-9">
                        <Input
                          value={this.state.fedexMethod.accountId}
                          onChange={e => {
                            this.onChangeInput(e, Type.FEDEX)
                          }}
                          name="accountId"
                        />
                      </div>
                    </FormGroup>
                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">
                        Meter Number
                      </Label>
                      <div className="col-9">
                        <Input
                          value={this.state.fedexMethod.meterNumber}
                          onChange={e => {
                            this.onChangeInput(e, Type.FEDEX)
                          }}
                          name="meterNumber"
                        />
                      </div>
                    </FormGroup>
                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">Key</Label>
                      <div className="col-9">
                        <Input
                          value={this.state.fedexMethod.key}
                          onChange={e => {
                            this.onChangeInput(e, Type.FEDEX)
                          }}
                          name="key"
                        />
                      </div>
                    </FormGroup>
                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">Hub ID</Label>
                      <div className="col-9">
                        <Input
                          value={this.state.fedexMethod.hubId}
                          onChange={e => {
                            this.onChangeInput(e, Type.FEDEX)
                          }}
                          name="hubId"
                        />
                      </div>
                    </FormGroup>
                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">Password</Label>
                      <div className="col-9">
                        <Input
                          value={this.state.fedexMethod.password}
                          onChange={e => {
                            this.onChangeInput(e, Type.FEDEX)
                          }}
                          type="password"
                          name="password"
                        />
                      </div>
                    </FormGroup>
                  </Section>
                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">
                      Web Services URL
                    </Label>
                    <div className="col-9">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">Http</span>
                        </div>
                        <Input
                          value={this.state.fedexMethod.webServicesURL}
                          onChange={e => {
                            this.onChangeInput(e, Type.FEDEX)
                          }}
                          name="webServicesURL"
                        />
                      </div>
                    </div>
                  </FormGroup>

                  <Separator size="lg" border={'dashed'} />

                  <Section title="Shipment" size="lg">
                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">
                        Package Type
                      </Label>
                      <div className="col-9">
                        <Select
                          options={this.props.fedexPackageType}
                          value={this.state.fedexMethod.packageType}
                          onChange={e =>
                            this.onChangeMultiCheckbox(
                              e,
                              'packageType',
                              Type.FEDEX,
                            )
                          }
                          isSearchable={true}
                        />
                      </div>
                    </FormGroup>
                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">
                        Dropoff Type
                      </Label>
                      <div className="col-9">
                        <Select
                          options={this.props.fedexDropoffType}
                          value={this.state.fedexMethod.dropoffType}
                          onChange={e =>
                            this.onChangeMultiCheckbox(
                              e,
                              'dropoffType',
                              Type.FEDEX,
                            )
                          }
                          isSearchable={true}
                        />
                      </div>
                    </FormGroup>
                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">
                        Weight Type
                      </Label>
                      <div className="col-9">
                        <Select
                          options={this.props.fedexWeightType}
                          value={this.state.fedexMethod.weightType}
                          onChange={e =>
                            this.onChangeMultiCheckbox(
                              e,
                              'weightType',
                              Type.FEDEX,
                            )
                          }
                          isSearchable={true}
                        />
                      </div>
                    </FormGroup>
                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">Label Type</Label>
                      <div className="col-9">
                        <Select
                          options={this.props.fedexLabelStockType}
                          value={this.state.fedexMethod.labelStockType}
                          onChange={e =>
                            this.onChangeMultiCheckbox(
                              e,
                              'labelStockType',
                              Type.FEDEX,
                            )
                          }
                          isSearchable={true}
                        />
                      </div>
                    </FormGroup>
                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">
                        Allowed Methods
                      </Label>
                      <div className="col-9">
                        <Select
                          options={this.props.fedexServiceType}
                          value={this.state.fedexMethod.allowedMethods}
                          onChange={e =>
                            this.onChangeMultiCheckbox(
                              e,
                              'allowedMethods',
                              Type.FEDEX,
                            )
                          }
                          isSearchable={true}
                          isMulti
                          closeMenuOnSelect={false}
                        />
                      </div>
                    </FormGroup>
                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">
                        Free Method
                      </Label>
                      <div className="col-9">
                        <Select
                          options={this.props.fedexServiceType}
                          value={this.state.fedexMethod.freeMethod}
                          onChange={e =>
                            this.onChangeMultiCheckbox(
                              e,
                              'freeMethod',
                              Type.FEDEX,
                            )
                          }
                          isSearchable={true}
                        />
                      </div>
                    </FormGroup>

                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">
                        Maximum Package Weight
                      </Label>
                      <div className="col-9">
                        <div className="input-group">
                          <Input
                            value={this.state.fedexMethod.maxPackageWeight}
                            onChange={e => {
                              this.onChangeInput(e, Type.FEDEX)
                            }}
                            name="maxPackageWeight"
                          />
                        </div>
                      </div>
                    </FormGroup>

                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">
                        Residential Delivery
                      </Label>
                      <div className="col-9">
                        <span className="k-switch k-switch--md k-switch--icon">
                          <label>
                            <Input
                              checked={
                                this.state.fedexMethod.residentialDelivery
                                  ? 'checked'
                                  : ''
                              }
                              onChange={e => {
                                this.onChangeCheckbox(e, Type.FEDEX)
                              }}
                              name="residentialDelivery"
                              type="checkbox"
                            />
                            <span />
                          </label>
                        </span>
                      </div>
                    </FormGroup>

                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">
                        Enable Free Shipping Threshold
                      </Label>
                      <div className="col-9">
                        <span className="k-switch k-switch--md k-switch--icon">
                          <label>
                            <Input
                              checked={
                                this.state.fedexMethod.enableFreeShippingAmount
                                  ? 'checked'
                                  : ''
                              }
                              onChange={e => {
                                this.onChangeCheckbox(e, Type.FEDEX)
                              }}
                              name="enableFreeShippingAmount"
                              type="checkbox"
                            />
                            <span />
                          </label>
                        </span>
                      </div>
                    </FormGroup>
                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">
                        Free Shipping Amount Threshold
                      </Label>
                      <div className="col-9">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">$</span>
                          </div>
                          <MaskedInput
                            value={this.state.fedexMethod.freeShippingAmount}
                            onChange={e => {
                              this.onChangeInput(e, Type.FEDEX)
                            }}
                            name="freeShippingAmount"
                            mask={Utils.currencyMask}
                            className={'form-control'}
                          />
                        </div>
                      </div>
                    </FormGroup>
                  </Section>

                  <Separator size="lg" border={'dashed'} />

                  <Section title="Region" size="lg">
                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">
                        All Regions
                      </Label>
                      <div className="col-9">
                        <span className="k-switch k-switch--md k-switch--icon">
                          <label>
                            <Input
                              checked={
                                this.state.fedexMethod.allRegion
                                  ? 'checked'
                                  : ''
                              }
                              onChange={e => {
                                this.onChangeCheckbox(e, Type.FEDEX)
                              }}
                              name="allRegion"
                              type="checkbox"
                            />
                            <span />
                          </label>
                        </span>
                      </div>
                    </FormGroup>

                    {!_.get(this.state.fedexMethod, 'allRegion', false) && (
                      <FormGroup className="row">
                        <Label className="col-3 col-form-label">
                          Specific Region
                        </Label>
                        <div className="col-9">
                          <Select
                            options={this.props.country}
                            value={this.state.fedexMethod.regions}
                            onChange={e =>
                              this.onChangeMultiCheckbox(
                                e,
                                'regions',
                                Type.FEDEX,
                              )
                            }
                            isSearchable={true}
                            isMulti
                            closeMenuOnSelect={false}
                          />
                        </div>
                      </FormGroup>
                    )}
                  </Section>

                  <Separator size="lg" border={'dashed'} />
                  <Section title="Sort" size="lg">
                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">Index</Label>
                      <div className="col-9">
                        <Input
                          value={this.state.fedexMethod.sort}
                          onChange={e => {
                            this.onChangeInput(e, Type.FEDEX)
                          }}
                          name="sort"
                        />
                      </div>
                    </FormGroup>
                  </Section>
                  <Separator size="lg" border={'dashed'} />
                  <Section title="Tracking" size="lg">
                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">Url</Label>
                      <div className="col-9">
                        <Input
                          value={this.state.fedexMethod.trackingUrl}
                          onChange={e => {
                            this.onChangeInput(e, Type.FEDEX)
                          }}
                          name="trackingUrl"
                        />
                      </div>
                    </FormGroup>
                  </Section>
                </Section>
              )}
            </div>
            <div className="col-xl-1" />
          </div>
        )}
      </PortletBody>
    </Portlet>
  )
}

export const mapStateToProps = state => ({
  settings: state.settings,
  country: state.country,
  fedexServiceType: state.fedexServiceType,
  fedexPackageType: state.fedexPackageType,
  fedexDropoffType: state.fedexDropoffType,
  fedexWeightType: state.fedexWeightType,
  fedexLabelStockType: state.fedexLabelStockType,

  uspsServiceType: state.uspsServiceType,
})

export const mapDispatchToProps = dispatch => ({
  post: function(payload, actionEvent) {
    return dispatch(Actions.post(payload, actionEvent))
  },
  storeGet: function(actionEvent, typeSuccess, typeError) {
    return dispatch(Actions.storeGet(actionEvent, typeSuccess, typeError))
  },
  storePost: function(payload, actionEvent, typeSuccess, typeError) {
    return dispatch(
      Actions.storePost(payload, actionEvent, typeSuccess, typeError),
    )
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ShippingMethod)
