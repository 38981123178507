import React, { useState, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import Modal from '../../layout/modal'
import {
  connect,
  Actions,
  Section,
  Portlet,
  PortletHeader,
  PortletBody,
  classNames,
  Button,
  ActionTypes,
  Notification,
  Utils,
  FormGroup,
  Label,
  Input,
} from '../../../Imports'

import AttributesForm from '../attributes/AttributesForm'
import AttributesTable from '../attributes/AttributesTable'

import VariantsTable from './VariantsTable'
import ChooseValue from './ChooseValue'
import EditForm from './EditForm'

import './VariantsForm.scss'

const VariantsForm = ({ variants, attributes, storePost, post, productId, filterVariants }) => {
  const location = useLocation()
  const storeId = useMemo(() => Utils.search(location).store, [])
  // State
  const [loading, setLoading] = useState(false)
  const [isComplex, setIsComplex] = useState(false)
  const [isTableOpen, setIsOpenIsTableOpen] = useState(false)
  const [isAttributesOpen, setIsAttributesOpen] = useState(false)
  const [isEdtOpen, setIsEdtOpen] = useState(false)
  const [isCreate, setIsCreate] = useState(false)
  const [variant, setVariant] = useState(null)
  const [selectedAttributes, setSelectedAttributes] = useState([])
  const [selectedValues, setSelectedValues] = useState([])

  const isComlpexToggle = () => setIsComplex(prevValue => !prevValue)

  const tableModalToggle = () => {
    setIsOpenIsTableOpen(prevIsOpen => !prevIsOpen)
    if (isTableOpen) setIsCreate(false)
  }

  const isEditModalToggle = () => {
    setIsEdtOpen(prevIsOpen => !prevIsOpen)
    if (isEdtOpen) setVariant(null)
  }
  
  const attributesModalToggle = () => setIsAttributesOpen(prevIsOpen => !prevIsOpen)

  const onAttributesSelect = newAttr => {
    const attr = selectedAttributes.find(({ id }) => id === newAttr.id)

    if (!!attr) {
      setSelectedAttributes(selectedAttributes.filter(({ id }) => !( id === newAttr.id )))
    } else {
      setSelectedAttributes(prevAttributes => [...prevAttributes, newAttr])
    }

    if (!!selectedValues.length) setSelectedValues([])
  }

  const onValueSelect = newValue => {
    const value = selectedValues.find(({ value }) => value === newValue.value)

    if (!!value) {
      setSelectedValues(selectedValues.filter(({ value }) => !( value === newValue.value )))
    } else {
      setSelectedValues(prevValues => [...prevValues, newValue])
    }
  }

  const clearState = () => {
    setSelectedAttributes([])
    setSelectedValues([])
    setIsComplex(false)
  }

  const setEdit = id => () => {
    isEditModalToggle()
    setVariant(variants.find(({ id: variantId }) => id === variantId))
  }

  const editVariant = () => {
    setLoading(true)
    post(variant,  'update-product').then(() => {
      getVariants()
      isEditModalToggle()
      setLoading(false)
      Notification.success('The Variant has been updated successfully')
    }).catch(() => setLoading(false))
  }

  const deleteVariant = id => () => {
    post(
      variants.find(({ id: variantId }) => id === variantId),
      'delete-product',
    ).then(() => {
      getVariants()
      Notification.success('The Variant has been deleted successfully')
    })
    filterVariants(variants.filter(({ id: variantId }) => !(variantId === id)))
  }

  const getVariants = () => {
    storePost(
      { productId },
      'list-variants',
      ActionTypes.FETCH_VARIANTS_SUCCESS,
      ActionTypes.FETCH_VARIANTS_FAILURE,
    )
  }

  const onSubmitHandle = () => {
    setLoading(true)
    post(
      {
        productId,
        storeId,
        isComplex,
        attributes: selectedValues,
      },
      'create-variant',
    ).then(({ status, data }) => {
      getVariants()
      clearState()
      tableModalToggle()
      setLoading(false)
      if (status === 'error') Notification.error(data)
      else Notification.success('The Variant has been created successfully')
    }).catch(() => setLoading(false))
  }

  return (
    <>
      <Section
        title="Product Variants"
        className="variants-form"
      >
        <Button
          label="New Variant"
          color="primary"
          className="add-attribute"
          onClick={tableModalToggle}
          disabled={!productId}
        />
        <VariantsTable
          deleteVariant={deleteVariant}
          setEdit={setEdit}
          variants={variants}
        />
      </Section>
      <Modal
        isOpen={isTableOpen}
        onClose={tableModalToggle}
        closeOnOverlayClick={false}
        closeOnEscape={false}
        className="create-variant-popup"
        size="xl"
      >
        <Portlet>
          <PortletHeader title="New Variant">
            <Button
              label="Back"
              className="btn btn-secondary mr-3"
              icon="la la-arrow-left"
              onClick={tableModalToggle}
              disabled={loading}
            />
            {!isCreate && (
              <Button
                label="New Attribute"
                color="primary"
                className="add-new-attribute"
                onClick={attributesModalToggle}
              />
            )}
          </PortletHeader>
          <PortletBody>
            <Section
              title={`Choose ${!isCreate ? 'Attribute' : 'Value'}`}
              className="attribute-table-section"
            >
              <div className={`progress-count ${!isCreate ? 'left' : 'right'}`} />
              <FormGroup className="row position-absolute is-complex-switch">
                <Label className="col-8 col-form-label">Complex Variant</Label>
                <div className="col-3">
                  <span className="k-switch k-switch--md k-switch--icon">
                    <label>
                      <Input
                        checked={isComplex ? 'checked' : ''}
                        onChange={isComlpexToggle}
                        type="checkbox"
                      />
                      <span />
                    </label>
                  </span>
                </div>
              </FormGroup>
              {!isCreate ? (
                <>
                  <AttributesTable
                    attributes={attributes}
                    selectedAttributes={selectedAttributes}
                    onChange={onAttributesSelect}
                    isVariants
                  />
                </>
              ) : (
                <ChooseValue
                  selectedAttributes={selectedAttributes}
                  selectedValues={selectedValues}
                  onSelect={onValueSelect}
                />
              )}
              <Button
                label={!isCreate ? 'Next' : 'Create Variant'}
                color="primary"
                className="next-button"
                icon={isCreate && `la ${classNames({ 'la-check': !loading })}`}
                isLoading={loading}
                disabled={!isCreate ? !selectedAttributes.length : loading}
                onClick={() => !isCreate ? setIsCreate(true) : onSubmitHandle()}
              />
            </Section>
          </PortletBody>
        </Portlet>
        <Modal
          isOpen={isAttributesOpen}
          onClose={attributesModalToggle}
          closeOnOverlayClick={false}
          closeOnEscape={false}
          size="xl"
        >
          <AttributesForm isVariants onClose={attributesModalToggle} />
        </Modal>
      </Modal>
      <Modal
        isOpen={isEdtOpen}
        onClose={isEditModalToggle}
        closeOnOverlayClick={false}
        closeOnEscape={false}
        className="edit-variant-popup"
        size="xl"
      >
        <EditForm
          post={post}
          loading={loading}
          variant={variant}
          setVariant={setVariant}
          editVariant={editVariant}
          onClose={isEditModalToggle}
        />
      </Modal>
    </>
  )
}

const mapDispatchToProps = dispatch => ({
  post: (payload, actionEvent) => dispatch(Actions.post(payload, actionEvent)),
  storePost: (payload, actionEvent, typeSuccess, typeError) =>
    dispatch(Actions.storePost(payload, actionEvent, typeSuccess, typeError)),
  filterVariants: data => dispatch({ type: ActionTypes.FETCH_VARIANTS_SUCCESS, payload: { data } }),
})

const mapStateToProps = ({ variants, attributes }) => ({
  variants: variants || [],
  attributes: attributes || [],
})

export default connect(mapStateToProps, mapDispatchToProps)(VariantsForm)
