import {
  React,
  Component,
  connect,
  Actions,
  ActionTypes,
  Portlet,
  PortletHeader,
  PortletBody,
  Section,
  FormGroup,
  Input,
  Label,
  FormFeedback,
  Utils,
  Notification,
  // Routes,
  // Link,
  FormValidator,
  Button,
  _,
} from '../../../Imports'
import { Helmet } from 'react-helmet'

const entity = 'Social Links'

class Social extends Component {
  constructor(props) {
    super(props)

    this.validator = new FormValidator([
      {
        field: 'websiteUrl',
        method: 'isEmpty',
        validWhen: false,
        message: 'The Store Url is required.',
      },
    ])

    this.state = {
      setting: null,
      submited: false,
      isCreate: false,
      btnLoading: false,
    }
  }

  UNSAFE_componentWillMount = () => {
    let storeId = Utils.search(this.props.location).store

    if (!this.props.settings.data) {
      this.props.storePost(
        { id: storeId },
        'get-store',
        ActionTypes.FETCH_SETTINGS_SUCCESS,
        ActionTypes.FETCH_SETTINGS_FAILURE,
      ).then(resp => {
        this.setState({
          setting: resp.data,
        })
      })
    } else {
      this.setState({
        setting: this.props.settings.data,
      })
    }
  }

  onChangeInput = e => {
    let setting = this.state.setting
    setting[e.target.name] = e.target.value

    this.setState({ setting })
  }

  update = () => {
    this.setState({
      submited: !this.state.submited,
    })
    let isValid = this.validator.validate(this.state.setting).isValid

    if (isValid) {
      this.setState({ btnLoading: true })
      this.props.post(this.state.setting, 'update-store').then(resp => {
        this.response(resp)
      })
    }
  }

  response = resp => {
    this.setState({
      btnLoading: false,
    })

    let msn = _.replace(
      _.replace(process.env.REACT_APP_SUCCESS_ACTION, '%entity%', entity),
      '%action%',
      'Updated',
    )

    if (resp.status === 'success') {
      Notification.success(msn)
    } else {
      Notification.error(process.env.REACT_APP_ERROR_ACTION)
    }
  }

  render = () => {
    const validator = this.state.submited
      ? this.validator.validate(this.state.setting)
      : this.validator.valid()

    return (
      <Portlet>
        <Helmet>
          <title>Social Media | Admin Panel - Max Ecommerce</title>
        </Helmet>
        <PortletHeader
          title={`Social Media - ${_.get(this.state.setting, 'name', '')}`}
          sticky
        >
          {/*
					<Link to={Routes.STORES} className="btn btn-secondary mr-3">
						<i className="la la-arrow-left" />
						Back
					</Link>
					*/}
          <Button
            label={'Update'}
            color="primary"
            icon={`la ${this.state.btnLoading ? '' : ' la-check'}`}
            isLoading={this.state.btnLoading}
            onClick={this.update}
          />
        </PortletHeader>
        <PortletBody>
          {this.state.setting === null && (
            <div className="row">
              <div className="col-12">
                <div>Loading...</div>
              </div>
            </div>
          )}

          {this.state.setting !== null && (
            <div className="row">
              <div className="col-xl-1" />
              <div className="col-xl-10">
                <Section title="Social Links">
                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">Facebook</Label>
                    <div className="col-9">
                      <div className="k-input-icon k-input-icon--left">
                        <Input
                          value={this.state.setting.facebook}
                          onChange={this.onChangeInput}
                          name="facebook"
                          placeholder="https://facebook.com"
                        />
                        <span className="k-input-icon__icon k-input-icon__icon--left">
                          <span>
                            <i
                              style={{
                                fontSize: 25,
                              }}
                              className="la la-facebook-official"
                            />
                          </span>
                        </span>
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">Instagram </Label>
                    <div className="col-9">
                      <div className="k-input-icon k-input-icon--left">
                        <Input
                          value={this.state.setting.instagram}
                          onChange={this.onChangeInput}
                          name="instagram"
                          placeholder="https://instagram.com"
                        />
                        <span className="k-input-icon__icon k-input-icon__icon--left">
                          <span>
                            <i
                              style={{
                                fontSize: 25,
                              }}
                              className="la la-instagram"
                            />
                          </span>
                        </span>
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">Twitter </Label>
                    <div className="col-9">
                      <div className="k-input-icon k-input-icon--left">
                        <Input
                          value={this.state.setting.twitter}
                          onChange={this.onChangeInput}
                          name="twitter"
                          placeholder="https://twitter.com"
                        />
                        <span className="k-input-icon__icon k-input-icon__icon--left">
                          <span>
                            <i
                              style={{
                                fontSize: 25,
                              }}
                              className="la la-twitter-square"
                            />
                          </span>
                        </span>
                      </div>
                    </div>
                  </FormGroup>

                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">Yelp </Label>
                    <div className="col-9">
                      <div className="k-input-icon k-input-icon--left">
                        <Input
                          value={this.state.setting.yelp}
                          onChange={this.onChangeInput}
                          name="yelp"
                          placeholder="https://yelp.com"
                        />
                        <span className="k-input-icon__icon k-input-icon__icon--left">
                          <span>
                            <i
                              style={{
                                fontSize: 25,
                              }}
                              className="la la-yelp"
                            />
                          </span>
                        </span>
                      </div>
                    </div>
                  </FormGroup>

                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">Store Url *</Label>
                    <div className="col-9">
                      <div
                        style={{ height: 38 }}
                        className="k-input-icon k-input-icon--left"
                      >
                        <Input
                          value={this.state.setting.websiteUrl}
                          onChange={this.onChangeInput}
                          name="websiteUrl"
                          placeholder="https://business.com"
                          invalid={validator.websiteUrl.isInvalid}
                        />
                        <FormFeedback>
                          {validator.websiteUrl.message}
                        </FormFeedback>
                        <span className="k-input-icon__icon k-input-icon__icon--left">
                          <span>
                            <i
                              style={{
                                fontSize: 25,
                              }}
                              className="la la-laptop"
                            />
                          </span>
                        </span>
                      </div>
                    </div>
                  </FormGroup>
                </Section>
              </div>
              <div className="col-xl-1" />
            </div>
          )}
        </PortletBody>
      </Portlet>
    )
  }
}

export const mapStateToProps = state => ({
  settings: state.settings,
})

export const mapDispatchToProps = dispatch => ({
  post: function(payload, actionEvent) {
    return dispatch(Actions.post(payload, actionEvent))
  },
  storeGet: function(actionEvent, typeSuccess, typeError) {
    return dispatch(Actions.storeGet(actionEvent, typeSuccess, typeError))
  },
  storePost: function(payload, actionEvent, typeSuccess, typeError) {
    return dispatch(
      Actions.storePost(payload, actionEvent, typeSuccess, typeError),
    )
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Social)
