import React from 'react'
import { Input } from '../../../Imports'

const ChooseValue = ({ selectedAttributes, selectedValues, onSelect }) => (
  <div className="create-section">
    {selectedAttributes.map(({ key: name, id, options }) => (
      <div key={id}>
        <h6>{name}</h6>
        <div>
          {options.map(value => (
            <div key={value}>
              <Input
                type="checkbox"
                checked={!!selectedValues.find(({ value: selectedValue }) => selectedValue === value)}
                onChange={() => onSelect({ id, name, value })}
              />
              <span>{value}</span>
            </div>
          ))}
        </div>
      </div>
    ))}
  </div>
)

export default ChooseValue
