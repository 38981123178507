import ActionTypes from './ActionTypes'
import { Auth, API } from 'aws-amplify'

let name = process.env.REACT_APP_API_GATEWAY_NAME

export const storeGet = (actionEvent, typeSuccess, typeError) => async (
  dispatch,
  getState,
) => {
  let session = await Auth.currentSession()

  let apiName = name
  let path = '/api/private'

  let myInit = {
    headers: {
      'Action-Event': actionEvent,
      'Access-Token': session.accessToken.jwtToken,
    },
    response: true,
    queryStringParameters: {},
  }

  return API.get(apiName, path, myInit)
    .then(response => {
      dispatch({
        type: typeSuccess,
        payload: response.data,
      })
    })
    .catch(error => {
      console.log(error)
      dispatch({
        type: typeError,
        error: error,
      })
    })
}

export const storePost = (
  payload,
  actionEvent,
  typeSuccess,
  typeError,
) => async (dispatch, getState) => {
  let session = await Auth.currentSession()

  let apiName = name
  let path = '/api/private'

  let myInit = {
    body: payload,
    response: true,
    headers: {
      'Action-Event': actionEvent,
      'Access-Token': session.accessToken.jwtToken,
    },
  }

  return API.post(apiName, path, myInit)
    .then(response => {
      dispatch({
        type: typeSuccess,
        payload: response.data,
      })
      return response.data
    })
    .catch(error => {
      dispatch({
        type: typeError,
        error: error,
      })
      return error
    })
}

export const storePostProducts = (payload, actionEvent, typeSuccess, typeError) => async (dispatch, getState) => {
  const session = await Auth.currentSession()
  const apiName = name
  const path = '/api/private'
  const myInit = {
    body: payload,
    response: true,
    headers: {
      'Action-Event': actionEvent,
      'Access-Token': session.accessToken.jwtToken,
    },
  }
  const resp = await API.post(apiName, path, myInit).catch(console.log)
  let products = (Array.isArray(resp.data.data) ? resp.data.data : resp.data.data.data) || []
  if (resp.data.data.totalPages > 1 && !Array.isArray(resp.data.data)) {
    const requests = []
    for (let i = 2; i <= resp.data.data.totalPages; i++) {
      myInit.body.page = i
      requests.push(API.post(apiName, path, myInit).then(resp => resp.data.data.data).catch(console.log))
    }
    delete myInit.body.page
    await Promise.all(requests)
      .then(data => {
        data.forEach(newProducts => {
          products = [...products, ...(newProducts || [])]
        })
      })
  }
  dispatch({
    type: typeSuccess,
    payload: Array.isArray(resp.data.data) ? resp.data : ({
      ...resp.data.data,
      data: products,
    }),
  })
  return new Promise(resolve => resolve({ data: products }))
}

export const storePostProductsTable = (payload, actionEvent, typeLoading,typeSuccess, typeError) => async (dispatch, getState) => {
  dispatch({type: typeLoading})
  const session = await Auth.currentSession()
  const apiName = name
  const path = '/api/private'
  const myInit = {
    body: { ...payload},
    response: true,
    headers: {
      'Action-Event': actionEvent,
      'Access-Token': session.accessToken.jwtToken,
    },
  }
  const resp = await API.post(apiName, path, myInit).catch(console.log)
  let products = (Array.isArray(resp.data.data) ? resp.data.data : resp.data.data.data) || []
  dispatch({
    type: typeSuccess,
    payload: Array.isArray(resp.data.data) ? resp.data : ({
      ...resp.data.data,
      data: products,
    }),
  })
  return new Promise(resolve => resolve({ data: products }))
}


export const post = (payload, actionEvent, path = '/api/private') => async (
  dispatch,
  getState,
) => {
  let session = await Auth.currentSession()
  let apiName = name
  let myInit = {
    body: payload,
    response: true,
    headers: {
      'Action-Event': actionEvent,
      'Access-Token': session.accessToken.jwtToken,
    },
  }

  return API.post(apiName, path, myInit)
    .then(response => response.data)
    .catch(error => error)
}

export const get = actionEvent => async (dispatch, getState) => {
  let session = await Auth.currentSession()

  let apiName = name
  let path = '/api/private'

  let myInit = {
    headers: {
      'Action-Event': actionEvent,
      'Access-Token': session.accessToken.jwtToken,
    },
    response: true,
    queryStringParameters: {},
  }

  return API.get(apiName, path, myInit)
    .then(response => response.data)
    .catch(error => error)
}

export const onChangeCurrentStore = storeId => ({
  type: ActionTypes.CHANGE_CURRENT_STORE,
  payload: storeId,
})

export const clearProductVariants = () => ({
  type: ActionTypes.CLEAR_VARIANTS,
})
