import React from 'react'
import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from 'react-spinners/BounceLoader'

function Loader({ active }) {
  return (
    <LoadingOverlay
      styles={{
        wrapper: {
          display: active ? 'block' : 'none',
          position: 'absolute',
          width: '100%',
          height: '100%',
        },
      }}
      active={active}
      spinner={<BounceLoader />}
    />
  )
}

export default Loader
