import React, { Component, Fragment } from 'react'
import { Route } from 'react-router-dom'

// Orders
import Orders from '../../pages/orders/Orders'
import OrderForm from '../../pages/orders/OrderForm'
import AbandonedOrders from '../../pages/orders/AbandonedOrders'
import Shipment from '../../pages/orders/Shipment'

// Products
import ProductForm from '../../pages/products/ProductForm'
import Products from '../../pages/products/Products'

// Customers
import CustomerForm from '../../pages/customers/CustomerForm'
import Customers from '../../pages/customers/Customers'

// Reports
import ShippingReport from '../../pages/reports/ShippingReport'

// Promotions
import PromotionForm from '../../pages/promotions/PromotionForm'
import Promotions from '../../pages/promotions/Promotions'

// Categories
import Categories from '../../pages/products/Categories'
import CategorieForm from '../../pages/products/CategorieForm'

// Attributes
import Attributes from '../../pages/Attributes'

// Pages
import Pages from '../../pages/pages/Pages'
import PageForm from '../../pages/pages/PageForm'

// Contact
import ContactUs from '../../pages/ContactUs'

// Home
import Home from '../../pages/dashboard/Home'

// User Accounts
import Users from '../../pages/users/Users'
import UserForm from '../../pages/users/UserForm'

// Setting
import Settings from '../../pages/settings/Settings'

// Store
import Stores from '../../pages/settings/Stores'

import * as Routes from '../Routes'
import { Auth } from 'aws-amplify'
import * as Utils from '../../../utils/Utils'

class ContentBody extends Component {
  state = {
    group: '',
  }

  UNSAFE_componentWillMount = () => {
    Auth.currentAuthenticatedUser()
      .then(user => {
        const group = user.signInUserSession.accessToken.payload['cognito:groups']
        this.setState({
          group: group && group[0],
        })
      })
      .catch(err => console.log(err))
  }

  render = () => {
    const { group } = this.state

    return (
      <main className="k-content__body k-grid__item k-grid__item--fluid">
        {/*Dasboard*/}
        {[Utils.GROUPS.SUPER_ADMIN, Utils.GROUPS.ADMIN, Utils.GROUPS.SALES, Utils.GROUPS.MANAGER].includes(group) && (
          <Fragment>
            <Route exact path={Routes.DASHBOARD} component={Home} />
          </Fragment>
        )}

        {/*Order*/}
        {[
          Utils.GROUPS.SUPER_ADMIN,
          Utils.GROUPS.ADMIN,
          Utils.GROUPS.MANAGER,
          Utils.GROUPS.SHIPPER,
          Utils.GROUPS.SALES,
        ].includes(group) && (
          <Fragment>
            <Route exact path={Routes.ORDER_LIST} component={Orders} />
            <Route path={Routes.ORDER_CREATE} component={OrderForm} />
            <Route path={Routes.ORDER_DETAIL} component={OrderForm} />
            <Route
              exact
              path={Routes.ORDER_ABANDONED_LIST}
              component={AbandonedOrders}
            />
          </Fragment>
        )}

        {/*Products*/}
        {[
          Utils.GROUPS.SUPER_ADMIN,
          Utils.GROUPS.ADMIN,
          Utils.GROUPS.MANAGER,
          Utils.GROUPS.SHIPPER,
        ].includes(group) && (
          <Fragment>
            <Route exact path={Routes.PRODUCT_LIST} component={Products} />
            <Route exact path={Routes.PRODUCT_CREATE} component={ProductForm} />
            <Route path={Routes.PRODUCT_DETAIL} component={ProductForm} />
          </Fragment>
        )}

        {/*Customers*/}
        {[
          Utils.GROUPS.SUPER_ADMIN,
          Utils.GROUPS.ADMIN,
          Utils.GROUPS.MANAGER,
          Utils.GROUPS.SHIPPER,
        ].includes(group) && (
          <Fragment>
            <Route exact path={Routes.CUSTOMER_LIST} component={Customers} />
            <Route
              exact
              path={Routes.CUSTOMER_CREATE}
              component={CustomerForm}
            />
            <Route path={Routes.CUSTOMER_DETAIL} component={CustomerForm} />
          </Fragment>
        )}

        {[
          Utils.GROUPS.SUPER_ADMIN,
          Utils.GROUPS.SHIPPER,
          Utils.GROUPS.ADMIN,
          Utils.GROUPS.MANAGER,
        ].includes(group) && (
          <Fragment>
            <Route
              exact
              path={Routes.SHIPPING_REPORT}
              component={ShippingReport}
            />
          </Fragment>
        )}

        {/* Product Categories*/}
        {[
          Utils.GROUPS.SUPER_ADMIN,
          Utils.GROUPS.ADMIN,
          Utils.GROUPS.MANAGER,
          Utils.GROUPS.SHIPPER,
        ].includes(group) && (
          <Fragment>
            <Route path={Routes.PRODUCT_CATEGORY_LIST} component={Categories} />
            <Route
              exact
              path={Routes.PRODUCT_CATEGORY_CREATE}
              component={CategorieForm}
            />
            <Route
              path={Routes.PRODUCT_CATEGORY_DETAIL}
              component={CategorieForm}
            />
          </Fragment>
        )}

        {/* Attributes */}
        {[
          Utils.GROUPS.SUPER_ADMIN,
          Utils.GROUPS.ADMIN,
          Utils.GROUPS.MANAGER,
          Utils.GROUPS.SHIPPER,
        ].includes(group) && (
          <Fragment>
            <Route path={Routes.ATTRIBUTES} component={Attributes} />
          </Fragment>
        )}

        {/*Promotions*/}
        {[
          Utils.GROUPS.SUPER_ADMIN,
          Utils.GROUPS.ADMIN,
          Utils.GROUPS.MANAGER,
        ].includes(group) && (
          <Fragment>
            <Route exact path={Routes.PROMOTION_LIST} component={Promotions} />
            <Route path={Routes.PROMOTION_CREATE} component={PromotionForm} />
            <Route path={Routes.PROMOTION_DETAIL} component={PromotionForm} />
          </Fragment>
        )}

        {/*Pages*/}
        {[Utils.GROUPS.SUPER_ADMIN, Utils.GROUPS.ADMIN].includes(group) && (
          <Fragment>
            <Route exact path={Routes.PAGE_LIST} component={Pages} />
            <Route path={Routes.PAGE_CREATE} component={PageForm} />
            <Route path={Routes.PAGE_DETAIL} component={PageForm} />
          </Fragment>
        )}

        {/*User Accounts*/}
        {[Utils.GROUPS.SUPER_ADMIN, Utils.GROUPS.ADMIN, Utils.GROUPS.MANAGER].includes(group) && (
          <Fragment>
            <Route path={Routes.USER_ACCOUNT_LIST} component={Users} />
            <Route path={Routes.USER_ACCOUNT_CREATE} component={UserForm} />
            <Route path={Routes.USER_ACCOUNT_DETAIL} component={UserForm} />
          </Fragment>
        )}

        {/*Settings*/}
        {[Utils.GROUPS.SUPER_ADMIN, Utils.GROUPS.ADMIN, Utils.GROUPS.MANAGER].includes(group) && (
          <Fragment>
            <Route path={Routes.SETTINGS} component={Settings} />
            <Route path={Routes.CONTACT} component={ContactUs} />
          </Fragment>
        )}

        {/*Stores*/}
        <Route path={Routes.STORES} component={Stores} />

        {/* Shipment */}
        <Route path={Routes.SHIPMENT} component={Shipment} />
      </main>
    )
  }
}

export default ContentBody
