import {
  React,
  Component,
  connect,
  Actions,
  ActionTypes,
  Portlet,
  PortletHeader,
  PortletBody,
  Section,
  Select,
  FormGroup,
  Input,
  Label,
  FormFeedback,
  Utils,
  Notification,
  FormValidator,
  Button,
  _,
  Separator,
  classNames,
} from '../../../Imports'
import { States } from '../../../reducers/DataReducers'
import { Helmet } from 'react-helmet'
import Dropzone from 'react-dropzone'
import axios from 'axios'
import moment from 'moment-timezone'

const entity = 'Profile'
const allowedFiles = 'image/jpeg, image/png, .svg'

class FulfilmentCenter extends Component {
  constructor(props) {
    super(props)

    this.validator = new FormValidator([
      {
        field: 'address1',
        method: 'isEmpty',
        validWhen: false,
        message: 'The Address is required.',
      },
      // {
      // 	field: "address2",
      // 	method: "isEmpty",
      // 	validWhen: false,
      // 	message: "The Address is required."
      // },
      {
        field: 'city',
        method: 'isEmpty',
        validWhen: false,
        message: 'The City code is required.',
      },
      {
        field: 'state',
        method: 'isEmpty',
        validWhen: false,
        message: 'The State code is required.',
      },
      {
        field: 'zipcode',
        method: 'isEmpty',
        validWhen: false,
        message: 'The Zip code is required.',
      },
    ])

    this.state = {
      states: States(),
      setting: null,
      submited: false,
      isCreate: false,
      btnLoading: false,
      fulfilment: null,
    }
  }

  UNSAFE_componentWillMount = () => {
    const storeId = Utils.search(this.props.location).store

    if (!this.props.settings.data) {
      this.props.storePost(
        { id: storeId },
        'get-store',
        ActionTypes.FETCH_SETTINGS_SUCCESS,
        ActionTypes.FETCH_SETTINGS_FAILURE,
      ).then(resp => {
        const fulfilment = _.get(resp.data, 'shipping.fulfilmentCenter', {})
        const state = this.getStateValue(fulfilment.state)
        fulfilment.state = state
        this.setState({
          setting: resp.data,
          fulfilment: fulfilment === null ? {} : fulfilment,
        })
      })
    } else {
      this.setState({
        setting: this.props.settings.data,
        fulfilment: _.get(this.props.settings.data, 'shipping.fulfilmentCenter', {}),
      })
    }
  }

  onChangeInput = e => {
    let fulfilment = this.state.fulfilment
    fulfilment[e.target.name] = e.target.value

    this.setState({ fulfilment })
  }

  onDrop = async (accepted, rejected, e) => {
    const field = e.target.name
    const loadingImg = 'loadingImg' + field
    this.setState({
      [loadingImg]: true,
    })

    if (rejected.length > 0) {
      this.setState({
        [loadingImg]: false,
      })

      return
    }

    if (accepted.length > 0) {
      let file = accepted[0]

      let fileName = `${
        this.state.setting.id
      }/images/${moment().valueOf()}-${_.toLower(file.name)}`
      let fileType = file.type

      let config = {
        headers: {
          'Content-Type': fileType,
        },
      }

      let payload = {
        fileName,
      }

      let resp = await this.props.post(payload, 'generate-url')
      let url = resp.data

      axios
        .put(url, file, config)
        .then(async response => {
          const setting = {
            ...this.state.setting,
            logoUrl: {
              ...this.state.setting.logoUrl,
              [field]: 'https://d2d8dl1lrybgkf.cloudfront.net/' + fileName,
            },
          }
          this.setState({ setting, [loadingImg]: false })
        })
        .catch(error => {
          this.setState({
            [loadingImg]: false,
          })

          console.log('ERROR: ', error)
          Notification.error('File failed to upload.')
        })
    }
  }

  onChangeCheckbox = e => {
    let fulfilment = this.state.fulfilment
    fulfilment[e.target.name] = !_.get(fulfilment, e.target.name, false)

    this.setState({ fulfilment })
  }

  onChangeSelect = (e, name) => {
    this.setState(prevState => ({
      fulfilment: {
        ...prevState.fulfilment,
        [name]: e,
      },
    }))
  }

  getStateValue = stateCode =>
    this.state.states.find(state => state.value === stateCode)

  update = () => {
    this.setState({
      submited: !this.state.submited,
    })
    let isValid = this.validator.validate(this.state.fulfilment).isValid

    let setting = this.state.setting

    let shipping = setting.shipping === null ? {} : setting.shipping

    console.log('shipping', shipping)

    shipping.fulfilmentCenter = {
      ...this.state.fulfilment,
      state: this.state.fulfilment.state.value,
    }

    setting.shipping = shipping

    if (isValid) {
      this.setState({ btnLoading: true })
      this.props.post(this.state.setting, 'update-store').then(resp => {
        this.response(resp)
      })
    }
  }

  response = resp => {
    this.setState({
      btnLoading: false,
    })

    let msn = _.replace(
      _.replace(process.env.REACT_APP_SUCCESS_ACTION, '%entity%', entity),
      '%action%',
      'Updated',
    )

    if (resp.status === 'success') {
      Notification.success(msn)
    } else {
      Notification.error(process.env.REACT_APP_ERROR_ACTION)
    }
  }

  render = () => {
    const validator = this.state.submited
      ? this.validator.validate(this.state.fulfilment)
      : this.validator.valid()

    return (
      <Portlet>
        <Helmet>
          <title>Fulfilment Center | Admin Panel - Max Ecommerce</title>
        </Helmet>
        <PortletHeader
          title={`Fulfilment Center - ${_.get(this.state.setting, 'name', '')}`}
          sticky
        >
          {/*
					<Link to={Routes.STORES} className="btn btn-secondary mr-3">
						<i className="la la-arrow-left" />
						Back
					</Link>
					*/}
          <Button
            label={'Update'}
            color="primary"
            icon={`la ${this.state.btnLoading ? '' : ' la-check'}`}
            isLoading={this.state.btnLoading}
            onClick={this.update}
          />
        </PortletHeader>
        <PortletBody>
          {this.state.fulfilment === null && (
            <div className="row">
              <div className="col-12">
                <div>Loading...</div>
              </div>
            </div>
          )}

          {this.state.fulfilment !== null && (
            <div className="row">
              <div className="col-xl-1" />
              <div className="col-xl-10">
                <Section title="Location" size="lg">
                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">Address 1 * </Label>
                    <div className="col-9">
                      <Input
                        value={this.state.fulfilment.address1}
                        onChange={this.onChangeInput}
                        name="address1"
                        invalid={validator.address1.isInvalid}
                      />
                      <FormFeedback>{validator.address1.message}</FormFeedback>
                    </div>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">Address 2 * </Label>
                    <div className="col-9">
                      <Input
                        value={this.state.fulfilment.address2}
                        onChange={this.onChangeInput}
                        name="address2"
                      />
                    </div>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">City * </Label>
                    <div className="col-9">
                      <Input
                        value={this.state.fulfilment.city}
                        onChange={this.onChangeInput}
                        name="city"
                        invalid={validator.city.isInvalid}
                      />
                      <FormFeedback>{validator.city.message}</FormFeedback>
                    </div>
                  </FormGroup>

                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">State * </Label>
                    <div className="col-9">
                      <Select
                        options={this.state.states}
                        value={this.state.fulfilment.state}
                        onChange={e => this.onChangeSelect(e, 'state')}
                        isSearchable={false}
                        closeMenuOnSelect={true}
                      />
                    </div>
                  </FormGroup>

                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">Zipcode *</Label>
                    <div className="col-9">
                      <Input
                        value={this.state.fulfilment.zipcode}
                        onChange={this.onChangeInput}
                        name="zipcode"
                        invalid={validator.zipcode.isInvalid}
                      />
                      <FormFeedback>{validator.zipcode.message}</FormFeedback>
                    </div>
                  </FormGroup>
                </Section>
                <Separator size="lg" border={'dashed'} />
                <Section>
                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">Invoice Logo</Label>
                    <div className="product-img">
                      <img
                        className="img"
                        src={this.state.setting.logoUrl.invoice}
                      ></img>
                    </div>
                    <div>
                      {this.state.loadingImginvoice && (
                        <i className="product-img-placeholder" />
                      )}
                    </div>
                  </FormGroup>
                  <div
                    className={classNames('dropZone', {
                      'dropZone-invalid': false,
                    })}
                  >
                    <Dropzone
                      disabled={this.state.loadingImginvoice}
                      onDrop={this.onDrop}
                      accept={allowedFiles}
                      multiple={false}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <div {...getRootProps()}>
                            <input name="invoice" {...getInputProps()} />
                            <p className="dropZone-msg">
                              {allowedFiles} (BROWSE)
                            </p>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  </div>
                </Section>
                <Separator size="lg" border={'dashed'} />
                <Section title="Settings" size="lg">
                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">
                      Package Slips
                    </Label>
                    <div className="col-9">
                      <span className="k-switch k-switch--md k-switch--icon">
                        <label>
                          <Input
                            checked={
                              this.state.fulfilment.packageslips
                                ? 'checked'
                                : ''
                            }
                            onChange={this.onChangeCheckbox}
                            name="packageslips"
                            type="checkbox"
                          />
                          <span />
                        </label>
                      </span>
                    </div>
                  </FormGroup>
                </Section>
              </div>
              <div className="col-xl-1" />
            </div>
          )}
        </PortletBody>
      </Portlet>
    )
  }
}

export const mapStateToProps = state => ({
  settings: state.settings,
})

export const mapDispatchToProps = dispatch => ({
  post: function(payload, actionEvent) {
    return dispatch(Actions.post(payload, actionEvent))
  },
  storeGet: function(actionEvent, typeSuccess, typeError) {
    return dispatch(Actions.storeGet(actionEvent, typeSuccess, typeError))
  },
  storePost: function(payload, actionEvent, typeSuccess, typeError) {
    return dispatch(
      Actions.storePost(payload, actionEvent, typeSuccess, typeError),
    )
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(FulfilmentCenter)
