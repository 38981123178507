export default {
  // UI
  HOVER_ASIDE: 'HOVER_ASIDE',
  MINIMIZE_ASIDE: 'MINIMIZE_ASIDE',
  TOGGLE_ASIDE: 'TOGGLE_ASIDE',
  TOGGLE_HEADER: 'TOGGLE_HEADER',
  BREADCRUMB_PATH: 'BREADCRUMB_PATH',
  SET_LOADING: 'SET_LOADING',

  // Metada
  FETCH_YEARS_SUCCESS: 'FETCH_YEARS_SUCCESS',
  FETCH_YEARS_FAILURE: 'FETCH_YEARS_FAILURE',
  FETCH_MAKES_SUCCESS: 'FETCH_MAKES_SUCCESS',
  FETCH_MAKES_FAILURE: 'FETCH_MAKES_FAILURE',
  FETCH_MODELS_SUCCESS: 'FETCH_MODELS_SUCCESS',
  FETCH_MODELS_FAILURE: 'FETCH_MODELS_FAILURE',

  // Products
  FETCH_PRODUCTS_SUCCESS: 'FETCH_PRODUCTS_SUCCESS',
  FETCH_PRODUCTS_FAILURE: 'FETCH_PRODUCTS_FAILURE',
  FETCH_PRODUCTS_LOADING: 'FETCH_PRODUCTS_LOADING',

  // Options
  FETCH_OPTIONS_SUCCESS: 'FETCH_OPTIONS_SUCCESS',
  FETCH_OPTIONS_FAILURE: 'FETCH_OPTIONS_FAILURE',

  // Customers
  FETCH_CUSTOMERS_SUCCESS: 'FETCH_CUSTOMERS_SUCCESS',
  FETCH_CUSTOMERS_FAILURE: 'FETCH_CUSTOMERS_FAILURE',

  // Customers
  SHIPPING_REPORT_SUCCESS: 'SHIPPING_REPORT_SUCCESS',
  SHIPPING_REPORT_FAILURE: 'SHIPPING_REPORT_FAILURE',

  // Promotions
  FETCH_PROMOTIONS_SUCCESS: 'FETCH_PROMOTIONS_SUCCESS',
  FETCH_PROMOTIONS_FAILURE: 'FETCH_PROMOTIONS_FAILURE',

  // Pages
  FETCH_PAGES_SUCCESS: 'FETCH_PAGES_SUCCESS',
  FETCH_PAGES_FAILURE: 'FETCH_PAGES_FAILURE',

  // Product Categories
  FETCH_CATEGORIES_SUCCESS: 'FETCH_CATEGORIES_SUCCESS',
  FETCH_CATEGORIES_FAILURE: 'FETCH_CATEGORIES_FAILURE',

  // Orders
  FETCH_ORDERS_SUCCESS: 'FETCH_ORDERS_SUCCESS',
  FETCH_ORDERS_FAILURE: 'FETCH_ORDERS_FAILURE',

  // Users
  FETCH_USERS_SUCCESS: 'FETCH_USERS_SUCCESS',
  FETCH_USERS_FAILURE: 'FETCH_USERS_FAILURE',

  // Settings
  FETCH_SETTINGS_SUCCESS: 'FETCH_SETTINGS_SUCCESS',
  FETCH_SETTINGS_FAILURE: 'FETCH_SETTINGS_FAILURE',

  // Attributes
  FETCH_ATTRIBUTES_SUCCESS: 'FETCH_ATTRIBUTES_SUCCESS',
  FETCH_ATTRIBUTES_FAILURE: 'FETCH_ATTRIBUTES_FAILURE',

  // Variants
  FETCH_VARIANTS_SUCCESS: 'FETCH_VARIANTS_SUCCESS',
  FETCH_VARIANTS_FAILURE: 'FETCH_VARIANTS_FAILURE',
  CLEAR_VARIANTS: 'CLEAR_VARIANTS',

  //Stores
  FETCH_STORES_SUCCESS: 'FETCH_STORES_SUCCESS',
  FETCH_STORES_FAILURE: 'FETCH_STORES_FAILURE',
  CHANGE_CURRENT_STORE: 'CHANGE_CURRENT_STORE',
}
