import React, { Component } from 'react'

class Message extends Component {
  render = () => (
    <div
      className="toast"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      <div className="toast-header">
        <i
          className={`toast-title-icon toast-icon flaticon2-attention-exclamation-triangular-signal k-font-${this.props.type} warning mr-2`}
        />
        <strong className="toast-title mr-auto">{this.props.title}</strong>
        <small className="text-muted">{this.props.muted}</small>
        <button className="close ml-3">
          <i className="la la-close small" />
        </button>
      </div>
      <div className="toast-body">
        <span className="toast-message">{this.props.message}</span>
      </div>
    </div>
  )
}

export default Message
