import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

class ContactUs extends Component {
  render = () => (
    <>
      <Helmet>
        <title>Contact Us | Admin Panel - Max Ecommerce</title>
      </Helmet>
      <Modal isOpen={false} centered fade={false}>
        <ModalHeader>Header</ModalHeader>
        <ModalBody>This is a modal component.</ModalBody>
        <ModalFooter>Footer</ModalFooter>
      </Modal>
    </>
  )
}

export default ContactUs
