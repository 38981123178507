import {
  React,
  Component,
  connect,
  Actions,
  Portlet,
  PortletHeader,
  PortletBody,
  BootstrapTable,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  paginationFactory,
  filterFactory,
  FILTER_TYPES,
  textFilter,
  Filters,
  Utils,
  Notification,
  Routes,
  Link,
  Button,
  ActionTypes,
  classNames,
  _,
} from '../../../Imports'
import { Helmet } from 'react-helmet'

const entity = 'Page'

class Pages extends Component {
  constructor(props) {
    super(props)

    this.state = {
      pages: [],
      isOpen: false,
      btnLoading: false,
      id: null,
      filters: [
        {
          id: 1,
          name: 'Page Name',
          filter: null,
          value: '',
          type: FILTER_TYPES.TEXT,
        },
      ],
      isFilterOpen: false,
      refresh: true,
    }

    this.storeId = Utils.search(this.props.location).store
  }

  UNSAFE_componentWillMount = () => {
    this.onRefresh()
  }

  onRefresh = () => {
    this.setState({ pages: [], refresh: true })

    let storeId = Utils.search(this.props.location).store

    this.props
      .storePost(
        {
          storeId: storeId,
        },
        'list-pages',
        ActionTypes.FETCH_PAGES_SUCCESS,
        ActionTypes.FETCH_PAGES_FAILURE,
      )
      .then(() => {
        this.setState({
          pages: this.props.pages.data,
          refresh: false,
        })
      })
  }

  delete = id => {
    this.setState({ isOpen: true, id: id })
  }

  close = () => {
    this.setState({ isOpen: false })
  }

  confirm = () => {
    this.setState({ btnLoading: true })
    this.props
      .post(
        {
          storeId: this.props.currentStore,
          id: this.state.id,
        },
        'delete-page',
      )
      .then(resp => {
        this.response(resp)
      })
  }

  response = resp => {
    this.setState({
      btnLoading: false,
      isOpen: false,
    })

    let msn = _.replace(
      _.replace(process.env.REACT_APP_SUCCESS_ACTION, '%entity%', entity),
      '%action%',
      'Deleted',
    )

    if (resp.status === 'success') {
      Notification.success(msn)
    } else {
      Notification.error(process.env.REACT_APP_ERROR_ACTION)
    }

    this.onRefresh()
  }

  toggleFilter = () => {
    this.setState({
      isFilterOpen: !this.state.isFilterOpen,
    })
  }

  clearFilter = () => {
    _.forEach(this.state.filters, filter => {
      this.removeFilter(filter)
    })
  }

  removeFilter = filter => {
    let filters = this.state.filters
    filter.filter('')
    filter.value = ''
    this.setState({ filters })
  }

  setFilterState = ({ dataSize }) => {
    console.log('Table size changed', dataSize)
    let filters = this.state.filters
    this.setState({ filters })
  }

  render = () => {
    const { pages } = this.state
    const headerFormatter = (
      column,
      colIndex,
      { sortElement, filterElement },
    ) => (
      <div className="d-flex justify-content-end flex-column">
        <div className="d-flex justify-content-between">
          {column.text}
          {sortElement}
        </div>
        {this.state.isFilterOpen && (
          <div
            style={{
              margin: '0 -.75rem',
              marginTop: '.75rem',
              padding: '1rem .75rem .25rem',
              borderTop: '2px solid #f7f8fa',
            }}
          >
            {filterElement}
          </div>
        )}
      </div>
    )

    const rowEvents = {
      onClick: (e, column, columnIndex, row, rowIndex) => {
        let url = '/page/detail/' + row.id + '?store=' + this.storeId
        this.props.history.push(url)
      },
    }

    const columns = [
      {
        dataField: 'id',
        hidden: true,
      },
      {
        dataField: 'name',
        text: 'Name',
        sort: true,
        headerFormatter,
        headerClasses: 'align-top',
        events: rowEvents,
        filter: textFilter({
          defaultValue: this.state.filters[0].value,
          getFilter: filter => {
            this.state.filters[0].filter = filter
          },
          onFilter: filterVal => {
            let filters = this.state.filters
            filters[0].value = filterVal

            // this.setState({ filters });
          },
        }),
      },
      {
        dataField: 'productCategory',
        text: 'Category',
        sort: true,
        headerFormatter,
        headerClasses: 'align-top',
        events: rowEvents,
        formatter: (cell, row, rowIndex, formatExtraData) =>
          cell ? <div>{cell.label}</div> : null,
      },
      {
        dataField: 'sort',
        text: 'Order',
        sort: true,
        headerFormatter,
        headerClasses: 'align-top',
        headerStyle: (colum, colIndex) => ({ width: '80px' }),
        events: rowEvents,
      },
      {
        dataField: 'url',
        text: 'URL',
        headerFormatter,
        headerClasses: 'align-top',
        events: rowEvents,
      },
      {
        dataField: 'title',
        text: 'Title',
        sort: true,
        headerFormatter,
        headerClasses: 'align-top',
        events: rowEvents,
      },
      {
        dataField: 'active',
        text: 'Status',
        headerFormatter,
        headerClasses: 'align-top',
        events: rowEvents,
        headerStyle: (colum, colIndex) => ({ width: '100px' }),
        formatter: (cell, row, rowIndex, formatExtraData) => (
          <span
            className={classNames(
              'badge',
              `badge-${cell ? 'success' : 'secondary'}`,
            )}
          >
            {cell ? 'Active' : 'Inactive'}
          </span>
        ),
      },
      {
        dataField: 'actions',
        text: 'Actions',
        headerAlign: 'right',
        align: 'right',
        headerFormatter,
        headerClasses: 'align-top',
        headerStyle: (colum, colIndex) => ({ width: '100px' }),
        formatter: (cell, row, rowIndex, formatExtraData) => {
          let url = `/page/detail/${row.id}?store=${this.storeId}`

          return (
            <div>
              <Link
                to={url}
                className="btn btn-outline-secondary btn-sm btn-icon"
                title="Edit"
              >
                <i className="la la-search" />
              </Link>
              &nbsp; &nbsp;
              <button
                className="btn btn-outline-secondary btn-sm btn-icon"
                title="Delete"
                onClick={() => this.delete(row.id)}
              >
                <i className="la la-trash" />
              </button>
            </div>
          )
        },
      },
    ]

    const options = {
      showTotal: true,
      sizePerPageList: [
        {
          text: '20',
          value: 20,
        },
        {
          text: '100',
          value: 100,
        },
        {
          text: '200',
          value: 200,
        },
        {
          text: '300',
          value: 300,
        },
        {
          text: 'All',
          value: pages.length,
        },
      ],
    }

    return (
      <Portlet>
        <Helmet>
          <title>Pages | Admin Panel - Max Ecommerce</title>
        </Helmet>
        <PortletHeader sticky title="Pages list">
          <Button
            label="Refresh"
            icon="la la-refresh"
            className="mr-3"
            color="secondary"
            onClick={this.onRefresh}
          />
          <Button
            label="Filters"
            icon="la la-filter"
            color="secondary"
            onClick={this.toggleFilter}
            className={classNames('mr-3', {
              active: this.state.isFilterOpen,
            })}
          />
          <Link
            to={`${Routes.PAGE_CREATE}?store=${
              Utils.search(this.props.location).store
            }`}
            className="btn btn-primary"
          >
            <i className="la la-plus" />
            Add new
          </Link>
        </PortletHeader>
        <PortletBody>
          <Filters
            removeFilter={this.removeFilter}
            clearAll={this.clearFilter}
            filters={this.state.filters}
          />
          {pages && !!pages.length && (
            <BootstrapTable
              pagination={paginationFactory(options)}
              bootstrap4
              data={pages}
              columns={columns}
              keyField="id"
              hover
              filter={filterFactory()}
              noDataIndication={() => (
                <div className="mt-2">
                  {this.state.refresh ? 'Loading...' : 'No Pages'}
                </div>
              )}
              onDataSizeChange={this.setFilterState}
            />
          )}
        </PortletBody>
        <Modal isOpen={this.state.isOpen}>
          <ModalHeader>Delete confirmation</ModalHeader>
          <ModalBody>Are you sure want to Delete this Page?</ModalBody>
          <ModalFooter>
            <Button
              isLoading={this.state.btnLoading}
              label="Confirm"
              color="danger"
              onClick={this.confirm}
            />
            <Button label="Cancel" color="secondary" onClick={this.close} />
          </ModalFooter>
        </Modal>
      </Portlet>
    )
  }
}

export const mapStateToProps = state => ({
  pages: state.pages,
  pageCategories: state.pageCategories,
  currentStore: state.currentStore,
})

export const mapDispatchToProps = dispatch => ({
  storePost: function(payload, actionEvent, typeSuccess, typeError) {
    return dispatch(
      Actions.storePost(payload, actionEvent, typeSuccess, typeError),
    )
  },
  post: function(payload, actionEvent) {
    return dispatch(Actions.post(payload, actionEvent))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Pages)
