import {
  React,
  Component,
  connect,
  Actions,
  ActionTypes,
  Portlet,
  PortletHeader,
  PortletBody,
  BootstrapTable,
  paginationFactory,
  filterFactory,
  Routes,
  Link,
  Button,
  _,
} from '../../../Imports'
import { Helmet } from 'react-helmet'

class Orders extends Component {
  constructor(props) {
    super(props)

    this.state = {
      stores: [],
      isOpen: false,
      btnLoading: false,
      id: null,
    }
  }

  UNSAFE_componentWillMount = () => {
    this.onRefresh()
  }

  onRefresh = async () => {
    this.setState({ stores: [] })

    this.props
      .storeGet(
        'list-stores',
        ActionTypes.FETCH_STORES_SUCCESS,
        ActionTypes.FETCH_STORES_FAILURE,
      )
      .then(() => {
        this.setState({
          stores: this.props.stores.data,
        })
      })
  }

  render = () => {
    const columns = [
      {
        dataField: 'id',
        hidden: true,
      },
      {
        dataField: 'name',
        text: 'Name',
        sort: true,
      },
      {
        dataField: 'location',
        text: 'Location',
        sort: true,
      },
      {
        dataField: 'actions',
        text: 'Actions',
        headerAlign: 'right',
        align: 'right',
        headerStyle: (colum, colIndex) => ({ width: '100px' }),
        formatter: (cell, row, rowIndex, formatExtraData) => {
          let url = _.replace(Routes.SETTINGS_PROFILE, ':id', row.id)

          return (
            <div>
              <Link
                to={url}
                className="btn btn-outline-secondary btn-sm btn-icon"
                title="Edit"
              >
                <i className="la la-search" />
              </Link>
            </div>
          )
        },
      },
    ]

    const options = {
      showTotal: true,
      sizePerPageList: [
        {
          text: '20',
          value: 20,
        },
        {
          text: '100',
          value: 100,
        },
        {
          text: '200',
          value: 200,
        },
        {
          text: '300',
          value: 300,
        },
        {
          text: 'All',
          value: this.state.stores.length,
        },
      ],
    }

    return (
      <Portlet>
        <Helmet>
          <title>Stores | Admin Panel - Max Ecommerce</title>
        </Helmet>
        <PortletHeader sticky title="Stores list">
          <Button
            label="Refresh"
            icon="la la-refresh"
            className="mr-0"
            color="secondary"
            onClick={this.onRefresh}
          />

          {/* <Link to={Routes.ORDER_CREATE} className="btn btn-primary">
                        <i className="la la-plus" />
                        Add new
                    </Link>*/}
        </PortletHeader>
        <PortletBody>
          <BootstrapTable
            pagination={paginationFactory(options)}
            filter={filterFactory()}
            bootstrap4
            data={this.state.stores}
            columns={columns}
            keyField="id"
            wrapperClasses="mx-n3"
            hover
            noDataIndication={() => <div className="mt-2">Loading...</div>}
          />
        </PortletBody>
      </Portlet>
    )
  }
}

export const mapStateToProps = state => ({
  stores: state.stores,
  settings: state.settings,
})

export const mapDispatchToProps = dispatch => ({
  storePost: function(actionEvent, typeSuccess, typeError) {
    return dispatch(Actions.storePost(actionEvent, typeSuccess, typeError))
  },
  storeGet: function(actionEvent, typeSuccess, typeError) {
    return dispatch(Actions.storeGet(actionEvent, typeSuccess, typeError))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Orders)
