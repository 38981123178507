import { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import {
  React,
  connect,
  Actions,
  Portlet,
  PortletHeader,
  PortletBody,
  FormGroup,
  Input,
  Label,
  Utils,
  classNames,
  Button,
  ActionTypes,
  Notification,
} from '../../../Imports'

import AttributesTable from './AttributesTable'

import './AttributesForm.scss'

const AttributesForm = ({ attributes, storePost, post, isVariants, filterAttributes, onClose }) => {
  const location = useLocation()
  const storeId = useMemo(() => Utils.search(location).store, [])
  const [editId, setEditId] = useState('')
  const [loading, setLoading] = useState(false)
  const [key, setKey] = useState('')
  const [option, setOption] = useState('')
  const [options, setOptions] = useState([])

  useEffect(() => {
    if (!key && !options.length && editId) setEditId('')
  }, [key, options])

  const addOption = event => {
    if ((!event.keyCode || event.keyCode === 13) && !!option) {
      setOptions(prevOptions => [
        ...prevOptions,
        { id: prevOptions.length, value: option },
      ])
      setOption('')
    }
  }

  const deleteOption = deleteId => () => {
    setOptions(prevOptions =>
      prevOptions.filter(({ id }) => !(id === deleteId)))
  }

  const clearState = () => {
    setKey('')
    setOption('')
    setOptions([])
    setEditId('')
  }

  const setEdit = editId => () => {
    const { key, options } = attributes.find(({ id }) => id === editId) || {}
    setKey(key || '')
    setOptions(
      (options || []).map((value, index) => ({
        id: index,
        value,
      })),
    )
    setEditId(editId)
  }

  const updateList = async response => {
    await getAttributes()
    setLoading(false)
    if (!!key && !!options.length) {
      Notification.success(
        `The Attribute has been ${
          !!editId ? 'updated' : 'created'
        } successfully`,
      )
    } else {
      if (response?.status === 'error') Notification.error(response.data)
      else Notification.success('The Attribute has been deleted successfully')
    }
    clearState()
  }

  const getAttributes = async () => {
    await storePost(
      { storeId: storeId },
      'list-attributes',
      ActionTypes.FETCH_ATTRIBUTES_SUCCESS,
      ActionTypes.FETCH_ATTRIBUTES_FAILURE,
    )
  }

  const addAttribute = () => {
    if (!!key && !!options.length) {
      const payload = {
        storeId,
        key,
        options: options.map(({ value }) => value),
      }
      return post(payload, 'create-attribute')
    }
  }

  const editAttribute = () => {
    if (!!key && !!options.length) {
      const payload = {
        id: editId,
        storeId,
        key,
        options: options.map(({ value }) => value),
      }
      return post(payload, 'update-attribute')
    }
  }

  const deleteAttribute = id => () => {
    setLoading(true)
    const payload = { id, storeId }
    post(payload, 'delete-attribute').then(updateList)
    filterAttributes(attributes.filter(({ id: attrId }) => !(attrId === id)))
  }

  const onSubmitHandle = () => {
    if (!loading) {
      setLoading(true)
      if (editId) {
        editAttribute().then(updateList)
      } else {
        addAttribute().then(updateList)
      }
    }
  }

  return (
    <Portlet className="attributes-page">
      <PortletHeader sticky title={isVariants ? 'Add New Attribute' : 'Attributes'}>
        {isVariants && (
          <Button
            label="Back"
            className="btn btn-secondary mr-3"
            icon="la la-arrow-left"
            onClick={onClose}
            disabled={loading}
          />
        )}
        <Button
          label={editId ? 'Update' : 'Create'}
          icon={`la ${classNames({ 'la-check': !loading })}`}
          className="mr-3"
          color="primary"
          onClick={onSubmitHandle}
          disabled={loading || !key || !options.length}
          isLoading={loading}
        />
      </PortletHeader>
      <PortletBody>
        <FormGroup className="row">
          <Label className="col-3 col-form-label">Label</Label>
          <div className="col-9">
            <Input value={key} onChange={e => setKey(e.target.value)} />
          </div>
        </FormGroup>
        <FormGroup className="row">
          <Label className="col-3 col-form-label">Options</Label>
          <div className="col-9 values-section">
            <div>
              <Input
                value={option}
                onChange={e => setOption(e.target.value)}
                onKeyDown={addOption}
              />
              <Button label="Add" color="primary" onClick={addOption} />
            </div>
            <div>
              {options.map(({ id, value }) => (
                <div key={id}>
                  <span>{value}</span>
                  <div onClick={deleteOption(id)} />
                </div>
              ))}
            </div>
          </div>
        </FormGroup>
        {/* <FormGroup className="row">
          <Label className="col-3 col-form-label">
            Activate existing attribute
          </Label>
          <div className="col-9">
            <Input
                value={''}
                onChange={() => null}
                name="Activate existing attribute"
            />
          </div>
        </FormGroup> */}
        <AttributesTable
          attributes={attributes}
          setEdit={setEdit}
          deleteAttribute={deleteAttribute}
        />
      </PortletBody>
    </Portlet>
  )
}

const mapDispatchToProps = dispatch => ({
  post: (payload, actionEvent) => dispatch(Actions.post(payload, actionEvent)),
  storePost: (payload, actionEvent, typeSuccess, typeError) =>
    dispatch(Actions.storePost(payload, actionEvent, typeSuccess, typeError)),
  filterAttributes: data => dispatch({ type: ActionTypes.FETCH_ATTRIBUTES_SUCCESS, payload: { data } }),
})

const mapStateToProps = ({ attributes }) => ({
  attributes: attributes || [],
})

export default connect(mapStateToProps, mapDispatchToProps)(AttributesForm)
