import { React, Component } from '../../../Imports'

class FilterBalloon extends Component {
  render = () => (
    <div
      className="bg-primary text-white pl-3 mr-3 my-2 d-flex align-items-center filter-ballon"
      style={{
        width: 'max-content',
        borderRadius: '2rem',
      }}
    >
      <span
        style={{
          opacity: '.6',
        }}
      >
        {this.props.filter.name.toUpperCase()}
      </span>
      : {this.props.filter.value}
      <i
        className="la la-close ml-2 small p-3 text-brand"
        style={{
          cursor: 'pointer',
        }}
        onClick={this.props.onRemove}
      />
    </div>
  )
}

export default FilterBalloon
