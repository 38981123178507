import React, { Component } from 'react'

class AsideSection extends Component {
  render = () => (
    <li className="k-menu__section">
      <h4 className="k-menu__section-text">{this.props.label}</h4>
      <i className="k-menu__section-icon flaticon-more-v2" />
    </li>
  )
}

export default AsideSection
