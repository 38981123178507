import React, { Component } from 'react'
import { connect } from 'react-redux'
import AsideSection from './AsideSection'
import AsideItem from './AsideItem'
import PerfectScrollbar from 'perfect-scrollbar'
import * as Routes from '../Routes'
import ActionTypes from '../../../actions/ActionTypes'
import { storeGet, onChangeCurrentStore } from '../../../actions/DataActions'
import * as Utils from '../../../utils/Utils'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'

class AsideMenu extends Component {
  constructor(props) {
    super(props)

    this.storeId = Utils.search(this.props.location).store

    this.state = {
      options: [],
      content: [],
      dashboard: [],
    }
  }

  componentDidMount = () => {
    this.ps = new PerfectScrollbar('.ps')

    Routes.getAsideMenuItems().then(res => {
      this.setState({
        options: res.orderItems,
        content: res.contentItems,
        dashboard: res.dashboardItems,
      })
    })
  }

  componentWillUnmount = () => {
    this.ps.destroy()
    this.ps = null
  }

  UNSAFE_componentWillMount = async () => {
    if (this.props.stores.data.length === 0) {
      await this.props.storeGet(
        'list-stores',
        ActionTypes.FETCH_STORES_SUCCESS,
        ActionTypes.FETCH_STORES_FAILURE,
      )
    }
  }

  getItems = items => {
    if (this.props.stores.data.length > 0) {
      if (this.storeId === null || this.storeId === undefined) {
        this.storeId = this.props.stores.data[0].id
      }

      return _.map(items, value =>
        value ? (
          <AsideItem
            key={value.label}
            url={value.url}
            label={value.label}
            icon={value.icon}
            store={this.storeId}
            options={value.options}
          />
        ) : (
          false
        ))
    }
  }

  render = () => {
    let options = this.getItems(this.state.options)
    let content = this.getItems(this.state.content)
    let dashboard = this.getItems(this.state.dashboard)

    return (
      <div className="k-aside-menu-wrapper k-grid__item k-grid__item--fluid">
        <nav
          className="k-aside-menu k-scroll ps"
          style={{
            height: 'calc(100vh - 105px)',
            overflow: 'hidden',
          }}
          data-kmenu-vertical="1"
          data-kmenu-scroll="1"
        >
          <ul className="k-menu__nav ps">
            {dashboard}
            <AsideSection label="Options" />
            {options}
            {this.state.content.length > 0 && <AsideSection label="Content" />}
            {content}
          </ul>
        </nav>
      </div>
    )
  }
}

export const mapStateToProps = state => ({
  stores: state.stores,
})

export const mapDispatchToProps = dispatch => ({
  storeGet: function(actionEvent, typeSuccess, typeError) {
    return dispatch(storeGet(actionEvent, typeSuccess, typeError))
  },
  onChangeCurrentStore: storeId => dispatch(onChangeCurrentStore(storeId)),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AsideMenu),
)
