import {
  React,
  Component,
  connect,
  Actions,
  ActionTypes,
  Portlet,
  PortletHeader,
  PortletBody,
  Section,
  BootstrapTable,
  Select,
  FormGroup,
  Input,
  Label,
  FormFeedback,
  Utils,
  Notification,
  Routes,
  Link,
  FormValidator,
  Separator,
  Button,
  UiActions,
  _,
} from '../../../Imports'
import { Helmet } from 'react-helmet'
import moment from 'moment-timezone'

import AddressEditForm from './AddressEditForm'
import PaymentForm from './PaymentForm'
import ProductSearchOption from '../../base/select/ProductSearchOption'
import cellEditFactory from 'react-bootstrap-table2-editor'
import { Auth } from 'aws-amplify'

import logo from '../../../static/images/cuticura-logo.jpg'

const entity = 'Order'
const payment = Number(process.env.REACT_APP_PAYMENT)

class OrderForm extends Component {
  constructor(props) {
    super(props)

    this.validator = new FormValidator([
      {
        field: 'status',
        method: 'isEmpty',
        validWhen: false,
        message: 'The Status is required.',
      },
    ])

    this.state = {
      paymentIsOpen: false,
      selectedItem: null,
      order: null,
      isAbandoned: false,
      isDeclined: false,
      submited: false,
      isCreate: false,
      btnLoading: false,
      isOpenModalShipment: false,
      showForm: true,
      showPackages: false,
      packages: [],
      productOptions: [],
      isLocationOpen: false,
      locationSubmited: false,
      summaryLoading: false,
      location: {},
      settings: {},
      subtotal: 0,
      total: 0,
      userGroup: '',
      userId: '',
    }

    this.storeId = Utils.search(this.props.location).store
    this.location = new FormValidator(this.getLocationValidator())
  }

  getLocationValidator = (addressValue = 'shipp') => ([
    {
      field: `${addressValue}ToFirstName`,
      method: 'isEmpty',
      validWhen: false,
      message: 'First Name is required.',
    },
    {
      field: `${addressValue}ToLastName`,
      method: 'isEmpty',
      validWhen: false,
      message: 'Last Name is required.',
    },
    {
      field: `${addressValue}ToEmail`,
      method: 'isEmail',
      validWhen: true,
      message: 'This is not a valid email.',
    },
    {
      field: `${addressValue}ToPhoneNumber`,
      method: 'isMobilePhone',
      args: ['en-US'],
      validWhen: true,
      message: 'This is not a valid phone number.',
    },
    {
      field: `${addressValue}ToAddress`,
      method: 'isEmpty',
      validWhen: false,
      message: 'Address is required.',
    },
    {
      field: `${addressValue}ToCity`,
      method: 'isEmpty',
      validWhen: false,
      message: 'City is required.',
    },
    {
      field: `${addressValue}ToState`,
      method: 'isEmpty',
      validWhen: false,
      message: 'State is required.',
    },
    {
      field: `${addressValue}ToCountry`,
      method: 'isEmpty',
      validWhen: false,
      message: 'Country is required.',
    },
    {
      field: `${addressValue}ToZipcode`,
      method: 'isPostalCode',
      args: ['US'],
      validWhen: true,
      message: 'That is not a valid zipcode.',
    },
  ])

  getColumns = () => {
    const { isAbandoned } = this.state
    const columns = [
      {
        dataField: 'id',
        hidden: true,
        editable: false,
      },
      {
        dataField: 'id',
        text: '#',
        sort: false,
        editable: false,
        headerStyle: () => ({ width: '40px' }),
        footer: !isAbandoned ? undefined : '',
        formatter: (cell, row, rowIndex) => (
          <span key={rowIndex}>{rowIndex + 1}</span>
        ),
      },
      {
        dataField: 'sku',
        text: 'SKU',
        sort: false,
        editable: false,
        footer: !isAbandoned ? undefined : '',
        headerStyle: () => ({ width: '100px' }),
      },
      {
        dataField: 'title',
        text: 'Name',
        sort: false,
        editable: false,
        footer: !isAbandoned ? undefined : '',
        footerFormatter: !isAbandoned ? undefined : (() => (
          <Select
            className="d-print-none"
            options={this.state.productOptions}
            placeholder={this.state.selectedItem ? this.state.selectedItem.title : 'Add item'}
            value={[]}
            onChange={this.onSelectItem}
            components={{ Option: ProductSearchOption }}
            isSearchable={true}
          />
        )),
        formatter: (cell, { id, inventoryDescription, inventoryLocation, price: itemPrice, selectedOptions, ...restProduct }, rowIndex) => {
          const options = this.props.options?.filter(({ id }) => !!selectedOptions.find(option => option.id === id)) || []
          const categories = [...(restProduct?.categories?.map(id => this.props?.productCategories?.find(category => id === category.id)?.label || '') || [])].join('/')
          const attributes = [...(restProduct?.attributes?.filter(({ value }) => value !== restProduct.brand).map(({ value }) => value) || [])].join('/')
          const price = `$${(itemPrice - (selectedOptions?.reduce((a, b) => a + b.price, 0) || 0)).toFixed(2)}`
          return (
            <>
              <a
                href={`/product/detail/${id}?store=${this.storeId}`}
                target="_blank"
                key={rowIndex}
              >
                {_.capitalize(cell)}
                {attributes || categories ? `\n(${categories ? `${categories}${attributes ? ' - ' : `, ${price}`}` : ''}${attributes ? `${attributes ? `${attributes}, ${price}` : ''}` : ''})` : ''}
              </a>
              {options?.map(({ name, price }) => <p style={{ margin: '0 0 0 14px', color: '#000', fontWeight: 400 }} >{`${name} - $${price.toFixed(2)}`}</p>)}
            </>
          )
        },
      },
      {
        dataField: 'qty',
        text: 'Qty',
        align: 'right',
        sort: true,
        editable: isAbandoned,
        footer: !isAbandoned ? undefined : '',
        headerStyle: () => ({ width: '100px' }),
        footerFormatter: !isAbandoned ? undefined : () => (
          <Input
            value={this.state.selectedItem?.qty || ''}
            className="mt-auto d-print-none"
            onChange={this.onQtyChange}
          />
        ),
      },
      {
        dataField: 'price',
        text: 'Price',
        align: 'right',
        sort: true,
        editable: isAbandoned,
        footer: !isAbandoned ? undefined : '',
        headerStyle: () => ({ width: '100px' }),
        formatter: (cell, row, rowIndex) => (
          <span key={rowIndex}>${(+cell).toFixed(2)}</span>
        ),
        footerFormatter: !isAbandoned ? undefined : () => (
          <Input
            value={this.state.selectedItem?.price || ''}
            className="mt-auto d-print-none"
            onChange={this.onPriceChange}
          />
        ),
      },
      {
        dataField: 'amount',
        text: 'Amount',
        sort: true,
        align: 'right',
        editable: false,
        footer: !isAbandoned ? undefined : '',
        headerStyle: () => ({ width: '130px' }),
        formatter: (cell, { id, qty, price }, rowIndex) => (
          <span
            key={rowIndex + id}
            style={{
              fontSize: '18',
              fontWeight: 600,
            }}
          >
            ${(price * qty)?.toFixed(2)}
          </span>
        ),
        footerFormatter: !isAbandoned ? undefined : (cell, row, rowIndex) => {
          const { selectedItem } = this.state
          let amount = selectedItem?.price * selectedItem?.qty
          return (
            <span
              key={rowIndex + row.id}
              style={{
                fontSize: '18',
                fontWeight: 600,
              }}
            >
              {!!amount && (
                `$${(+amount)?.toFixed(2)}`
              )}
            </span>
          )
        },
      },
    ]

    if (isAbandoned) {
      columns.push({
        dataField: 'actions',
        text: 'Actions',
        headerAlign: 'right',
        align: 'right',
        footer: '',
        footerFormatter: () => (
          <button
            style={{ marginBottom: '.7rem' }}
            className="btn btn-outline-secondary btn-sm btn-icon mr-3 d-print-none"
            title="Add Item"
            onClick={this.addItem}
          >
            <i className="la la-plus" />
          </button>
        ),
        headerStyle: () => ({ width: '80px' }),
        footerClasses: 'text-right',
        headerClasses: 'align-top',
        editable: false,
        formatter: (cell, { id: removeId }) => (
          <button
            className="btn btn-outline-secondary btn-sm btn-icon mr-3 d-print-none"
            title="Delete"
            onClick={this.removeItem(removeId)}
          >
            <i className="la la-trash" />
          </button>
        ),
      })
    }

    return columns
  }

  addNewColOnFooter = () => {
    let node = document.createElement('th')
    let footer = document.querySelector('.main-table-package-items tfoot tr')

    footer.insertBefore(node, footer.childNodes[0])
  }

  UNSAFE_componentWillMount = async () => {
    Auth.currentAuthenticatedUser()
      .then(user => {
        this.setState({
          userGroup: user.signInUserSession.accessToken.payload['cognito:groups'][0],
          userId: user.signInUserSession.accessToken.payload.sub,
        })
      })
      .catch(err => console.log(err))

    // if (this.props.products?.length === 0) {
    //   this.props.storePostProducts(
    //     {
    //       storeId: this.storeId,
    //     },
    //     'list-products',
    //     ActionTypes.FETCH_PRODUCTS_SUCCESS,
    //     ActionTypes.FETCH_PRODUCTS_FAILURE,
    //   )
    // }
    if (this.props.options?.length === 0) {
      this.props.storePost(
        {
          storeId: this.storeId,
        },
        'list-options',
        ActionTypes.FETCH_OPTIONS_SUCCESS,
        ActionTypes.FETCH_OPTIONS_FAILURE,
      )
    }
    if (this.props.productCategories?.length === 0) {
      this.props.storePost(
        {
          storeId: this.storeId,
        },
        'list-categories',
        ActionTypes.FETCH_CATEGORIES_SUCCESS,
        ActionTypes.FETCH_CATEGORIES_FAILURE,
      )
    }

    if (this.props.settings.data === null) {
      await this.props.storePost(
        { id: this.storeId },
        'get-store',
        ActionTypes.FETCH_SETTINGS_SUCCESS,
        ActionTypes.FETCH_SETTINGS_FAILURE,
      )
    }

    this.setState(
      { isCreate: Utils.isCreate(this.props.location.pathname) },
      () => {
        if (this.state.isCreate) {
          this.setState({ order: {} })
        } else {
          this.fetchOrder()
          this.fetchUsers()
          if (this.state.isAbandoned) {this.fetchProducts()}
        }
      },
    )
  }

  fetchOrder = () => this.props.post(
    {
      id: this.props.match.params.id,
      storeId: this.storeId,
    },
    'get-order',
  )
    .then(resp => {
      let order = resp.data
      let pa = _.get(order, 'shippingPackages', [])

      this.setState({
        order,
        settings: _.get(this.props.settings, 'data', {}),
        packages: pa === null ? [] : pa,
        isAbandoned: order.status === 'ABANDONED_SHOPPING_CART' || order.status === 'PUBLISHED' || order.status === 'REQUESTED',
        isDeclined: order.status === 'DECLINED',
      }, this.updateSummary)
    })

  fetchUsers = () => this.props
    .storePost(
      {
        storeId: this.storeId,
      },
      'list-user-account',
      ActionTypes.FETCH_USERS_SUCCESS,
      ActionTypes.FETCH_USERS_FAILURE,
    )

  fetchProducts = () => {
    this.props.storePostProducts(
      {
        storeId: this.storeId,
      },
      'list-products',
      ActionTypes.FETCH_PRODUCTS_SUCCESS,
      ActionTypes.FETCH_PRODUCTS_FAILURE,
    ).then(({ data }) => {
      const productOptions = []
      data
        ?.filter(({ id }) => !(id === this.state.selectedItem?.id))
        .forEach(({ variants, id, ...restProduct }) => {
          if (variants?.length) {
            variants.forEach(({ attributes, id: variantId, ...restVariant }) => {
              const variant = {}
              Object.keys(restVariant).forEach(key => {
                if (restVariant[key] === null) return
                variant[key] = restVariant[key]
              })
              const product = {
                ...restProduct,
                ...variant,
                dimension: {
                  ...restProduct.dimension,
                  ...variant.dimension,
                },
                variantId,
                id,
              }
              productOptions.push({
                showSalePrice: product.showSalePrice,
                salePrice: product.salePrice,
                retailPrice: product.retailPrice,
                thumbnail: product.thumbnail,
                name: product.name,
                label: product.name,
                value: product,
              })
            })
          } else {
            const product = { ...restProduct, id }
            productOptions.push({
              showSalePrice: product.showSalePrice,
              salePrice: product.salePrice,
              retailPrice: product.retailPrice,
              thumbnail: product.thumbnail,
              name: product.name,
              label: product.name,
              value: product,
            })
          }
        })
      this.setState({ productOptions })
    })
  }

  onChangeInput = (e, name) => this.setState(({ order }) => ({
    order: {
      ...order,
      [name || e.target.name]: e.target.value,
    },
  }))

  onChangeSelect = (e, name) => this.setState(({ order }) => ({
    order: {
      ...order,
      [name]: e.value,
    },
  }))

  response = resp => {
    this.props.onSetLoading(false)
    this.setState({ btnLoading: false })

    let msn = _.replace(
      _.replace(process.env.REACT_APP_SUCCESS_ACTION, '%entity%', entity),
      '%action%',
      this.state.isCreate ? 'Created' : 'Updated',
    )

    if (resp.status === 'success') {
      Notification.success(msn)

      if (this.state.isCreate) {
        let url = _.replace(Routes.ORDER_DETAIL, ':id', resp.data.id)
        this.props.history.push(url)
      }
    } else {
      Notification.error(process.env.REACT_APP_ERROR_ACTION)
    }
  }

  action = async () => {
    this.setState({
      submited: true,
    })
    let isValid = this.validator.validate(this.state.order).isValid
    let action = this.state.isCreate ? 'create-order' : 'update-order'

    if (isValid) {
      this.props.onSetLoading(true)
      this.setState({ btnLoading: true })
      const resp = await this.props.post(this.state.order, action)
      this.response(resp)
    }
  }

  onPrint = async option => {
    if (option === 1) {
      let pdf = await Utils.printInvoice(
        [{
          ...this.state.order,
          products: this.state.order.products?.map(product => {
            return {
              ...product,
              categories: [
                ...(product?.categories?.map(
                  id => this.props?.productCategories?.find(
                    category => id === category.id,
                  )?.label || '',
                ) || []),
              ].join('/'),
              attributes: product.attributes?.filter(({ value }) => value !== product.brand).map(({ value }) => value)?.join('/') || '',
              selectedOptions: product?.selectedOptions.map(option => ({
                ...option,
                name: this.props.options?.find(({ id }) => id === option.id).name,
              }))
            }
          }) || [],
        }],
        this.props.settings.data,
        this.props.country,
        this.props.states,
        this.state.isAbandoned,
      )
      pdf.print()
      // pdf.open()
    } else {
      if (option === 2) {
        const packages = [{
          items: (this.state.packages.length === 0 ? this.state.order.products || [] : this.state.packages || [])
            .map(product => {
              // const p = this.props.products?.find(p => p.id === product.id)
              return {
                id: product.id,
                title: product.title,
                qtyInPackage: product.qty,
                sku: product.sku,
                categories: [
                  ...(product?.categories?.map(
                    id => this.props?.productCategories?.find(
                      category => id === category.id,
                    )?.label || '',
                  ) || []),
                ].join(', '),
                attributes: product.attributes?.map(({ value }) => value)?.join(', ') || '',
                selectedOptions: product?.selectedOptions.map(option => ({
                  ...option,
                  name: this.props.options?.find(({ id }) => id === option.id).name,
                }))
              }
            }),
        }]
        const order = _.cloneDeep(this.state.order)
        order.shippingPackages = packages
        let pdf = await Utils.printSlip(
          [order],
          this.props.settings.data,
          this.props.country,
          this.props.states,
        )
        pdf.print()
        // pdf.open()
      }
    }
  }

  onChangeLocation = (event, name) => this.setState(({ location }) => ({
    location: {
      ...location,
      [name || event.target.name]: name
        ? event.value
        : event.target.value,
    },
  }))

  onCloseLocation = () => {
    this.setState({
      isLocationOpen: false,
      locationSubmited: false,
    })
  }

  onUpdateLocation = (e, callback) => {
    e?.preventDefault()
    const { addressValue, location } = this.state
    const isValid = addressValue === 'shipp'
      ? this.location.validate(this.state.location).isValid
      : true

    if (!this.state.locationSubmited) {
      this.setState({
        locationSubmited: true,
      })
    }

    if (isValid) {
      this.setState(({ order }) => ({
        order: {
          ...order,
          [`${addressValue}ToFirstName`]: location[`${addressValue}ToFirstName`],
          [`${addressValue}ToLastName`]: location[`${addressValue}ToLastName`],
          [`${addressValue}ToEmail`]: location[`${addressValue}ToEmail`],
          [`${addressValue}ToCompany`]: location[`${addressValue}ToCompany`],
          [`${addressValue}ToPhoneNumber`]: location[`${addressValue}ToPhoneNumber`],
          [`${addressValue}ToAddress`]: location[`${addressValue}ToAddress`],
          [`${addressValue}ToCity`]: location[`${addressValue}ToCity`],
          [`${addressValue}ToState`]: location[`${addressValue}ToState`],
          [`${addressValue}ToZipcode`]: location[`${addressValue}ToZipcode`],
          [`${addressValue}ToCountry`]: location[`${addressValue}ToCountry`],
        },
        isLocationOpen: false,
      }), () => {
        this.updateSummary()
        if (typeof callback === 'function') callback()
      })
    }
  }

  onOpenLocation = addressValue => () => {
    this.onCopyLocation(addressValue)
    this.setState({ isLocationOpen: true })
  }

  onCopyLocation = addressValue => {
    const { order } = this.state
    this.location = new FormValidator(this.getLocationValidator(addressValue))
    this.setState({
      location: {
        [`${addressValue}ToFirstName`]: order[`${addressValue}ToFirstName`],
        [`${addressValue}ToLastName`]: order[`${addressValue}ToLastName`],
        [`${addressValue}ToEmail`]: order[`${addressValue}ToEmail`],
        [`${addressValue}ToCompany`]: order[`${addressValue}ToCompany`],
        [`${addressValue}ToPhoneNumber`]: order[`${addressValue}ToPhoneNumber`],
        [`${addressValue}ToAddress`]: order[`${addressValue}ToAddress`],
        [`${addressValue}ToCity`]: order[`${addressValue}ToCity`],
        [`${addressValue}ToState`]: order[`${addressValue}ToState`],
        [`${addressValue}ToZipcode`]: order[`${addressValue}ToZipcode`],
        [`${addressValue}ToCountry`]: order[`${addressValue}ToCountry`],
      },
      addressValue,
    })
  }

  paymentPopupToggle = () => (
    this.setState(({ paymentIsOpen }) => {
      if (!paymentIsOpen) this.onCopyLocation('bill')
      return ({
        paymentIsOpen: !paymentIsOpen,
      })
    })
  )
  getFilteredUsers = () => {
    const { order } = this.state
    if (!!order) {
      const { primaryRepId, secondaryRepId } = order
      return [
        { label: 'None', value: null },
        ...this.props.users.filter(
          ({ id, role }) => (role === Utils.GROUPS.SALES || role === Utils.GROUPS.ADMIN || role === Utils.GROUPS.MANAGER) && id !== primaryRepId && id !== secondaryRepId,
        ).map(
          ({ id, firstName, lastName }) => ({ label: [firstName, lastName].join(' '), value: id }),
        ),
      ]
    }

    return []
  }

  getSelectedUser = field => {
    const user = this.props.users.find(({ id }) => id === this.state.order[field])
    return user ?
      { label: [user.firstName, user.lastName].join(' '), value: user.id } :
      { label: 'None', value: null }
  }

  onSelectItem = ({ value: selectedItem }) => this.setState({
    selectedItem: {
      id: selectedItem.id,
      inventoryDescription: selectedItem.inventoryDescription,
      inventoryLocation: selectedItem.inventoryLocation,
      price: selectedItem.showSalePrice ? selectedItem.salePrice : selectedItem.retailPrice,
      qty: selectedItem.qty || 1,
      sku: selectedItem.sku,
      thumbnail: selectedItem.thumbnail,
      title: selectedItem.name,
      variantId: selectedItem.variantId,
      dimension: selectedItem.dimension,
    },
  })

  addItem = () => this.setState(prevState => ({
    order: {
      ...prevState.order,
      products: [
        ...prevState.order.products,
        this.state.selectedItem,
      ],
    },
    selectedItem: null,
  }), this.updateSummary)

  removeItem = removeId => () => this.setState(prevState => ({
    order: {
      ...prevState.order,
      products: prevState.order.products.filter(({ id }) => !(id === removeId)),
    },
  }), this.updateSummary)

  onQtyChange = e => (
    this.setState(prevState => ({
      selectedItem: {
        ...(prevState.selectedItem || {}),
        qty: e.target.value,
      },
    }))
  )

  onPriceChange = e => (
    this.setState(prevState => ({
      selectedItem: {
        ...(prevState.selectedItem || {}),
        price: e.target.value,
      },
    }))
  )

  subTotal = products => {
    let optionsPrice = 0
    products?.map(p => {
      // const optionPrice = (p.selectedOptions || []).reduce((acc, value) => acc + value.price, 0)
      optionsPrice += (p.price) * p.qty
    })
    return optionsPrice
  }

  updateSummary = async () => {
    const { order } = this.state
    const { labelFET } = this.props.settings.data?.mappings?.acima || {}

    this.setState({ summaryLoading: true })

    const { data, status } = await this.props.post(order, 'calculate-summary', '/api/public')

    if (status === 'success') {
      const { tax, fetTax, discount, shipping: shippingPrice } = data
      const totalTax = Object.values(tax || {}).reduce((a, b) => a + b, 0)
      this.setState(({ order }) => ({
        order: {
          ...order,
          tax,
          fetTax,
          discount,
          shippingPrice,
        },
        subtotal: this.subTotal(order.products),
        total: this.subTotal(order.products) + shippingPrice + totalTax,
      }))
    }

    this.setState({ summaryLoading: false })
  }

  publishEmail = () => {
    const { id } = this.state.order
    this.props.onSetLoading(true)
    this.setState({ btnLoading: true })
    this.props.post({ id, storeId: this.storeId }, 'publish-order').then(resp => {
      this.response(resp)
      this.fetchOrder()
    })
  }

  onTaxToggle = () => {
    const { summaryLoading } = this.state

    if (!summaryLoading) {
      this.setState(({ order }) => ({
        order: {
          ...order,
          ignoreTax: !order.ignoreTax,
        },
      }), this.updateSummary)
    }
  }

  render() {
    const {
      addressValue,
      isAbandoned,
      isDeclined,
      settings,
      order,
      summaryLoading,
      subtotal,
      total,
      btnLoading,
      paymentIsOpen,
      isLocationOpen,
      locationSubmited,
      location: stateLocation,
    } = this.state
    const { labelFET } = this.props.settings.data?.mappings?.acima || {}
    const { country, states, orderTypes } = this.props
    const { products, ignoreTax, promoCode, discount, tax, shippingPrice, invoiceDate, createdDate } = order || {}
    const filteredUsers = this.getFilteredUsers()
    const timeZone = settings?.timeZone?.value || 'America/New_York'
    const buttonsDiabled = summaryLoading || !order || !order?.products.length
    const totalTax = Object.values({ ...tax, [labelFET]: 0 }).reduce((a, b) => a + b, 0).toFixed(2)
    const formattedInvoiceDate = isAbandoned
      ? moment(createdDate || 0).tz(timeZone).format('MM/DD/YYYY HH:mm')
      : moment(invoiceDate || 0).tz(timeZone).format('MM/DD/YYYY HH:mm')
    const validator = this.state.submited
      ? this.validator.validate(this.state.order)
      : this.validator.valid()
    const validLocation = locationSubmited
      ? this.location?.validate(stateLocation)
      : this.location?.valid()

    return (
      <Portlet>
        <Helmet>
          <title>Order Details | Admin Panel - Max Ecommerce</title>
        </Helmet>
        <PortletHeader
          title={this.state.isCreate ? 'New Order' : isAbandoned ? 'Update Quote' : 'Update Order'}
          sticky
        >
          <Link
            to={`${isAbandoned ? Routes.ORDER_ABANDONED_LIST : Routes.ORDER_LIST}?store=${this.storeId}`}
            className="btn btn-secondary mr-3"
          >
            <i className="la la-arrow-left" />
            Back
          </Link>
          {!isAbandoned && !!order && (
            <Link
              to={`${Routes.SHIPPING_PACKAGE}?id=${this.props.match.params.id}&store=${this.storeId}`}
              className="btn btn-secondary mr-3"
            >
              <i className="flaticon-open-box" />
              Shipment
            </Link>
          )}
          <div className="btn-group">
            <button
              type="button"
              className="btn btn-secondary dropdown-toggle mr-3"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              disabled={buttonsDiabled}
            >
              <i className={'la la-print'} />
              Print
            </button>
            <div
              className="dropdown-menu dropdown-menu-right"
              x-placement="bottom-end"
            >
              <a
                onClick={e => this.onPrint(1)}
                className="dropdown-item"
                href="#"
              >
                {this.state.isAbandoned ? 'Print Quote' : 'Print Invoice'}
              </a>
              {!isAbandoned && (
                <a
                  onClick={e => this.onPrint(2)}
                  className="dropdown-item"
                  href="#"
                >
                  Print Packing Slips
                </a>
              )}
            </div>
          </div>
          {isAbandoned && (
            <Button
              label="Publish"
              color="secondary"
              className="mr-3 d-print-none"
              icon={`la ${btnLoading ? '' : 'flaticon2-mail'}`}
              isLoading={btnLoading}
              disabled={buttonsDiabled}
              onClick={this.publishEmail}
            />
          )}
          {(isAbandoned || isDeclined) && (
            <Button
              label="Payment"
              color="success"
              className="mr-3 d-print-none"
              icon="la la-credit-card"
              disabled={buttonsDiabled}
              onClick={this.paymentPopupToggle}
            />
          )}
          <Button
            label={this.state.isCreate ? 'Create' : 'Update'}
            color="primary"
            icon={`la ${btnLoading ? '' : ' la-check'}`}
            isLoading={btnLoading}
            disabled={buttonsDiabled}
            onClick={this.action}
          />
        </PortletHeader>
        <PortletBody>
          {!this.state.order ? (
            'Loading...'
          ) : (
            <div className="row justify-content-center">
              <div className="col-xl-10 py-5">
                <img
                  className="d-none d-print-block"
                  width="180px"
                  alt="Logo"
                  src={logo}
                />

                <Section size="lg" title="Address Information">
                  <div className="row">
                    <div className="col-md-4 order-md-3">
                      <span className="text-right">
                        <h3># {isAbandoned ? this.state.order?.quoteReference : this.state.order?.invoiceNumber}</h3>
                        <div>
                          <strong className="d-block">{isAbandoned ? 'Quote Date' : 'Order Date:'}</strong>
                          {formattedInvoiceDate}
                        </div>
                      </span>
                      <FormGroup className="mb-3">
                        <Select
                          options={
                            isAbandoned
                              ? orderTypes?.slice(5) || []
                              : orderTypes?.slice(0, 5) || []
                          }
                          value={Utils.object(
                            orderTypes,
                            this.state.order.status,
                          )}
                          onChange={e => this.onChangeSelect(e, 'status')}
                          className="mt-3"
                          isSearchable={false}
                        />
                      </FormGroup>
                      {this.state.userGroup !== Utils.GROUPS.SALES && this.state.userGroup !== Utils.GROUPS.MANAGER && this.state.userGroup !== Utils.GROUPS.ADMIN && (
                        <div className="row">
                          <FormGroup className="col-sm">
                            <Label>Primary Rep.</Label>
                            <Select
                              options={filteredUsers}
                              value={this.getSelectedUser('primaryRepId')}
                              onChange={e => this.onChangeSelect(e, 'primaryRepId')}
                              isSearchable
                            />
                          </FormGroup>
                          <FormGroup className="col-sm">
                            <Label>Secondary Rep.</Label>
                            <Select
                              options={filteredUsers}
                              value={this.getSelectedUser('secondaryRepId')}
                              onChange={e => this.onChangeSelect(e, 'secondaryRepId')}
                              isSearchable
                            />
                          </FormGroup>
                        </div>
                      )}
                      {!!this.state.packages.length && (
                        <FormGroup className="text-right">
                          <strong className="d-block">Shipment:</strong>
                          {_.startCase(
                            _.lowerCase(this.state.order.shippingType),
                          )}
                          <br />
                          {_.map(this.state.order.shippingPackages, (p, i) => (
                            <React.Fragment key={i}>
                              {p.trackingNumber &&
                                `Package #${i + 1}: ${p.trackingNumber}`}
                            </React.Fragment>
                          ))}
                        </FormGroup>
                      )}
                    </div>
                    <div className="col-md-4 order-md-1">
                      <h3>
                        Bill to
                        {isAbandoned && (
                          <button
                            className="btn p-0 ml-3 d-print-none k-link"
                            onClick={this.onOpenLocation('bill')}
                          >
                            (Edit)
                          </button>
                        )}
                      </h3>
                      <div>
                        <span>
                          {`${_.startCase(
                            _.lowerCase(this.state.order.billToFirstName),
                          ) || ''
                            }${this.state.order.billToFirstName ? ' ' : ''}${_.startCase(
                              _.lowerCase(this.state.order.billToLastName),
                            ) || ''
                            }` || 'N/A'}
                        </span>
                        <p className="mb-0">
                          {order.deliveryLocation ? Utils.toCapitalize(order.deliveryLocation.toLowerCase()) : ''}
                        </p>
                        <p className="mb-0">
                          {_.startCase(
                            _.lowerCase(this.state.order.billToCompany),
                          )}
                        </p>
                        <p>
                          {_.startCase(
                            _.lowerCase(this.state.order.billToAddress),
                          )}
                          <br />
                          {_.startCase(
                            _.lowerCase(this.state.order.billToCity),
                          )}
                          {', '}
                          {this.state.order.billToState}{' '}
                          {this.state.order.billToZipcode}
                          <br />
                          {_.get(
                            _.find(
                              country,
                              c => c.value == this.state.order.billToCountry,
                            ),
                            'label',
                            '',
                          )}
                        </p>
                        <strong>T</strong>: {this.state.order.billToPhoneNumber}
                        <br />
                        <strong>E</strong>: {this.state.order.billToEmail}
                        <br />
                        <br />
                        <strong>TRANS.</strong> {this.state.order.transactionID}
                        <br />
                        <strong>CT.</strong> {this.state.order.cardType}
                        <br />
                        <strong>CC.</strong> xxxx xxxx xxxx{' '}
                        {this.state.order.lastFourCreditCard}
                      </div>
                    </div>
                    <div className="col-md-4 my-4 my-md-0 order-md-2">
                      <h3>
                        Ship to
                        <button
                          className="btn p-0 ml-3 d-print-none k-link"
                          onClick={this.onOpenLocation('shipp')}
                        >
                          (Edit)
                        </button>
                      </h3>
                      <div>
                        <span>
                          {`${_.startCase(
                            _.lowerCase(this.state.order.shippToFirstName),
                          )} ${_.startCase(
                            _.lowerCase(this.state.order.shippToLastName),
                          )}`}
                        </span>
                        <p className="mb-0">
                          {order.deliveryLocation ? Utils.toCapitalize(order.deliveryLocation.toLowerCase()) : ''}
                        </p>
                        <p className="mb-0">
                          {_.startCase(
                            _.lowerCase(this.state.order.shippToCompany),
                          )}
                        </p>
                        <p>
                          {_.startCase(
                            _.lowerCase(this.state.order.shippToAddress),
                          )}
                          <br />
                          {_.startCase(
                            _.lowerCase(this.state.order.shippToCity),
                          )}
                          {', '}
                          {this.state.order.shippToState}{' '}
                          {this.state.order.shippToZipcode}
                          <br />
                          {_.get(
                            _.find(
                              country,
                              c => c.value == this.state.order.shippToCountry,
                            ),
                            'label',
                            '',
                          )}
                        </p>
                        <strong>T</strong>:{' '}
                        {this.state.order.shippToPhoneNumber}
                        <br />
                        <strong>E</strong>: {this.state.order.shippToEmail}
                      </div>
                    </div>
                  </div>
                </Section>
                <div className="py-5">
                  <Separator size="xl" border={'dashed'} />
                </div>
                <Section title={isAbandoned ? 'Items' : 'Items Ordered'} size="lg">
                  <BootstrapTable
                    bootstrap4
                    hover
                    key={String(new Date())}
                    data={Array.isArray(products) ? products : []}
                    columns={this.getColumns()}
                    keyField="id"
                    cellEdit={cellEditFactory({
                      mode: 'click',
                      blurToSave: true,
                      afterSaveCell: () => {
                        const { order } = this.state
                        const { products } = order
                        this.setState(
                          {
                            order: {
                              ...order,
                              products: products.map(({ price, qty, ...restProduct }) => ({
                                ...restProduct,
                                price: +price,
                                qty: +qty,
                              })),
                            },
                          },
                          this.updateSummary,
                        )
                      },
                    })}
                  />
                </Section>
                <div className="py-5">
                  <Separator size="xl" border={'dashed'} />
                </div>
                <Section>
                  <div className="row justify-content-end">
                    <div className="col-auto">
                      <table>
                        <tbody>
                          <tr>
                            <td />
                            <td className="h3 py-1">Summary</td>
                          </tr>
                          {promoCode ? (
                            <tr>
                              <td className="h5 pr-5 py-1 text-right">
                                Promotion:
                              </td>
                              <td>{promoCode}</td>
                            </tr>
                          ) : null}
                          <tr>
                            <td className="h5 pr-5 py-1 text-right">
                              Subtotal:
                            </td>
                            <td>{summaryLoading ? '-' : `$${subtotal.toFixed(2)}`}</td>
                          </tr>
                          {discount ? (
                            <tr className="text-danger">
                              <td className="h5 pr-5 py-1 text-right">
                                Discount:
                              </td>
                              <td>{summaryLoading ? '-' : `$${discount.toFixed(2)}`}</td>
                            </tr>
                          ) : null}
                          <tr style={{ marginTop: '5px' }}>
                            <td className="h5 pr-5 py-1 text-right">
                              Shipping:
                            </td>
                            <td>
                              {isAbandoned ? (
                                <Input
                                  value={shippingPrice}
                                  onChange={this.onChangeInput}
                                  onBlur={this.updateSummary}
                                  name="shippingPrice"
                                />
                              ) : (
                                `$${shippingPrice.toFixed(2)}`
                              )}
                            </td>
                          </tr>
                          <tr style={{ marginTop: '5px' }}>
                            {!!totalTax && (
                              <>
                                <td className="h5 pr-5 py-1 text-right">Tax:</td>
                                <td style={{ display: 'flex', alignItems: 'center' }}>
                                  ${ignoreTax ? 0 : totalTax}
                                  {isAbandoned && (
                                    <div className="col-9">
                                      <span className="k-switch k-switch--md k-switch--icon" style={{ display: 'flex' }}>
                                        <label style={{ margin: 0 }}>
                                          <Input
                                            checked={!order.ignoreTax ? 'checked' : ''}
                                            onChange={this.onTaxToggle}
                                            name="tax"
                                            type="checkbox"
                                          />
                                          <span />
                                        </label>
                                      </span>
                                    </div>
                                  )}
                                </td>
                              </>
                            )}
                          </tr>
                          <tr>
                            <td className="h5 pr-5 py-1 text-right">Fet tax:</td>
                            <td style={{ display: 'flex', alignItems: 'center' }}>{tax[labelFET] || 0}</td>
                          </tr>
                          <tr>
                            <td className="h1 text-danger pr-5 py-1 text-right">
                              Total:
                            </td>
                            {/*font-weight-bold*/}
                            <td className="h1 text-danger">
                              {summaryLoading ? '-' : `$${ignoreTax ? (total - totalTax).toFixed(2) : total.toFixed(2)}`}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Section>
                <div className="py-5">
                  <Separator size="xl" border={'dashed'} />
                </div>
                <Section title="Comments History" size="lg">
                  <FormGroup>
                    <Label>Customer Notes:</Label>
                    <Input
                      value={''}
                      type="textarea"
                      onChange={this.onChangeInput}
                      name="label"
                      invalid={validator.status.isInvalid}
                      placeholder="Customer notes..."
                    />
                    <FormFeedback>{validator.status.message}</FormFeedback>
                  </FormGroup>
                </Section>
              </div>
              {validLocation && (
                <AddressEditForm
                  onChangeLocation={this.onChangeLocation}
                  onUpdateLocation={this.onUpdateLocation}
                  onCloseLocation={this.onCloseLocation}
                  validLocation={validLocation}
                  addressValue={addressValue}
                  isOpen={isLocationOpen}
                  values={stateLocation}
                  country={country}
                  states={states}
                />
              )}
              {(isAbandoned || isDeclined) && (
                <PaymentForm
                  isOpen={paymentIsOpen}
                  modalToggle={this.paymentPopupToggle}
                  orderId={order?.id}
                  fetchOrder={this.fetchOrder}
                  storeId={this.storeId}
                  payment={payment}
                  onChangeLocation={this.onChangeLocation}
                  onUpdateLocation={this.onUpdateLocation}
                  action={this.action}
                  validLocation={validLocation}
                  addressValue={addressValue}
                  values={stateLocation}
                  states={states}
                />
              )}
            </div>
          )}
        </PortletBody>
      </Portlet>
    )
  }
}

export const mapStateToProps = state => ({
  products: state.products?.data || [],
  options: state.options?.data || [],
  orderTypes: state.orderType,
  settings: state.settings,
  fedexPackageType: state.fedexPackageType,
  fedexWeightType: state.fedexWeightType,
  fedexDimensionType: state.fedexDimensionType,
  country: state.country,
  states: state.states,
  users: state.users?.data || [],
  productCategories: state?.productCategories?.data || [],
})

export const mapDispatchToProps = dispatch => ({
  post: function (payload, actionEvent, path) {
    return dispatch(Actions.post(payload, actionEvent, path))
  },
  storePost: function (payload, actionEvent, typeSuccess, typeError) {
    return dispatch(
      Actions.storePost(payload, actionEvent, typeSuccess, typeError),
    )
  },
  storePostProducts: function (payload, actionEvent, typeSuccess, typeError) {
    return dispatch(
      Actions.storePostProducts(payload, actionEvent, typeSuccess, typeError),
    )
  },
  onSetLoading: payload => dispatch(UiActions.onSetLoading(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(OrderForm)
