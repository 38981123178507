import { combineReducers } from 'redux'

import { uiReducers } from './UiReducers'
import * as dataReducers from './DataReducers'

const CombineReducers = combineReducers({
  ui: uiReducers,

  // Ordes
  orders: dataReducers.Order,

  // Products
  products: dataReducers.Product,
  
  // Options
  options: dataReducers.Option,

  // Customers
  customers: dataReducers.Customer,

  // Reports
  reports: dataReducers.Reports,

  // Promotions
  promotions: dataReducers.Promotion,

  // Pages
  pages: dataReducers.Page,

  // Users
  users: dataReducers.User,

  // Settings
  settings: dataReducers.Settings,

  // Attributes
  attributes: dataReducers.Attributes,

  // Variants
  variants: dataReducers.Variants,

  // Stores
  stores: dataReducers.Stores,
  currentStore: dataReducers.currentStore,

  // Others
  years: dataReducers.Year,
  productCategories: dataReducers.productCategories,
  pageCategories: dataReducers.PageCategories,
  taxType: dataReducers.TaxType,
  orderType: dataReducers.OrderType,
  country: dataReducers.Country,
  states: dataReducers.States,

  // Fedex
  fedexServiceType: dataReducers.FedexServiceType,
  fedexPackageType: dataReducers.FedexPackageType,
  fedexDropoffType: dataReducers.FedexDropoffType,
  fedexWeightType: dataReducers.FedexWeightType,
  fedexDimensionType: dataReducers.FedexDimensionType,
  fedexLabelStockType: dataReducers.FedexLabelStockType,

  // Usps
  uspsServiceType: dataReducers.UspsServiceType,
  uspsPackageType: dataReducers.UspsPackageType,

  // Promotions
  broadcastType: dataReducers.BroadcastType,
  associateToType: dataReducers.AssociateToType,
  discountType: dataReducers.DiscountType,
  discountRateType: dataReducers.DiscountRateType,
  discountApplyType: dataReducers.DiscountApplyType,
  discountQuantityApplyType: dataReducers.DiscountQuantityApplyType,
  noRate: dataReducers.NoRate,
  discountRateReadonlyType: dataReducers.DiscountRateReadonlyType,
})

export default CombineReducers
