import React from 'react'

const VariantsTable = ({ variants, setEdit, deleteVariant }) => (
  variants.length ? (
    <table className="variants-table">
      <colgroup>
        <col span="1" style={{ width: '30%' }} />
        <col span="1" style={{ width: '10%' }} />
        <col span="1" style={{ width: '10%' }} />
        <col span="1" style={{ width: '7%' }} />
        <col span="1" style={{ width: '7%' }} />
        <col span="1" style={{ width: '8%' }} />
        <col span="1" style={{ width: '8%' }} />
        <col span="1" style={{ width: '5%' }} />
        <col span="1" style={{ width: '5%' }} />
      </colgroup>
      <thead>
        <tr>
          <th>Name</th>
          <th>Image</th>
          <th>SKU</th>
          <th>Price</th>
          <th>Weight</th>
          <th>Inventory</th>
          <th>Stock</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {variants.map(({ id, name, thumbnail, sku, retailPrice, dimension, inventory, inStock, status }) => (
          <tr key={id}>
            <td>{name}</td>
            <td className="image">
              {thumbnail && (
                <a
                  href={thumbnail}
                  style={{ backgroundImage: `url('${thumbnail}')` }}
                  target="_blank"
                />
              )}
            </td>
            <td>{sku}</td>
            <td>{retailPrice}</td>
            <td>{dimension?.weight || ''}</td>
            <td>{inventory}</td>
            <td>{inStock ? 'In Stock' : 'Out of Stock'}</td>
            <td>{status ? 'Active' : 'Inactive'}</td>
            <td>
              <div className="actions">
                <div onClick={deleteVariant(id)} />
                <div onClick={setEdit(id)} />
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  ) : (
    <p className="variants-table-empty">
      Currently no variant assigned
    </p>
  )
)

export default VariantsTable
