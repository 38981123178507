import {
  React,
  Component,
  connect,
  Actions,
  Portlet,
  PortletHeader,
  PortletBody,
  Section,
  Utils,
  Notification,
  Routes,
  Link,
  FormValidator,
  Button,
  ActionTypes,
  _,
} from '../../../Imports'
import { Helmet } from 'react-helmet'

const entity = 'Package Slips'

class PackageSlips extends Component {
  constructor(props) {
    super(props)

    this.validator = new FormValidator([
      {
        field: 'calculateTaxTypeForTax',
        method: 'isEmpty',
        validWhen: false,
        message: 'The Tax Type is required.',
      },
      {
        field: 'taxAmountForTax',
        method: 'isEmpty',
        validWhen: false,
        message: 'The Tax Amount is required.',
      },
    ])

    this.state = {
      order: null,
      setting: null,
      submited: false,
      isCreate: false,
      btnLoading: false,
      packages: [],
    }

    let search = Utils.search(this.props.location)

    this.storeId = search.store
    this.id = search.id
  }

  UNSAFE_componentWillMount = async () => {
    if (this.props.settings.data === null) {
      await this.props.storePost(
        { id: this.storeId },
        'get-store',
        ActionTypes.FETCH_SETTINGS_SUCCESS,
        ActionTypes.FETCH_SETTINGS_FAILURE,
      )
    }

    await this.props
      .post(
        {
          id: this.id,
          storeId: this.storeId,
        },
        'get-order',
      )
      .then(resp => {
        let order = resp.data
        let pa = _.get(order, 'shippingPackages', [])

        this.setState({
          order,
          packages: pa === null ? [] : pa,
        })
      })

    if (this.state.packages.length > 0) {
      this.pdf = await Utils.printSlip(
        [this.state.order],
        this.props.settings.data,
      )

      this.pdf.getDataUrl(url => {
        document.getElementById('pdfV').src = url
      })
    }
  }

  update = () => {
    this.setState({
      submited: !this.state.submited,
    })

    let isValid = true //this.validator.validate(this.state.setting).isValid;

    if (isValid) {
      // this.setState({ btnLoading: true });
      // let order = this.state.order;
      // order.shippingPackages = this.state.packages;

      // this.props.post(order, "update-order").then(resp => {
      // 	this.response(resp);

      // 	this.setState({ btnLoading: false });
      this.goNext()
      // }).catch(e => {
      // 	this.setState({ btnLoading: false });
      // });
    }
  }

  goNext = () => {
    const items = Routes.SHIPMENT_OPTIONS

    const index = _.findIndex(
      items,
      i => i.url === this.props.location.pathname,
    )

    if (items.length > index + 1) {
      this.props.history.push(
        `${items[index + 1].url}?id=${this.id}&store=${this.storeId}`,
      )
    }
  }

  response = resp => {
    this.setState({
      btnLoading: false,
    })

    let msn = _.replace(
      _.replace(process.env.REACT_APP_SUCCESS_ACTION, '%entity%', entity),
      '%action%',
      'Updated',
    )

    if (resp.status === 'success') {
      Notification.success(msn)
    } else {
      Notification.error(process.env.REACT_APP_ERROR_ACTION)
    }
  }

  print = () => {
    if (this.state.packages.length > 0) {
      this.pdf.open()
    }
  }

  render = () => (
    //const validator = this.state.submited
    //	? this.validator.validate(this.state.setting)
    //	: this.validator.valid();

    <Portlet>
      <Helmet>
        <title>Package Slips | Admin Panel - Max Ecommerce</title>
      </Helmet>
      <PortletHeader
        title={`Package slips for order #${_.get(
          this.state.order,
          'invoiceNumber',
          '',
        )}`}
        sticky
      >
        <Link
          to={`/shipment/carrier?id=${this.id}&store=${this.storeId}`}
          className="btn btn-secondary mr-3"
        >
          <i className="la la-arrow-left" />
          Back
        </Link>

        <Link
          to={`/order/detail/${this.id}?store=${this.storeId}`}
          className="btn btn-secondary mr-3"
        >
          <i className="la la-arrow-left" />
          Back to Order
        </Link>

        {/*
					<button
						onClick={this.print}
						type="button"
						className="btn btn-secondary mr-3"
					>
						<i className={"la la-print"} />
						Print
					</button>
                    */}
        {this.state.order !== null && this.state.packages.length > 0 && (
          <Button
            label={'Next'}
            color="primary"
            icon={`la ${this.state.btnLoading ? '' : ' la-arrow-right'}`}
            isLoading={this.state.btnLoading}
            onClick={this.update}
          />
        )}
      </PortletHeader>
      <PortletBody>
        {this.state.order === null && (
          <div className="row">
            <div className="col-12">
              <div>Loading...</div>
            </div>
          </div>
        )}

        {this.state.order !== null && this.state.packages.length > 0 && (
          <div className="row mr-2 ml-2">
            <div className="col-xl-12">
              <Section title="">
                <iframe
                  id="pdfV"
                  frameBorder="0"
                  marginHeight="0"
                  marginWidth="0"
                  style={{
                    width: '100%',
                    height: '900px',
                  }}
                />
              </Section>
            </div>
          </div>
        )}

        {this.state.order !== null && this.state.packages.length === 0 && (
          <div className="row mr-2 ml-2">
            <div className="col-xl-12">
              <Section title="">Pleace create the shipping packages.</Section>
            </div>
          </div>
        )}
      </PortletBody>
    </Portlet>
  )
}

export const mapStateToProps = state => ({
  settings: state.settings,
  taxType: state.taxType,
})

export const mapDispatchToProps = dispatch => ({
  post: function(payload, actionEvent) {
    return dispatch(Actions.post(payload, actionEvent))
  },
  storePost: function(payload, actionEvent, typeSuccess, typeError) {
    return dispatch(
      Actions.storePost(payload, actionEvent, typeSuccess, typeError),
    )
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(PackageSlips)
