import {
  React,
  Component,
  connect,
  Actions,
  Portlet,
  PortletHeader,
  PortletBody,
  BootstrapTable,
  filterFactory,
  Utils,
  ActionTypes,
  CSVLink,
  _,
} from '../../../Imports'
import { Helmet } from 'react-helmet'
import moment from 'moment-timezone'

class ShippingReport extends Component {
  storeId = Utils.search(this.props.location).store

  UNSAFE_componentWillMount() {
    if (!this.props.settings) {
      this.props.storePost(
        { id: this.storeId },
        'get-store',
        ActionTypes.FETCH_SETTINGS_SUCCESS,
        ActionTypes.FETCH_SETTINGS_FAILURE,
      )
    }
    this.props.storePost(
      { storeId: this.storeId },
      'orders-shipped-today',
      ActionTypes.SHIPPING_REPORT_SUCCESS,
      ActionTypes.SHIPPING_REPORT_FAILURE,
    )
  }

  getColumns = () => {
    const columns = [
      { dataField: 'invoiceNumber', text: 'Inv. #' },
      { dataField: 'orderDate', text: 'Order Date' },
      { dataField: 'customerName', text: 'Name' },
      { dataField: 'shippingZipCode', text: 'Sh. Zip Code' },
      { dataField: 'packageCount', text: 'Pkg. Count' },
      { dataField: 'totalWeight', text: 'Weight' },
      { dataField: 'cost', text: 'Cost' },
      { dataField: 'shippingDate', text: 'Ship Date' },
      { dataField: 'trackingNumber', text: 'Tracking #' },
      { dataField: 'ponumber', text: 'PO Number' },
    ].map(item => ({
      dataField: item.dataField,
      text: item.text,
      headerClasses: 'align-top',
      headerStyle: (colum, colIndex) => ({ width: '110px' }),
      formatter: (cell, row, rowIndex, formatExtraData) => {
        const settings = _.get(this.props.settings, 'data', {})
        const timeZone =
          (settings && settings.timeZone && settings.timeZone.value) ||
          'America/New_York'
        switch (item.dataField) {
        case 'orderDate':
        case 'shippingDate':
          return (
            <span>
              {moment(cell).tz(timeZone).format('MM/DD/YYYY HH:mm')}
            </span>
          )

        case 'trackingNumber':
          return (
            <span
              style={{
                cursor: 'pointer',
                color: '#5867dd',
                textDecoration: 'underline',
              }}
              onClick={() => window.open(row.trackingUrl)}
            >
              {cell}
            </span>
          )

        case 'cost':
          return <span>${cell}</span>

        default:
          return <span>{cell}</span>
        }
      },
    }))

    return columns
  }

  getCsvLinkData = () => {
    const { reports, settings } = this.props
    const ss = _.get(settings, 'data', {})
    const timeZone =
      (ss && ss.timeZone && ss.timeZone.value) || 'America/New_York'

    const data = reports.data
      ? reports.data.map(report => [
        report['invoiceNumber'] + ' ',
        moment(report['orderDate']).tz(timeZone).format('MM/DD/YYYY HH:mm') +
            ' ',
        report['customerName'] + ' ',
        report['shippingZipCode'] + ' ',
        report['packageCount'] + ' ',
        report['totalWeight'] + ' ',
        report['cost'] + ' ',
        moment(report['shippingDate'])
          .tz(timeZone)
          .format('MM/DD/YYYY HH:mm') + ' ',
        report['trackingNumber'] + ' ',
        report['ponumber'] + ' ',
        report['trackingUrl'] + ' ',
      ])
      : []

    const headers = [
      'Inv. #',
      'Order Date',
      'Name',
      'Sh. Zip Code',
      'Pkg. Count',
      'Weight',
      'Cost',
      'Ship Date',
      'Tracking #',
      'PO Number',
      'Tracking URL',
    ]

    return {
      data,
      headers,
    }
  }

  render() {
    const { reports, settings } = this.props
    const columns = this.getColumns()
    const csvLinkData = this.getCsvLinkData()
    const { cost, packageCount } =
      reports.data.length &&
      reports.data.reduce(
        (acc = { cost: 0, packageCount: 0 }, { cost, packageCount }) => ({
          cost: +acc.cost + +cost,
          packageCount: +acc.packageCount + +packageCount,
        }),
      )

    return (
      <Portlet>
        <Helmet>
          <title>Shipping Report | Admin Panel - Max Ecommerce</title>
        </Helmet>
        <PortletHeader sticky title="Shipping Report" />
        <PortletBody>
          <div className="row">
            <div className="col-4">
              <CSVLink
                data={csvLinkData.data}
                headers={csvLinkData.headers}
                className="mb-4 btn btn-primary"
              >
                Export CSV
              </CSVLink>
            </div>
            <div className="col-4" />
            <div className="col-4" />
            <div
              style={{
                display: 'flex',
                margin: '0 0 20px 15px',
                fontSize: '16px',
                flexDirection: 'column',
                justifyContent: 'space-around',
                height: '64px',
              }}
            >
              <p style={{ margin: '0' }}>
                Total Cost:{' '}
                <span style={{ fontWeight: '500' }}>{cost || 0}</span>
              </p>
              <p style={{ margin: '0' }}>
                Total Number of Packages Shipped:{' '}
                <span style={{ fontWeight: '500' }}>{packageCount || 0}</span>
              </p>
            </div>
          </div>
          {!!reports.data.length && settings && (
            <BootstrapTable
              filter={filterFactory()}
              bootstrap4
              data={reports.data}
              columns={columns}
              keyField="invoiceNumber"
              classes="mb-0"
              wrapperClasses="mx-n3 mb-2 overflow-auto"
              hover
            />
          )}
        </PortletBody>
      </Portlet>
    )
  }
}

const mapStateToProps = state => ({
  reports: state.reports,
  settings: state.settings.data,
})

const mapDispatchToProps = dispatch => ({
  storePost: function(payload, actionEvent, typeSuccess, typeError) {
    return dispatch(
      Actions.storePost(payload, actionEvent, typeSuccess, typeError),
    )
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ShippingReport)
