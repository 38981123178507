import {
  React,
  Component,
  connect,
  Actions,
  Portlet,
  PortletHeader,
  PortletBody,
  Section,
  Select,
  FormGroup,
  Input,
  Label,
  FormFeedback,
  Utils,
  Notification,
  Routes,
  Link,
  FormValidator,
  Button,
  ActionTypes,
  UiActions,
  _,
} from '../../../Imports'
import moment from 'moment-timezone'
import { Helmet } from 'react-helmet'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import ReactSummernote from 'react-summernote'
import 'react-summernote/dist/react-summernote.css'
import 'bootstrap'
import ProductAside from './ProductAside'
import MaskedInput from 'react-text-mask'
import VariantsForm from '../../base/variants/VariantsForm'
import DropZone from '../../base/dropzone/DropZone'
import UnitSelect from '../../base/select/UnitSelect'

const entity = 'Product'

let imgStyle = {
  height: '70px',
  border: '1px solid #f5f2f2',
}

const allowedFiles = 'image/jpeg, image/png'
class ProductForm extends Component {
  constructor(props) {
    super(props)

    this.validator = new FormValidator([
      {
        field: 'name',
        method: 'isEmpty',
        validWhen: false,
        message: 'Name is required.',
      },
      {
        field: 'categories',
        method: 'isEmpty',
        validWhen: false,
        message: 'At least one category is required.',
      },
      // {
      //     field: "productName",
      //     method: "isEmpty",
      //     validWhen: false,
      //     message: "Name is required."
      // },
      // {
      //     field: "categoryId",
      //     method: "isEmpty",
      //     validWhen: false,
      //     message: "This Title is required."
      // },
      {
        field: 'thumbnail',
        method: 'isEmpty',
        validWhen: false,
        message: 'This Thumbnail is required.',
      },
    ])

    this.state = {
      product: null,
      settings: null,
      dimension: {},
      imageInput: '',
      categories: [],
      attributes: [],
      submited: false,
      isCreate: false,
      isAutoGenerateURL: true,
      currentSection: Routes.PRODUCT_SECTION_INFORMATION,
      manufacturerIdTypes: [
        {
          value: 'UPC',
          label: 'UPC',
        },
        {
          value: 'GTIN',
          label: 'GTIN',
        },
        {
          value: 'ISBN',
          label: 'ISBN',
        },
        {
          value: 'EAN',
          label: 'EAN',
        },
      ],
    }

    this.storeId = Utils.search(this.props.location).store
  }

  UNSAFE_componentWillMount = async () => {
    this.props.clearProductVariants()
    if (this.props.categories.data.length === 0) {
      await this.props.storePost(
        {
          storeId: this.storeId,
        },
        'list-categories',
        ActionTypes.FETCH_CATEGORIES_SUCCESS,
        ActionTypes.FETCH_CATEGORIES_FAILURE,
      )
    }

    if (this.props.settings.data === null) {
      await this.props.storePost(
        { id: this.storeId },
        'get-store',
        ActionTypes.FETCH_SETTINGS_SUCCESS,
        ActionTypes.FETCH_SETTINGS_FAILURE,
      )
    }

    let categories = _.map(this.props.categories.data, p => ({
      value: p.id,
      label: _.capitalize(p.label),
    }))

    this.setState({ categories })

    this.setState(
      { isCreate: Utils.isCreate(this.props.location.pathname) },
      () => {
        if (this.state.isCreate) {
          this.setState({
            product: {},
          })
        } else {
          const settings = _.get(this.props.settings, 'data', {})

          let payload = {
            storeId: this.storeId,
            id: this.props.match.params.id,
          }

          this.props.post(payload, 'get-product').then(resp => {
            const product = resp.data
            this.setState({
              product: {
                ...product,
                categories: product.categories || [],
                productsImagesUrl: product.productsImagesUrl?.filter(
                  image => image !== product.thumbnail,
                ),
              },
              settings,
              dimension: _.get(product, 'dimension', {}),
            }, () => {
              this.props.storePost(
                { productId: product.id },
                'list-variants',
                ActionTypes.FETCH_VARIANTS_SUCCESS,
                ActionTypes.FETCH_VARIANTS_FAILURE,
              )
            })
          })
        }
        this.props.storePost(
          { storeId: this.storeId },
          'list-attributes',
          ActionTypes.FETCH_ATTRIBUTES_SUCCESS,
          ActionTypes.FETCH_ATTRIBUTES_FAILURE,
        )
          .then(({ data }) => this.setState({
            attributes: (data || [])
              .map(({ id, key: name, options }) => options.map(value => ({ id, name, value }))).flat(),
          }))
      },
    )
  }
  componentDidUpdate() {
    this.autoGenerateURL()
  }

  autoGenerateURL() {
    if(this.state.isAutoGenerateURL && this.state.isCreate && this.state.product &&
      this.state.product.name) {
      const autoGeneratedURL = this.state.product.name
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, ' ')
        .trim()
        .replace(/\s+/g, "-")
      if(autoGeneratedURL !== this.state.product?.url) {
        const product = JSON.parse(JSON.stringify(this.state.product))
        product.url = autoGeneratedURL
        product.canonical = autoGeneratedURL
        this.setState({product})
      }
    }
  }

  onChangeSection = currentSection => {
    this.setState({
      currentSection,
    })
  }

  onChangeInput = e => {
    let product = this.state.product
    product[e.target.name] = e.target.value
    this.setState({ product })
  }

  onChangeHTTP = e => {
    this.setState({isAutoGenerateURL: false})
    let product = this.state.product
    product[e.target.name] = e.target.value
    this.setState({ product })

  }

  onChangeDimentionInput = e => {
    let dimension = this.state.dimension
    dimension[e.target.name] = e.target.value
    this.setState({ dimension })
  }

  onChangeDimentionUnit = (e, value) => this.setState(({ dimension }) => ({
    dimension: {
      ...(dimension || {}),
      [e.target.name]: value,
    },
  }))

  change = (e, attribute) => {
    let product = this.state.product
    product[attribute] = e.target.value
    this.setState({ product: product })
  }

  onChangeCheckbox = e => {
    let product = this.state.product
    product[e.target.name] = !_.get(product, e.target.name, false)

    this.setState({ product })
  }

  onChangeMultiCategories = (e, name) => {
    this.setState(({ product }) => ({
      product: {
        ...product,
        [name]: e.map(c => c.value),
      },
    }))
  }

  onChangeMultiAttributes = name => event => {
    const attrNames = event.map(({ value }) => value)
    this.setState(({ product }) => ({
      product: {
        ...product,
        [name]: this.state.attributes.filter(({ value }) => attrNames.includes(value)),
      },
    }))
  }

  onChangeSelect = (e, name) => {
    const product = { ...this.state.product }
    product[name] = e.value

    this.setState({ product })
  }

  onChangeHtml = (content, name) => {
    let product = this.state.product
    product[name] = content
    this.setState({ product })
  }

  onTextareaHtml = e => {
    let product = this.state.product
    product['description'] = e.target.value
    this.setState({ product })
  }

  onChangeSpanishHtml = value => {
    let product = this.state.product
    product.descriptionSpanish = value
    this.setState({ product })
  }

  action = async () => {
    if (!this.state.submited) {
      this.setState({
        submited: true,
      })
    }

    let isValid = this.validator.validate(this.state.product).isValid
    let action = this.state.isCreate ? 'create-product' : 'update-product'

    if (isValid) {
      const { product } = this.state
      this.props.onSetLoading(true)
      this.setState({ btnLoading: true })
      const payload = {
        ...product,
        dimension: this.state.dimension,
        storeId: this.storeId,
        productsImagesUrl: product.productsImagesUrl?.filter(
          image => image !== product.thumbnail,
        ),
      }
      this.props.post(payload, action).then(resp => {
        this.response(resp)
      })
    }
  }

  response = resp => {
    this.props.onSetLoading(false)
    this.setState({ btnLoading: false })

    let msn = _.replace(
      _.replace(process.env.REACT_APP_SUCCESS_ACTION, '%entity%', entity),
      '%action%',
      this.state.isCreate ? 'Created' : 'Updated',
    )

    if (resp.status === 'success') {
      Notification.success(msn)

      if (this.state.isCreate) {
        this.props.history.push(
          '/product/detail/' + resp.data.id + '?store=' + this.storeId,
        )
      }
    } else {
      Notification.error(process.env.REACT_APP_ERROR_ACTION)
    }
  }

  applyAvailability = (e, picker) => {
    let product = this.state.product
    let settings = this.state.settings

    product['availabilityDate'] = picker.startDate.tz(settings.timeZone.value)

    this.setState({ product })
  }

  onImageChange = (images, selected) => this.setState(({ product }) => ({
    product: {
      ...product,
      productsImagesUrl: images,
      thumbnail: selected,
    },
  }))

  render = () => {
    const { product, settings } = this.state
    const validator = this.state.submited
      ? this.validator.validate(product)
      : this.validator.valid()
    const { productsImagesUrl, thumbnail } = this.state.product || {}

    let toolbar = [
      ['style', ['style']],
      ['font', ['bold', 'underline', 'clear']],
      ['fontname', ['fontname']],
      ['para', ['ul', 'ol', 'paragraph']],
      ['table', ['table']],
      ['insert', ['link', 'picture', 'video']],
      ['view', ['fullscreen', 'codeview']],
    ]

    const timeZone = _.get(settings, 'timeZone.value', 'America/New_York')
    let availabilityDate = _.get(product, 'availabilityDate', 0)
    availabilityDate = availabilityDate
      ? moment(availabilityDate).tz(timeZone).format('MM-DD-YYYY')
      : moment().tz(timeZone).format('MM-DD-YYYY')

    const columns = [
      {
        dataField: 'id',
        hidden: true,
      },
      {
        dataField: 'id',
        text: '#',
        sort: false,
        footer: '',
        footerFormatter: (column, colIndex) => (
          <div>
            {this.state.order.specialItem !== null && (
              <span>{this.state.order.products.length + 1}</span>
            )}
          </div>
        ),
        headerStyle: (colum, colIndex) => ({ width: '50px' }),
        formatter: (cell, row, rowIndex, formatExtraData) => (
          <span key={rowIndex}>{rowIndex + 1}</span>
        ),
      },
      {
        dataField: 'name',
        text: 'Item',
        sort: false,
        footer: '',
        footerFormatter: (column, colIndex) => (
          <div>
            {this.state.order.specialItem !== null && (
              <span>{this.state.order.specialItem.label}</span>
            )}
          </div>
        ),
        formatter: (cell, row, rowIndex, formatExtraData) => {
          let url = '/product/detail/' + row.id
          let name = `${row.brand} - ${row.name} (${row.size})`
          return (
            <a href={url} target="_blank" key={rowIndex}>
              {name} {/*{_.capitalize(name)}*/}
            </a>
          )
        },
      },
      {
        dataField: 'quantity',
        text: 'Qty',
        align: 'right',
        sort: true,
        footer: '',
        footerFormatter: (column, colIndex) => (
          <div>
            {this.state.order.specialItem !== null && (
              <span className="pull-right">-</span>
            )}
          </div>
        ),
        headerStyle: (colum, colIndex) => ({ width: '100px' }),
      },
      {
        dataField: 'price',
        text: 'Price',
        align: 'right',
        sort: true,
        footer: '',
        footerFormatter: (column, colIndex) => (
          <div>
            {this.state.order.specialItem !== null && (
              <span className="pull-right">
                ${this.state.order.specialItem.price.toFixed(2)}
              </span>
            )}
          </div>
        ),
        headerStyle: (colum, colIndex) => ({ width: '100px' }),
        formatter: (cell, row, rowIndex, formatExtraData) => (
          <span key={rowIndex}>${cell.toFixed(2)}</span>
        ),
      },
      {
        dataField: 'amount',
        text: 'Amount',
        sort: true,
        footer: '',
        align: 'right',
        footerFormatter: (column, colIndex) => (
          <div>
            {this.state.order.specialItem !== null && (
              <span
                style={{
                  fontSize: '18',
                  fontWeight: 600,
                }}
                className="pull-right"
              >
                ${this.state.order.specialItem.price.toFixed(2)}
              </span>
            )}
          </div>
        ),
        headerStyle: (colum, colIndex) => ({ width: '100px' }),
        formatter: (cell, row, rowIndex, formatExtraData) => {
          let amount = row.price * row.quantity
          return (
            <span
              key={rowIndex + row.id}
              style={{
                fontSize: '18',
                fontWeight: 600,
              }}
            >
              ${amount.toFixed(2)}
            </span>
          )
        },
      },
    ]

    return (
      <div className="row product-form">
        <Helmet>
          <title>Product | Admin Panel - Max Ecommerce</title>
        </Helmet>
        <div className="col-lg-3 col-xl-3">
          <ProductAside
            currentSection={this.state.currentSection}
            onChangeSection={this.onChangeSection}
          />
        </div>
        <div className="col-lg-9 col-xl-9">
          <Portlet
            className={`form-fields product-form-${this.state.currentSection.replace(
              '#',
              '',
            )}`}
          >
            <PortletHeader
              title={this.state.isCreate ? 'New Product' : 'Update Product'}
              sticky
            >
              <Link
                to={`/products?store=${
                  Utils.search(this.props.location).store
                }`}
                className="btn btn-secondary mr-3"
              >
                <i className="la la-arrow-left" />
                Back
              </Link>
              <Button
                label={this.state.isCreate ? 'Create' : 'Update'}
                color="primary"
                icon={`la ${this.state.btnLoading ? '' : ' la-check'}`}
                isLoading={this.state.btnLoading}
                onClick={this.action}
              />
            </PortletHeader>
            <PortletBody>
              {product === null && (
                <div className="row">
                  <div className="col-12">
                    <div>Loading...</div>
                  </div>
                </div>
              )}

              {product !== null && (
                <div className="row">
                  <div className="col-xl-1" />
                  <div className="col-xl-10">
                    {this.state.currentSection ==
                      Routes.PRODUCT_SECTION_INFORMATION && (
                      <Section title="Information" size="lg">
                        <FormGroup className="row">
                          <Label className="col-3 col-form-label">Active</Label>
                          <div className="col-9">
                            <span className="k-switch k-switch--md k-switch--icon">
                              <label>
                                <Input
                                  checked={product.status ? 'checked' : ''}
                                  onChange={this.onChangeCheckbox}
                                  name="status"
                                  type="checkbox"
                                />
                                <span />
                              </label>
                            </span>
                          </div>
                        </FormGroup>
                        <FormGroup className="row">
                          <Label className="col-3 col-form-label">
                            Featured
                          </Label>
                          <div className="col-9">
                            <span className="k-switch k-switch--md k-switch--icon">
                              <label>
                                <Input
                                  checked={product.featured ? 'checked' : ''}
                                  onChange={this.onChangeCheckbox}
                                  name="featured"
                                  type="checkbox"
                                />
                                <span />
                              </label>
                            </span>
                          </div>
                        </FormGroup>
                        <FormGroup className="row">
                          <Label className="col-3 col-form-label">Name </Label>
                          <div className="col-9">
                            <Input
                              value={product.name || ''}
                              onChange={e => this.change(e, 'name')}
                              invalid={validator.name.isInvalid}
                            />
                            <FormFeedback>
                              {validator.name.message}
                            </FormFeedback>
                          </div>
                        </FormGroup>
                        <FormGroup className="row">
                          <Label className="col-3 col-form-label">SKU </Label>
                          <div className="col-9">
                            <Input
                              value={product.sku || ''}
                              onChange={this.onChangeInput}
                              name="sku"
                            />
                          </div>
                        </FormGroup>
                        <FormGroup className="row">
                          <Label className="col-3 col-form-label">
                            Show SKU
                          </Label>
                          <div className="col-9">
                            <span className="k-switch k-switch--md k-switch--icon">
                              <label>
                                <Input
                                  checked={product.showSku ? 'checked' : ''}
                                  onChange={this.onChangeCheckbox}
                                  name="showSku"
                                  type="checkbox"
                                />
                                <span />
                              </label>
                            </span>
                          </div>
                        </FormGroup>
                        <FormGroup className="row">
                          <Label className="col-3 col-form-label">
                            Manufacturer ID
                          </Label>
                          <div className="col-9">
                            <Input
                              value={product.manufacturerId || ''}
                              onChange={this.onChangeInput}
                              name="manufacturerId"
                              type="number"
                            />
                          </div>
                        </FormGroup>
                        <FormGroup className="row">
                          <Label className="col-3 col-form-label">
                            Manufacturer ID Type
                          </Label>
                          <div className="col-9">
                            <Select
                              options={this.state.manufacturerIdTypes}
                              value={{
                                value: product.manufacturerIdType || '',
                                label: product.manufacturerIdType || '',
                              }}
                              onChange={e =>
                                this.onChangeSelect(e, 'manufacturerIdType')
                              }
                              isSearchable={false}
                              closeMenuOnSelect={true}
                            />
                          </div>
                        </FormGroup>
                        <FormGroup className="row">
                          <Label className="col-3 col-form-label">
                            Categories
                          </Label>
                          <div className="col-9">
                            <Select
                              options={this.state.categories}
                              value={Utils.multiObject(
                                this.state.categories,
                                product.categories,
                              )}
                              onChange={e => this.onChangeMultiCategories(e, 'categories')}
                              className={
                                validator.categories.isInvalid
                                  ? 'is-invalid'
                                  : ''
                              }
                              isSearchable
                              isMulti
                              closeMenuOnSelect={false}
                            />
                            <FormFeedback>
                              {validator.categories.message}
                            </FormFeedback>
                          </div>
                        </FormGroup>
                        <FormGroup className="row">
                          <Label className="col-3 col-form-label">
                            Sort Index{' '}
                          </Label>
                          <div className="col-9">
                            <Input
                              value={product.sortIndex || ''}
                              onChange={this.onChangeInput}
                              type="number"
                              name="sortIndex"
                            />
                          </div>
                        </FormGroup>
                        <FormGroup className="row">
                          <Label className="col-3 col-form-label">
                            Availability Date{' '}
                          </Label>
                          <div className="col-9">
                            <DateRangePicker
                              autoApply
                              autoUpdateInput
                              onApply={this.applyAvailability}
                              singleDatePicker
                              containerClass="d-block react-bootstrap-daterangepicker-container"
                            >
                              <div className="input-group date">
                                <Input
                                  value={availabilityDate}
                                  placeholder="Select date"
                                  readOnly={true}
                                />
                                <div className="input-group-append">
                                  <span className="input-group-text">
                                    <i className="la la-calendar" />
                                  </span>
                                </div>
                              </div>
                            </DateRangePicker>
                          </div>
                        </FormGroup>
                        <FormGroup className="row">
                          <Label className="col-3 col-form-label">
                            New Product
                          </Label>
                          <div className="col-9">
                            <span className="k-switch k-switch--md k-switch--icon">
                              <label>
                                <Input
                                  checked={product.newProduct ? 'checked' : ''}
                                  onChange={this.onChangeCheckbox}
                                  name="newProduct"
                                  type="checkbox"
                                />
                                <span />
                              </label>
                            </span>
                          </div>
                        </FormGroup>
                        <FormGroup className="row">
                          <Label className="col-3 col-form-label">
                            New Product Sort Index{' '}
                          </Label>
                          <div className="col-9">
                            <Input
                              value={product.newProductSortIndex || ''}
                              type="number"
                              onChange={this.onChangeInput}
                              name="newProductSortIndex"
                            />
                          </div>
                        </FormGroup>
                        <FormGroup className="row">
                          <Label className="col-3 col-form-label">
                            Attributes
                          </Label>
                          <div className="col-9">
                            <Select
                              options={
                                this.state.attributes
                                  .filter(attr => !product?.attributes?.map(({ id }) => id).includes(attr.id))
                                  .map(({ value }) => ({ label: value, value }))
                              }
                              value={
                                product.attributes
                                  ?.map(({ value }) => ({ label: value, value })) || []
                              }
                              onChange={this.onChangeMultiAttributes('attributes')}
                              isSearchable={true}
                              isMulti
                              closeMenuOnSelect={false}
                            />
                          </div>
                        </FormGroup>
                        <FormGroup className="row">
                          <Label className="col-3 col-form-label">
                            Description{' '}
                          </Label>
                          <div className="col-9">
                            <ReactSummernote
                              children={
                                (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: product.description,
                                    }}
                                  ></div>
                                ) || ''
                              }
                              options={{
                                height: 150,
                                dialogsInBody: true,
                                popover: {
                                  image: [],
                                  link: [],
                                  air: [],
                                },
                                toolbar: toolbar,
                              }}
                              onChange={e => {
                                this.onChangeHtml(e, 'description')
                              }}
                            />
                          </div>
                        </FormGroup>
                        <FormGroup className="row">
                          <Label className="col-3 col-form-label">
                            Short Description{' '}
                          </Label>
                          <div className="col-9">
                            <ReactSummernote
                              children={
                                (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: product.shortDescription,
                                    }}
                                  />
                                ) || ''
                              }
                              options={{
                                height: 150,
                                dialogsInBody: true,
                                popover: {
                                  image: [],
                                  link: [],
                                  air: [],
                                },
                                toolbar: toolbar,
                              }}
                              onChange={e => {
                                this.onChangeHtml(e, 'shortDescription')
                              }}
                            />
                          </div>
                        </FormGroup>
                      </Section>
                    )}

                    {this.state.currentSection ==
                      Routes.PRODUCT_SECTION_DIMENSIONS && (
                      <Section title="Dimensions" size="lg">
                        <FormGroup className="row">
                          <Label className="col-3 col-form-label">
                            Weight{' '}
                          </Label>
                          <div className="col-9">
                            <div className="input-group">
                              <UnitSelect
                                isWeight
                                onChange={this.onChangeDimentionUnit}
                                value={this.state.dimension.weightUnit}
                                name="weightUnit"
                              />
                              <Input
                                value={this.state.dimension.weight}
                                type="number"
                                onChange={this.onChangeDimentionInput}
                                name="weight"
                              />
                            </div>
                          </div>
                        </FormGroup>
                        <FormGroup className="row">
                          <Label className="col-3 col-form-label">
                            Length{' '}
                          </Label>
                          <div className="col-9">
                            <div className="input-group">
                              <UnitSelect
                                onChange={this.onChangeDimentionUnit}
                                value={this.state.dimension.lengthUnit}
                                name="lengthUnit"
                              />
                              <Input
                                value={this.state.dimension.length || ''}
                                type="number"
                                onChange={this.onChangeDimentionInput}
                                name="length"
                              />
                            </div>
                          </div>
                        </FormGroup>
                        <FormGroup className="row">
                          <Label className="col-3 col-form-label">Width </Label>
                          <div className="col-9">
                            <div className="input-group">
                              <UnitSelect
                                onChange={this.onChangeDimentionUnit}
                                value={this.state.dimension.widthUnit}
                                name="widthUnit"
                              />
                              <Input
                                value={this.state.dimension.width || ''}
                                type="number"
                                onChange={this.onChangeDimentionInput}
                                name="width"
                              />
                            </div>
                          </div>
                        </FormGroup>
                        <FormGroup className="row">
                          <Label className="col-3 col-form-label">
                            Height{' '}
                          </Label>
                          <div className="col-9">
                            <div className="input-group">
                              <UnitSelect
                                onChange={this.onChangeDimentionUnit}
                                value={this.state.dimension.heightUnit}
                                name="heightUnit"
                              />
                              <Input
                                value={this.state.dimension.height || ''}
                                type="number"
                                onChange={this.onChangeDimentionInput}
                                name="height"
                              />
                            </div>
                          </div>
                        </FormGroup>
                      </Section>
                    )}
                    {this.state.currentSection ==
                      Routes.PRODUCT_SECTION_INVENTORY && (
                      <Section title="Inventory" size="lg">
                        <FormGroup className="row">
                          <Label className="col-3 col-form-label">
                            Inventory Location{' '}
                          </Label>
                          <div className="col-9">
                            <Input
                              value={product.inventoryLocation || ''}
                              onChange={this.onChangeInput}
                              type="text"
                              name="inventoryLocation"
                            />
                          </div>
                        </FormGroup>
                        <FormGroup className="row">
                          <Label className="col-3 col-form-label">
                            Inventory Description{' '}
                          </Label>
                          <div className="col-9">
                            <Input
                              value={product.inventoryDescription || ''}
                              onChange={this.onChangeInput}
                              type="text"
                              name="inventoryDescription"
                            />
                          </div>
                        </FormGroup>
                        <FormGroup className="row">
                          <Label className="col-3 col-form-label">
                            In Stock
                          </Label>
                          <div className="col-9">
                            <span className="k-switch k-switch--md k-switch--icon">
                              <label>
                                <Input
                                  checked={product.inStock ? 'checked' : ''}
                                  onChange={this.onChangeCheckbox}
                                  name="inStock"
                                  type="checkbox"
                                />
                                <span />
                              </label>
                            </span>
                          </div>
                        </FormGroup>
                        <FormGroup className="row">
                          <Label className="col-3 col-form-label">
                            Inventory{' '}
                          </Label>
                          <div className="col-9">
                            <Input
                              value={product.inventory || ''}
                              type="number"
                              onChange={this.onChangeInput}
                              name="inventory"
                            />
                          </div>
                        </FormGroup>
                      </Section>
                    )}
                    {this.state.currentSection ==
                      Routes.PRODUCT_SECTION_SALES && (
                      <Section title="Sales" size="lg">
                        <FormGroup className="row">
                          <Label className="col-3 col-form-label">Cost </Label>
                          <div className="col-9">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text">$</span>
                              </div>
                              <MaskedInput
                                name="cost"
                                value={product.cost}
                                onChange={this.onChangeInput}
                                mask={Utils.currencyMask}
                                className="form-control"
                              />
                            </div>
                          </div>
                        </FormGroup>

                        <FormGroup className="row">
                          <Label className="col-3 col-form-label">Price </Label>
                          <div className="col-9">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text">$</span>
                              </div>
                              <MaskedInput
                                name="retailPrice"
                                value={product.retailPrice}
                                onChange={this.onChangeInput}
                                mask={Utils.currencyMask}
                                className="form-control"
                              />
                            </div>
                          </div>
                        </FormGroup>

                        <FormGroup className="row">
                          <Label className="col-3 col-form-label">
                            Show Price
                          </Label>
                          <div className="col-9">
                            <span className="k-switch k-switch--md k-switch--icon">
                              <label>
                                <Input
                                  checked={
                                    product.showRetailPrice ? 'checked' : ''
                                  }
                                  onChange={this.onChangeCheckbox}
                                  name="showRetailPrice"
                                  type="checkbox"
                                />
                                <span />
                              </label>
                            </span>
                          </div>
                        </FormGroup>

                        <FormGroup className="row">
                          <Label className="col-3 col-form-label">
                            Sale Price{' '}
                          </Label>
                          <div className="col-9">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text">$</span>
                              </div>
                              <MaskedInput
                                name="salePrice"
                                value={product.salePrice}
                                onChange={this.onChangeInput}
                                mask={Utils.currencyMask}
                                className="form-control"
                              />
                            </div>
                          </div>
                        </FormGroup>

                        <FormGroup className="row">
                          <Label className="col-3 col-form-label">
                            Show Sale Price
                          </Label>
                          <div className="col-9">
                            <span className="k-switch k-switch--md k-switch--icon">
                              <label>
                                <Input
                                  checked={
                                    product.showSalePrice ? 'checked' : ''
                                  }
                                  onChange={this.onChangeCheckbox}
                                  name="showSalePrice"
                                  type="checkbox"
                                />
                                <span />
                              </label>
                            </span>
                          </div>
                        </FormGroup>

                        <FormGroup className="row">
                          <Label className="col-3 col-form-label">
                            Special Sort Index{' '}
                          </Label>
                          <div className="col-9">
                            <Input
                              value={product.specialSortIndex || ''}
                              onChange={this.onChangeInput}
                              type="number"
                              name="specialSortIndex"
                            />
                          </div>
                        </FormGroup>
                        <FormGroup className="row">
                          <Label className="col-3 col-form-label">
                            Best Seller
                          </Label>
                          <div className="col-9">
                            <span className="k-switch k-switch--md k-switch--icon">
                              <label>
                                <Input
                                  checked={product.bestSeller ? 'checked' : ''}
                                  onChange={this.onChangeCheckbox}
                                  name="bestSeller"
                                  type="checkbox"
                                />
                                <span />
                              </label>
                            </span>
                          </div>
                        </FormGroup>

                        <FormGroup className="row">
                          <Label className="col-3 col-form-label">
                            Best Seller Sort Index{' '}
                          </Label>
                          <div className="col-9">
                            <Input
                              value={product.bestSellerSortIndex || ''}
                              type="number"
                              onChange={this.onChangeInput}
                              name="bestSellerSortIndex"
                            />
                          </div>
                        </FormGroup>
                        <FormGroup className="row">
                          <Label className="col-3 col-form-label">
                            Pre Order
                          </Label>
                          <div className="col-9">
                            <span className="k-switch k-switch--md k-switch--icon">
                              <label>
                                <Input
                                  checked={product.preOrder ? 'checked' : ''}
                                  onChange={this.onChangeCheckbox}
                                  name="preOrder"
                                  type="checkbox"
                                />
                                <span />
                              </label>
                            </span>
                          </div>
                        </FormGroup>

                        <FormGroup className="row">
                          <Label className="col-3 col-form-label">
                            Clearance
                          </Label>
                          <div className="col-9">
                            <span className="k-switch k-switch--md k-switch--icon">
                              <label>
                                <Input
                                  checked={product.clearance ? 'checked' : ''}
                                  onChange={this.onChangeCheckbox}
                                  name="clearance"
                                  type="checkbox"
                                />
                                <span />
                              </label>
                            </span>
                          </div>
                        </FormGroup>
                        <FormGroup className="row">
                          <Label className="col-3 col-form-label">
                            Tax Class
                          </Label>
                          <div className="col-9">
                            <span className="k-switch k-switch--md k-switch--icon">
                              <label>
                                <Input
                                  checked={product.taxClass ? 'checked' : ''}
                                  onChange={this.onChangeCheckbox}
                                  name="taxClass"
                                  type="checkbox"
                                />
                                <span />
                              </label>
                            </span>
                          </div>
                        </FormGroup>
                      </Section>
                    )}

                    {this.state.currentSection ==
                      Routes.PRODUCT_SECTION_SEARCHENGINE && (
                      <Section title="Search engine" size="lg">
                        <FormGroup className="row">
                          <Label className="col-3 col-form-label">URL</Label>
                          <div className="col-9">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text">Http</span>
                              </div>
                              <Input
                                value={product.url}
                                onChange={this.onChangeHTTP}
                                name="url"
                              />
                            </div>
                          </div>
                        </FormGroup>
                        <FormGroup className="row">
                          <Label className="col-3 col-form-label">Title</Label>
                          <div className="col-9">
                            <Input
                              value={product.title || ''}
                              onChange={this.onChangeInput}
                              name="title"
                            />
                          </div>
                        </FormGroup>
                        <FormGroup className="row">
                          <Label className="col-3 col-form-label">
                            Meta Keywords
                          </Label>
                          <div className="col-9">
                            <Input
                              value={product.metaKeywords || ''}
                              onChange={this.onChangeInput}
                              name="metaKeywords"
                            />
                          </div>
                        </FormGroup>
                        <FormGroup className="row">
                          <Label className="col-3 col-form-label">
                            Meta Description
                          </Label>
                          <div className="col-9">
                            <Input
                              value={product.metaDescription || ''}
                              onChange={this.onChangeInput}
                              name="metaDescription"
                            />
                          </div>
                        </FormGroup>
                        <FormGroup className="row">
                          <Label className="col-3 col-form-label">
                            Canonical
                          </Label>
                          <div className="col-9">
                            <Input
                              value={product.canonical || ''}
                              onChange={this.onChangeHTTP}
                              name="canonical"
                            />
                          </div>
                        </FormGroup>
                      </Section>
                    )}
                    {this.state.currentSection ==
                      Routes.PRODUCT_SECTION_MEDIA && (
                      <Section title="Media">
                        <FormGroup className="row">
                          <Label className="col-3 col-form-label">
                            Product Video
                          </Label>
                          <div className="col-9">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text">Http</span>
                              </div>
                              <Input
                                value={this.state.product.productVideo || ''}
                                onChange={this.onChangeInput}
                                name="productVideo"
                              />
                            </div>
                          </div>
                        </FormGroup>
                        <DropZone
                          isMultiple
                          images={productsImagesUrl}
                          selected={thumbnail}
                          submited={this.state.submited}
                          validator={validator}
                          onChange={this.onImageChange}
                        />
                      </Section>
                    )}
                    {this.state.currentSection == Routes.PRODUCT_SECTION_VARIANTS && (
                      <VariantsForm productId={product.id} />
                    )}
                  </div>
                  <div className="col-xl-1" />
                </div>
              )}
            </PortletBody>
          </Portlet>
        </div>
      </div>
    )
  }
}

export const mapStateToProps = state => ({
  categories: state.productCategories,
  settings: state.settings,
})

export const mapDispatchToProps = dispatch => ({
  post: function(payload, actionEvent) {
    return dispatch(Actions.post(payload, actionEvent))
  },
  storePost: function(payload, actionEvent, typeSuccess, typeError) {
    return dispatch(
      Actions.storePost(payload, actionEvent, typeSuccess, typeError),
    )
  },
  onSetLoading: payload => dispatch(UiActions.onSetLoading(payload)),
  clearProductVariants: () => dispatch(Actions.clearProductVariants()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductForm)
