import React, { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import classNames from 'classnames'
import {
  Portlet,
  PortletHeader,
  PortletBody,
  Notification,
  Button,
  Actions,
  ActionTypes,
  Utils,
} from '../../../../Imports'
import { Helmet } from 'react-helmet'

import TaxForm from './TaxForm'

const Tax = ({ }) => {
  // Router
  const location = useLocation()
  // Actions
  const dispatch = useDispatch()
  const post = useCallback((...arg) => dispatch(Actions.post(...arg)))
  const storePost = useCallback((...arg) => dispatch(Actions.storePost(...arg)))
  // State
  const [settings, setSettings] = useState(useSelector(({ settings }) => settings.data || {}))
  const [loading, setLoading] = useState(false)
  // Hooks
  useEffect(() => {
    if (!Object.keys(settings).length) {
      const id = Utils.search(location).store
      storePost(
        { id },
        'get-store',
        ActionTypes.FETCH_SETTINGS_SUCCESS,
        ActionTypes.FETCH_SETTINGS_FAILURE,
      ).then(({ status, data }) => {
        if (status === 'success') {
          setSettings(data)
        } else {
          Notification.error(data || process.env.REACT_APP_ERROR_ACTION)
        }
      })
    }
  }, [])
  // Functions
  const onChangeCheckbox = (index, name) => () => setSettings(prevSettings => ({
    ...prevSettings,
    taxes: prevSettings.taxes.map((tax, i) => i !== index ? tax : ({
      ...tax,
      [name]: !tax[name],
    })),
  }))
  
  const onChange = (index, name) => event => setSettings(prevSettings => ({
    ...prevSettings,
    taxes: prevSettings.taxes.map((tax, i) => i !== index ? tax : ({
      ...tax,
      [name]: event.target.value,
    })),
  }))
  
  const onChangeSelect = (index, name) => event => setSettings(prevSettings => ({
    ...prevSettings,
    taxes: prevSettings.taxes.map((tax, i) => i !== index ? tax : ({
      ...tax,
      [name]: event.value,
    })),
  }))

  const onChangeMultiSelect = (index, name) => value => setSettings(prevSettings => ({
    ...prevSettings,
    taxes: prevSettings.taxes.map((tax, i) => i !== index ? tax : ({
      ...tax,
      [name]: value,
    })),
  }))

  const removeTax = index => () => setSettings(prevSettings => ({
    ...prevSettings,
    taxes: prevSettings.taxes.filter((tax, i) => !(i === index)),
  }))

  const addTax = () => setSettings(prevSettings => ({
    ...prevSettings,
    taxes: [
      ...prevSettings.taxes,
      {
        activeTax: false,
        amountTax: '',
        globalTax: false,
        idTax: '',
        labelTax: '',
        restrictedTax: false,
        restrictionTax: [],
        typeTax: '',
      },
    ],
  }))

  const onSubmit = () => {
    const isNotValid = !!settings.taxes.find(
      ({ labelTax, idTax, typeTax, amountTax }) => !labelTax || !idTax || !typeTax || !amountTax,
    )

    if (!isNotValid && !loading) {
      setLoading(true)
      post(settings, 'update-store').then(({ status, data }) => {
        setLoading(false)
        if (status === 'success') {
          const message = process.env.REACT_APP_SUCCESS_ACTION
            .replace('%entity%', 'Tax')
            .replace('%action%', 'Updated')
          Notification.success(message)
          setSettings(data)
        } else {
          Notification.error(data || process.env.REACT_APP_ERROR_ACTION)
        }
      })
    }
  }

  return (
    <Portlet>
      <Helmet>
        <title>Tax | Admin Panel - Max Ecommerce</title>
      </Helmet>
      <PortletHeader
        title={`Tax ${settings.name ? `- ${settings.name}` : ''}`}
        sticky
      >
        <Button
          label="Add tax"
          className="btn btn-secondary mr-3"
          disabled={loading}
          onClick={addTax}
        />
        <Button
          label="Update"
          color="primary"
          icon={classNames('la', { 'la-check': !loading })}
          disabled={loading}
          isLoading={loading}
          onClick={onSubmit}
        />
      </PortletHeader>
      <PortletBody>
        {settings ? (
          <div className="row">
            <div className="col-xl-1" />
            <div className="col-xl-10">
              {settings.taxes?.map((tax, index, arr) => (
                <TaxForm
                  onChangeMultiSelect={onChangeMultiSelect}
                  onChangeCheckbox={onChangeCheckbox}
                  onChangeSelect={onChangeSelect}
                  onChange={onChange}
                  removeTax={removeTax}
                  index={index}
                  key={index}
                  tax={tax}
                  arr={arr}
                />
              ))}
            </div>
            <div className="col-xl-1" />
          </div>
        ) : (
          <div className="row">
            <div className="col-12">
              <div>Loading...</div>
            </div>
          </div>
        )}
      </PortletBody>
    </Portlet>
  )
}

export default Tax
