import React from 'react'
import { toast } from 'react-toastify'
import Message from './Message'

export const show = props =>
  toast(
    <Message title={props.title} type={props.type} message={props.message} />,
  )

export const success = message =>
  show({
    title: 'Success',
    type: 'success',
    message: message,
  })

export const info = message =>
  show({
    title: 'Info',
    type: 'info',
    message: message,
  })

export const warn = message =>
  show({
    title: 'Warning',
    type: 'warning',
    message: message,
  })

export const error = message =>
  show({
    title: 'Error',
    type: 'danger',
    message: message,
  })
