import React from 'react'
import ReactDOM from 'react-dom'

import './static/scss/style.scss'

import { createStore, applyMiddleware } from 'redux'
import CombineReducers from './reducers/CombineReducers'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import promise from 'redux-promise'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'

const store = createStore(
  CombineReducers,
  composeWithDevTools(applyMiddleware(thunk, promise)),
)

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root'),
)
