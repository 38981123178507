import React, { Component } from 'react'
import classNames from 'classnames'
import * as Routes from '../../layout/Routes'
import _ from 'lodash'

class ProductAside extends Component {
  getItems = () =>
    _.map(Routes.PRODUCT_SECTIONS, s => (
      <li className="k-nav__item active" key={s.url}>
        <a
          onClick={e => {
            e.preventDefault()
            this.props.onChangeSection(s.url)
          }}
          className={classNames('k-nav__link', {
            active: this.props.currentSection == s.url,
          })}
        >
          <span className="k-nav__link-icon">
            <i className={s.icon} />
          </span>
          <span className="k-nav__link-text">{s.label}</span>
        </a>
      </li>
    ))

  render = () => {
    let items = this.getItems()

    return (
      <div className="k-portlet settings-aside">
        <div className="k-portlet__body k-portlet__body--fit">
          <ul className="k-nav k-nav--bold k-nav--md-space k-nav--v3 k-margin-t-20 k-margin-b-20 nav nav-tabs">
            {items}
          </ul>
        </div>
      </div>
    )
  }
}

export default ProductAside
