import {
  React,
  Component,
  connect,
  Actions,
  Portlet,
  PortletHeader,
  PortletBody,
  Section,
  Select,
  FormGroup,
  Input,
  Label,
  FormFeedback,
  Utils,
  Notification,
  Link,
  FormValidator,
  Separator,
  Button,
  ActionTypes,
  DateRangePicker,
  UiActions,
  _,
} from '../../../Imports'
import moment from 'moment-timezone'
import {Async as AsyncSelect} from 'react-select'
import ProductSearchOption from '../../base/select/ProductSearchOption'
import MaskedInput from 'react-text-mask'
import isEmpty from 'validator/lib/isEmpty'
import { Helmet } from 'react-helmet'

const entity = 'Promotion'

class PromotionForm extends Component {
  constructor(props) {
    super(props)

    this.validator = new FormValidator([
      {
        field: 'name',
        method: 'isEmpty',
        validWhen: false,
        message: 'Name is required.',
      },
      {
        field: 'label',
        method: 'isEmpty',
        validWhen: false,
        message: 'Label is required.',
      },
      {
        field: 'broadcastType',
        method: this.broadcastValid,
        validWhen: true,
        message: 'Broadcast Type is required.',
      },
      {
        field: 'discountType',
        method: 'isEmpty',
        validWhen: false,
        message: 'Discount Type is required.',
      },
      {
        field: 'discountQuantity',
        method: this.discountQuantityValid,
        validWhen: true,
        message: 'Discount Quantity is required.',
      },
      {
        field: 'discountQuantityApply',
        method: this.discountQuantityValid,
        validWhen: true,
        message: 'Discount Quantity Apply is required.',
      },
      {
        field: 'rate',
        method: this.rateValid,
        validWhen: true,
        message: 'Rate is required.',
      },
      {
        field: 'promoCode',
        method: this.promoCodeValid,
        validWhen: true,
        message: 'Promotion Code is required.',
      },
      {
        field: 'limitQuantity',
        method: this.limitQuantityValid,
        validWhen: true,
        message: 'Limit Quantity is required.',
      },
      {
        field: 'minimumOrderAmount',
        method: this.minimumOrderAmountValid,
        validWhen: true,
        message: 'Minimum Order Amount is required.',
      },
      {
        field: 'priority',
        method: 'isEmpty',
        validWhen: false,
        message: 'Priority is required.',
      },
    ])

    this.debouncedLoadOptions = _.debounce(this.loadProductOptions.bind(this), 1000);

    this.storeId = Utils.search(this.props.location).store

    this.productsOptions = []
    this.categoriesOptions = []

    this.state = {
      promotion: null,
      associateTo: '',
      submited: false,
      isCreate: false,
      btnLoading: false,
      productsSelectSearchValue: '',
    }
  }

  UNSAFE_componentWillMount = async () => {
    if (this.props.settings.data === null) {
      await this.props.storePost(
        { id: this.storeId },
        'get-store',
        ActionTypes.FETCH_SETTINGS_SUCCESS,
        ActionTypes.FETCH_SETTINGS_FAILURE,
      )
    }
    if (this.props.categories.data.length === 0) {
      await this.props.storePost(
        {
          storeId: this.storeId,
        },
        'list-categories',
        ActionTypes.FETCH_CATEGORIES_SUCCESS,
        ActionTypes.FETCH_CATEGORIES_FAILURE,
      )
    }

    if (this.props.products.data.length === 0) {
      this.getProducts('')
    }

    this.productsDefaultOptions = _.map(this.props.products.data, p => ({
      value: p.id,
      label: p.name,
      object: p,
    }))
    this.categoriesOptions = _.map(this.props.categories.data, c => ({
      value: c.id,
      label: c.label,
      object: c,
    }))

    const settings = this.props.settings.data

    this.setState(
      { isCreate: Utils.isCreate(this.props.location.pathname) },
      () => {
        if (this.state.isCreate) {
          this.setState({
            promotion: {
              expirationDate: moment()
                .tz(settings.timeZone.value)
                .format('MM-DD-YYYY'),
              products: [],
              categories: [],
              discountApplyType: 'AUTOMATICALLY',
              discountRateType: 'AMOUNT',
            },
            associateTo: 'PRODUCTS',
            settings,
          })
        } else {
          const id = this.props.match.params.id
          const payload = { storeId: this.storeId, id }

          this.props.post(payload, 'get-promotion').then(resp => {
            let associateTo = _.get(resp.data, 'categories', [])
            associateTo =
              associateTo && associateTo.length ? 'CATEGORIES' : 'PRODUCTS'

            console.log((resp.data.rate || 0) / 100)

            this.setState({
              promotion: {
                ...resp.data,
                rate: (resp.data.rate || 0) / 100,
              },
              associateTo,
              settings,
            })
          })
        }
      },
    )
  }

  onProductsSelectSearchValueChange = (newValue, {action}) => {
    // if (action === 'menu-close' || action === 'input-blur' || action === 'set-value') {return}
    this.setState({
      productsSelectSearchValue: newValue,
    })
  }

  loadProductOptions = async ( filterValue, callback) => {
    const products = await this.getProducts(filterValue)

    const mappedProducts = _.map(products.data, p => ({
      value: p.id,
      label: p.name,
      object: p,
    }))

    callback(mappedProducts)
  }


  getProducts = async (filterValue) => {
    const payload = {
      clientId: this.storeId,
      paging: {
        pageNumber: 0,
        pageSize: 1000,
      },
    }

    if(filterValue) {
      payload.filter = {
        '@type': 'Contains',
        attribute: 'productName',
        value: filterValue,
      }
    }

    return await this.props.storePostProductsTable(
      payload,
      'list-products',
      ActionTypes.FETCH_PRODUCTS_LOADING,
      ActionTypes.FETCH_PRODUCTS_SUCCESS,
      ActionTypes.FETCH_PRODUCTS_FAILURE,
    )
  }

  broadcastValid = value =>
    !this.state.promotion.broadcast ||
    (this.state.promotion.broadcast && !isEmpty(value))

  promoCodeValid = value =>
    this.state.promotion.discountApplyType !== 'PROMOTION_CODE' ||
    (this.state.promotion.discountApplyType === 'PROMOTION_CODE' &&
      !isEmpty(value))

  discountQuantityValid = value =>
    this.state.promotion.discountType !== 'QUANTITY_DISCOUNT' ||
    (this.state.promotion.discountType === 'QUANTITY_DISCOUNT' &&
      !isEmpty(value))

  rateValid = value => {
    const isNoRate = _.includes(
      this.props.noRate,
      this.state.promotion.discountType,
    )

    return (
      isNoRate || (!isNoRate && !isEmpty(value) && !_.includes(value, '_'))
    )
  }

  limitQuantityValid = value =>
    !this.state.promotion.limit ||
    (this.state.promotion.limit && !isEmpty(value))

  minimumOrderAmountValid = value =>
    !this.state.promotion.minimumOrderRequired ||
    (this.state.promotion.minimumOrderRequired && !isEmpty(value))

  applyExpirationDate = (e, picker) => {
    let promotion = this.state.promotion
    promotion['expirationDate'] = picker.startDate.format('MM-DD-YYYY')
    this.setState({ promotion })
  }

  isInvalidDate = date => {
    const { settings } = this.state
    const _date = moment(date).tz(settings.timeZone.value)

    return _date.isBefore(moment().tz(settings.timeZone.value), 'day')
  }

  onChangeInput = e => {
    let promotion = this.state.promotion
    promotion[e.target.name] = e.target.value

    this.setState({ promotion })
  }

  onChangeCheckbox = e => {
    let promotion = this.state.promotion
    promotion[e.target.name] = !_.get(promotion, e.target.name, false)

    if (e.target.name === 'broadcast') {
      this.setState({
        promotion: {
          ...promotion,
          broadcastColor: e.target.checked ? '#000000' : undefined,
        },
      })
    } else {
      this.setState({ promotion })
    }
  }

  onChangeSelect = (e, name) => {
    let promotion = this.state.promotion
    promotion[name] = e.value

    if (name === 'discountType') {
      this.setState({
        promotion: {
          ...promotion,
          ...(_.includes(this.props.discountRateReadonlyType, e.value)
            ? {
              discountRateType: _.includes(e.value, '_AMOUNT_')
                ? 'AMOUNT'
                : 'PERCENTAGE',
            }
            : {}),
        },
      })
    } else {
      this.setState({ promotion })
    }
  }

  onChangeProductsOrCategories = e => {
    let promotion = this.state.promotion

    promotion[_.lowerCase(this.state.associateTo)] = _.map(e, 'object')

    this.setState({ promotion })
  }

  action = async () => {
    this.props.onSetLoading(true)
    this.setState({
      submited: true,
      btnLoading: true,
    })

    let isValid = this.validator.validate(this.state.promotion).isValid
    let action = this.state.isCreate ? 'create-promotion' : 'update-promotion'

    let rate = this.state.promotion.rate
    rate = _.replace(rate, '_', '')
    rate = _.replace(rate, ',', '')
    rate = rate * 100

    console.log('PAYLOAD', {
      ...this.state.promotion,
      rate,
    })

    if (isValid) {
      this.props.onSetLoading(true)
      this.setState({ btnLoading: true })
      this.props
        .post(
          {
            ...this.state.promotion,
            rate,
            storeId: this.storeId,
          },
          action,
        )
        .then(resp => {
          this.response(resp)
        })
    } else {
      this.props.onSetLoading(false)
      this.setState({ btnLoading: false })
    }
  }

  response = resp => {
    this.props.onSetLoading(false)
    this.setState({ btnLoading: false })

    let msn = _.replace(
      _.replace(process.env.REACT_APP_SUCCESS_ACTION, '%entity%', entity),
      '%action%',
      this.state.isCreate ? 'Created' : 'Updated',
    )

    if (resp.status !== 'error') {
      Notification.success(msn)

      if (this.state.isCreate) {
        this.props.history.push(
          `/promotion/detail/${resp.data.id}?store=${this.storeId}`,
        )
      }
    } else {
      Notification.error(process.env.REACT_APP_ERROR_ACTION)
    }
  }

  render = () => {
    const validator = this.state.submited
      ? this.validator.validate(this.state.promotion)
      : this.validator.valid()

    const discountType = _.get(this.state.promotion, 'discountType', null)
    const { settings } = this.state

    return (
      <Portlet>
        <Helmet>
          <title>Promotion | Admin Panel - Max Ecommerce</title>
        </Helmet>
        <PortletHeader
          title={this.state.isCreate ? 'New Promotion' : 'Update Promotion'}
          sticky
        >
          <Link
            to={`/promotions?store=${this.storeId}`}
            className="btn btn-secondary mr-3"
          >
            <i className="la la-arrow-left" />
            Back
          </Link>
          <Button
            label={this.state.isCreate ? 'Create' : 'Update'}
            color="primary"
            icon={`la ${this.state.btnLoading ? '' : ' la-check'}`}
            isLoading={this.state.btnLoading}
            onClick={this.action}
          />
        </PortletHeader>
        <PortletBody>
          {this.state.promotion === null && (
            <div className="row">
              <div className="col-12">
                <div>Loading...</div>
              </div>
            </div>
          )}
          {this.state.promotion !== null && (
            <div className="row">
              <div className="col-xl-2" />
              <div className="col-xl-8">
                <Section title="Information" size="lg">
                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">Active</Label>
                    <div className="col-9">
                      <span className="k-switch k-switch--md k-switch--icon">
                        <label>
                          <Input
                            checked={
                              this.state.promotion.status ? 'checked' : ''
                            }
                            onChange={this.onChangeCheckbox}
                            name="status"
                            type="checkbox"
                          />
                          <span />
                        </label>
                      </span>
                    </div>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">Name </Label>
                    <div className="col-9">
                      <Input
                        value={this.state.promotion.name}
                        onChange={this.onChangeInput}
                        name="name"
                        invalid={validator.name.isInvalid}
                      />
                      <FormFeedback>{validator.name.message}</FormFeedback>
                    </div>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">Label </Label>
                    <div className="col-9">
                      <Input
                        value={this.state.promotion.label}
                        onChange={this.onChangeInput}
                        name="label"
                        invalid={validator.label.isInvalid}
                      />
                      <FormFeedback>{validator.label.message}</FormFeedback>
                    </div>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">Priority </Label>
                    <div className="col-9">
                      <Input
                        value={this.state.promotion.priority}
                        onChange={this.onChangeInput}
                        name="priority"
                        invalid={validator.priority.isInvalid}
                      />
                      <FormFeedback>{validator.priority.message}</FormFeedback>
                    </div>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">
                      Expiration Date{' '}
                    </Label>
                    <div className="col-9">
                      <DateRangePicker
                        autoApply
                        autoUpdateInput
                        isInvalidDate={this.isInvalidDate}
                        onApply={this.applyExpirationDate}
                        singleDatePicker
                        containerClass="d-block react-bootstrap-daterangepicker-container"
                      >
                        <div className="input-group date">
                          {settings && (
                            <Input
                              value={moment(
                                this.state.promotion.expirationDate,
                                'MM-DD-YYYY',
                                true,
                              )
                                .tz(settings.timeZone.value)
                                .format('MM/DD/YYYY')}
                              placeholder="Select date"
                              readOnly={true}
                            />
                          )}
                          <div className="input-group-append">
                            <span className="input-group-text">
                              <i className="la la-calendar" />
                            </span>
                          </div>
                        </div>
                      </DateRangePicker>
                    </div>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">Broadcast</Label>
                    <div className="col-9">
                      <span className="k-switch k-switch--md k-switch--icon">
                        <label>
                          <Input
                            checked={
                              this.state.promotion.broadcast ? 'checked' : ''
                            }
                            onChange={e => {
                              this.onChangeCheckbox(e)
                            }}
                            name="broadcast"
                            type="checkbox"
                          />
                          <span />
                        </label>
                      </span>
                    </div>
                  </FormGroup>
                  {this.state.promotion.broadcast && (
                    <React.Fragment>
                      <FormGroup className="row">
                        <Label className="col-3 col-form-label">
                          Broadcast Type{' '}
                        </Label>
                        <div className="col-9">
                          <Select
                            options={this.props.broadcastType}
                            value={Utils.object(
                              this.props.broadcastType,
                              this.state.promotion.broadcastType,
                            )}
                            onChange={e =>
                              this.onChangeSelect(e, 'broadcastType')
                            }
                            className={
                              validator.broadcastType.isInvalid
                                ? 'is-invalid'
                                : ''
                            }
                          />
                          <FormFeedback>
                            {validator.broadcastType.message}
                          </FormFeedback>
                        </div>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-3 col-form-label">
                          Broadcast Color{' '}
                        </Label>
                        <div className="col-9">
                          <Input
                            value={this.state.promotion.broadcastColor}
                            type="color"
                            onChange={this.onChangeInput}
                            name="broadcastColor"
                          />
                        </div>
                      </FormGroup>
                    </React.Fragment>
                  )}
                </Section>
                <Separator size="lg" border={'dashed'} />
                <Section title="Elements" size="lg">
                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">
                      Associate To{' '}
                    </Label>
                    <div className="col-9">
                      <Select
                        options={this.props.associateToType}
                        value={Utils.object(
                          this.props.associateToType,
                          this.state.associateTo,
                        )}
                        onChange={e => {
                          if (this.state.associateTo !== e.value) {
                            this.setState({
                              promotion: {
                                ...this.state.promotion,
                                products: undefined,
                                categories: undefined,
                              },
                              associateTo: e.value,
                            })
                          }
                        }}
                      />
                    </div>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">
                      {_.startCase(_.lowerCase(this.state.associateTo))}
                    </Label>
                    <div className="col-9">
                      {this.state.associateTo === 'PRODUCTS' ? (
                        <AsyncSelect
                          cacheOptions
                          isMulti
                          value={_.map(
                            this.state.promotion.products,
                            o => ({
                              value: o.id,
                              label: o.name,
                              object: o,
                            }),
                          )}
                          loadOptions = {this.debouncedLoadOptions}
                          inputValue={this.state.productsSelectSearchValue}
                          onInputChange={this.onProductsSelectSearchValueChange}
                          onChange={this.onChangeProductsOrCategories}
                          isSearchable={true}
                          {...
                            {components: {
                              Option: ProductSearchOption,
                            },}
                          }

                        />
                      ) : (
                        <Select
                          options={this.categoriesOptions}
                          value={_.map(this.state.promotion.categories,
                            o => ({
                              value: o.id,
                              label: o.label,
                              object: o,
                            }),
                          )}
                          onChange={e => this.onChangeProductsOrCategories(e)}
                          isSearchable={true}
                          isMulti
                          closeMenuOnSelect={false}
                        />
                      ) }
                    </div>
                  </FormGroup>
                </Section>
                <Separator size="lg" border={'dashed'} />
                <Section title="Discount Type" size="lg">
                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">
                      Discount Type{' '}
                    </Label>
                    <div className="col-9">
                      <Select
                        options={this.props.discountType}
                        value={Utils.object(
                          this.props.discountType,
                          this.state.promotion.discountType,
                        )}
                        onChange={e => this.onChangeSelect(e, 'discountType')}
                        className={
                          validator.discountType.isInvalid ? 'is-invalid' : ''
                        }
                      />
                      <FormFeedback>
                        {validator.discountType.message}
                      </FormFeedback>
                    </div>
                  </FormGroup>
                  {discountType === 'QUANTITY_DISCOUNT' && (
                    <React.Fragment>
                      <FormGroup className="row">
                        <Label className="col-3 col-form-label">
                          Quantity{' '}
                        </Label>
                        <div className="col-9">
                          <Input
                            value={this.state.promotion.discountQuantity}
                            onChange={this.onChangeInput}
                            name="discountQuantity"
                            type="number"
                            invalid={validator.discountQuantity.isInvalid}
                          />
                          <FormFeedback>
                            {validator.discountQuantity.message}
                          </FormFeedback>
                        </div>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-3 col-form-label">
                          Apply To{' '}
                        </Label>
                        <div className="col-9">
                          <Select
                            options={this.props.discountQuantityApplyType}
                            value={Utils.object(
                              this.props.discountQuantityApplyType,
                              this.state.promotion.discountQuantityApply,
                            )}
                            onChange={e =>
                              this.onChangeSelect(e, 'discountQuantityApply')
                            }
                            className={
                              validator.discountQuantityApply.isInvalid
                                ? 'is-invalid'
                                : ''
                            }
                          />
                          <FormFeedback>
                            {validator.discountQuantityApply.message}
                          </FormFeedback>
                        </div>
                      </FormGroup>
                    </React.Fragment>
                  )}
                  {!_.includes(this.props.noRate, discountType) && (
                    <React.Fragment>
                      <FormGroup className="row">
                        <Label className="col-3 col-form-label">
                          Discount Rate Type{' '}
                        </Label>
                        <div className="col-9">
                          <Select
                            isDisabled={_.includes(
                              this.props.discountRateReadonlyType,
                              discountType,
                            )}
                            options={this.props.discountRateType}
                            value={Utils.object(
                              this.props.discountRateType,
                              this.state.promotion.discountRateType,
                            )}
                            onChange={e =>
                              this.onChangeSelect(e, 'discountRateType')
                            }
                          />
                        </div>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-3 col-form-label">Rate </Label>
                        <div className="col-9">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                {this.state.promotion.discountRateType ==
                                'PERCENTAGE'
                                  ? '%'
                                  : '$'}
                              </span>
                            </div>
                            <MaskedInput
                              name="rate"
                              value={this.state.promotion.rate}
                              onChange={this.onChangeInput}
                              mask={Utils.currencyMask}
                              className={`form-control ${
                                validator.rate.isInvalid ? 'is-invalid' : ''
                              }`}
                            />
                            <FormFeedback>
                              {validator.rate.message}
                            </FormFeedback>
                          </div>
                        </div>
                      </FormGroup>
                    </React.Fragment>
                  )}
                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">Apply Type </Label>
                    <div className="col-9">
                      <Select
                        options={this.props.discountApplyType}
                        value={Utils.object(
                          this.props.discountApplyType,
                          this.state.promotion.discountApplyType,
                        )}
                        onChange={e =>
                          this.onChangeSelect(e, 'discountApplyType')
                        }
                      />
                    </div>
                  </FormGroup>
                  {this.state.promotion.discountApplyType ==
                    'PROMOTION_CODE' && [
                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">
                        Promotion Code{' '}
                      </Label>
                      <div className="col-9">
                        <Input
                          value={this.state.promotion.promoCode}
                          onChange={this.onChangeInput}
                          name="promoCode"
                          invalid={validator.promoCode.isInvalid}
                        />
                        <FormFeedback>
                          {validator.promoCode.message}
                        </FormFeedback>
                      </div>
                    </FormGroup>,
                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">Use Limit </Label>
                      <div className="col-9">
                        <Input
                          value={this.state.promotion.codeLimit}
                          onChange={this.onChangeInput}
                          name="codeLimit"
                        />
                      </div>
                    </FormGroup>,
                  ]}
                </Section>
                <Separator size="lg" border={'dashed'} />
                <Section title="Limits" size="lg">
                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">Limit</Label>
                    <div className="col-9">
                      <span className="k-switch k-switch--md k-switch--icon">
                        <label>
                          <Input
                            checked={
                              this.state.promotion.limit ? 'checked' : ''
                            }
                            onChange={this.onChangeCheckbox}
                            name="limit"
                            type="checkbox"
                          />
                          <span />
                        </label>
                      </span>
                    </div>
                  </FormGroup>
                  {this.state.promotion.limit && (
                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">
                        Limit Quantity{' '}
                      </Label>
                      <div className="col-9">
                        <Input
                          value={this.state.promotion.limitQuantity}
                          onChange={this.onChangeInput}
                          name="limitQuantity"
                          invalid={validator.limitQuantity.isInvalid}
                        />
                        <FormFeedback>
                          {validator.limitQuantity.message}
                        </FormFeedback>
                      </div>
                    </FormGroup>
                  )}
                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">
                      Minimum Order Required
                    </Label>
                    <div className="col-9">
                      <span className="k-switch k-switch--md k-switch--icon">
                        <label>
                          <Input
                            checked={
                              this.state.promotion.minimumOrderRequired
                                ? 'checked'
                                : ''
                            }
                            onChange={this.onChangeCheckbox}
                            name="minimumOrderRequired"
                            type="checkbox"
                          />
                          <span />
                        </label>
                      </span>
                    </div>
                  </FormGroup>
                  {this.state.promotion.minimumOrderRequired && (
                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">
                        Minimum Order Amount{' '}
                      </Label>
                      <div className="col-9">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">$</span>
                          </div>
                          <MaskedInput
                            name="minimumOrderAmount"
                            value={this.state.promotion.minimumOrderAmount}
                            onChange={this.onChangeInput}
                            mask={Utils.currencyMask}
                            className={`form-control ${
                              validator.minimumOrderAmount.isInvalid
                                ? 'is-invalid'
                                : ''
                            }`}
                          />
                          <FormFeedback>
                            {validator.minimumOrderAmount.message}
                          </FormFeedback>
                        </div>
                      </div>
                    </FormGroup>
                  )}
                </Section>
                <Separator size="lg" border={'dashed'} />
                <Section title="Subsequence" size="lg">
                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">
                      Allow Subsequence
                    </Label>
                    <div className="col-9">
                      <span className="k-switch k-switch--md k-switch--icon">
                        <label>
                          <Input
                            checked={
                              this.state.promotion.subsequence ? 'checked' : ''
                            }
                            onChange={this.onChangeCheckbox}
                            name="subsequence"
                            type="checkbox"
                          />
                          <span />
                        </label>
                      </span>
                    </div>
                  </FormGroup>
                </Section>
              </div>
              <div className="col-xl-2" />
            </div>
          )}
        </PortletBody>
      </Portlet>
    )
  }
}

export const mapStateToProps = state => ({
  settings: state.settings,
  products: state.products,
  categories: state.productCategories,
  promotions: state.promotions,
  broadcastType: state.broadcastType,
  associateToType: state.associateToType,
  discountType: state.discountType,
  discountRateType: state.discountRateType,
  discountApplyType: state.discountApplyType,
  discountQuantityApplyType: state.discountQuantityApplyType,
  noRate: state.noRate,
  discountRateReadonlyType: state.discountRateReadonlyType,
})

export const mapDispatchToProps = dispatch => ({
  post: function(path, payload, actionEvent) {
    return dispatch(Actions.post(path, payload, actionEvent))
  },
  storeGet: function(path, actionEvent, typeSuccess, typeError) {
    return dispatch(
      Actions.storeGet(path, actionEvent, typeSuccess, typeError),
    )
  },
  storePost: function(payload, actionEvent, typeSuccess, typeError) {
    return dispatch(
      Actions.storePost(payload, actionEvent, typeSuccess, typeError),
    )
  },
  storePostProductsTable: function(payload, actionEvent, typeLoading,typeSuccess, typeError) {
    return dispatch(
      Actions.storePostProductsTable(payload, actionEvent, typeLoading,typeSuccess, typeError),
    )
  },
  onSetLoading: payload => dispatch(UiActions.onSetLoading(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PromotionForm)
