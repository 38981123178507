import React from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import MaskedInput from 'react-text-mask'
import { Select, FormGroup, Input, Label, Utils } from '../../../../Imports'
import { States } from '../../../../reducers/DataReducers'

const states = States().map(state => ({ ...state, country: 'US' }))

const TaxForm = ({
  onChangeMultiSelect,
  onChangeCheckbox,
  onChangeSelect,
  onChange,
  removeTax,
  index,
  tax,
  arr,
}) => {
  // Selectors
  const taxType = useSelector(({ taxType }) => taxType)

  return (
    <>
  
      <div
        className={
          classNames('row d-flex justify-content-between position-relative', {
            'mt-4': index,
          })
        }
      >
        <div className="col-xl-2">
          <FormGroup className="mb-4">
            <Label>Tax active</Label>
            <div>
              <span className="k-switch k-switch--md k-switch--icon">
                <label className="m-1">
                  <Input
                    checked={tax.activeTax ? 'checked' : ''}
                    onChange={onChangeCheckbox(index, 'activeTax')}
                    type="checkbox"
                  />
                  <span />
                </label>
              </span>
            </div>
          </FormGroup>
          <FormGroup>
            <Label>Tax global</Label>
            <div>
              <span className="k-switch k-switch--md k-switch--icon">
                <label className="m-1">
                  <Input
                    checked={tax.globalTax ? 'checked' : ''}
                    onChange={onChangeCheckbox(index)}
                    name="globalTax"
                    type="checkbox"
                  />
                  <span />
                </label>
              </span>
            </div>
          </FormGroup>
        </div>
        <div className="col-xl-3">
          <FormGroup>
            <Label>
              Label *
            </Label>
            <div>
              <Input
                value={tax.labelTax}
                onChange={onChange(index, 'labelTax')}
                className={classNames({ 'is-invalid': !tax.labelTax })}
              />
            </div>
          </FormGroup>
          <FormGroup>
            <Label>
              Tax id *
            </Label>
            <div>
              <Input
                value={tax.idTax}
                onChange={onChange(index, 'idTax')}
                className={classNames({ 'is-invalid': !tax.idTax })}
              />
            </div>
          </FormGroup>
        </div>
        <div className="col-xl-3">
          <FormGroup>
            <Label>
              Calculate Type *
            </Label>
            <div>
              <Select
                options={taxType}
                value={taxType.find(({ value }) => value === tax.typeTax)}
                onChange={onChangeSelect(index, 'typeTax')}
                className={classNames({ 'is-invalid-select': !tax.typeTax })}
              />
            </div>
          </FormGroup>
          <FormGroup>
            <Label>Amount *</Label>
            <div>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    {
                      tax.typeTax == 'PERCENTAGE'
                        ? '%'
                        : '$'
                    }
                  </span>
                </div>
                <MaskedInput
                  value={tax.amountTax}
                  onChange={onChange(index, 'amountTax')}
                  mask={Utils.currencyMask}
                  className={classNames('form-control', { 'is-invalid': !tax.amountTax })}
                />
              </div>
            </div>
          </FormGroup>
        </div>
        <div className="col-xl-3">
          <FormGroup>
            <Label>
              State
            </Label>
            <div>
              <Select
                options={states}
                value={tax.restrictionTax}
                onChange={onChangeMultiSelect(index, 'restrictionTax')}
                closeMenuOnSelect={false}
                isMulti
              />
            </div>
          </FormGroup>
          <FormGroup className="mb-4">
            <Label>Restricted Tax</Label>
            <div>
              <span className="k-switch k-switch--md k-switch--icon">
                <label className="m-1">
                  <Input
                    checked={tax.restrictedTax ? 'checked' : ''}
                    onChange={onChangeCheckbox(index, 'restrictedTax')}
                    type="checkbox"
                  />
                  <span />
                </label>
              </span>
            </div>
          </FormGroup>
        </div>
      </div>
      <div
        className={
          classNames('d-flex justify-content-center align-items-center', {
            'border-bottom': index !== arr.length - 1,
          })
        }
      >
        <h6 className="m-4 text-danger btn" onClick={removeTax(index)}>
          Remove Tax
        </h6>
      </div>
    </>
  )
}

export default TaxForm
