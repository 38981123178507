import {
  React,
  Component,
  connect,
  Actions,
  Portlet,
  PortletHeader,
  PortletBody,
  Section,
  BootstrapTable,
  cellEditFactory,
  Type,
  Select,
  Input,
  Utils,
  Notification,
  Alert,
  Routes,
  Link,
  FormValidator,
  Separator,
  Button,
  moment,
  ActionTypes,
  _,
} from '../../../Imports'
import { Helmet } from 'react-helmet'
import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'

const entity = 'Package'

const signatureOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
]

const declaredValueDefault = ''

class Package extends Component {
  constructor(props) {
    super(props)

    this.validator = new FormValidator([
      {
        field: 'calculateTaxTypeForTax',
        method: 'isEmpty',
        validWhen: false,
        message: 'The Tax Type is required.',
      },
      {
        field: 'taxAmountForTax',
        method: 'isEmpty',
        validWhen: false,
        message: 'The Tax Amount is required.',
      },
    ])

    this.state = {
      order: null,
      setting: null,
      submited: false,
      isCreate: false,
      btnLoading: false,
      isAlertOpen: false,
      alertText: '',
      selection: [],
      packages: [],
      orderItems: [],
      packageItem: {
        id: null,
        type: null,
        weight: 0,
        weightUnit: 'LB',
        length: 0,
        width: 0,
        height: 0,
        dimensionUnit: 'IN',
        declaredValue: declaredValueDefault,
        signature: false,
      },
      packageOptions: [],
      editableDimensions: true,
    }

    let search = Utils.search(this.props.location)

    this.storeId = search.store
    this.id = search.id
  }

  UNSAFE_componentWillMount = async () => {
    if (this.props.settings.data === null) {
      await this.props.storePost(
        { id: this.storeId },
        'get-store',
        ActionTypes.FETCH_SETTINGS_SUCCESS,
        ActionTypes.FETCH_SETTINGS_FAILURE,
      )
    }

    await this.props
      .post(
        {
          id: this.id,
          storeId: this.storeId,
        },
        'get-order',
      )
      .then(resp => {
        let order = resp.data

        let orderItems = _.map(order.products, p => ({
          id: p.id,
          title: p.title,
          weight: p.weight,
          qty: p.qty,
          price: p.price,
          qtyInPackage: p.qty,
        }))

        let pa = _.get(order, 'shippingPackages', [])
        if (pa !== null)
          pa.forEach(elem => {
            if (!elem.declaredValue) elem.declaredValue = declaredValueDefault
          }) // FIXME: declaredValue mock; after adding declaredValue in backend, remove this line

        let packageOptions = _.concat(
          this.props.fedexPackageType,
          this.props.uspsPackageType,
        )

        // Add Your Package Option
        packageOptions.push({
          value: {
            id: 'CUSTOM1',
            pk: 'YOUR_PACKAGING',
            length: 0,
            width: 0,
            height: 0,
          },
          label: 'Your Packaging',
        })

        this.setState(
          {
            order,
            orderItems,
            packages: pa === null ? [] : pa,
            packageOptions,
          },
          () => {
            // Remove the items where the qty is already
            // distribute on the careated packages
            if (this.state.packages.length > 0) {
              this.removeDistributedItems()
            }
          },
        )
      })
  }

  getAttributesFromPackage = (options, p) => {
    let _package = _.find(options, v => {
      const type = typeof p.type == 'string' ? p.type : p.type.pk

      if (type == 'YOUR_PACKAGING') {
        return v.value.pk === 'YOUR_PACKAGING'
      }

      return (
        v.value.pk == type &&
        v.value.length == p.length &&
        v.value.width == p.width &&
        v.value.height == p.height
      )
    })

    if (_package && _package.value.pk == 'YOUR_PACKAGING') {
      _package.value.length = p.length
      _package.value.width = p.width
      _package.value.height = p.height
    }

    return _package
  }

  onSelectPackageType = e => {
    this.setState(
      {
        editableDimensions: e.value.pk == 'YOUR_PACKAGING',
        packageItem: {
          ...this.state.packageItem,
          length: e.value.length,
          width: e.value.width,
          height: e.value.height,
        },
      },
      () => {
        this.onChangePackageValueSelect(e, 'type')
      },
    )
  }

  onChangePackageInput = e => {
    let packageItem = this.state.packageItem
    packageItem[e.target.name] = e.target.value

    this.setState({ packageItem })
  }

  // onChangePackageSelect = (e, name) => {
  //     let packageItem = this.state.packageItem;
  //     packageItem[name] = e;

  //     this.setState({ packageItem });
  // };

  onChangePackageValueSelect = (e, name) => {
    let packageItem = this.state.packageItem
    packageItem[name] = e.value

    this.setState({ packageItem })
  }

  update = () => {
    if (_.get(this.state.order, 'isShippingCompleted', false)) {
      this.setState({ btnLoading: true })
      this.goNext()
      this.setState({ btnLoading: false })
    } else {
      this.setState({
        submited: !this.state.submited,
      })

      let isValid = true //this.validator.validate(this.state.setting).isValid;

      if (isValid) {
        this.setState({ btnLoading: true })

        let shippingType = null
        let typeFound = false

        let order = this.state.order
        order.shippingPackages = this.state.packages

        order.shippingPackages = _.map(order.shippingPackages, p => {
          const attrs = this.getAttributesFromPackage(
            this.state.packageOptions,
            p,
          )

          // Remove typeLabel form the shipping
          // object
          p = _.omit(p, ['typeLabel'])

          if (!typeFound) {
            if (_.includes(attrs.value.id, 'FEDEX')) {
              shippingType = 'FEDEX'
              typeFound = true
            } else if (_.includes(attrs.value.id, 'USPS')) {
              shippingType = 'USPS'
              typeFound = true
            }
          }

          return {
            ...p,
            type: typeof p.type == 'string' ? p.type : p.type.pk,
          }
        })

        order.shippingType = shippingType

        this.props
          .post(order, 'update-order')
          .then(resp => {
            this.response(resp)
            this.goNext()
            this.setState({ btnLoading: false })
          })
          .catch(e => {
            this.setState({ btnLoading: false })
          })
      }
    }
  }

  response = resp => {
    this.setState({
      btnLoading: false,
    })

    let msn = _.replace(
      _.replace(process.env.REACT_APP_SUCCESS_ACTION, '%entity%', entity),
      '%action%',
      'Updated',
    )

    if (resp.status === 'success') {
      Notification.success(msn)
    } else {
      Notification.error(process.env.REACT_APP_ERROR_ACTION)
    }
  }

  addItem = async () => {
    let packages = this.state.packages

    this.setState({ isAlertOpen: false })

    // Generate Id
    let p = _.cloneDeep(this.state.packageItem)
    p.id = moment().valueOf()

    // Validation
    if (this.state.selection.length == 0) {
      this.setState({
        isAlertOpen: true,
        alertText: 'You must select at least one product for this package',
      })
      return
    }

    let items = _.map(this.state.selection, id =>
      _.find(this.state.orderItems, item => item.id === id))

    let validQty = true
    if (packages.length === 0) {
      _.forEach(items, item => {
        if (item.qtyInPackage > item.qty) {
          validQty = false
        }
      })
    }

    if (packages.length > 0) {
      _.forEach(items, item => {
        let id = item.id
        let qty = item.qty
        let qtyInPackage = item.qtyInPackage
        let totalItem = []
        let total = 0

        _.forEach(packages, p => {
          let inItem = _.find(p.items, pitem => pitem.id === id)

          if (inItem) {
            totalItem.push(inItem)
          }
        })

        if (totalItem.length > 0) {
          total = _.sum(_.map(totalItem, i => i.qtyInPackage))
        }

        if (
          parseInt(total, 10) + parseInt(qtyInPackage, 10) >
          parseInt(qty, 10)
        ) {
          validQty = false
        }
      })
    }

    if (!validQty) {
      this.setState({
        isAlertOpen: true,
        alertText:
          'The item quantity exceeds ordered quantity. Please review and revise the Quantity in Package information to match the ordered quantity!',
      })
      return
    }

    if (
      p.id === null ||
      p.type === null ||
      p.weight === 0 ||
      p.weightUnit === null ||
      p.width === 0 ||
      p.height === 0 ||
      p.dimensionUnit === null ||
      this.state.selection.length == 0
    ) {
      this.setState({
        isAlertOpen: true,
        alertText: 'Please provide all the required package information',
      })
      return
    }

    // Add items
    p.items = items

    // Add Package
    packages.push(p)
    await this.setState({
      packages,
      // editableDimensions: true
    })

    // Clear Package
    this.removeDistributedItems()
  }

  deleteItem = id => {
    let p = _.find(this.state.packages, p => p.id === id)

    let orderItems = []
    if (this.state.orderItems.length === 0) {
      _.forEach(p.items, i => {
        i.qtyInPackage = i.qty
        orderItems.push(i)
      })
    } else {
      orderItems = this.state.orderItems
      _.forEach(p.items, i => {
        let item = _.find(this.state.orderItems, a => a.id === i.id)

        if (!item) {
          i.qtyInPackage = i.qty
          orderItems.push(i)
        }
      })
    }

    this.setState({ orderItems })

    let packages = _.filter(this.state.packages, p => p.id !== id)

    this.setState({ packages })
  }

  deleteItemFromPackage = (e, itemId, packageId) => {
    e.preventDefault()

    let p = _.find(this.state.packages, v => v.id === packageId)

    if (p.items.length === 1) {
      return
    }

    let dItem = _.find(p.items, i => i.id === itemId)

    let newItems = _.filter(p.items, i => i.id !== itemId)

    p.items = newItems

    let packages = _.filter(this.state.packages, p => p.id !== packageId)

    packages.push(p)

    this.setState({ packages })

    // Put the deleted
    // item back
    this.backItem(dItem)
  }

  backItem = item => {
    let orderItems = []

    if (this.state.orderItems.length === 0) {
      item.qtyInPackage = item.qty
      orderItems.push(item)
    } else {
      orderItems = this.state.orderItems
      let itemExist = _.find(this.state.orderItems, a => a.id === item.id)

      if (!itemExist) {
        item.qtyInPackage = item.qty
        orderItems.push(item)
      }
    }

    this.setState({ orderItems })
  }

  onSelectRow = (row, isSelect, rowIndex, e) => {
    let selection = []

    if (isSelect) {
      selection = [...this.state.selection, row.id]
    } else {
      selection = this.state.selection.filter(s => s != row.id)
    }

    // Add the total weight to
    // the packageItem
    let totalWeight = this.calculateTotalWeight(selection)
    let packageItem = this.state.packageItem
    packageItem.weight = totalWeight

    this.setState({
      selection,
      packageItem,
    })
  }

  onSelectAllRows = (isSelect, rows) => {
    let selection = []

    if (isSelect) {
      rows.map((r, index) => {
        selection.push(r.id)
      })
    }

    // Add the total weight to
    // the packageItem
    let totalWeight = this.calculateTotalWeight(selection)
    let packageItem = this.state.packageItem
    packageItem.weight = totalWeight

    this.setState({
      selection,
      packageItem,
    })
  }

  calculateTotalWeight = selection => {
    let totalWeight = 0
    _.forEach(selection, value => {
      let item = _.find(this.state.orderItems, p => p.id === value)

      if (item) {
        totalWeight += item.qtyInPackage * item.weight
      }
    })

    return totalWeight
  }

  removeDistributedItems = () => {
    // Delete the items the qty in package
    // is equals the order qty
    if (this.state.packages.length > 0) {
      let deleteItem = []

      deleteItem = this.qtyDistribured(this.state.orderItems)

      console.log('deleteItem', deleteItem)

      let orderItems = []
      if (deleteItem.length > 0) {
        orderItems = _.filter(
          this.state.orderItems,
          item => !_.find(deleteItem, p => item.id == p.id),
        )

        console.log('orderItems', orderItems)

        this.setState({ orderItems })
      }
    }

    let packageItem = {
      id: null,
      type: null,
      weight: 0,
      weightUnit: 'LB',
      length: 0,
      width: 0,
      height: 0,
      dimensionUnit: 'IN',
      declaredValue: declaredValueDefault,
      signature: false,
    }

    this.setState({ packageItem, selection: [] })
  }

  qtyDistribured = items => {
    let deleteItem = []

    _.forEach(items, item => {
      let id = item.id
      let qty = item.qty
      let qtyInPackage = item.qtyInPackage
      let totalItem = []
      let total = 0

      _.forEach(this.state.packages, p => {
        let inItem = _.find(p.items, pitem => pitem.id === id)

        if (inItem) {
          totalItem.push(inItem)
        }
      })

      if (totalItem.length > 0) {
        total = _.sum(_.map(totalItem, i => parseInt(i.qtyInPackage, 10)))

        if (parseInt(total, 10) === parseInt(qty, 10)) {
          deleteItem.push(item)
        }
      }
    })

    return deleteItem
  }

  closeAlert = () => {
    this.setState({ isAlertOpen: false })
  }

  goNext = () => {
    const items = Routes.SHIPMENT_OPTIONS

    const index = _.findIndex(
      items,
      i => i.url == this.props.location.pathname,
    )

    if (items.length > index + 1) {
      this.props.history.push(
        `${items[index + 1].url}?id=${this.id}&store=${this.storeId}`,
      )
    }
  }

  render = () => {
    //const validator = this.state.submited
    //	? this.validator.validate(this.state.setting)
    //	: this.validator.valid();

    // console.log(this.state.packages);

    let packageList = _.map(this.state.packages, p => ({
      ...p,
      typeLabel: _.get(
        this.getAttributesFromPackage(this.state.packageOptions, p),
        'label',
        '',
      ),
    }))

    const expandRow = {
      renderer: row => (
        <div className="d-flex w-100 align-items-center justify-content-between">
          <table
            style={{
              marginLeft: '78px',
              marginRight: '-10px',
              marginBottom: '-10px',
              width: 'calc(100% - 78px + 10px)',
            }}
          >
            <thead>
              <tr>
                <th className="border-0">Item</th>
                {/*<th>Weight</th> */}
                {/*<th>Qty</th>*/}
                <th className="border-0" style={{ width: '130px' }}>
                  Qty in Package
                </th>
                {/* <th
                                    className="border-0"
                                    style={{ width: "80px" }}
                                /> */}
              </tr>
            </thead>
            <tbody>
              {_.map(row.items, v => (
                <tr key={v.title}>
                  <td className="border-0">{v.title}</td>
                  {/*<td>{v.weight} </td>*/}
                  {/*<td>{v.qty} </td>*/}
                  <td className="border-0" style={{ textAlign: 'right' }}>
                    {v.qtyInPackage}
                  </td>

                  {/* <td
                                            className="border-0"
                                            style={{ textAlign: "right" }}
                                        >
                                            <button
                                                onClick={(e) => {
                                                    this.deleteItemFromPackage(
                                                        e,
                                                        v.id,
                                                        row.id
                                                    );
                                                }}
                                                className="btn btn-outline-secondary btn-sm btn-icon mr-3"
                                                title="Download"
                                            >
                                                <i className="la la-trash" />
                                            </button>
                                        </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ),
      showExpandColumn: true,
      expandByColumnOnly: true,
      expandHeaderColumnRenderer: ({ isAnyExpands }) => {
        if (isAnyExpands) {
          return <i className="la la-angle-double-down" />
        }
        return <i className="la la-angle-double-right" />
      },
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return (
            <i className="la la-angle-down" style={{ cursor: 'pointer' }} />
          )
        }
        return (
          <i className="la la-angle-right" style={{ cursor: 'pointer' }} />
        )
      },
    }

    const selectRow = {
      mode: 'checkbox',
      classes: 'table-secondary',
      clickToSelect: false,
      clickToEdit: true,
      onSelect: this.onSelectRow,
      onSelectAll: this.onSelectAllRows,
      selected: this.state.selection,
      selectionHeaderRenderer: ({ indeterminate, ...rest }) => (
        <label className="k-checkbox k-checkbox--brand">
          <Input
            type="checkbox"
            disabled
            ref={input => {
              if (input) input.indeterminate = indeterminate
            }}
            {...rest}
          />{' '}
          <span />
        </label>
      ),
      selectionRenderer: ({ mode, ...rest }) => (
        <label className="k-checkbox k-checkbox--brand">
          <Input type={mode} {...rest} disabled /> <span />
        </label>
      ),
    }

    const itemsColumns = [
      {
        dataField: 'id',
        hidden: true,
        editable: false,
      },
      {
        dataField: 'title',
        text: 'Item',
        sort: false,
        editable: false,
      },
      {
        dataField: 'weight',
        text: 'Weight',
        align: 'right',
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => ({ width: '100px' }),
        formatter: (cell, row, rowIndex, formatExtraData) => (
          <span key={rowIndex}>{row.weight * row.qty}</span>
        ),
      },
      {
        dataField: 'qty',
        text: 'Qty',
        align: 'right',
        sort: true,
        editable: false,
        headerStyle: (colum, colIndex) => ({ width: '100px' }),
      },
      {
        dataField: 'qtyInPackage',
        text: 'Qty in Package',
        align: 'right',
        sort: true,
        headerStyle: (colum, colIndex) => ({ width: '150px' }),
        formatter: (cell, row, rowIndex, formatExtraData) => (
          <Input
            value={cell}
            className="mt-auto"
            onChange={e => console.log('')}
          />
        ),
      },
    ]

    const packageColumns = [
      {
        dataField: 'id',
        hidden: true,
      },
      {
        dataField: 'id',
        text: '#',
        sort: false,
        classes: 'text-center',
        headerClasses: 'text-center',
        headerStyle: (colum, colIndex) => ({ width: '50px' }),
        formatter: (cell, row, rowIndex, formatExtraData) => (
          <span key={rowIndex}>{rowIndex + 1}</span>
        ),
      },
      {
        dataField: 'typeLabel',
        text: 'Type',
        sort: false,
        editable: false,
        // editor: {
        //     type: Type.SELECT,
        //     options: _.map(this.state.packageOptions, (p) => (
        //         {
        //             value: p.id,
        //             label: p.label
        //         }
        //     ))
        // },
        headerStyle: () => ({ width: '120px' }),
        formatter: (cell, row, rowIndex, formatExtraData) => (
          // let option = _.find(this.state.packageOptions, (v) => {
          //     if (cell == "YOUR_PACKAGING") {
          //         return v.value.pk === "YOUR_PACKAGING";
          //     }

          //     return v.value.pk == cell &&
          //         v.value.length == row.length &&
          //         v.value.width == row.length &&
          //         v.value.height == row.height;
          // });

          // return <span>{_.get(option, "label", "")}</span>;
          <span>{cell}</span>
        ),
      },
      {
        dataField: 'weight',
        text: 'Weight',
        align: 'right',
        sort: true,
        headerStyle: (colum, colIndex) => ({ width: '100px' }),
      },
      {
        dataField: 'weightUnit',
        text: 'Unit',
        align: 'right',
        sort: true,
        editor: {
          type: Type.SELECT,
          options: this.props.fedexWeightType,
        },
        headerStyle: (colum, colIndex) => ({ width: '70px' }),
        formatter: (cell, row, rowIndex, formatExtraData) => (
          <span>{cell}</span>
        ),
      },
      {
        dataField: 'length',
        text: 'Length',
        align: 'right',
        sort: true,
        editable: (content, row, rowIndex, columnIndex) =>
          (typeof row.type == 'string' ? row.type : row.type.pk) ==
          'YOUR_PACKAGING',
        headerStyle: (colum, colIndex) => ({ width: '100px' }),
      },
      {
        dataField: 'width',
        text: 'Width',
        align: 'right',
        sort: true,
        editable: (content, row, rowIndex, columnIndex) =>
          (typeof row.type == 'string' ? row.type : row.type.pk) ==
          'YOUR_PACKAGING',
        headerStyle: (colum, colIndex) => ({ width: '100px' }),
      },
      {
        dataField: 'height',
        text: 'Height',
        align: 'right',
        sort: true,
        editable: (content, row, rowIndex, columnIndex) =>
          (typeof row.type == 'string' ? row.type : row.type.pk) ==
          'YOUR_PACKAGING',
        headerStyle: (colum, colIndex) => ({ width: '100px' }),
      },
      {
        dataField: 'dimensionUnit',
        text: 'Unit',
        align: 'right',
        sort: true,
        editor: {
          type: Type.SELECT,
          options: this.props.fedexDimensionType,
        },
        editable: (content, row, rowIndex, columnIndex) =>
          (typeof row.type == 'string' ? row.type : row.type.pk) ==
          'YOUR_PACKAGING',
        headerStyle: (colum, colIndex) => ({ width: '70px' }),
        formatter: (cell, row, rowIndex, formatExtraData) => (
          <span>{cell}</span>
        ),
      },
      {
        dataField: 'declaredValue',
        text: 'Declared Value',
        align: 'right',
        sort: true,
        editable: (content, row, rowIndex, columnIndex) =>
          (typeof row.type == 'string' ? row.type : row.type.pk) ==
          'YOUR_PACKAGING',
        headerStyle: () => ({ width: '100px' }),
      },
      {
        dataField: 'signature',
        text: 'Signature',
        align: 'right',
        sort: true,
        editor: {
          type: Type.SELECT,
          options: signatureOptions,
        },
        headerStyle: (colum, colIndex) => ({ width: '100px' }),
        formatter: (cell, row, rowIndex, formatExtraData) => (
          <span>{cell ? 'Yes' : 'No'}</span>
        ),
      },
    ]

    if (!_.get(this.state.order, 'isShippingCompleted', false)) {
      packageColumns.push({
        dataField: 'actions',
        text: 'Actions',
        headerAlign: 'right',
        align: 'right',
        headerStyle: (colum, colIndex) => ({ width: '80px' }),
        headerClasses: 'align-top',
        editable: false,
        formatter: (cell, row, rowIndex, formatExtraData) => (
          <button
            className={'btn btn-outline-secondary btn-sm btn-icon mr-3'}
            title="Delete"
            onClick={id => this.deleteItem(row.id)}
          >
            <i className="la la-trash" />
          </button>
        ),
      })
    }

    return (
      <Portlet>
        <Helmet>
          <title>Package | Admin Panel - Max Ecommerce</title>
        </Helmet>
        <PortletHeader
          title={`Update package for order #${_.get(
            this.state.order,
            'invoiceNumber',
            '',
          )}`}
          sticky
        >
          <Link
            to={`/order/detail/${this.id}?store=${this.storeId}`}
            className="btn btn-secondary mr-3"
          >
            <i className="la la-arrow-left" />
            Back
          </Link>

          <Button
            label="Next"
            color="primary"
            icon={`la ${this.state.btnLoading ? '' : ' la-arrow-right'}`}
            isLoading={this.state.btnLoading}
            onClick={this.update}
          />
        </PortletHeader>
        <PortletBody>
          {this.state.order === null && (
            <div className="row">
              <div className="col-12">
                <div>Loading...</div>
              </div>
            </div>
          )}

          {this.state.order !== null && (
            <div className="row mr-2 ml-2">
              <div className="col-12">
                <Section title="New Package">
                  <Alert
                    label={this.state.alertText}
                    isOpen={this.state.isAlertOpen}
                    type={'danger'}
                    close={this.closeAlert}
                  />

                  {this.state.orderItems.length === 0 && (
                    <span>All products are distributed into the packages.</span>
                  )}

                  {this.state.orderItems.length > 0 && (
                    <table className="package-table-form">
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>Weight</th>
                          <th>Unit</th>
                          <th>Length</th>
                          <th>Width</th>
                          <th>Height</th>
                          <th>Unit</th>
                          <th>Declared Value</th>
                          <th>Signature</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <Select
                              options={this.state.packageOptions}
                              value={
                                _.find(
                                  this.state.packageOptions,
                                  p =>
                                    p.value.id ==
                                    _.get(this.state.packageItem.type, 'id'),
                                ) || null
                              }
                              onChange={e => {
                                this.onSelectPackageType(e)
                              }}
                              isSearchable={true}
                              placeholder="Select type..."
                            />
                          </td>
                          <td>
                            <Input
                              value={this.state.packageItem.weight}
                              className="mt-auto"
                              onChange={this.onChangePackageInput}
                              name="weight"
                            />
                          </td>
                          <td>
                            <Select
                              options={this.props.fedexWeightType}
                              value={Utils.object(
                                this.props.fedexWeightType,
                                this.state.packageItem.weightUnit,
                              )}
                              onChange={e =>
                                this.onChangePackageValueSelect(e, 'weightUnit')
                              }
                              isSearchable={true}
                            />
                          </td>
                          <td>
                            <Input
                              value={this.state.packageItem.length}
                              className="mt-auto"
                              onChange={this.onChangePackageInput}
                              name="length"
                              disabled={!this.state.editableDimensions}
                            />
                          </td>
                          <td>
                            <Input
                              value={this.state.packageItem.width}
                              className="mt-auto"
                              onChange={this.onChangePackageInput}
                              name="width"
                              disabled={!this.state.editableDimensions}
                            />
                          </td>
                          <td>
                            <Input
                              value={this.state.packageItem.height}
                              className="mt-auto"
                              onChange={this.onChangePackageInput}
                              name="height"
                              disabled={!this.state.editableDimensions}
                            />
                          </td>
                          <td>
                            <Select
                              options={this.props.fedexDimensionType}
                              value={Utils.object(
                                this.props.fedexDimensionType,
                                this.state.packageItem.dimensionUnit,
                              )}
                              onChange={e =>
                                this.onChangePackageValueSelect(
                                  e,
                                  'dimensionUnit',
                                )
                              }
                              isDisabled={!this.state.editableDimensions}
                              isSearchable={true}
                            />
                          </td>
                          <td>
                            <InputGroup>
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText className="px-2 bg-white">
                                  $
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                value={this.state.packageItem.declaredValue}
                                className="mt-auto pl-0"
                                onChange={this.onChangePackageInput}
                                name="declaredValue"
                                style={{ borderLeftStyle: 'none' }}
                              />
                            </InputGroup>
                          </td>
                          <td>
                            <Select
                              options={signatureOptions}
                              value={Utils.object(
                                signatureOptions,
                                this.state.packageItem.signature,
                              )}
                              onChange={e =>
                                this.onChangePackageValueSelect(e, 'signature')
                              }
                              isSearchable={false}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )}

                  {/* Ordered Items */}

                  {this.state.orderItems.length > 0 && (
                    <BootstrapTable
                      bootstrap4
                      data={this.state.orderItems}
                      columns={itemsColumns}
                      keyField="id"
                      hover
                      wrapperClasses="mt-n3 container-table-package-items table-responsive"
                      classes="table-package-items"
                      noDataIndication={() => (
                        <div className="mt-2">
                          All products are distributed into the packages
                        </div>
                      )}
                      selectRow={selectRow}
                      cellEdit={cellEditFactory({
                        mode: 'click',
                        blurToSave: true,
                        // nonEditableRows: () => [0, 3],
                        afterSaveCell: (oldValue, newValue, row, column) => {
                          // Add Validation
                          // for Qty in Package
                          let orderItems = this.state.orderItems

                          // if ()

                          this.setState({
                            orderItems,
                          })
                        },
                      })}
                    />
                  )}

                  {this.state.orderItems.length > 0 && (
                    <div className="d-flex justify-content-end mt-4">
                      <Button
                        label={'Add Package'}
                        color="primary"
                        icon={`la ${false ? '' : ' la-plus'}`}
                        isLoading={false}
                        onClick={this.addItem}
                      />
                    </div>
                  )}

                  <div className="py-1">
                    <Separator size="xl" border={'dashed'} />
                  </div>

                  <h3 className="k-section__title mt-1">Packages</h3>

                  {this.state.packages.length === 0 && (
                    <span>Create a shipment packages</span>
                  )}

                  {this.state.packages.length > 0 && (
                    <BootstrapTable
                      bootstrap4
                      data={packageList}
                      columns={packageColumns}
                      // footerClasses="footer-bg"
                      classes="main-table-package-items bg-white"
                      wrapperClasses="table-responsive"
                      keyField="id"
                      hover
                      noDataIndication={() => (
                        <div className="mt-2">No packages</div>
                      )}
                      expandRow={expandRow}
                      cellEdit={cellEditFactory({
                        mode: 'click',
                        blurToSave: true,
                        afterSaveCell: (oldValue, newValue, row, column) => {
                          let packages = packageList
                          this.setState({ packages })
                        },
                      })}
                    />
                  )}
                </Section>
              </div>
            </div>
          )}
        </PortletBody>
      </Portlet>
    )
  }
}

export const mapStateToProps = state => ({
  settings: state.settings,
  taxType: state.taxType,
  fedexPackageType: state.fedexPackageType,
  fedexWeightType: state.fedexWeightType,
  fedexDimensionType: state.fedexDimensionType,
  uspsPackageType: state.uspsPackageType,
})

export const mapDispatchToProps = dispatch => ({
  post: function(payload, actionEvent) {
    return dispatch(Actions.post(payload, actionEvent))
  },
  storePost: function(payload, actionEvent, typeSuccess, typeError) {
    return dispatch(
      Actions.storePost(payload, actionEvent, typeSuccess, typeError),
    )
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Package)
