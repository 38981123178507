import {
  React,
  Component,
  connect,
  classNames,
  Utils,
  Routes,
  Notification,
  _, Modal, ModalHeader, ModalBody, ModalFooter, Button
} from '../../../Imports'
import { Auth } from 'aws-amplify'

import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap'
import { Link } from 'react-router-dom'
import HeaderUserMenu from './HeaderUserMenu'
import ActionTypes from '../../../actions/ActionTypes'
import { post, storeGet } from '../../../actions/DataActions'
import axios from 'axios'


class HeaderTopbar extends Component {
  constructor(props) {
    super(props)

    let store = Utils.search(this.props.location).store
    this.cacheInterval = null
    this.state = {
      storeSelectorIsOpen: false,
      cacheLoading: !!localStorage.getItem('cacheInvalidateId'),
      currentStore: store,
      group: '',
      isConformModalOpen: false,
    }
  }

  UNSAFE_componentWillMount = async () => {
    const { stores } = this.props
    if (stores.data.length === 0) {
      await this.props.storeGet(
        'list-stores',
        ActionTypes.FETCH_STORES_SUCCESS,
        ActionTypes.FETCH_STORES_FAILURE,
      )
    }

    if (
      this.state.currentStore === null ||
      this.state.currentStore === undefined
    ) {
      let stores = this.props.stores.data
      stores = _.sortBy(stores, 'name')
      this.changeCurrentStore(stores[0].id)
    }
    this.cacheInvalidateStatus()
    // TODO use for dynamic head title update for each shop
    // const current = _.filter(_.get(this.props.stores, "data", []), st => st.id === this.state.currentStore)[0];
    // if (current) document.title = current.name;
  }

  componentDidMount = () => {
    Auth.currentAuthenticatedUser()
      .then(user => {
        this.setState({
          group:
            user.signInUserSession.accessToken.payload['cognito:groups'][0],
        })
      })
      .catch(err => console.log(err))
  }

  toggleStoreSelector = () => {
    this.setState({
      storeSelectorIsOpen: !this.state.storeSelectorIsOpen,
    })
  }

  changeCurrentStore = storeId => {
    this.setState({ currentStore: storeId })

    window.location = Utils.replaceUrlParam('/', 'store', storeId)

    this.toggleStoreSelector()
  }

  onPublish = async () => {
    this.setState({ publishBtnLoading: true })
    this.onConfirmToggle()
    if (process.env.REACT_APP_DEPLOY_API) {
      axios.post(process.env.REACT_APP_DEPLOY_API)
        .then((response) => {
          this.setState({ publishBtnLoading: false })
          let msn = _.replace(
            _.replace(process.env.REACT_APP_SUCCESS_ACTION, '%entity%', 'Site'),
            '%action%',
            'Updated',
          )
          Notification.success(msn)

        }, (error) => {
          console.log(error);
        });
    } else {
      await this.props.post(
        {
          storeId: this.state.currentStore,
        },
        'build',
      )

      this.setState({ publishBtnLoading: false })

      let msn = _.replace(
        _.replace(process.env.REACT_APP_SUCCESS_ACTION, '%entity%', 'Site'),
        '%action%',
        'Updated',
      )

      Notification.success(msn)
    }
  }

  onConfirmToggle = () => {
    const prevState = this.state.isConformModalOpen;
    this.setState({isConformModalOpen: !prevState});
  }

  currentStore = () => {
    const { currentStore } = this.state
    const stores = _.get(this.props.stores, 'data', [])
    let name = ''
    if (currentStore && stores.length > 0) {
      const store = stores.find(s => currentStore == s.id)

      name = store && Object.keys(store).length ? store.name : ''
    }

    return <span className="k-header__topbar-username">{name}</span>
  }

  onCacheInvalidate = async () => {
    const { currentStore: path, cacheLoading } = this.state
    if (!cacheLoading) {
      this.setState({ cacheLoading: true }, () => {
        this.props
          .post({ path }, 'clear-image-cache', '/api/utils')
          .then(
            ({
              data: {
                result: { id },
              },
            }) => {
              try {
                localStorage.setItem('cacheInvalidateId', id)
              } catch (e) {
                console.log(e.message)
              }
              this.cacheInvalidateStatus(id)
            },
          )
          .catch(this.cacheInvalidateError)
      })
    }
  }

  cacheInvalidateStatus = (id = localStorage.getItem('cacheInvalidateId')) => {
    if (id) {
      this.cacheInterval = setInterval(() => {
        this.props
          .post({ id }, 'check-invalidation', '/api/utils')
          .then(
            ({
              data: {
                result: { status },
              },
            }) => {
              console.log(status)
              if (status === 'InProgress') return
              if (status === 'Completed') {
                clearInterval(this.cacheInterval)
                this.setState({ cacheLoading: false })
                localStorage.removeItem('cacheInvalidateId')
                Notification.success('Cache invalidation completed')
                return
              }
              this.cacheInvalidateError()
            },
          )
          .catch(this.cacheInvalidateError)
      }, 2000)
    }
  }

  cacheInvalidateError = () => {
    this.setState({ cacheLoading: false })
    Notification.error('Something went wrong. Please try again later.')
    localStorage.removeItem('cacheInvalidateId')
  }

  render = () => {
    const { group, currentStore } = this.state

    const settingsUrl = `${Routes.SETTINGS_PROFILE}?store=${currentStore}`

    return (
      <div className="k-header__topbar">
        {/* begin: Search  */}
        <div className="k-header__topbar-item k-header__topbar-item--search">
          <div className="k-header__topbar-wrapper">
            <span className="k-header__topbar-icon">
              <i className="flaticon2-search-1" />
            </span>
          </div>
        </div>
        {/* end: Search  */}

        <div className="k-header__topbar-item k-header__topbar-item--search">
          <div
            className="k-header__topbar-wrapper"
            onClick={this.onCacheInvalidate}
          >
            <span className="k-header__topbar-icon">
              {!this.state.cacheLoading ? (
                <i
                  className="la la-refresh"
                  aria-hidden="true"
                  title="Cache invalidation"
                />
              ) : (
                <span className="spinner-border spinner-border-sm k-font-metal" />
              )}
            </span>
          </div>
        </div>
        <div
          id="publishBtn"
          className="k-header__topbar-item k-header__topbar-item--search"
        >
          <div className="k-header__topbar-wrapper" onClick={this.onConfirmToggle}>
            <span className="k-header__topbar-icon">
              {!this.state.publishBtnLoading ? (
                <i className="la la-sellsy" title="Publish" />
              ) : (
                <span className="spinner-border spinner-border-sm k-font-metal" />
              )}
            </span>
          </div>
        </div>

        {/* begin: Quick actions  */}
        {[Utils.GROUPS.SUPER_ADMIN, Utils.GROUPS.ADMIN].includes(group) && (
          <Link to={settingsUrl} className="k-header__topbar-item">
            <div className="k-header__topbar-wrapper">
              <span className="k-header__topbar-icon">
                <i className="flaticon2-gear" />
              </span>
            </div>
          </Link>
        )}
        {/* end: Quick actions  */}

        {/* Store selector */}
        <Dropdown
          className="k-header__topbar-item k-header__topbar-item--user"
          isOpen={this.state.storeSelectorIsOpen}
          toggle={this.toggleStoreSelector}
        >
          <DropdownToggle tag="div" className="k-header__topbar-wrapper">
            <div className="k-header__topbar-user">
              {/* <span
                                className="k-header__topbar-icon"
                                style={{
                                    backgroundColor: "transparent"
                                }}
                            >
                                <i className="flaticon2-architecture-and-city" />
                            </span> */}

              <span className="k-header__topbar-welcome ml-2">
                {currentStore ? 'Store:' : 'No store'}
              </span>
              {this.currentStore()}
            </div>
          </DropdownToggle>
          <DropdownMenu>
            {!this.props.stores.data.length && (
              <span className="text-center d-block">No stores to show.</span>
            )}
            {_.get(this.props.stores, 'data', []).map(s => (
              <a
                key={s.id}
                className={classNames('dropdown-item', {
                  'text-primary': currentStore === s.id,
                })}
                href="#"
                onClick={() => {
                  this.changeCurrentStore(s.id)
                }}
              >
                {currentStore === s.id && (
                  <i className="la la-check text-primary" />
                )}
                {s.name}
              </a>
            ))}
          </DropdownMenu>
        </Dropdown>
        {/* End Store selector */}

        <HeaderUserMenu />

        <Modal isOpen={this.state.isConformModalOpen}>
          <ModalHeader>Publish/build confirmation</ModalHeader>
          <ModalBody>Are you sure want to Publish/build the Project?</ModalBody>
          <ModalFooter>
            <Button
              label="Confirm"
              color="success"
              onClick={this.onPublish}
            />
            <Button label="Cancel" color="secondary" onClick={this.onConfirmToggle} />
          </ModalFooter>
        </Modal>

      </div>
    )
  }
}

export const mapStateToProps = state => ({
  stores: state.stores,
})

export const mapDispatchToProps = dispatch => ({
  post: function (payload, actionEvent, path) {
    return dispatch(post(payload, actionEvent, path))
  },
  storeGet: function (actionEvent, typeSuccess, typeError) {
    return dispatch(storeGet(actionEvent, typeSuccess, typeError))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(HeaderTopbar)
