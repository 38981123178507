import {
  React,
  Component,
  connect,
  Actions,
  Portlet,
  PortletHeader,
  PortletBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  BootstrapTable,
  paginationFactory,
  filterFactory,
  Filters,
  textFilter,
  numberFilter,
  FILTER_TYPES,
  Comparator,
  Utils,
  Notification,
  Routes,
  Link,
  Button,
  ActionTypes,
  classNames,
  DateFilter,
  customFilter,
  _,
} from '../../../Imports'
import { Helmet } from 'react-helmet'

import moment from 'moment-timezone'
const entity = 'Promotion'

class Promotions extends Component {
  constructor(props) {
    super(props)

    this.state = {
      promotions: [],
      filters: [
        {
          id: 1,
          name: 'Name',
          filter: null,
          value: '',
          type: FILTER_TYPES.TEXT,
        },
        {
          id: 2,
          name: 'Discount Type',
          filter: null,
          value: '',
          type: FILTER_TYPES.TEXT,
        },
        {
          id: 3,
          name: 'Discount',
          filter: null,
          value: '',
          type: FILTER_TYPES.NUMBER,
        },
        {
          id: 4,
          name: 'Expiration',
          filter: null,
          value: '',
          type: FILTER_TYPES.DATE,
        },
      ],
      isOpen: false,
      isFilterOpen: false,
      btnLoading: false,
      refresh: true,
      id: null,
    }

    this.storeId = Utils.search(this.props.location).store
  }

  UNSAFE_componentWillMount = async () => {
    if (this.props.settings.data === null) {
      await this.props.storePost(
        { id: this.storeId },
        'get-store',
        ActionTypes.FETCH_SETTINGS_SUCCESS,
        ActionTypes.FETCH_SETTINGS_FAILURE,
      )
    }

    this.onRefresh()
  }

  onRefresh = () => {
    this.setState({ promotions: [], refresh: true })

    let storeId = Utils.search(this.props.location).store

    this.props
      .storePost(
        {
          storeId,
        },
        'list-promotions',
        ActionTypes.FETCH_PROMOTIONS_SUCCESS,
        ActionTypes.FETCH_PROMOTIONS_FAILURE,
      )
      .then(() => {
        this.setState({
          promotions: this.props.promotions.data,
          settings: this.props.settings.data,
          refresh: false,
        })
      })
  }

  onPublish = () => {
    console.log('Publish')
  }

  onClean = () => {
    this.setState({ promotions: [], refresh: true })
  }

  delete = id => {
    this.setState({ isOpen: true, id: id })
  }

  close = () => {
    this.setState({ isOpen: false })
  }

  confirm = () => {
    this.setState({ btnLoading: true })

    this.props
      .post(
        {
          storeId: this.storeId,
          id: this.state.id,
        },
        'delete-promotion',
      )
      .then(resp => {
        this.response(resp)
      })
  }

  response = resp => {
    this.setState({
      btnLoading: false,
      isOpen: false,
    })

    let msn = _.replace(
      _.replace(process.env.REACT_APP_SUCCESS_ACTION, '%entity%', entity),
      '%action%',
      'Deleted',
    )

    if (resp !== 'error') {
      Notification.success(msn)
    } else {
      Notification.error(process.env.REACT_APP_ERROR_ACTION)
    }

    this.onRefresh()
  }

  toggleFilter = () => {
    this.setState({
      isFilterOpen: !this.state.isFilterOpen,
    })
  }

  clearFilter = () => {
    _.forEach(this.state.filters, filter => {
      this.removeFilter(filter)
    })
  }

  removeFilter = filter => {
    let filters = this.state.filters
    filter.filter('')
    filter.value = ''
    this.setState({ filters })
  }

  setFilterState = ({ dataSize }) => {
    console.log('Table size changed', dataSize)
    let filters = this.state.filters
    this.setState({ filters })
  }

  render = () => {
    const { promotions, settings } = this.state
    const headerFormatter = (
      column,
      colIndex,
      { sortElement, filterElement },
    ) => (
      <div className="d-flex justify-content-end flex-column">
        <div className="d-flex justify-content-between">
          {column.text}
          {sortElement}
        </div>
        {this.state.isFilterOpen && (
          <div
            style={{
              margin: '0 -.75rem',
              marginTop: '.75rem',
              padding: '1rem .75rem .25rem',
              borderTop: '2px solid #f7f8fa',
            }}
          >
            {filterElement}
          </div>
        )}
      </div>
    )

    const defaultSorted = [
      {
        dataField: 'priority',
        order: 'asc',
      },
    ]

    const rowEvents = {
      onClick: (e, column, columnIndex, row, rowIndex) => {
        let url = `/promotion/detail/${row.id}?store=${this.storeId}`
        this.props.history.push(url)
      },
    }

    const columns = [
      {
        dataField: 'id',
        text: 'Id',
        headerFormatter,
        headerClasses: 'align-top',
        hidden: true,
      },
      {
        dataField: 'name',
        text: 'Offer',
        events: rowEvents,
        headerFormatter,
        headerClasses: 'align-top',
        filter: textFilter({
          defaultValue: this.state.filters[0].value,
          getFilter: filter => {
            this.state.filters[0].filter = filter
          },
          onFilter: filterVal => {
            let filters = this.state.filters
            filters[0].value = filterVal
          },
        }),
        filterValue: (cell, row) => `${cell} - ${row.label}`,
        headerStyle: (colum, colIndex) => ({ width: '120px' }),
        formatter: (cell, row, rowIndex, formatExtraData) =>
          `${cell} - ${row.label}`,
        sort: true,
      },
      {
        dataField: 'discountType',
        text: 'Discount Type',
        events: rowEvents,
        headerFormatter,
        headerClasses: 'align-top',
        filter: textFilter({
          defaultValue: this.state.filters[1].value,
          getFilter: filter => {
            this.state.filters[1].filter = filter
          },
          onFilter: filterVal => {
            let filters = this.state.filters
            filters[1].value = filterVal
          },
        }),
        headerStyle: (colum, colIndex) => ({ width: '100px' }),
        formatter: (cell, row, rowIndex, formatExtraData) => {
          let fullname = _.startCase(_.lowerCase(cell))
          return fullname
        },
        sort: true,
      },
      {
        dataField: 'rate',
        text: 'Discount',
        events: rowEvents,
        headerFormatter,
        align: 'right',
        headerClasses: 'align-top',
        filter: numberFilter({
          defaultValue: { comparator: Comparator.GT },
          getFilter: filter => {
            this.state.filters[2].filter = filter
          },
          onFilter: filterVal => {
            let filters = this.state.filters
            if (filterVal.number) {
              filters[2].value = `${filterVal.comparator} ${filterVal.number}`
            } else {
              filters[2].value = ''
            }
          },
        }),
        headerStyle: (colum, colIndex) => ({ width: '120px' }),
        formatter: (cell, row, rowIndex, formatExtraData) =>
          `${row.discountRateType == 'AMOUNT' ? '$' : ''}${(cell || 0) / 100}${
            row.discountRateType == 'PERCENTAGE' ? '%' : ''
          }`,
        sort: true,
      },
      {
        dataField: 'expirationDate',
        text: 'Expiration',
        headerFormatter,
        headerClasses: 'align-top',
        events: rowEvents,
        sort: true,
        headerStyle: (colum, colIndex) => ({ width: '140px' }),
        formatter: (cell, row, rowIndex, formatExtraData) => (
          <span>
            {moment(cell, 'MM-DD-YYYY', true)
              .tz(settings.timeZone.value)
              .format('MM/DD/YYYY')}
          </span>
        ),
        filter: customFilter({
          type: FILTER_TYPES.DATE,
          onFilter: (filterVal, data) => {
            let filters = this.state.filters

            if (filterVal.date) {
              // Custom filtering by (start)date and endDate
              const mdate = moment(filterVal.date).tz(settings.timeZone.value)
              const mendDate = moment(filterVal.endDate).tz(
                settings.timeZone.value,
              )

              filters[3].value =
                mdate.format('MM/DD/YYYY') +
                (filterVal.date === filterVal.endDate ||
                (filterVal.endDate &&
                  moment.duration(mendDate.diff(mdate))._milliseconds ===
                    86399000)
                  ? ''
                  : ' - ' + mendDate.format('MM/DD/YYYY'))

              return _.filter(
                data,
                s =>
                  moment(s.expirationDate, 'MM-DD-YYYY', true).tz(
                    settings.timeZone.value,
                  ) > mdate &&
                  moment(s.expirationDate, 'MM-DD-YYYY', true).tz(
                    settings.timeZone.value,
                  ) < mendDate,
              )
            } else {
              filters[3].value = ''
              return true
            }
          },
        }),
        filterRenderer: (onFilter, column) => {
          this.state.filters[3].filter = onFilter

          return (
            <DateFilter
              timeZone={settings.timeZone.value}
              onFilter={onFilter}
              column={column}
            />
          )
        },
      },
      {
        dataField: 'priority',
        text: 'Priority',
        headerAlign: 'right',
        align: 'right',
        headerFormatter,
        headerClasses: 'align-top',
        events: rowEvents,
        headerStyle: (colum, colIndex) => ({ width: '80px' }),
        formatter: (cell, row, rowIndex, formatExtraData) => cell,
      },
      {
        dataField: 'status',
        text: 'Status',
        headerFormatter,
        headerClasses: 'align-top',
        events: rowEvents,
        headerStyle: (colum, colIndex) => ({ width: '100px' }),
        formatter: (cell, row, rowIndex, formatExtraData) => (
          <span
            className={classNames(
              'badge',
              `badge-${cell ? 'success' : 'secondary'}`,
            )}
          >
            {cell ? 'Active' : 'Inactive'}
          </span>
        ),
      },
      {
        dataField: 'actions',
        text: 'Actions',
        headerAlign: 'right',
        align: 'right',
        headerFormatter,
        headerClasses: 'align-top',
        headerStyle: (colum, colIndex) => ({ width: '100px' }),
        formatter: (cell, row, rowIndex, formatExtraData) => {
          let url = `/promotion/detail/${row.id}?store=${this.storeId}`
          return (
            <div>
              <Link
                to={url}
                className="btn btn-outline-secondary btn-sm btn-icon"
                title="Edit"
              >
                <i className="la la-search" />
              </Link>
              &nbsp; &nbsp;
              <button
                className="btn btn-outline-secondary btn-sm btn-icon"
                title="Delete"
                onClick={() => this.delete(row.id)}
              >
                <i className="la la-trash" />
              </button>
            </div>
          )
        },
      },
    ]

    const options = {
      showTotal: true,
      sizePerPageList: [
        {
          text: '20',
          value: 20,
        },
        {
          text: '100',
          value: 100,
        },
        {
          text: '200',
          value: 200,
        },
        {
          text: '300',
          value: 300,
        },
        {
          text: 'All',
          value: promotions.length,
        },
      ],
    }

    return (
      <Portlet>
        <Helmet>
          <title>Promotions | Admin Panel - Max Ecommerce</title>
        </Helmet>
        <PortletHeader sticky title="Promotions List">
          <Button
            label="Refresh"
            icon="la la-refresh"
            color="secondary"
            className="mr-3"
            onClick={this.onRefresh}
          />
          <Button
            label="Filters"
            icon="la la-filter"
            color="secondary"
            onClick={this.toggleFilter}
            className={classNames('mr-3', {
              active: this.state.isFilterOpen,
            })}
          />
          {/* <CSVLink
                        data={promotions}
                        filename={"promotions.csv"}
                        className="btn btn-secondary mx-3"
                        headers={headers}
                    >
                        <i className="la la-download" />
                        Export
                    </CSVLink> */}
          <Link
            to={`${Routes.PROMOTION_CREATE}?store=${
              Utils.search(this.props.location).store
            }`}
            className="btn btn-primary"
          >
            <i className="la la-plus" />
            Add new
          </Link>
          {/*
                        <Button
                            label="Publish"
                            icon="la la-sellsy"
                            color="secondary"
                            className="mr-3"
                            onClick={this.onPublish}
                        />
                    */}
        </PortletHeader>
        <PortletBody>
          <Filters
            removeFilter={this.removeFilter}
            clearAll={this.clearFilter}
            filters={this.state.filters}
          />
          {!!promotions.length && (
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={promotions}
              columns={columns}
              hover
              noDataIndication={() => (
                <div className="mt-2">
                  {this.state.refresh ? 'Loading...' : `No ${entity + 's'}`}
                </div>
              )}
              pagination={paginationFactory(options)}
              filter={filterFactory()}
              defaultSorted={defaultSorted}
              onDataSizeChange={this.setFilterState}
            />
          )}
        </PortletBody>
        <Modal isOpen={this.state.isOpen}>
          <ModalHeader>Delete confirmation</ModalHeader>
          <ModalBody>Are you sure want to Delete this Promotion?</ModalBody>
          <ModalFooter>
            <Button
              isLoading={this.state.btnLoading}
              label="Confirm"
              color="danger"
              onClick={this.confirm}
            />
            <Button label="Cancel" color="secondary" onClick={this.close} />
          </ModalFooter>
        </Modal>
      </Portlet>
    )
  }
}

export const mapStateToProps = state => ({
  promotions: state.promotions,
  settings: state.settings,
  currentStore: state.currentStore,
})

export const mapDispatchToProps = dispatch => ({
  post: function(path, payload, actionEvent) {
    return dispatch(Actions.post(path, payload, actionEvent))
  },
  storePost: function(path, payload, actionEvent, typeSuccess, typeError) {
    return dispatch(
      Actions.storePost(path, payload, actionEvent, typeSuccess, typeError),
    )
  },
  storeGet: function(path, actionEvent, typeSuccess, typeError) {
    return dispatch(
      Actions.storeGet(path, actionEvent, typeSuccess, typeError),
    )
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Promotions)
