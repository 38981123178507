import { Auth } from 'aws-amplify'
import * as Utils from '../../utils/Utils'

//Dashboard
export const DASHBOARD = '/'

//Orders
export const ORDER_LIST = '/orders'
export const ORDER_ABANDONED_LIST = '/orders-abandoned'
export const ORDER_CREATE = '/order/create'
export const ORDER_DETAIL = '/order/detail/:id'

//Pages
export const PAGE_LIST = '/pages'
export const PAGE_CREATE = '/page/create'
export const PAGE_DETAIL = '/page/detail/:id'

//Products
export const PRODUCT_LIST = '/products'
export const PRODUCT_CREATE = '/product/create'
export const PRODUCT_DETAIL = '/product/detail/:id'
export const PRODUCT_SECTION_INFORMATION = '#information'
export const PRODUCT_SECTION_DIMENSIONS = '#dimensions'
export const PRODUCT_SECTION_INVENTORY = '#inventory'
export const PRODUCT_SECTION_SALES = '#sales'
export const PRODUCT_SECTION_SEARCHENGINE = '#searchengine'
export const PRODUCT_SECTION_MEDIA = '#media'
export const PRODUCT_SECTION_VARIANTS = '#variants'

//Customers
export const CUSTOMER_LIST = '/customers'
export const CUSTOMER_CREATE = '/customer/create'
export const CUSTOMER_DETAIL = '/customer/detail/:email'

//Reports
export const SHIPPING_REPORT = '/shipping-report'

//Promotions
export const PROMOTION_LIST = '/promotions'
export const PROMOTION_CREATE = '/promotion/create'
export const PROMOTION_DETAIL = '/promotion/detail/:id'

// Products Categories
export const PRODUCT_CATEGORY_LIST = '/product-categories'
export const PRODUCT_CATEGORY_CREATE = '/product-category/create'
export const PRODUCT_CATEGORY_DETAIL = '/product-category/detail/:id'

// Attributes
export const ATTRIBUTES = '/attributes'

//Stores
export const STORES = '/stores'

//User Accounts
export const USER_ACCOUNT_LIST = '/user-accounts'
export const USER_ACCOUNT_CREATE = '/user-account/create'
export const USER_ACCOUNT_DETAIL = '/user-account/detail/:id'

//Settings
export const SETTINGS = '/store/'
export const SETTINGS_PROFILE = '/store/profile'
export const SETTINGS_TAX = '/store/tax'
export const SETTINGS_TIMEZONE = '/store/timezone'
export const SETTINGS_FET_TAX = '/store/fet-tax'
export const SETTINGS_ORDER_SETTINGS = '/store/order-settings'
export const SETTINGS_SOCIAL_LINKS = '/store/social-links'
export const SETTINGS_PAYMENT = '/store/payment'
export const SETTINGS_INTEGRATION = '/store/integration'
export const T14_PRODUCTS = '/store/t14-products'
export const SETTINGS_FULFILMENT = '/store/shipping'
export const SETTINGS_SERVICE_AREA = '/store/service-area'
export const SETTINGS_SHIPPING_METHOD = '/store/shipping-method'
export const QUICKBOOK_ONLINE = '/store/quickbooks-online'

//Shipping
export const SHIPMENT = '/shipment/'
export const SHIPPING_PACKAGE = '/shipment/package'
export const SHIPPING_CARRIER = '/shipment/carrier'
export const PACKAGE_SLIPS = '/shipment/package-slips'
export const SHIPPING_LABEL = '/shipment/label'

export const SHIPMENT_OPTIONS = [
  {
    url: SHIPPING_PACKAGE,
    label: 'Package',
    icon: 'flaticon-open-box',
  },
  {
    url: SHIPPING_CARRIER,
    label: 'Carrier',
    icon: 'flaticon-open-box',
  },
  {
    url: PACKAGE_SLIPS,
    label: 'Package Slips',
    icon: 'flaticon-list-1',
  },
  {
    url: SHIPPING_LABEL,
    label: 'Shipping Label',
    icon: 'flaticon-notes',
  },
]

export const SETTINGS_OPTIONS = [
  {
    url: SETTINGS_PROFILE,
    label: 'Profile',
    icon: 'flaticon-profile',
  },
  {
    url: SETTINGS_ORDER_SETTINGS,
    label: 'Order Settings',
    icon: 'flaticon-list-1',
  },
  {
    url: SETTINGS_FULFILMENT,
    label: 'Fulfillment Center',
    icon: 'flaticon-home',
  },
  {
    url: SETTINGS_TAX,
    label: 'Tax',
    icon: 'flaticon-coins',
  },

  {
    url: SETTINGS_TIMEZONE,
    label: 'Timezone',
    icon: 'flaticon-clock',
  },

  // {
  //     url: SETTINGS_FET_TAX,
  //     order:settings,
  //     label: "Fet Tax",
  //     icon: "flaticon-coins"
  // },
  {
    url: SETTINGS_PAYMENT,
    label: 'Payment',
    icon: 'flaticon-web',
  },
  {
    url: SETTINGS_SERVICE_AREA,
    label: 'Service Area',
    icon: 'flaticon-map-location',
  },
  {
    url: SETTINGS_SHIPPING_METHOD,
    label: 'Shipping Method',
    icon: 'flaticon-open-box',
  },
  {
    url: QUICKBOOK_ONLINE,
    label: 'Quickbooks Online',
    icon: 'flaticon-cart',
  },
  {
    url: SETTINGS_SOCIAL_LINKS,
    label: 'Social Media',
    icon: 'flaticon-browser',
  },
  {
    url: SETTINGS_INTEGRATION,
    label: 'Integration',
    icon: 'flaticon-interface-4',
  },
  {
    url: T14_PRODUCTS,
    label: 'T14 Products',
    icon: '',
  },
]

// Product Form Sections
export const PRODUCT_SECTIONS = [
  {
    url: PRODUCT_SECTION_INFORMATION,
    label: 'Information',
    icon: 'flaticon2-paper',
  },
  {
    url: PRODUCT_SECTION_DIMENSIONS,
    label: 'Dimensions',
    icon: 'flaticon2-cube',
  },
  {
    url: PRODUCT_SECTION_SALES,
    label: 'Sales',
    icon: 'flaticon-coins',
  },
  {
    url: PRODUCT_SECTION_SEARCHENGINE,
    label: 'Search Engine',
    icon: 'flaticon-interface-4',
  },
  {
    url: PRODUCT_SECTION_INVENTORY,
    label: 'Inventory',
    icon: 'flaticon2-layers-1',
  },
  {
    url: PRODUCT_SECTION_MEDIA,
    label: 'Media',
    icon: 'flaticon2-photograph',
  },
  {
    url: PRODUCT_SECTION_VARIANTS,
    label: 'Product Variants',
    icon: 'flaticon-delete-2',
  },
]

//Contact
export const CONTACT = '/contact'

export const getAsideMenuItems = async () => {
  const OPTION_ITEMS = []
  const CONTENT_ITEMS = []
  const DASHBOARD_ITEMS = []

  const user = await Auth.currentAuthenticatedUser()
  const groups = user.signInUserSession.accessToken.payload['cognito:groups']
  const group = groups && groups[0]

  DASHBOARD_ITEMS.push(
    [Utils.GROUPS.SUPER_ADMIN, Utils.GROUPS.ADMIN, Utils.GROUPS.SALES, Utils.GROUPS.MANAGER].includes(group) && {
      label: 'Dashboard',
      icon: 'flaticon2-graphic',
      url: DASHBOARD,
    },
  )

  // Orders
  OPTION_ITEMS.push(
    [
      Utils.GROUPS.SUPER_ADMIN,
      Utils.GROUPS.ADMIN,
      Utils.GROUPS.MANAGER,
      Utils.GROUPS.SHIPPER,
      Utils.GROUPS.SALES,
    ].includes(group) && {
      label: 'Sales',
      icon: 'flaticon-notes',
      options: [
        {
          url: ORDER_LIST,
          label: 'Orders',
        },
        {
          url: ORDER_ABANDONED_LIST,
          label: 'Abandoned Orders',
        },
        /*
                    {
                        url: ORDER_CREATE,
                        label: "Create new order"
                    }*/
      ],
    },
  )

  //  Reports
  OPTION_ITEMS.push(
    [
      Utils.GROUPS.SUPER_ADMIN,
      Utils.GROUPS.SHIPPER,
      Utils.GROUPS.ADMIN,
      Utils.GROUPS.MANAGER,
    ].includes(group) && {
      label: 'Reports',
      icon: 'flaticon-analytics',
      options: [
        {
          url: SHIPPING_REPORT,
          label: 'Shipping Report',
        },
      ],
    },
  )

  //  Catalog
  if ([
    Utils.GROUPS.SUPER_ADMIN,
    Utils.GROUPS.ADMIN,
    Utils.GROUPS.MANAGER,
    Utils.GROUPS.SHIPPER,
  ].includes(group)) {
    OPTION_ITEMS.push({
      label: 'Catalog',
      icon: 'flaticon2-lorry',
      options: [
        //  Products
        {
          url: PRODUCT_LIST,
          label: 'Products',
        },
        {
          url: PRODUCT_CREATE,
          label: 'Create Product',
        },
        {
          url: PRODUCT_CATEGORY_LIST,
          label: 'Categories',
        },
        {
          url: PRODUCT_CATEGORY_CREATE,
          label: 'Create Category',
        },
        //  Attributes
        {
          url: ATTRIBUTES,
          label: 'Attributes',
        },
      ],
    })
  }

  OPTION_ITEMS.push(
    [
      Utils.GROUPS.SUPER_ADMIN,
      Utils.GROUPS.ADMIN,
      Utils.GROUPS.MANAGER,
    ].includes(group) && {
      label: 'Promotions',
      icon: 'flaticon2-gift-1',
      options: [
        {
          url: PROMOTION_LIST,
          label: 'Promotions',
        },
        {
          url: PROMOTION_CREATE,
          label: 'Create Promotion',
        },
      ],
    },
  )

  //  Customers
  OPTION_ITEMS.push(
    [
      Utils.GROUPS.SUPER_ADMIN,
      Utils.GROUPS.ADMIN,
      Utils.GROUPS.MANAGER,
    ].includes(group) && {
      label: 'Customers',
      icon: 'flaticon-users',
      options: [
        {
          url: CUSTOMER_LIST,
          label: 'Customers',
        },
        {
          url: CUSTOMER_CREATE,
          label: 'Create Customer',
        },
      ],
    },
  )

  // CONTENT_ITEMS
  //  Pages
  CONTENT_ITEMS.push(
    [Utils.GROUPS.SUPER_ADMIN, Utils.GROUPS.ADMIN, Utils.GROUPS.MANAGER].includes(group) && {
      label: 'Pages',
      icon: 'flaticon2-file-1',
      options: [
        {
          url: PAGE_LIST,
          label: 'Pages',
        },
        {
          url: PAGE_CREATE,
          label: 'Create Page',
        },
      ],
    },
  )

  return {
    orderItems: OPTION_ITEMS,
    contentItems: CONTENT_ITEMS,
    dashboardItems: DASHBOARD_ITEMS,
  }
}
