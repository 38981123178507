import {
  React,
  Select,
  FormGroup,
  Input,
  Label,
  FormFeedback,
  Utils,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from '../../../Imports'

const AddressEditForm = ({
  onChangeLocation,
  onUpdateLocation,
  onCloseLocation,
  validLocation,
  addressValue,
  country,
  isOpen,
  values,
  states,
}) => (
  <Modal size="sm" isOpen={isOpen}>
    <ModalHeader>Update {addressValue === 'shipp' ? 'Shipping' : 'Billing'}</ModalHeader>
    <ModalBody>
      <FormGroup className="mb-2">
        <Label>First Name *</Label>
        <Input
          value={values[`${addressValue}ToFirstName`] || ''}
          onChange={onChangeLocation}
          name={`${addressValue}ToFirstName`}
          invalid={addressValue === 'shipp' && validLocation[`${addressValue}ToFirstName`].isInvalid}
        />
        <FormFeedback>
          {addressValue === 'shipp' && validLocation[`${addressValue}ToFirstName`].message}
        </FormFeedback>
      </FormGroup>
      <FormGroup className="mb-2">
        <Label>Last Name *</Label>
        <Input
          value={values[`${addressValue}ToLastName`] || ''}
          onChange={onChangeLocation}
          name={`${addressValue}ToLastName`}
          invalid={addressValue === 'shipp' && validLocation[`${addressValue}ToLastName`].isInvalid}
        />
        <FormFeedback>
          {addressValue === 'shipp' && validLocation[`${addressValue}ToLastName`].message}
        </FormFeedback>
      </FormGroup>
      <FormGroup className="mb-2">
        <Label>Email *</Label>
        <div className="input-group">
          <div className="input-group-prepend">
            <i className="la la-at input-group-text" />
          </div>
          <Input
            value={values[`${addressValue}ToEmail`] || ''}
            onChange={onChangeLocation}
            name={`${addressValue}ToEmail`}
            invalid={addressValue === 'shipp' && validLocation[`${addressValue}ToEmail`].isInvalid}
          />
          <FormFeedback>
            {addressValue === 'shipp' && validLocation[`${addressValue}ToEmail`].message}
          </FormFeedback>
        </div>
      </FormGroup>
      <FormGroup className="mb-2">
        <Label>Phone number *</Label>
        <div className="input-group">
          <div className="input-group-prepend">
            <i className="la la-phone input-group-text" />
          </div>
          <Input
            value={values[`${addressValue}ToPhoneNumber`] || ''}
            onChange={onChangeLocation}
            name={`${addressValue}ToPhoneNumber`}
            invalid={addressValue === 'shipp' && validLocation[`${addressValue}ToPhoneNumber`].isInvalid}
          />
          <FormFeedback>
            {addressValue === 'shipp' && validLocation[`${addressValue}ToPhoneNumber`].message}
          </FormFeedback>
        </div>
      </FormGroup>
      <FormGroup className="mb-2">
        <Label>Company</Label>
        <Input
          value={values[`${addressValue}ToCompany`] || ''}
          onChange={onChangeLocation}
          name={`${addressValue}ToCompany`}
        />
      </FormGroup>
      <FormGroup className="mb-2">
        <Label>Address *</Label>
        <Input
          value={values[`${addressValue}ToAddress`] || ''}
          onChange={onChangeLocation}
          name={`${addressValue}ToAddress`}
          invalid={addressValue === 'shipp' && validLocation[`${addressValue}ToAddress`].isInvalid}
        />
        <FormFeedback>
          {addressValue === 'shipp' && validLocation[`${addressValue}ToAddress`].message}
        </FormFeedback>
      </FormGroup>
      <FormGroup className="mb-2">
        <Label>City *</Label>
        <Input
          value={values[`${addressValue}ToCity`] || ''}
          onChange={onChangeLocation}
          name={`${addressValue}ToCity`}
          invalid={addressValue === 'shipp' && validLocation[`${addressValue}ToCity`].isInvalid}
        />
        <FormFeedback>{addressValue === 'shipp' && validLocation[`${addressValue}ToCity`].message}</FormFeedback>
      </FormGroup>
      <FormGroup className="mb-2">
        <Label>Country *</Label>
        <Select
          options={country}
          value={Utils.object(
            country,
            values[`${addressValue}ToCountry`],
          ) || ''}
          onChange={e =>
            onChangeLocation(e, `${addressValue}ToCountry`)
          }
          className={
            addressValue === 'shipp' && validLocation[`${addressValue}ToCountry`].isInvalid ? 'is-invalid' : ''
          }
        />
        <FormFeedback>
          {addressValue === 'shipp' && validLocation[`${addressValue}ToCountry`].message}
        </FormFeedback>
      </FormGroup>
      <FormGroup className="mb-2">
        <Label>State *</Label>
        <Select
          options={states}
          value={Utils.object(
            states,
            values[`${addressValue}ToState`],
          ) || ''}
          onChange={e =>
            onChangeLocation(e, `${addressValue}ToState`)
          }
          className={
            addressValue === 'shipp' && validLocation[`${addressValue}ToState`].isInvalid ? 'is-invalid' : ''
          }
        />
        <FormFeedback>{addressValue === 'shipp' && validLocation[`${addressValue}ToState`].message}</FormFeedback>
      </FormGroup>
      <FormGroup className="mb-2">
        <Label>Zip Code *</Label>
        <Input
          value={values[`${addressValue}ToZipcode`] || ''}
          onChange={onChangeLocation}
          name={`${addressValue}ToZipcode`}
          invalid={addressValue === 'shipp' && validLocation[`${addressValue}ToZipcode`].isInvalid}
        />
        <FormFeedback>
          {addressValue === 'shipp' && validLocation[`${addressValue}ToZipcode`].message}
        </FormFeedback>
      </FormGroup>
    </ModalBody>
    <ModalFooter>
      <Button
        label={'Update'}
        color="success"
        className="mr-2"
        icon={'la la-check-circle'}
        onClick={onUpdateLocation}
      />
      <Button
        label="Close"
        color="secondary"
        onClick={onCloseLocation}
      />
    </ModalFooter>
  </Modal>
)

export default AddressEditForm
