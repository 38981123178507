import {
  React,
  Component,
  connect,
  Actions,
  Portlet,
  PortletHeader,
  PortletBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  BootstrapTable,
  paginationFactory,
  filterFactory,
  textFilter,
  Filters,
  FILTER_TYPES,
  Utils,
  Notification,
  Routes,
  Link,
  classNames,
  Button,
  ActionTypes,
  _,
} from '../../../Imports'
import { Helmet } from 'react-helmet'

const entity = 'Product Category'

class Categories extends Component {
  constructor(props) {
    super(props)

    this.state = {
      filters: [
        {
          id: 1,
          name: 'Category Name',
          filter: null,
          value: '',
          type: FILTER_TYPES.TEXT,
        },
      ],
      isFilterOpen: false,
      categories: [],
      isOpen: false,
      btnLoading: false,
      refresh: true,
      id: null,
    }

    this.storeId = Utils.search(this.props.location).store
  }

  UNSAFE_componentWillMount = () => {
    this.onRefresh()
  }

  delete = id => {
    this.setState({ isOpen: true, id: id })
  }

  close = () => {
    this.setState({ isOpen: false })
  }

  confirm = () => {
    this.setState({ btnLoading: true })

    this.props
      .post(
        {
          id: this.state.id,
          storeId: Utils.search(this.props.location).store,
        },
        'delete-category',
      )
      .then(resp => {
        if (resp.status == 'error') {
          Notification.error(resp.data)
        } else {
          this.onRefresh()
          Notification.success('Category Deleted Successfully.')
        }
      })
    this.setState({ btnLoading: false, isOpen: false })
  }

  response = resp => {
    this.setState({
      btnLoading: false,
      isOpen: false,
    })

    let msn = _.replace(
      _.replace(process.env.REACT_APP_SUCCESS_ACTION, '%entity%', entity),
      '%action%',
      'Deleted',
    )

    if (resp.status === 'success') {
      Notification.success(msn)
    } else {
      Notification.error(process.env.REACT_APP_ERROR_ACTION)
    }

    this.onRefresh()
  }

  onRefresh = () => {
    this.setState({ categories: [], refresh: true })

    let storeId = Utils.search(this.props.location).store

    this.props
      .storePost(
        {
          storeId: storeId,
        },
        'list-categories',
        ActionTypes.FETCH_CATEGORIES_SUCCESS,
        ActionTypes.FETCH_CATEGORIES_FAILURE,
      )
      .then(() => {
        const getCategories = _.get(this.props.categories, 'data', [])
        const cats = _.map(getCategories, category =>
          category.parentId !== null && category.parentId !== ''
            ? _.assign(category, {
              parentName: _.filter(
                getCategories,
                c => c.id === category.parentId,
              )[0].label,
            })
            : category)

        this.setState({
          categories: cats,
          refresh: false,
        })
      })
  }

  toggleFilter = () => {
    this.setState({
      isFilterOpen: !this.state.isFilterOpen,
    })
  }

  clearFilter = () => {
    _.forEach(this.state.filters, filter => {
      this.removeFilter(filter)
    })
  }

  removeFilter = filter => {
    let filters = this.state.filters
    filter.filter('')
    filter.value = ''
    this.setState({ filters })
  }

  setFilterState = ({ dataSize }) => {
    console.log('Table size changed', dataSize)
    let filters = this.state.filters
    this.setState({ filters })
  }

  render = () => {
    const { categories } = this.state
    const headerFormatter = (
      column,
      colIndex,
      { sortElement, filterElement },
    ) => (
      <div className="d-flex justify-content-end flex-column">
        <div className="d-flex justify-content-between">
          {column.text}
          {sortElement}
        </div>
        {this.state.isFilterOpen && (
          <div
            style={{
              margin: '0 -.75rem',
              marginTop: '.75rem',
              padding: '1rem .75rem .25rem',
              borderTop: '2px solid #f7f8fa',
            }}
          >
            {filterElement}
          </div>
        )}
      </div>
    )

    const rowEvents = {
      onClick: (e, column, columnIndex, row, rowIndex) => {
        let url =
          '/product-category/detail/' + row.id + '?store=' + this.storeId
        this.props.history.push(url)
      },
    }

    const columns = [
      {
        dataField: 'id',
        text: 'Id',
        hidden: true,
      },
      {
        dataField: 'label',
        text: 'Category name',
        headerFormatter,
        headerClasses: 'align-top',
        sort: true,
        events: rowEvents,
        filter: textFilter({
          defaultValue: this.state.filters[0].value,
          getFilter: filter => {
            this.state.filters[0].filter = filter
          },
          onFilter: filterVal => {
            let filters = this.state.filters
            filters[0].value = filterVal

            // this.setState({ filters });
          },
        }),
      },
      {
        dataField: 'parentName',
        text: 'Category Parent',
        headerFormatter,
        headerClasses: 'align-top',
        sort: true,
      },
      // {
      //     dataField: "url",
      //     text: "Url",
      //     headerFormatter,
      //     headerClasses: "align-top",
      //     sort: true,
      //     events: rowEvents
      // },
      // {
      //     dataField: "ebayCategoryId",
      //     text: "Ebay Category Id",
      //     sort: true
      // },
      {
        dataField: 'active',
        text: 'Active',
        sort: true,
        events: rowEvents,
        headerFormatter,
        headerClasses: 'align-top',
        headerStyle: (colum, colIndex) => ({ width: '90px' }),
        formatter: (cell, row, rowIndex, formatExtraData) => (
          <span
            className={classNames(
              'badge',
              `badge-${cell ? 'success' : 'danger'}`,
            )}
          >
            {cell ? 'Active' : 'No Active'}
          </span>
        ),
      },
      {
        dataField: 'actions',
        text: 'Actions',
        headerAlign: 'right',
        align: 'right',
        headerFormatter,
        headerClasses: 'align-top',
        sort: true,
        // events: rowEvents,
        headerStyle: (colum, colIndex) => ({ width: '100px' }),
        formatter: (cell, row, rowIndex, formatExtraData) => {
          let storeId = Utils.search(this.props.location).store

          let url = _.replace(Routes.PRODUCT_CATEGORY_DETAIL, ':id', row.id)

          url = `${url}?store=${storeId}`

          return (
            <div>
              <Link
                to={url}
                className="btn btn-outline-secondary btn-sm btn-icon"
                title="Edit"
              >
                <i className="la la-edit" />
              </Link>
              &nbsp; &nbsp;
              <button
                className="btn btn-outline-secondary btn-sm btn-icon"
                title="Delete"
                onClick={() => this.delete(row.id)}
              >
                <i className="la la-trash" />
              </button>
            </div>
          )
        },
      },
    ]

    const options = {
      showTotal: true,
      sizePerPageList: [
        {
          text: '30',
          value: 30,
        },
        {
          text: '200',
          value: 200,
        },
        {
          text: '300',
          value: 300,
        },
        {
          text: 'All',
          value: categories.length,
        },
      ],
    }
    return (
      <Portlet>
        <Helmet>
          <title>Categories | Admin Panel - Max Ecommerce</title>
        </Helmet>
        <PortletHeader sticky title="Categories List">
          <Button
            label="Refresh"
            icon="la la-refresh"
            color="secondary"
            className="mr-3"
            onClick={this.onRefresh}
          />
          <Button
            label="Filters"
            icon="la la-filter"
            color="secondary"
            onClick={this.toggleFilter}
            className={classNames('mr-3', {
              active: this.state.isFilterOpen,
            })}
          />
          <Link
            to={`${Routes.PRODUCT_CATEGORY_CREATE}?store=${
              Utils.search(this.props.location).store
            }`}
            className="btn btn-primary"
          >
            <i className="la la-plus" />
            Add new
          </Link>
        </PortletHeader>
        <PortletBody>
          <Filters
            removeFilter={this.removeFilter}
            clearAll={this.clearFilter}
            filters={this.state.filters}
          />
          {!!categories.length && (
            <BootstrapTable
              pagination={paginationFactory(options)}
              bootstrap4
              data={categories}
              columns={columns}
              keyField="id"
              hover
              filter={filterFactory()}
              noDataIndication={() => (
                <div className="mt-2">
                  {this.state.refresh ? 'Loading...' : 'No Categories'}
                </div>
              )}
              onDataSizeChange={this.setFilterState}
            />
          )}
        </PortletBody>
        <Modal isOpen={this.state.isOpen}>
          <ModalHeader>Delete confirmation</ModalHeader>
          <ModalBody>
            Are you sure want to Delete this Product Category?
          </ModalBody>
          <ModalFooter>
            <Button
              isLoading={this.state.btnLoading}
              label="Confirm"
              color="danger"
              onClick={this.confirm}
            />
            <Button label="Cancel" color="secondary" onClick={this.close} />
          </ModalFooter>
        </Modal>
      </Portlet>
    )
  }
}

export const mapStateToProps = state => ({
  categories: state.productCategories,
  currentStore: state.currentStore,
})

export const mapDispatchToProps = dispatch => ({
  storePost: function(payload, actionEvent, typeSuccess, typeError) {
    return dispatch(
      Actions.storePost(payload, actionEvent, typeSuccess, typeError),
    )
  },
  post: function(payload, actionEvent) {
    return dispatch(Actions.post(payload, actionEvent))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Categories)
