import React, { Component } from 'react'
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap'
import * as Utils from '../../../utils/Utils'
import { Auth } from 'aws-amplify'
import { Link } from 'react-router-dom'

class HeaderUserMenu extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
      username: '',
      group: '',
    }

    this.storeId = Utils.search(this.props.location).store
  }

  componentDidMount() {
    Auth.currentAuthenticatedUser()
      .then(user => {
        const group = user.signInUserSession.accessToken.payload['cognito:groups']
        this.setState({
          username: user.attributes.email,
          group: group && group[0],
        })
      })
      .catch(err => console.log(err))
  }

  toggleMenu = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  close = () => {
    this.setState({
      isOpen: false,
    })
  }

  signOut = () => {
    Auth.signOut()
      .then(data => {
        window.location.reload()
      })
      .catch(err => console.log(err))
  }

  render = () => {
    const { group } = this.state

    return (
      <Dropdown
        className="k-header__topbar-item k-header__topbar-item--user"
        isOpen={this.state.isOpen}
        toggle={this.toggleMenu}
      >
        <DropdownToggle
          tag="div"
          className="k-header__topbar-wrapper"
          data-offset="10px -2px"
        >
          <div className="k-header__topbar-user">
            <span className="k-header__topbar-welcome k-hidden-mobile">
              Hi,
            </span>
            <span className="k-header__topbar-username k-hidden-mobile">
              {this.state.username}
            </span>
            {/* <img alt="Pic" src={userImage} /> */}
            {/* use below badge element instead the user avatar to display username's first letter(remove k-hidden class to display it)  */}
            <span className="k-badge k-badge--username k-badge--lg k-badge--brand">
              <i className="flaticon2-user" />
            </span>
          </div>
        </DropdownToggle>
        <DropdownMenu>
          <Link
            onClick={this.close}
            to={'/notifications'}
            className="dropdown-item"
          >
            <i className="la la-bell" />
            Notifications
          </Link>

          {[Utils.GROUPS.SUPER_ADMIN, Utils.GROUPS.ADMIN, Utils.GROUPS.MANAGER].includes(group) && (
            <Link
              onClick={this.close}
              to={`/user-accounts?store=${this.storeId}`}
              className="dropdown-item"
            >
              <i className="la la-user" />
              User Accounts
            </Link>
          )}

          {[Utils.GROUPS.SUPER_ADMIN, Utils.GROUPS.ADMIN, Utils.GROUPS.MANAGER].includes(group) && (
            <Link
              onClick={this.close}
              to={`/store/profile?store=${this.storeId}`}
              className="dropdown-item"
            >
              <i className="la la-cog" />
              Settings
            </Link>
          )}

          <div className="dropdown-divider" />
          <a className="dropdown-item" href="#" onClick={this.signOut}>
            <i className="la la-sign-out" />
            Sing Out
          </a>
        </DropdownMenu>
      </Dropdown>
    )
  }
}

export default HeaderUserMenu
