import {
  React,
  Component,
  connect,
  Actions,
  Portlet,
  PortletHeader,
  PortletBody,
  Section,
  Utils,
  Notification,
  Link,
  FormValidator,
  Button,
  ActionTypes,
  _,
} from '../../../Imports'
import { Helmet } from 'react-helmet'

const entity = 'Label'

class ShippingLabel extends Component {
  constructor(props) {
    super(props)

    this.validator = new FormValidator([
      {
        field: 'calculateTaxTypeForTax',
        method: 'isEmpty',
        validWhen: false,
        message: 'The Tax Type is required.',
      },
      {
        field: 'taxAmountForTax',
        method: 'isEmpty',
        validWhen: false,
        message: 'The Tax Amount is required.',
      },
    ])

    this.state = {
      order: null,
      setting: null,
      submited: false,
      isCreate: false,
      btnLoading: false,
      packages: [],
      labels: null,
    }

    let search = Utils.search(this.props.location)

    this.storeId = search.store
    this.id = search.id
  }

  UNSAFE_componentWillMount = async () => {
    if (this.props.settings.data === null) {
      await this.props.storePost(
        { id: this.storeId },
        'get-store',
        ActionTypes.FETCH_SETTINGS_SUCCESS,
        ActionTypes.FETCH_SETTINGS_FAILURE,
      )
    }

    await this.props
      .post(
        {
          id: this.id,
          storeId: this.storeId,
        },
        'get-order',
      )
      .then(resp => {
        let order = resp.data
        let pa = _.get(order, 'shippingPackages', [])

        this.setState(
          {
            order,
            packages: pa === null ? [] : pa,
          },
          () => {
            this.getLabel()
          },
        )
      })
  }

  response = resp => {
    this.setState({
      btnLoading: false,
    })

    let msn = _.replace(
      _.replace(process.env.REACT_APP_SUCCESS_ACTION, '%entity%', entity),
      '%action%',
      'Updated',
    )

    if (resp.status === 'success') {
      Notification.success(msn)
    } else {
      Notification.error(process.env.REACT_APP_ERROR_ACTION)
    }
  }

  getLabel = async value => {
    let labels = []
    let resp = await this.props.post(
      {
        storeId: this.storeId,
        orderId: this.state.order.id,
      },
      'get-label',
    )

    labels = _.map(
      resp.data,
      label => process.env.REACT_APP_LABEL_URL + label,
    )

    this.setState({ labels })
  }

  complete = () => {
    this.setState({ btnLoading: true })

    let order = this.state.order
    order.isShippingCompleted = true
    order.status = 'SHIPPED'

    this.props
      .post(order, 'update-order')
      .then(resp => {
        this.props.history.push(
          `/order/detail/${this.id}?store=${this.storeId}`,
        )
        this.setState({ btnLoading: false })
      })
      .catch(e => {
        this.setState({ btnLoading: false })
      })
  }

  render = () => (
    // const validator = this.state.submited
    //	? this.validator.validate(this.state.setting)
    //	: this.validator.valid();

    <Portlet>
      <Helmet>
        <title>Shipping Label | Admin Panel - Max Ecommerce</title>
      </Helmet>
      <PortletHeader
        title={`Shipping label for order #${_.get(
          this.state.order,
          'invoiceNumber',
          '',
        )}`}
        sticky
      >
        {this.state.order !== null && this.state.packages.length > 0 ? (
          _.get(this.state.order, 'isShippingCompleted', false) ? (
            <Link
              to={`/order/detail/${this.id}?store=${this.storeId}`}
              className="btn btn-secondary"
            >
              <i className="la la-arrow-left" />
              Go Back to Order
            </Link>
          ) : (
            <Button
              label={'Complete Shipment'}
              color="secondary"
              icon={`la ${this.state.btnLoading ? '' : ' la-file-text'}`}
              isLoading={this.state.btnLoading}
              onClick={this.complete}
              className="ml-3"
            />
          )
        ) : null}
      </PortletHeader>
      <PortletBody>
        {this.state.order === null && this.state.labels === null && (
          <div className="row">
            <div className="col-12">
              <div>Loading...</div>
            </div>
          </div>
        )}

        {this.state.order !== null && this.state.packages.length > 0 && (
          <div className="row mr-2 ml-2">
            <div className="col-xl-12">
              <Section title="">
                {/*Click the button to genertate
                                        shipping label.*/}
                <span>
                  {_.map(this.state.labels, (label, index) => (
                    <iframe
                      key={index}
                      src={label}
                      frameBorder="0"
                      marginHeight="0"
                      marginWidth="0"
                      style={{
                        width: '100%',
                        height: '900px',
                      }}
                    />
                  ))}
                </span>
              </Section>
            </div>
          </div>
        )}

        {this.state.order !== null && this.state.packages.length === 0 && (
          <div className="row mr-2 ml-2">
            <div className="col-xl-12">
              <Section title="">Pleace create the shipping packages.</Section>
            </div>
          </div>
        )}
      </PortletBody>
    </Portlet>
  )
}

export const mapStateToProps = state => ({
  settings: state.settings,
  taxType: state.taxType,
})

export const mapDispatchToProps = dispatch => ({
  post: function(payload, actionEvent) {
    return dispatch(Actions.post(payload, actionEvent))
  },
  storePost: function(payload, actionEvent, typeSuccess, typeError) {
    return dispatch(
      Actions.storePost(payload, actionEvent, typeSuccess, typeError),
    )
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ShippingLabel)
