import {
  React,
  Component,
  connect,
  Actions,
  ActionTypes,
  Portlet,
  PortletHeader,
  PortletBody,
  Section,
  FormGroup,
  Input,
  Label,
  Utils,
  Notification,
  // Routes,
  // Link,
  FormValidator,
  Button,
  _,
} from '../../../Imports'
import { Helmet } from 'react-helmet'

const entity = 'Payment'

const Type = {
  PAYPAL: 'PAYPAL',
  STRIPE: 'STRIPE',
  AUTHORIZE: 'AUTHORIZE',
}

class Payment extends Component {
  constructor(props) {
    super(props)

    this.validator = new FormValidator([
      {
        field: 'calculateTaxTypeForFetTax',
        method: 'isEmpty',
        validWhen: false,
        message: 'The Tax Type is required.',
      },
    ])

    this.state = {
      setting: null,
      submited: false,
      isCreate: false,
      btnLoading: false,
      activeStape: 1,
      authorize: null,
      paypal: null,
      stripe: null,
    }
  }

  UNSAFE_componentWillMount = () => {
    let storeId = Utils.search(this.props.location).store

    if (!this.props.settings.data) {
      this.props.storePost(
        { id: storeId },
        'get-store',
        ActionTypes.FETCH_SETTINGS_SUCCESS,
        ActionTypes.FETCH_SETTINGS_FAILURE,
      ).then(resp => {
        let authorize = _.get(resp.data, 'payment.authorizeNet', {})
        let paypal = _.get(resp.data, 'payment.paypal', {})
        let stripe = _.get(resp.data, 'payment.stripe', {})
  
        this.setState({
          setting: resp.data,
          authorize: authorize === null ? {} : authorize,
          paypal: paypal === null ? {} : paypal,
          stripe,
        })
      })
    } else {
      this.setState({
        setting: this.props.settings.data,
        authorize: _.get(this.props.settings.data, 'payment.authorizeNet', {}),
        paypal: _.get(this.props.settings.data, 'payment.paypal', {}),
        stripe: _.get(this.props.settings.data, 'payment.stripe', {}),
      })
    }
  }

  onChangeInput = (e, type) => {
    let method = this.getPayment(type)
    method[e.target.name] = e.target.value
    this.setPayment(type, method)
  }

  onChangeCheckbox = (e, type) => {
    let method = this.getPayment(type)
    method[e.target.name] = !_.get(method, e.target.name, false)
    this.setPayment(type, method)
  }

  getPayment = type => {
    switch (String(type)) {
    case Type.AUTHORIZE:
      return this.state.authorize
      break
    case Type.PAYPAL:
      return this.state.paypal
      break
    case Type.STRIPE:
      return this.state.stripe
      break
    }
  }

  setPayment = (type, value) => {
    switch (type) {
    case Type.AUTHORIZE:
      this.setState({ authorize: value })
      break
    case Type.PAYPAL:
      this.setState({ paypal: value })
      break
    case Type.STRIPE:
      this.setState({ stripe: value })
      break
    }
  }

  update = () => {
    if (this.state.submited === false) {
      this.setState({
        submited: true,
      })
    }

    let isValid = true //this.validator.validate(this.state.setting).isValid;

    if (isValid) {
      let setting = this.state.setting

      // Payment
      let payment = setting.payment === null ? {} : setting.payment
      payment.authorizeNet = this.state.authorize
      payment.paypal = this.state.paypal
      payment.stripe = this.state.stripe

      setting.payment = payment

      this.setState({ btnLoading: true })
      this.props.post(setting, 'update-store').then(resp => {
        this.response(resp)
      })
    }
  }

  response = resp => {
    this.setState({
      btnLoading: false,
    })

    let msn = _.replace(
      _.replace(process.env.REACT_APP_SUCCESS_ACTION, '%entity%', entity),
      '%action%',
      'Updated',
    )

    if (resp.status === 'success') {
      Notification.success(msn)
    } else {
      Notification.error(process.env.REACT_APP_ERROR_ACTION)
    }
  }

  render = () => {
    const validator = this.state.submited
      ? this.validator.validate(this.state.setting)
      : this.validator.valid()

    return (
      <Portlet>
        <Helmet>
          <title>Payment | Admin Panel - Max Ecommerce</title>
        </Helmet>
        <PortletHeader
          title={`${entity} - ${_.get(this.state.setting, 'name', '')}`}
          sticky
        >
          {/*
					<Link to={Routes.STORES} className="btn btn-secondary mr-3">
						<i className="la la-arrow-left" />
						Back
					</Link>
					*/}
          <Button
            label={'Update'}
            color="primary"
            icon={`la ${this.state.btnLoading ? '' : ' la-check'}`}
            isLoading={this.state.btnLoading}
            onClick={this.update}
          />
        </PortletHeader>
        <PortletBody>
          {this.state.setting === null && (
            <div className="row">
              <div className="col-12">
                <div>Loading...</div>
              </div>
            </div>
          )}

          {this.state.setting !== null && (
            <div className="row">
              <div className="col-xl-1" />
              <div className="col-xl-10">
                <ul
                  className="nav nav-tabs nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-brand"
                  role="tablist"
                >
                  <li
                    className="nav-item"
                    onClick={() => {
                      this.setState({
                        activeStape: 1,
                      })
                    }}
                  >
                    <a
                      className="nav-link active"
                      data-toggle="tab"
                      href="#kt_tabs_19_15c9c30a225194"
                      role="tab"
                      aria-selected="true"
                    >
                      {/*
												<span className="nav-link-icon">
													<i className="flaticon2-graphic" />
												</span>
												*/}

                      <span className="nav-link-title">Authorize Net</span>
                    </a>
                  </li>
                  <li
                    className="nav-item"
                    onClick={() => {
                      this.setState({
                        activeStape: 2,
                      })
                    }}
                  >
                    <a
                      className="nav-link"
                      data-toggle="tab"
                      href="#kt_tabs_19_25c9c30a225194"
                      role="tab"
                      aria-selected="false"
                    >
                      {/* 
												<span className="nav-link-icon">
													<i className="flaticon2-position" />
												</span>
												*/}
                      <span className="nav-link-title">PayPal</span>
                    </a>
                  </li>
                  <li
                    className="nav-item"
                    onClick={() => {
                      this.setState({
                        activeStape: 3,
                      })
                    }}
                  >
                    <a
                      className="nav-link"
                      data-toggle="tab"
                      href="#kt_tabs_19_25c9c30a225194"
                      role="tab"
                      aria-selected="false"
                    >
                      <span className="nav-link-title">Stripe</span>
                    </a>
                  </li>
                </ul>

                {this.state.activeStape === 1 && (
                  <Section title="Settings" size="lg" className="mt-5">
                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">Active</Label>
                      <div className="col-9">
                        <span className="k-switch k-switch--md k-switch--icon">
                          <label>
                            <Input
                              disabled={
                                !this.state.authorize?.active &&
                                this.state.stripe &&
                                this.state.stripe?.active
                              }
                              checked={
                                this.state.authorize?.active ? 'checked' : ''
                              }
                              onChange={e => {
                                this.onChangeCheckbox(e, Type.AUTHORIZE)
                              }}
                              name="active"
                              type="checkbox"
                            />
                            <span />
                          </label>
                        </span>
                      </div>
                    </FormGroup>
                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">
                        Api Login Id
                      </Label>
                      <div className="col-9">
                        <Input
                          value={this.state.authorize?.apiLoginId}
                          onChange={e => {
                            this.onChangeInput(e, Type.AUTHORIZE)
                          }}
                          name="apiLoginId"
                        />
                      </div>
                    </FormGroup>
                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">
                        Transaction Key
                      </Label>
                      <div className="col-9">
                        <Input
                          value={this.state.authorize.transactionKey}
                          onChange={e => {
                            this.onChangeInput(e, Type.AUTHORIZE)
                          }}
                          name="transactionKey"
                        />
                      </div>
                    </FormGroup>
                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">Sandbox</Label>
                      <div className="col-9">
                        <span className="k-switch k-switch--md k-switch--icon">
                          <label>
                            <Input
                              checked={
                                this.state.authorize.sandbox ? 'checked' : ''
                              }
                              onChange={e => {
                                this.onChangeCheckbox(e, Type.AUTHORIZE)
                              }}
                              name="sandbox"
                              type="checkbox"
                            />
                            <span />
                          </label>
                        </span>
                      </div>
                    </FormGroup>
                  </Section>
                )}

                {this.state.activeStape === 2 && (
                  <Section title="Settings" size="lg" className="mt-5">
                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">Active</Label>
                      <div className="col-9">
                        <span className="k-switch k-switch--md k-switch--icon">
                          <label>
                            <Input
                              checked={
                                this.state.paypal?.active ? 'checked' : ''
                              }
                              onChange={e => {
                                this.onChangeCheckbox(e, Type.PAYPAL)
                              }}
                              name="active"
                              type="checkbox"
                            />
                            <span />
                          </label>
                        </span>
                      </div>
                    </FormGroup>
                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">
                        Credential Id
                      </Label>
                      <div className="col-9">
                        <Input
                          value={this.state.paypal.credentialId}
                          onChange={e => {
                            this.onChangeInput(e, Type.PAYPAL)
                          }}
                          name="credentialId"
                        />
                      </div>
                    </FormGroup>
                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">
                        Credential Secret
                      </Label>
                      <div className="col-9">
                        <Input
                          value={this.state.paypal.credentialSecret}
                          onChange={e => {
                            this.onChangeInput(e, Type.PAYPAL)
                          }}
                          name="credentialSecret"
                        />
                      </div>
                    </FormGroup>
                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">Sandbox</Label>
                      <div className="col-9">
                        <span className="k-switch k-switch--md k-switch--icon">
                          <label>
                            <Input
                              checked={
                                this.state.paypal.sandbox ? 'checked' : ''
                              }
                              onChange={e => {
                                this.onChangeCheckbox(e, Type.PAYPAL)
                              }}
                              name="sandbox"
                              type="checkbox"
                            />
                            <span />
                          </label>
                        </span>
                      </div>
                    </FormGroup>
                  </Section>
                )}

                {this.state.activeStape === 3 && (
                  <Section title="Settings" size="lg" className="mt-5">
                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">Active</Label>
                      <div className="col-9">
                        <span className="k-switch k-switch--md k-switch--icon">
                          <label>
                            <Input
                              disabled={
                                !this.state.stripe?.active &&
                                this.state.authorize &&
                                this.state.authorize?.active
                              }
                              checked={
                                this.state.stripe?.active ? 'checked' : ''
                              }
                              onChange={e => {
                                this.onChangeCheckbox(e, Type.STRIPE)
                              }}
                              name="active"
                              type="checkbox"
                            />
                            <span />
                          </label>
                        </span>
                      </div>
                    </FormGroup>
                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">Key</Label>
                      <div className="col-9">
                        <Input
                          value={this.state.stripe.secretKey}
                          onChange={e => {
                            this.onChangeInput(e, Type.STRIPE)
                          }}
                          name="secretKey"
                        />
                      </div>
                    </FormGroup>
                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">Sandbox</Label>
                      <div className="col-9">
                        <span className="k-switch k-switch--md k-switch--icon">
                          <label>
                            <Input
                              checked={
                                this.state.stripe.sandbox ? 'checked' : ''
                              }
                              onChange={e => {
                                this.onChangeCheckbox(e, Type.STRIPE)
                              }}
                              name="sandbox"
                              type="checkbox"
                            />
                            <span />
                          </label>
                        </span>
                      </div>
                    </FormGroup>
                  </Section>
                )}
              </div>
              <div className="col-xl-1" />
            </div>
          )}
        </PortletBody>
      </Portlet>
    )
  }
}

export const mapStateToProps = state => ({
  settings: state.settings,
  taxType: state.taxType,
})

export const mapDispatchToProps = dispatch => ({
  post: function(payload, actionEvent) {
    return dispatch(Actions.post(payload, actionEvent))
  },
  storeGet: function(actionEvent, typeSuccess, typeError) {
    return dispatch(Actions.storeGet(actionEvent, typeSuccess, typeError))
  },
  storePost: function(payload, actionEvent, typeSuccess, typeError) {
    return dispatch(
      Actions.storePost(payload, actionEvent, typeSuccess, typeError),
    )
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Payment)
