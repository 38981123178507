import {
  React,
  Component,
  connect,
  Actions,
  ActionTypes,
  Portlet,
  PortletHeader,
  PortletBody,
  Section,
  FormGroup,
  Input,
  Label,
  Utils,
  Notification,
  // Routes,
  // Link,
  FormValidator,
  Separator,
  Button,
  _,
} from '../../../Imports'
import { Helmet } from 'react-helmet'

const entity = 'Integration'

class Integration extends Component {
  constructor(props) {
    super(props)

    this.validator = new FormValidator([
      {
        field: 'websiteUrl',
        method: 'isEmpty',
        validWhen: false,
        message: 'The Store Url is required.',
      },
    ])

    this.state = {
      setting: null,
      submited: false,
      isCreate: false,
      btnLoading: false,
    }
  }

  UNSAFE_componentWillMount = () => {
    let storeId = Utils.search(this.props.location).store
  
    if (!this.props.settings.data) {
      this.props.storePost(
        { id: storeId },
        'get-store',
        ActionTypes.FETCH_SETTINGS_SUCCESS,
        ActionTypes.FETCH_SETTINGS_FAILURE,
      ).then(resp => {
        this.setState({
          setting: resp.data,
        })
      })
    } else {
      this.setState({
        setting: this.props.settings.data,
      })
    }
  }

  onChangeInput = e => {
    let setting = this.state.setting
    setting[e.target.name] = e.target.value

    this.setState({ setting })
  }

  onChangeCheckbox = e => {
    let setting = this.state.setting
    setting[e.target.name] = !_.get(setting, e.target.name, false)

    this.setState({ setting })
  }

  update = () => {
    this.setState({
      submited: !this.state.submited,
    })

    let isValid = this.validator.validate(this.state.setting).isValid

    if (isValid) {
      this.setState({ btnLoading: true })
      this.props.post(this.state.setting, 'update-store').then(resp => {
        this.response(resp)
      })
    }
  }

  response = resp => {
    this.setState({
      btnLoading: false,
    })

    let msn = _.replace(
      _.replace(process.env.REACT_APP_SUCCESS_ACTION, '%entity%', entity),
      '%action%',
      'Updated',
    )

    if (resp.status === 'success') {
      Notification.success(msn)
    } else {
      Notification.error(process.env.REACT_APP_ERROR_ACTION)
    }
  }

  render = () => (
    // const validator = this.state.submited
    //	? this.validator.validate(this.state.setting)
    //	: this.validator.valid();

    <Portlet>
      <Helmet>
        <title>Integration | Admin Panel - Max Ecommerce</title>
      </Helmet>
      <PortletHeader
        title={`Integration - ${_.get(this.state.setting, 'name', '')}`}
        sticky
      >
        {/*
					<Link to={Routes.STORES} className="btn btn-secondary mr-3">
						<i className="la la-arrow-left" />
						Back
					</Link>
					*/}
        <Button
          label={'Update'}
          color="primary"
          icon={`la ${this.state.btnLoading ? '' : ' la-check'}`}
          isLoading={this.state.btnLoading}
          onClick={this.update}
        />
      </PortletHeader>
      <PortletBody>
        {this.state.setting === null && (
          <div className="row">
            <div className="col-12">
              <div>Loading...</div>
            </div>
          </div>
        )}

        {this.state.setting !== null && (
          <div className="row">
            <div className="col-xl-1" />
            <div className="col-xl-10">
              <Section title="Goolge">
                <FormGroup className="row">
                  <Label className="col-3 col-form-label">GTM active</Label>
                  <div className="col-9">
                    <span className="k-switch k-switch--md k-switch--icon">
                      <label>
                        <Input
                          checked={
                            this.state.setting.gtmActive ? 'checked' : ''
                          }
                          onChange={this.onChangeCheckbox}
                          name="gtmActive"
                          type="checkbox"
                        />
                        <span />
                      </label>
                    </span>
                  </div>
                </FormGroup>
                <FormGroup className="row">
                  <Label className="col-3 col-form-label">GTM</Label>
                  <div className="col-9">
                    <Input
                      value={this.state.setting.gtmId}
                      onChange={this.onChangeInput}
                      name="gtmId"
                      placeholder="Enter GTM ID"
                    />
                  </div>
                </FormGroup>
              </Section>
              <Separator size="lg" border={'dashed'} />
              <Section title="Others" size="lg">
                <FormGroup className="row">
                  <Label className="col-3 col-form-label">Other JS</Label>
                  <div className="col-9">
                    <Input
                      value={this.state.setting.threeRdPartyJs}
                      onChange={this.onChangeInput}
                      name="threeRdPartyJs"
                      type="textarea"
                      placeholder="Other JavaScripts libs"
                    />
                  </div>
                </FormGroup>
              </Section>
            </div>
            <div className="col-xl-1" />
          </div>
        )}
      </PortletBody>
    </Portlet>
  )
}

export const mapStateToProps = state => ({
  settings: state.settings,
})

export const mapDispatchToProps = dispatch => ({
  post: function(payload, actionEvent) {
    return dispatch(Actions.post(payload, actionEvent))
  },
  storeGet: function(actionEvent, typeSuccess, typeError) {
    return dispatch(Actions.storeGet(actionEvent, typeSuccess, typeError))
  },
  storePost: function(payload, actionEvent, typeSuccess, typeError) {
    return dispatch(
      Actions.storePost(payload, actionEvent, typeSuccess, typeError),
    )
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Integration)
