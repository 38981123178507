import {
  React,
  Component,
  connect,
  Actions,
  Portlet,
  PortletHeader,
  PortletBody,
  BootstrapTable,
  paginationFactory,
  filterFactory,
  Utils,
  FILTER_TYPES,
  textFilter,
  Filters,
  Routes,
  Link,
  Button,
  classNames,
  ActionTypes,
  moment,
  _,
} from '../../../Imports'
import { Auth } from 'aws-amplify'
import { Helmet } from 'react-helmet'

class AbandonedOrders extends Component {
  constructor(props) {
    super(props)

    this.state = {
      orders: [],
      isOpen: false,
      btnLoading: false,
      id: null,
      filters: [
        {
          id: 1,
          name: 'Status',
          filter: null,
          value: '',
          type: FILTER_TYPES.TEXT,
        },
      ],
      isFilterOpen: false,
      refresh: true,
      userGroup: '',
      userId: '',
    }

    this.storeId = Utils.search(this.props.location).store
  }

  UNSAFE_componentWillMount = () => {
    Auth.currentAuthenticatedUser()
      .then(user => {
        this.setState({
          userGroup: user.signInUserSession.accessToken.payload['cognito:groups'][0],
          userId: user.signInUserSession.accessToken.payload.sub,
        })
      })
      .catch(err => console.log(err))

    if (this.props.settings.data === null) {
      this.props.storePost(
        { id: this.storeId },
        'get-store',
        ActionTypes.FETCH_SETTINGS_SUCCESS,
        ActionTypes.FETCH_SETTINGS_FAILURE,
      )
    }
    this.onRefresh()
  }

  onRefresh = () => {
    this.setState({ orders: [], refresh: true })

    this.props
      .storePost(
        {
          storeId: this.storeId,
          status: ['ABANDONED_SHOPPING_CART', 'REQUESTED', 'PUBLISHED'],
        },
        'search-order',
        ActionTypes.FETCH_ORDERS_SUCCESS,
        ActionTypes.FETCH_ORDERS_FAILURE,
      )
      .then(() => {
        this.setState({
          orders: this.props.orders.data,
          refresh: false,
        })
      })
  }

  toggleFilter = () => {
    this.setState({
      isFilterOpen: !this.state.isFilterOpen,
    })
  }

  clearFilter = () => {
    _.forEach(this.state.filters, filter => {
      this.removeFilter(filter)
    })
  }

  removeFilter = filter => {
    let filters = this.state.filters
    filter.filter('')
    filter.value = ''
    this.setState({ filters })
  }

  setFilterState = ({ dataSize }) => {
    console.log('Table size changed', dataSize)
    let filters = this.state.filters
    this.setState({ filters })
  }

  getFilteredOrders = () => {
    const { userGroup, userId } = this.state
    const { SALES } = Utils.GROUPS
    if (userGroup === SALES) {
      return this.state.orders?.filter(({ primaryRepId, secondaryRepId }) => (
        primaryRepId === userId || secondaryRepId === userId
      ))
    }
    return this.state.orders
  }

  render = () => {
    const orders = this.getFilteredOrders()
    const settings = _.get(this.props.settings, 'data', {})
    const timeZone = (settings && settings.timeZone) || 'America/New_York'

    const headerFormatter = (
      column,
      colIndex,
      { sortElement, filterElement },
    ) => (
      <div className="d-flex justify-content-end flex-column">
        <div className="d-flex justify-content-between">
          {column.text}
          {sortElement}
        </div>
        {this.state.isFilterOpen && (
          <div
            style={{
              margin: '0 -.75rem',
              marginTop: '.75rem',
              padding: '1rem .75rem .25rem',
              borderTop: '2px solid #f7f8fa',
            }}
          >
            {filterElement}
          </div>
        )}
      </div>
    )

    const rowEvents = {
      onClick: (e, column, columnIndex, row) => {
        let url = '/order/detail/' + row.id + '?store=' + this.storeId
        this.props.history.push(url)
      },
    }

    const columns = [
      {
        dataField: 'id',
        hidden: true,
      },
      // {
      //     dataField: "invoiceNumber",
      //     text: "Invoice",
      //     headerFormatter,
      //     headerClasses: "align-top",
      //     events: rowEvents,
      //     sort: true,
      //     headerStyle: (colum, colIndex) => {
      //         return { width: "100px" };
      //     }
      // },

      // {
      //     dataField: "cardType",
      //     text: "Source",
      //     sort: true,
      //     headerFormatter,
      //     headerClasses: "align-top",
      //     events: rowEvents,
      //     headerStyle: (colum, colIndex) => {
      //         return { width: "100px" };
      //     },
      //     formatter: (cell, row, rowIndex, formatExtraData) => {
      //         return <span>{"Other"}</span>;
      //     }
      // },
      {
        dataField: 'quoteReference',
        text: '#',
        sort: true,
        headerClasses: 'align-top',
        headerStyle: () => ({ width: '110px' }),
        formatter: (cell, row) => (
          <span>{row.quoteReference}</span>
        ),
      },
      {
        dataField: 'billToFirstName',
        text: 'Customer Name',
        sort: true,
        headerFormatter,
        headerClasses: 'align-top',
        events: rowEvents,
        filter: textFilter({
          defaultValue: this.state.filters[0].value,
          getFilter: filter => {
            this.state.filters[0].filter = filter
          },
          onFilter: filterVal => {
            let filters = this.state.filters
            filters[0].value = filterVal

            // this.setState({ filters });
          },
        }),
        formatter: (cell, row) => {
          const fullname = `${row.billToFirstName || ''}${
            row.billToFirstName ? ' ' : ''
          }${row.billToLastName || ''}`

          return <span>{_.startCase(_.lowerCase(fullname)) || 'N/A'}</span>
        },
      },
      {
        dataField: 'shippToAddress',
        text: 'Shipping Address',
        sort: true,
        headerFormatter,
        headerClasses: 'align-top',
        events: rowEvents,
        formatter: (cell, row) => (
          <span>
            {_.startCase(
              _.lowerCase(
                `${row.shippToAddress} ${row.shippToCity}, ${row.shippToState} ${row.shippToZipcode}`,
              ),
            )}
          </span>
        ),
      },
      {
        dataField: 'total',
        text: 'Total',
        sort: true,
        align: 'right',
        headerFormatter,
        headerClasses: 'align-top',
        events: rowEvents,
        headerStyle: () => ({ width: '100px' }),
        formatter: (cell, row) => {
          const subtotal = Utils.baseSubtotal(row.products)
          const shipping = row.shippingPrice
          const tax = Object.values(row.tax).reduce((a, b) => a + b, 0)

          const total = subtotal + tax + shipping

          return <strong>${total.toFixed(2)}</strong>
        },
      },
      {
        dataField: 'createdDate',
        text: 'Date',
        headerFormatter,
        headerClasses: 'align-top',
        events: rowEvents,
        sort: true,
        headerStyle: () => ({ width: '150px' }),
        formatter: (cell) => (
          <span>
            {moment(cell)?.tz(timeZone.value)?.format('MM/DD/YYYY HH:mm')}
          </span>
        ),
      },
      {
        dataField: 'actions',
        text: 'Actions',
        headerAlign: 'right',
        headerFormatter,
        headerClasses: 'align-top',
        align: 'right',
        headerStyle: () => ({ width: '100px' }),
        formatter: (cell, row) => {
          let url = _.replace(Routes.ORDER_DETAIL, ':id', row.id)
          url = url + '?store=' + this.storeId
          return (
            <div>
              <Link
                to={url}
                className="btn btn-outline-secondary btn-sm btn-icon"
                title="Edit"
              >
                <i className="la la-search" />
              </Link>
            </div>
          )
        },
      },
    ]

    const options = {
      showTotal: true,
      sizePerPageList: [
        {
          text: '20',
          value: 20,
        },
        {
          text: '100',
          value: 100,
        },
        {
          text: '200',
          value: 200,
        },
        {
          text: '300',
          value: 300,
        },
        {
          text: 'All',
          value: orders.length,
        },
      ],
    }

    return (
      <Portlet>
        <Helmet>
          <title>Abandoned Orders | Admin Panel - Max Ecommerce</title>
        </Helmet>
        <PortletHeader sticky title="Abandoned Orders List">
          <Button
            label="Refresh"
            icon="la la-refresh"
            className="mr-3"
            color="secondary"
            onClick={this.onRefresh}
          />
          <Button
            label="Filters"
            icon="la la-filter"
            color="secondary"
            onClick={this.toggleFilter}
            className={classNames({
              active: this.state.isFilterOpen,
            })}
          />
        </PortletHeader>
        <PortletBody>
          <Filters
            removeFilter={this.removeFilter}
            clearAll={this.clearFilter}
            filters={this.state.filters}
          />
          {!!orders.length && (
            <BootstrapTable
              pagination={paginationFactory(options)}
              filter={filterFactory()}
              bootstrap4
              data={orders}
              columns={columns}
              wrapperClasses="mx-n3"
              keyField="id"
              hover
              noDataIndication={() => (
                <div className="mt-2">
                  {this.state.refresh ? 'Loading...' : 'No Abandoned Orders'}
                </div>
              )}
              onDataSizeChange={this.setFilterState}
            />
          )}
        </PortletBody>
      </Portlet>
    )
  }
}

export const mapStateToProps = state => ({
  orders: state.orders,
  settings: state.settings,
  currentStore: state.currentStore,
})

export const mapDispatchToProps = dispatch => ({
  storePost: function(payload, actionEvent, typeSuccess, typeError) {
    return dispatch(
      Actions.storePost(payload, actionEvent, typeSuccess, typeError),
    )
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(AbandonedOrders)
