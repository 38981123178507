import { React, Component, Routes, Route, connect } from '../../../Imports'
import ShipmentAside from './ShipmentAside'
import Package from './Package'
import Carrier from './Carrier'
import PackageSlips from './PackageSlips'
import ShippingLabel from './ShippingLabel'

class Shipment extends Component {
  render = () => {
    let pso =
      this.props.settings.data &&
      this.props.settings.data.shipping.fulfilmentCenter.packageslips
    return (
      <div className="row">
        <div className="col-lg-2 col-xl-2">
          <ShipmentAside packageslips={pso} />
        </div>
        <div className="col-lg-10 col-xl-10">
          <div className="tab-pane">
            <Route path={Routes.SHIPPING_PACKAGE} component={Package} />
            <Route path={Routes.SHIPPING_CARRIER} component={Carrier} />
            {pso && (
              <Route path={Routes.PACKAGE_SLIPS} component={PackageSlips} />
            )}
            <Route path={Routes.SHIPPING_LABEL} component={ShippingLabel} />
          </div>
        </div>
      </div>
    )
  }
}

export const mapStateToProps = state => ({
  settings: state.settings,
})

export default connect(mapStateToProps)(Shipment)
