import React, { Component } from 'react'
import classNames from 'classnames'

class Separator extends Component {
  render = () => (
    <div
      className={classNames(
        'k-separator',
        {
          [`k-separator--border-${this.props.border}`]: this.props.border,
        },
        {
          [`k-separator--space-${this.props.size}`]: this.props.size,
        },
        { 'k-separator--portlet-fit': this.props.portletFit },
      )}
    />
  )
}

export default Separator
