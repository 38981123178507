import {
  React,
  Component,
  connect,
  Actions,
  Portlet,
  PortletHeader,
  PortletBody,
  Section,
  // BootstrapTable,
  Select,
  FormGroup,
  Input,
  Label,
  Utils,
  Notification,
  Routes,
  Link,
  FormValidator,
  Button,
  Alert,
  ActionTypes,
  _,
} from '../../../Imports'
import { Helmet } from 'react-helmet'

const entity = 'Carrier'

const shippingTypeOptions = [
  { label: 'USPS', value: 'USPS' },
  { label: 'FEDEX', value: 'FEDEX' },
  //{ label: "Free Shipping", value: "FREE" },
  //{ label: "Flat Rate", value: "FLAT" }
]

const oneRateOptions = [
  { value: 'NONE', label: 'None' },
  { value: 'ONE_RATE', label: 'One Rate Shipment' },
]

class Carrier extends Component {
  constructor(props) {
    super(props)

    this.validator = new FormValidator([
      {
        field: 'calculateTaxTypeForTax',
        method: 'isEmpty',
        validWhen: false,
        message: 'The Tax Type is required.',
      },
      {
        field: 'taxAmountForTax',
        method: 'isEmpty',
        validWhen: false,
        message: 'The Tax Amount is required.',
      },
    ])

    this.state = {
      order: null,
      setting: null,
      submited: false,
      isCreate: false,
      btnLoading: false,
      packages: [],

      serverError: '',

      rates: [],
      refresh: false,
      selection: [],
      oneRateOption: oneRateOptions[0].value,

      packageOptions: [],
      labels: null,
    }

    let search = Utils.search(this.props.location)

    this.storeId = search.store
    this.id = search.id
  }

  UNSAFE_componentWillMount = async () => {
    if (this.props.settings.data === null) {
      await this.props.storePost(
        { id: this.storeId },
        'get-store',
        ActionTypes.FETCH_SETTINGS_SUCCESS,
        ActionTypes.FETCH_SETTINGS_FAILURE,
      )
    }

    await this.props
      .post(
        {
          id: this.id,
          storeId: this.storeId,
        },
        'get-order',
      )
      .then(resp => {
        let order = resp.data
        let pa = _.get(order, 'shippingPackages', [])

        const shippingType = _.get(order, 'shippingType', false)

        let packageOptions = _.concat(
          this.props.fedexPackageType,
          this.props.uspsPackageType,
        )
        // Add Your Package Option
        packageOptions.push({
          value: {
            id: 'CUSTOM1',
            pk: 'YOUR_PACKAGING',
            length: 0,
            width: 0,
            height: 0,
          },
          label: 'Your Packaging',
        })

        this.setState(
          {
            packageOptions,
            order,
            packages: pa === null ? [] : pa,
          },
          () => {
            if (!order.isShippingCompleted) {
              if (shippingType) {
                this.getRate(shippingType)
              }
            }
          },
        )
      })
  }

  getRate = value => {
    let action = ''

    switch (value) {
    case 'USPS':
      action = 'usps-rate'

      break
    case 'FEDEX':
      action = 'fedex-rate'

      break
    }

    if (action) {
      this.setState({
        refresh: true,
      })

      let oneRateOption = this.state.oneRateOption

      this.props
        .post(
          {
            storeId: this.storeId,
            orderId: this.state.order.id,
            oneRateOption: oneRateOption == 'NONE' ? '' : oneRateOption,
          },
          action,
        )
        .then(resp => {
          this.setState({
            serverError: resp.data.errors ? resp.data.errors : '',
            refresh: false,
            rates: resp.data.rates ? resp.data.rates : [],
          })
        })
    }
  }

  getAttributesFromPackage = (options, p) => {
    let _package = _.find(options, v => {
      const type = typeof p.type == 'string' ? p.type : p.type.pk

      if (type == 'YOUR_PACKAGING') {
        return v.value.pk === 'YOUR_PACKAGING'
      }

      return (
        v.value.pk == type &&
        v.value.length == p.length &&
        v.value.width == p.width &&
        v.value.height == p.height
      )
    })

    if (_package && _package.value.pk == 'YOUR_PACKAGING') {
      _package.value.length = p.length
      _package.value.width = p.width
      _package.value.height = p.height
    }

    return _package
  }

  closeAlert = () => {
    this.setState({
      serverError: '',
    })
  }

  onChangeInput = e => {
    let order = this.state.order
    order[e.target.name] = e.target.value

    this.setState({ order })
  }

  onChangeSelect = (e, name) => {
    let order = this.state.order
    order[name] = e.value

    if (name == 'shippingType') {
      this.getRate(e.value)
    }

    this.setState({ order })
  }

  onChangeCheckbox = e => {
    let order = this.state.order
    order[e.target.name] = !_.get(order, e.target.name, false)

    this.setState({ order })
  }

  getLabel = async value => {
    let labels = []
    let resp = await this.props.post(
      {
        storeId: this.storeId,
        orderId: this.state.order.id,
      },
      'get-label',
    )

    labels = _.map(
      resp.data,
      label => process.env.REACT_APP_LABEL_URL + label,
    )

    this.setState({ labels })
  }

  generateLabel = async value => {
    if (_.get(this.state.order, 'isShippingCompleted', false)) {
      this.setState({ btnLoading: true })
      this.goNext()
      this.setState({ btnLoading: false })
    } else {
      this.setState({
        btnLoading: true,
      })

      // Update order
      let order = this.state.order
      await this.props.post(order, 'update-order')

      let valid = true
      _.forEach(this.state.order.shippingPackages, p => {
        if (!p.service) {
          valid = false
        }
      })

      if (valid) {
        let action = ''

        value = this.state.order.shippingType

        switch (value) {
        case 'USPS':
          action = 'usps-label'

          break
        case 'FEDEX':
          action = 'fedex-label'

          break
        }

        let oneRateOption = this.state.oneRateOption

        if (action) {
          this.props
            .post(
              {
                storeId: this.storeId,
                orderId: this.state.order.id,
                oneRateOption: oneRateOption == 'NONE' ? '' : oneRateOption,
              },
              action,
            )
            .then(async resp => {
              if (resp.status == 'error') {
                this.setState({
                  serverError: resp.data,
                  btnLoading: false,
                })
              } else {
                await this.getLabel()
                this.goNext()

                this.setState({
                  serverError: '',
                  btnLoading: false,
                })
              }
            })
        }
      } else {
        this.setState({
          serverError:
            'Please select the service for each of the package below.',
        })
      }
    }
  }

  goNext = () => {
    const items = _.filter(
      Routes.SHIPMENT_OPTIONS,
      i =>
        i.url != Routes.PACKAGE_SLIPS ||
        this.props.settings.data.shipping.fulfilmentCenter.packageslips,
    )

    const index = _.findIndex(
      items,
      i => i.url == this.props.location.pathname,
    )

    if (items.length > index + 1) {
      this.props.history.push(
        `${items[index + 1].url}?id=${this.id}&store=${this.storeId}`,
      )
    }
  }

  response = resp => {
    this.setState({
      btnLoading: false,
      serverError: '',
    })

    let msn = _.replace(
      _.replace(process.env.REACT_APP_SUCCESS_ACTION, '%entity%', entity),
      '%action%',
      'Updated',
    )

    if (resp.status === 'success') {
      Notification.success(msn)
    } else {
      Notification.error(process.env.REACT_APP_ERROR_ACTION)
    }
  }

  onSelectService = (e, r, packageId) => {
    let order = this.state.order

    _.forEach(order.shippingPackages, p => {
      p.service = r.service
      p.price = r.price
    })

    this.setState({
      order,
      // selection: [r]
    })
  }

  render = () => {
    //const validator = this.state.submited
    //	? this.validator.validate(this.state.setting)
    //	: this.validator.valid();

    const selectRow = {
      mode: 'radio',
      classes: 'table-secondary',
      clickToSelect: true,
      onSelect: this.onSelectRow,
      selected: _.without(
        [_.get(this.state.selection, [0, 'name'], null)],
        null,
      ),
      selectionRenderer: ({ mode, ...rest }) => (
        <label className="k-radio k-radio--brand">
          <Input type={mode} {...rest} name="rate-radio" /> <span />
        </label>
      ),
    }

    let columns = [
      {
        dataField: 'name',
        text: 'Name',
        sort: true,
        hidden: true,
      },
      {
        dataField: 'price',
        text: 'Price',
        sort: true,
        formatter: (cell, row, rowIndex, formatExtraData) =>
          `$${cell.toFixed(2)}`,
      },
      {
        dataField: 'service',
        text: 'Service',
        sort: true,
      },
    ]

    return (
      <Portlet>
        <Helmet>
          <title>Carrier | Admin Panel - Max Ecommerce</title>
        </Helmet>
        <PortletHeader
          title={`Carrier order #${_.get(
            this.state.order,
            'invoiceNumber',
            '',
          )}`}
          sticky
        >
          <Link
            to={`/shipment/package?id=${this.id}&store=${this.storeId}`}
            className="btn btn-secondary mr-3"
          >
            <i className="la la-arrow-left" />
            Back
          </Link>
          <Link
            to={`/order/detail/${this.id}?store=${this.storeId}`}
            className="btn btn-secondary mr-3"
          >
            <i className="la la-arrow-left" />
            Back to Order
          </Link>

          {this.state.order !== null && this.state.packages.length > 0 && (
            <Button
              label={
                _.get(this.state.order, 'isShippingCompleted', false)
                  ? 'Next'
                  : 'Create Shipment'
              }
              color="primary"
              icon={`la ${this.state.btnLoading ? '' : ' la-arrow-right'}`}
              isLoading={this.state.btnLoading}
              onClick={this.generateLabel}
            />
          )}
        </PortletHeader>
        <PortletBody>
          {this.state.order === null && (
            <div className="row">
              <div className="col-12">
                <div>Loading...</div>
              </div>
            </div>
          )}

          {this.state.order !== null && this.state.packages.length > 0 && (
            <div className="row mr-2 ml-2">
              <div className="col-md-12">
                <Section title="Information" size="lg">
                  <Alert
                    label={_.map(this.state.serverError, e => (
                      <React.Fragment key={e}>
                        {e}
                        <br />
                      </React.Fragment>
                    ))}
                    isOpen={!!this.state.serverError}
                    type={'danger'}
                    close={this.closeAlert}
                  />

                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">
                      Select Carrier
                    </Label>
                    <div className="col-4">
                      <Select
                        options={shippingTypeOptions}
                        value={Utils.object(
                          shippingTypeOptions,
                          this.state.order.shippingType,
                        )}
                        placeholder="Select Provider"
                        onChange={e => this.onChangeSelect(e, 'shippingType')}
                        isSearchable={false}
                        // isDisabled={
                        //     !_.find(
                        //         this.state.rates,
                        //         r =>
                        //             r.type ==
                        //             "YOUR_PACKAGING"
                        //     )
                        // }
                      />
                    </div>
                  </FormGroup>

                  {!this.state.order.isShippingCompleted && (
                    <FormGroup className="row">
                      <Label className="col-3 col-form-label">
                        Special Service
                      </Label>
                      <div className="col-4">
                        <Select
                          options={oneRateOptions}
                          value={Utils.object(
                            oneRateOptions,
                            this.state.oneRateOption,
                          )}
                          placeholder="Select Option"
                          onChange={e => {
                            this.setState(
                              {
                                oneRateOption: e.value,
                              },
                              () => {
                                this.getRate(this.state.order.shippingType)
                              },
                            )
                          }}
                          isSearchable={false}
                        />
                      </div>
                    </FormGroup>
                  )}

                  {this.state.refresh ? (
                    <div className="form-group row">
                      <div className="col-3 content-placeholder">
                        <div className="cp-w-sm cp-h-sm mb-3" />
                        <div className="cp-w-md cp-h-sm" />
                      </div>
                      <div className="col-4 content-placeholder">
                        <div className="cp-w-md cp-h-sm mb-3" />
                        <div className="cp-w-md cp-h-sm mb-3" />
                        <div className="cp-w-lg cp-h-sm" />
                      </div>
                    </div>
                  ) : this.state.order.isShippingCompleted ? (
                    _.map(this.state.packages, (p, i) => (
                      <FormGroup className="row">
                        <Label className="col-3 col-form-label">
                          Service for Package #{i + 1}:<br />
                          {_.get(
                            this.getAttributesFromPackage(
                              this.state.packageOptions,
                              p,
                            ),
                            'label',
                            '',
                          )}
                        </Label>
                        <div className="col-4">
                          <label
                            className={
                              'k-radio k-radio-brand k-radio--disabled d-block'
                            }
                          >
                            <Input
                              type="radio"
                              name={`rate-radio-${p.id}`}
                              defaultChecked={true}
                              disabled
                            />
                            <span />
                            <strong>${(p.price || 0).toFixed(2)}</strong> -{' '}
                            {_.startCase(_.lowerCase(p.service))}
                          </label>
                        </div>
                      </FormGroup>
                    ))
                  ) : (
                    _.map(this.state.rates, (p, i) => (
                      <FormGroup className="row">
                        <Label className="col-3 col-form-label">
                          Select Service for Package #{i + 1}:<br />
                          {_.get(
                            this.getAttributesFromPackage(
                              this.state.packageOptions,
                              p,
                            ),
                            'label',
                            '',
                          )}
                          {/* (<strong>L: </strong>
                                                            {p.length} x{" "}
                                                            <strong>W: </strong>
                                                            {p.width} x{" "}
                                                            <strong>H: </strong>
                                                            {p.height}) */}
                        </Label>
                        <div className="col-4">
                          {_.map(_.sortBy(p.rates, 'price'), r => (
                            <label
                              className="k-radio k-radio--brand d-block"
                              key={r.service}
                            >
                              <Input
                                type="radio"
                                name={`rate-radio-${p.id}`}
                                defaultChecked={p.service == r.service}
                                onChange={e =>
                                  this.onSelectService(e, r, p.id)
                                }
                              />
                              <span />
                              <strong>
                                ${(r.price || 0).toFixed(2)}
                              </strong> - {_.startCase(_.lowerCase(r.service))}
                            </label>
                          ))}
                        </div>
                      </FormGroup>
                    ))
                  )}
                </Section>
                {/* {this.state.data.length && */}
                {/* <Section title="Rates">
                                    <BootstrapTable
                                        bootstrap4
                                        data={this.state.rates}
                                        columns={columns}
                                        keyField="name"
                                        hover
                                        noDataIndication={() => (
                                            <div className="mt-2">
                                                {this.state.refresh
                                                    ? "Loading..."
                                                    : `No Rates`}
                                            </div>
                                        )}
                                        selectRow={selectRow}
                                    />
                                </Section> */}
                {/* } */}
              </div>
            </div>
          )}

          {this.state.order !== null && this.state.packages.length === 0 && (
            <div className="row mr-2 ml-2">
              <div className="col-xl-12">
                <Section title="">Pleace create the shipping packages.</Section>
              </div>
            </div>
          )}
        </PortletBody>
      </Portlet>
    )
  }
}

export const mapStateToProps = state => ({
  settings: state.settings,
  taxType: state.taxType,
  fedexPackageType: state.fedexPackageType,
  uspsPackageType: state.uspsPackageType,
})

export const mapDispatchToProps = dispatch => ({
  post: function(payload, actionEvent) {
    return dispatch(Actions.post(payload, actionEvent))
  },
  storePost: function(payload, actionEvent, typeSuccess, typeError) {
    return dispatch(
      Actions.storePost(payload, actionEvent, typeSuccess, typeError),
    )
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Carrier)
