// PDF
import pdfMake from 'pdfmake/build/pdfmake'
import vfsFonts from 'pdfmake/build/vfs_fonts'
import moment from 'moment-timezone'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import _ from 'lodash'

export const GROUPS = {
  SUPER_ADMIN: 'SuperAdmin',
  ADMIN: 'Admin',
  CUSTOMER: 'Customer',
  MANAGER: 'Manager',
  SHIPPER: 'Shipper',
  SALES: 'Sales',
}

export const getPdfLogo = async imageUrl => ({
  text: 'Thank you for ordering US Tire Outlet products. This order is paid in full. Shop with confidence at www.ustireoutlet.com',
  fit: [50, 50],
  image: await getBase64FromImage(imageUrl),
})

const getBase64FromImage = async url => {
  const response = await fetch(url)
  const arraybuffer = await response.arrayBuffer()
  const bytes = new Uint8Array(arraybuffer)
  const binary = [].map
    .call(bytes, byte => String.fromCharCode(byte))
    .join('')
  const mediaType = response.headers.get('content-type')
  const base64 = [
    'data:',
    mediaType ? mediaType + ';' : '',
    'base64,',
    btoa(binary),
  ].join('')
  return base64
}

export const getInventoryData = (invDsc, invLct) => {
  if (invDsc && invLct) return `[${invDsc} - ${invLct}]`
  if (invDsc && !invLct) return `[${invDsc}]`
  if (!invDsc && invLct) return `[${invLct}]`
  return ''
}

export const currencyMask = rawValue => {
  let numberMask = createNumberMask({
    prefix: '',
    includeThousandsSeparator: false,
    allowDecimal: true,
    requireDecimal: true,
    allowLeadingZeroes: false,
  })

  let mask = numberMask(rawValue)
  let decimalsRegex = /\.([0-9]{1,2})/
  let result = decimalsRegex.exec(rawValue)

  // In case there is only
  // one decimal
  if (result && result[1].length < 2) {
    mask.push('0')
  } else if (!result) {
    mask.push('0')
    mask.push('0')
  }

  return mask
}

export const toCapitalize = (str, separator = ' ', final = ' ') => {
  let res = str.split(separator)

  for (let i = 0; i < res.length; i++) {
    res[i] = `${res[i].charAt(0).toUpperCase()}${res[i].substring(1)}`
  }

  return res.join(final)
}

export const getPath = (pathname = '') => {
  pathname = pathname.split('/')
  if (!pathname[pathname.length - 1]) {
    pathname.pop()
  }

  pathname = pathname.map((p, i) => ({
    url: pathname.slice(0, i + 1).join('/'),
    label: toCapitalize(p, '-', ' ') || 'Dashboard',
  }))

  return pathname
}

export const getPagination = (total, page, sizePerPage) => {
  let mod = total % sizePerPage
  let totalPage =
    mod === 0 ? _.floor(total / sizePerPage) : _.floor(total / sizePerPage) + 1
  return totalPage === page && mod !== 0 ? mod : sizePerPage
}

export const subtotal = products => {
  let price = 0

  _.forEach(products, function(value) {
    price += value.qty * (value.selectedOptions || []).reduce((a, b) => a + b.price, +value.price)
  })

  return price
}

export const baseSubtotal = products => {
  let price = 0

  _.forEach(products, function(value) {
    price += value.qty * +value.price
  })

  return price
}

export const isCreate = (path = '') => _.includes(path, 'create')

export const object = (options, value) => {
  let v = value ? value : ''

  let l = _.filter(options, function(o) {
    return o.value == v
  })

  return l.length > 0 ? l[0] : ''
}

export const multiObject = (options, values = []) =>
  values.map(value => ({
    value,
    label: _.get(
      options.find(o => o.value == value),
      'label',
      '',
    ),
  }))

export const search = () => {
  var hash = window.location.search.substring(1)

  var params = {}

  hash.split('&').map(hk => {
    let temp = hk.split('=')
    params[temp[0]] = temp[1]
  })

  return params
}

export const replaceUrlParam = (url, paramName, paramValue) => {
  if (paramValue == null) {
    paramValue = ''
  }
  var pattern = new RegExp('\\b(' + paramName + '=).*?(&|#|$)')
  if (url.search(pattern) >= 0) {
    return url.replace(pattern, '$1' + paramValue + '$2')
  }
  url = url.replace(/[?#]$/, '')
  return (
    url + (url.indexOf('?') > 0 ? '&' : '?') + paramName + '=' + paramValue
  )
}

export const printInvoice = async (orders, store, country, states, isAbandoned) => {
  const { vfs } = vfsFonts.pdfMake
  const { image, fit, text } = await getPdfLogo(store.logoUrl?.invoice)
  pdfMake.vfs = vfs
  let pdf = {
    pageSize: 'A4',
    pageMargins: [20, 20, 20, 20],
    content: [],
    footer: {
      columns: [
        {
          text: isAbandoned ? '' : text,
          italics: true,
          margin: [20, 0, 0, 20],
        },
      ],
    },
    styles: {
      header: {
        fontSize: 18,
        bold: true,
      },
      bigger: {
        fontSize: 15,
        italics: true,
      },
      title: {
        fontSize: 12,
        bold: true,
      },
    },
    defaultStyle: {
      columnGap: 20,
      fontSize: 10,
    },
  }

  // Create Pdf
  _.forEach(orders, order => {
    let header = {
      columns: [
        {
          width: 'auto',
          image,
          fit,
        },
        {
          width: '*',
          stack: [
            {
              text: `${store.name}`,
              fontSize: 14,
            },
            `${store.address} ${store.city}, ${store.state} ${store.zipcode}`,
            //`${store.businessPrimaryPhone}`,
            `${store.businessPrimaryEmailId}`,
          ],
        },
        {
          width: 100,
          stack: [
            //{ qr: 'text in QR' },
            {
              text: isAbandoned ? 'Quote Request' : 'Sales Receipt',
              fontSize: 10,
            },
          ],

          alignment: 'right',
        },
      ],
    }

    pdf.content.push(header)

    let detail = {
      margin: [0, 40, 0, 0],
      columns: [
        {
          width: '40%',
          stack: [
            { text: 'Ship To', style: 'title' },
            '\n',
            {
              text: `${order.shippToFirstName} ${order.shippToLastName}`,
              italics: false,
              fontSize: 11,
            },
            {
              text: order.deliveryLocation ? toCapitalize(order.deliveryLocation.toLowerCase()) : '',
              italics: false,
              fontSize: 11,
            },
            {
              text: [
                ` ${order.shippToCompany}`,
              ],
            },
            `${order.shippToAddress}`,
            // `${order.shippToCity}, ${
            //     _.find(states, s => s.value == order.shippToState)
            //         .label
            // } ${order.shippToZipcode}`,
            `${order.shippToCity}, ${order.shippToState} ${order.shippToZipcode}`,
            `${_.get(
              _.find(country, c => c.value == order.shippToCountry),
              'label',
              '',
            )}`,
            '\n',
            {
              text: [
                { text: 'T:', bold: true },
                ` ${order.shippToPhoneNumber}`,
              ],
            },
            {
              text: [{ text: 'E:', bold: true }, ` ${order.shippToEmail}`],
            },
          ],
        },
        {
          width: '40%',
          stack: [
            { text: 'Bill To', style: 'title' },
            '\n',
            {
              text: `${order.billToFirstName} ${order.billToLastName}`,
              italics: false,
              fontSize: 11,
            },
            {
              text: order.deliveryLocation ? toCapitalize(order.deliveryLocation.toLowerCase()) : '',
              italics: false,
              fontSize: 11,
            },
            {
              text: [` ${order.billToCompany}`],
            },
            `${order.billToAddress}`,
            // `${order.billToCity}, ${
            //     _.find(states, s => s.value == order.billToState)
            //         .label
            // } ${order.billToZipcode}`,
            `${order.billToCity}, ${order.billToState} ${order.billToZipcode}`,
            `${_.get(
              _.find(country, c => c.value == order.billToCountry),
              'label',
              '',
            )}`,
            '\n',
            {
              text: [{ text: 'T:', bold: true }, ` ${order.billToPhoneNumber}`],
            },
            {
              text: [{ text: 'E:', bold: true }, ` ${order.billToEmail}`],
            },

            //'\n',
            //{text: [{text: 'TRANS:', bold: true}, ' 83493094059']},
            //{text: [{text: 'CT:', bold: true}, ' Visa']},
            //{text: [{text: 'CC:', bold: true}, ' xxxx-xxxx-xxxx-3598']}
          ],
        },
        {
          width: '20%',
          stack: [
            {
              text: `# ${order.invoiceNumber || order.quoteReference}`,
              alignment: 'right',
              fontSize: 14,
              bold: true,
            },
            {
              text: isAbandoned ? 'Quote Date' : 'Order Date:',
              alignment: 'right',
              fontSize: 10,
              bold: true,
            },
            {
              text: `${moment(order.invoiceDate || order.createdDate)
                .tz(store.timeZone.value)
                .format('YYYY-MM-DD HH:mm')}`,
              alignment: 'right',
            },
          ],
        },
      ],
    }

    // Invoice
    detail.columns[0].stack.push('\n')
    detail.columns[0].stack.push({
      text: [{ text: 'TRANS: ', bold: true }, order.transactionID],
    })
    detail.columns[0].stack.push({
      text: [{ text: 'CT: ', bold: true }, order.cardType],
    })
    detail.columns[0].stack.push({
      text: [
        { text: 'CC: ', bold: true },
        `xxxx-xxxx-xxxx-${order.lastFourCreditCard}`,
      ],
    })

    pdf.content.push(detail)

    let title = {
      text: isAbandoned ? 'Items\n\n' : 'Items Ordered\n\n',
      style: 'header',
      margin: [0, 40, 0, 0],
    }

    pdf.content.push(title)

    let table = {
      table: {
        widths: [15, 60, '*', 20, 50, 60, 60],
        body: [['#', 'SKU', 'Item', 'Qty', 'Price', 'Amount']],
      },
      layout: {
        hLineWidth: function(i, node) {
          return 0.1
        },
        vLineWidth: function(i, node) {
          return 0.1
        },
        hLineColor: function(i, node) {
          return 'gray'
        },
        vLineColor: function(i, node) {
          return 'gray'
        },
        paddingTop: function(i, node) {
          return 5
        },
        paddingBottom: function(i, node) {
          return 5
        },
      },
    }

    _.forEach(
      order.products,
      (
        {
          price,
          qty,
          sku,
          title,
          inventoryDescription: invDsc,
          inventoryLocation: invLct,
          categories,
          attributes,
          selectedOptions,
        },
        index,
      ) => {
        let amount = price * qty
        table.table.body.push([
          `${index + 1}`,
          sku || '',
          {
            text: [
              `${title} `,
              {
                text: getInventoryData(invDsc, invLct),
                fontSize: 8.5,
                italics: true,
              },
              {
                text: selectedOptions?.length ? (
                  selectedOptions.map(({ price, name }) => `\n(${name} - $${price})`)
                ) : (
                  attributes || categories ? `\n(${categories ? `${categories}${attributes ? ' - ' : ''}` : ''}${attributes ? `${attributes ? attributes : ''}` : ''})` : ''
                ),
                fontSize: selectedOptions?.length ? 8 : 9,
                italics: selectedOptions?.length ? false : true,
              },
            ],
            italics: true,
          },
          { text: `${qty}`, alignment: 'right' },
          { text: `$${price ? price.toFixed(2) : ''}`, alignment: 'right' },
          {
            text: `$${amount ? amount.toFixed(2) : ''}`,
            alignment: 'right',
            bold: true,
          },
        ])
      },
    )

    //table.pageBreak = "after";

    pdf.content.push(table)

    let _subtotal = baseSubtotal(order.products)
    let shipping = order.shippingPrice
    let tax = Object.values(order.tax).reduce((a, b) => a + b, 0)
    let total = _subtotal + tax + shipping

    let orderSummary = {
      // pageBreak: 'after',
      margin: [0, 40, 0, 0],
      columns: [
        {
          width: '60%',
          text: '',
        },
        {
          width: '40%',
          stack: [
            {
              text: 'Summary',
              alignment: 'right',
              fontSize: 18,
              bold: true,
            },
            {
              text: [
                {
                  text: 'Subtotal:',
                  bold: true,
                  alignment: 'right',
                },
                ` $${_subtotal ? _subtotal.toFixed(2) : ''}`,
              ],
              margin: [0, 5, 0, 0],
            },
            {
              text: [
                {
                  text: 'Shipping:',
                  bold: true,
                  alignment: 'right',
                },
                ` $${shipping ? shipping.toFixed(2) : '0.00'}`,
              ],
              margin: [0, 5, 0, 0],
            },
            {
              text: [
                {
                  text: 'Tax:',
                  bold: true,
                  alignment: 'right',
                },
                ` $${tax ? tax.toFixed(2) : '0.00'}`,
              ],
              margin: [0, 5, 0, 0],
            },
            {
              text: [
                {
                  text: 'Total:',
                  bold: true,
                  fontSize: 14,
                  alignment: 'right',
                  //color: "red"
                },
                {
                  text: ` $${total ? total.toFixed(2) : ''}`,
                  fontSize: 14,
                  //color: "red"
                },
              ],
              margin: [0, 5, 0, 0],
            },
            {
              text: order.status === 'ABANDONED_SHOPPING_CART' || order.status === 'PUBLISHED' || order.status === 'REQUESTED' ? undefined : [
                {
                  text: 'Balance:',
                  bold: true,
                  fontSize: 14,
                  alignment: 'right',
                  //color: "red"
                },
                {
                  text: ' $0.00',
                  fontSize: 14,
                  //color: "red"
                },
              ],
              margin: [0, 5, 0, 0],
            },
          ],
        },
      ],
    }

    pdf.content.push(orderSummary)
  })

  return pdfMake.createPdf(pdf)
}

export const printSlip = async (orders, store, country, states) => {
  const { vfs } = vfsFonts.pdfMake
  const { image, fit, text } = await getPdfLogo(store.logoUrl.invoice)
  pdfMake.vfs = vfs
  let pdf = {
    pageSize: 'A4',
    pageMargins: [20, 20, 20, 20],
    content: [],
    footer: {
      columns: [
        {
          text,
          italics: true,
          margin: [20, 0, 0, 20],
        },
      ],
    },
    styles: {
      header: {
        fontSize: 18,
        bold: true,
      },
      bigger: {
        fontSize: 15,
        italics: true,
      },
      title: {
        fontSize: 12,
        bold: true,
      },
    },
    defaultStyle: {
      columnGap: 20,
      fontSize: 10,
    },
  }

  // Create Pdf
  _.forEach(orders, order => {
    _.forEach(order.shippingPackages, pacakge => {
      let header = {
        columns: [
          {
            width: 'auto',
            image,
            fit,
          },
          {
            width: '*',
            stack: [
              {
                text: `${store.name}`,
                fontSize: 14,
              },
              `${store.address} ${store.city}, ${store.state} ${store.zipcode}`,
              //`${store.businessPrimaryPhone}`,
              `${store.businessPrimaryEmailId}`,
            ],
          },
          {
            width: 100,
            stack: [
              //{ qr: 'text in QR' },
              {
                text: 'Packing Slip',
                fontSize: 10,
              },
            ],

            alignment: 'right',
          },
        ],
      }

      pdf.content.push(header)

      let detail = {
        margin: [0, 40, 0, 0],
        columns: [
          {
            width: '40%',
            stack: [
              { text: 'Ship To', style: 'title' },
              '\n',
              {
                text: `${order.shippToFirstName} ${order.shippToLastName}`,
                italics: false,
                fontSize: 11,
              },
              {
                text: order.deliveryLocation ? toCapitalize(order.deliveryLocation.toLowerCase()) : '',
                italics: false,
                fontSize: 11,
              },
              {
                text: [
                  ` ${order.shippToCompany}`,
                ],
              },
              `${order.shippToAddress}`,
              // `${order.shippToCity}, ${
              //     _.find(
              //         states,
              //         s => s.value == order.shippToState
              //     ).label
              // } ${order.shippToZipcode}`,
              `${order.shippToCity}, ${order.shippToState} ${order.shippToZipcode}`,
              `${_.get(
                _.find(country, c => c.value == order.shippToCountry),
                'label',
                '',
              )}`,
              '\n',
              {
                text: [
                  { text: 'T:', bold: true },
                  ` ${order.shippToPhoneNumber}`,
                ],
              },
              {
                text: [{ text: 'E:', bold: true }, ` ${order.shippToEmail}`],
              },
            ],
          },
          {
            width: '40%',
            stack: [
              { text: 'Bill To', style: 'title' },
              '\n',
              {
                text: `${order.billToFirstName} ${order.billToLastName}`,
                italics: false,
                fontSize: 11,
              },
              {
                text: order.deliveryLocation ? toCapitalize(order.deliveryLocation.toLowerCase()) : '',
                italics: false,
                fontSize: 11,
              },
              {
                text: [` ${order.billToCompany}`],
              },
              `${order.billToAddress}`,
              // `${order.billToCity}, ${
              //     _.find(
              //         states,
              //         s => s.value == order.billToState
              //     ).label
              // } ${order.billToZipcode}`,
              `${order.billToCity}, ${order.billToState} ${order.billToZipcode}`,
              `${_.get(
                _.find(country, c => c.value == order.billToCountry),
                'label',
                '',
              )}`,
              '\n',
              {
                text: [
                  { text: 'T:', bold: true },
                  ` ${order.billToPhoneNumber}`,
                ],
              },
              {
                text: [{ text: 'E:', bold: true }, ` ${order.billToEmail}`],
              },
            ],
          },
          {
            width: '20%',
            stack: [
              {
                text: `# ${order.invoiceNumber}`,
                alignment: 'right',
                fontSize: 14,
                bold: true,
              },
              {
                text: 'Order Date:',
                alignment: 'right',
                fontSize: 10,
                bold: true,
              },
              {
                text: `${moment(order.invoiceDate)
                  .tz(store.timeZone.value)
                  .format('YYYY-MM-DD HH:mm')}`,
                alignment: 'right',
              },
              '\n',
              {
                text: 'Tracking #',
                style: 'title',
                alignment: 'right',
              },
              {
                text: pacakge.trackingNumber || '',
                alignment: 'right',
                fontSize: 11,
              },
            ],
          },
        ],
      }

      pdf.content.push(detail)

      let title = {
        text: 'Items Ordered\n\n',
        style: 'header',
        margin: [0, 40, 0, 0],
      }

      pdf.content.push(title)

      let table = {
        table: {
          widths: [15, 60, '*', 50],
          body: [['#', 'SKU', 'Item', 'Qty']],
        },
        layout: {
          hLineWidth: function(i, node) {
            return 0.1
          },
          vLineWidth: function(i, node) {
            return 0.1
          },
          hLineColor: function(i, node) {
            return 'gray'
          },
          vLineColor: function(i, node) {
            return 'gray'
          },
          paddingTop: function(i, node) {
            return 5
          },
          paddingBottom: function(i, node) {
            return 5
          },
        },
      }

      _.forEach(
        pacakge.items,
        (
          {
            sku,
            title,
            qtyInPackage,
            inventoryDescription: invDsc,
            inventoryLocation: invLct,
            categories,
            attributes,
            selectedOptions,
          },
          index,
        ) => {
          //let amount = p.price * p.qty;
          table.table.body.push([
            `${index + 1}`,
            sku || '',
            {
              text: [
                `${title} `,
                {
                  text: getInventoryData(invDsc, invLct),
                  fontSize: 8.5,
                  italics: true,
                },
                {
                  text: selectedOptions?.length ? (
                    selectedOptions.map(({ price, name }) => `\n(${name} - $${price})`)
                  ) : (
                    attributes || categories ? `\n(${categories ? `${categories}${attributes ? ' - ' : ''}` : ''}${attributes ? `${attributes ? attributes : ''}` : ''})` : ''
                  ),
                  fontSize: selectedOptions?.length ? 8 : 9,
                  italics: selectedOptions?.length ? false : true,
                },
              ],
              italics: true,
            },
            { text: `${qtyInPackage}`, alignment: 'right' },
            //{ text: `$${p.price.toFixed(2)}`, alignment: "right" },
            //{
            //	text: `$${amount.toFixed(2)}`,
            //	alignment: "right",
            //		bold: true
            //}
          ])
        },
      )

      //table.pageBreak = "after";
      pdf.content.push(table)
    })
  })

  return pdfMake.createPdf(pdf)
}

export const deepCopy = obj => {
  let newObj = Array.isArray(obj) ? [] : {}
  if (obj !== Object(obj)) return obj
  Object.keys(obj).forEach(key => (newObj[key] = deepCopy(obj[key])))
  return newObj
}
