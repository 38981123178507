import React, { useState } from 'react'
import cardValidator from 'card-validator'
import { connect } from 'react-redux'
import { FormGroup, Input, Notification, Button, Utils, Label, FormFeedback, Select } from '../../../../../Imports'
import * as DataActions from '../../../../../actions/DataActions'

const AuthorizePayment = ({
  orderId,
  post,
  storeId,
  fetchOrder,
  modalToggle,
  values,
  onChangeLocation,
  onUpdateLocation,
  addressValue,
  states,
  validLocation,
  action,
}) => {
  const [cardNumber, setCardNumber] = useState({})
  const [expMonth, setExpMonth] = useState({})
  const [expYear, setExpYear] = useState({})
  const [cardCode, setCardCode] = useState({})
  const [loading, setLoading] = useState(false)

  const cardNumberChange = event => {
    setCardNumber({
      value: event.target.value,
      validation: cardValidator.number(event.target.value),
    })
  }

  const expMonthChange = event => {
    setExpMonth({
      value: event.target.value,
      validation: cardValidator.expirationMonth(event.target.value),
    })
  }

  const expYearChange = event => {
    setExpYear({
      value: event.target.value,
      validation: cardValidator.expirationYear(event.target.value),
    })
  }

  const cardCodeChange = event => {
    setCardCode({
      value: event.target.value,
      validation: cardValidator.cvv(event.target.value),
    })
  }

  const isNotValid = () => (
    !cardNumber.validation?.isValid ||
    !expMonth.validation?.isValid ||
    !expYear.validation?.isValid ||
    !cardCode.validation?.isValid ||
    validLocation[`${addressValue}ToAddress`].isInvalid ||
    validLocation[`${addressValue}ToCity`].isInvalid ||
    validLocation[`${addressValue}ToState`].isInvalid ||
    validLocation[`${addressValue}ToZipcode`].isInvalid ||
    loading
  )

  const handleSubmit = async event => {
    event.preventDefault()
    if (!isNotValid()) {
      setLoading(true)
      onUpdateLocation(null, async () => {
        const data = {
          storeId,
          orderId,
          cardNumber: cardNumber.value,
          expMonth: expMonth.value,
          expYear: expYear.value,
          cardCode: cardCode.value,
          address: values[`${addressValue}ToAddress`],
          city: values[`${addressValue}ToCity`],
          state: values[`${addressValue}ToState`],
          zipcode: values[`${addressValue}ToZipcode`],
        }
        await action()
        const response = await post(data, 'authorize-pay-order', '/api/payment')
        if (response?.status === 'success') {
          Notification.success('Order submitted successfully')
        } else {
          Notification.error(response?.data || 'Unexpected error')
        }
        fetchOrder()
        setLoading(false)
        modalToggle()
      })
    }
  }

  return (
    <form className="card-form authorize" onSubmit={handleSubmit}>
      <FormGroup>
        <Label>Card Number *</Label>
        <Input
          value={cardNumber.value || ''}
          name="cardNumber"
          onChange={cardNumberChange}
          placeholder="Card Number"
        />
      </FormGroup>
      <div className="row">
        <FormGroup className="col-md-4">
          <Label>MM *</Label>
          <Input
            value={expMonth.value || ''}
            name="expMonth"
            onChange={expMonthChange}
            placeholder="MM"
          />
        </FormGroup>
        <FormGroup className="col-md-4">
          <Label>YY *</Label>
          <Input
            value={expYear.value || ''}
            name="expYear"
            onChange={expYearChange}
            placeholder="YY"
          />
        </FormGroup>
        <FormGroup className="col-md-4">
          <Label>CVC *</Label>
          <Input
            value={cardCode.value || ''}
            name="cardCode"
            onChange={cardCodeChange}
            placeholder="CVC"
          />
        </FormGroup>
      </div>
      <FormGroup className="mb-2">
        <Label>Address *</Label>
        <Input
          value={values[`${addressValue}ToAddress`] || ''}
          onChange={onChangeLocation}
          name={`${addressValue}ToAddress`}
          invalid={validLocation[`${addressValue}ToAddress`].isInvalid}
        />
      </FormGroup>
      <FormGroup className="mb-2">
        <Label>City *</Label>
        <Input
          value={values[`${addressValue}ToCity`] || ''}
          onChange={onChangeLocation}
          name={`${addressValue}ToCity`}
          invalid={validLocation[`${addressValue}ToCity`].isInvalid}
        />
      </FormGroup>
      <FormGroup className="mb-2">
        <Label>State *</Label>
        <Select
          options={states}
          value={Utils.object(
            states,
            values[`${addressValue}ToState`],
          ) || ''}
          onChange={e => onChangeLocation(e, `${addressValue}ToState`)}
          className={validLocation[`${addressValue}ToState`].isInvalid ? 'is-invalid' : ''}
        />
      </FormGroup>
      <FormGroup className="mb-2">
        <Label>Zip Code *</Label>
        <Input
          value={values[`${addressValue}ToZipcode`] || ''}
          onChange={onChangeLocation}
          name={`${addressValue}ToZipcode`}
          invalid={addressValue === 'shipp' && validLocation[`${addressValue}ToZipcode`].isInvalid}
        />
      </FormGroup>
      <Button
        label="Submit"
        color="primary"
        type="submit"
        icon={`la ${loading ? '' : ' la-check'}`}
        disabled={isNotValid()}
        isLoading={loading}
      />
    </form>
  )
}


export const mapStateToProps = state => ({})

export const mapDispatchToProps = dispatch => ({
  post: (payload, actionEvent, pathname) =>
    dispatch(
      DataActions.post(payload, actionEvent, pathname),
    ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuthorizePayment)
