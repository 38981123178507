import React from 'react'
import { Input } from '../../../Imports'

const AttributesTable = ({ attributes, setEdit, deleteAttribute, isVariants, onChange, selectedAttributes }) => (
  <table className="attributes-table">
    <colgroup>
      <col span="1" style={{ width: isVariants ? '5%' : undefined }} />
      <col span="1"/>
      <col span="1" style={{ width: !isVariants ? '10%' : undefined }} />
    </colgroup>
    <thead>
      <tr>
        {isVariants && (
          <th />
        )}
        <th>Label</th>
        <th>Options</th>
        {!isVariants && (
          <th>Actions</th>
        )}
      </tr>
    </thead>
    <tbody>
      {attributes.map(attribute => (
        <tr key={attribute.id}>
          {isVariants && (
            <td>
              <Input
                checked={!!selectedAttributes.find(({ id: attrId }) => attribute.id === attrId)}
                onChange={() => onChange(attribute)}
                type="checkbox"
              />
            </td>
          )}
          <td>{attribute.key}</td>
          <td>{attribute.options.join(', ')}</td>
          {!isVariants && (
            <td>
              <div onClick={deleteAttribute(attribute.id)} />
              <div onClick={setEdit(attribute.id)} />
            </td>
          )}
        </tr>
      ))}
    </tbody>
  </table>
)

export default AttributesTable
