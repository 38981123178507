import {
  React,
  Component,
  connect,
  Actions,
  ActionTypes,
  Portlet,
  PortletHeader,
  PortletBody,
  Section,
  Utils,
  Notification,
  // Routes,
  // Link,
  FormValidator,
  _,
} from '../../../Imports'
import { Helmet } from 'react-helmet'

import logo from '../../../static/images/qb_btn_lg.png'
const entity = 'Quickbooks'
const OAUTH_REDIRECT_URL = window.location.origin + window.location.pathname

class Quickbooks extends Component {
  constructor(props) {
    super(props)

    this.validator = new FormValidator([
      {
        field: 'calculateTaxTypeForTax',
        method: 'isEmpty',
        validWhen: false,
        message: 'The Tax Type is required.',
      },
      {
        field: 'taxAmountForTax',
        method: 'isEmpty',
        validWhen: false,
        message: 'The Tax Amount is required.',
      },
    ])

    this.state = {
      setting: null,
      submited: false,
      isCreate: false,
      btnLoading: false,
      oauth: {},
    }
  }

  UNSAFE_componentWillMount = async () => {
    let payload = Utils.search()

    let storeId = Utils.search(this.props.location).store

    payload.appRedirectUri = OAUTH_REDIRECT_URL
    payload.storeId = storeId

    //Save the Oauth Token
    if (_.get(payload, 'code', null) !== null) {
      let respToken = await this.props.post(payload, 'retrieve-bearer-tokens')

      if (respToken.status === 'success') {
        Notification.success(
          'Your Quickbook Online is contencted to the Store',
        )
      }
    }

    // Testing get Quickbook Company Information
    //let p = await this.props.post(
    //	{ storeId: this.props.match.params.id },
    //	"quickbook-company-info"
    //);

    //Get the store
    if (!this.props.settings.data) {
      let resp = await this.props.storePost(
        { id: storeId },
        'get-store',
        ActionTypes.FETCH_SETTINGS_SUCCESS,
        ActionTypes.FETCH_SETTINGS_FAILURE,
      )
      await this.setState({ setting: resp.data, oauth: payload })
    } else {
      this.setState({
        setting: this.props.settings.data,
      })
    }
  }

  update = () => {
    this.setState({
      submited: !this.state.submited,
    })
    let isValid = this.validator.validate(this.state.setting).isValid

    console.log(this.validator.validate(this.state.setting))
    console.log(this.state.setting)

    if (isValid) {
      this.setState({ btnLoading: true })
      this.props.post(this.state.setting, 'update-store').then(resp => {
        this.response(resp)
      })
    }
  }

  response = resp => {
    this.setState({
      btnLoading: false,
    })

    let msn = _.replace(
      _.replace(process.env.REACT_APP_SUCCESS_ACTION, '%entity%', entity),
      '%action%',
      'Updated',
    )

    if (resp.status === 'success') {
      Notification.success(msn)
    } else {
      Notification.error(process.env.REACT_APP_ERROR_ACTION)
    }
  }

  connect = async () => {
    // Generate the Oauth url
    let resp = await this.props.post(
      { appRedirectUri: OAUTH_REDIRECT_URL },
      'generate-oauth-url',
    )

    if (resp.status === 'success') {
      window.location = resp.data
    } else {
      Notification.error(process.env.REACT_APP_ERROR_ACTION)
    }
  }

  render = () => {
    const validator = this.state.submited
      ? this.validator.validate(this.state.setting)
      : this.validator.valid()

    return (
      <Portlet>
        <Helmet>
          <title>Quickbooks Online | Admin Panel - Max Ecommerce</title>
        </Helmet>
        <PortletHeader
          title={`Quickbooks Online - ${_.get(this.state.setting, 'name', '')}`}
          sticky
        >
          {/*
					<Link to={Routes.STORES} className="btn btn-secondary mr-0">
						<i className="la la-arrow-left" />
						Back
					</Link>
					<Button
						label={"Update"}
						color="primary"
						icon={`la ${this.state.btnLoading ? "" : " la-check"}`}
						isLoading={this.state.btnLoading}
						onClick={this.update}
					/>
					*/}
        </PortletHeader>
        <PortletBody>
          {this.state.setting === null && (
            <div className="row">
              <div className="col-12">
                <div>Loading...</div>
              </div>
            </div>
          )}

          {this.state.setting !== null && (
            <div className="row">
              <div className="col-xl-1" />
              <div className="col-xl-10">
                <Section title="">
                  <img
                    onClick={this.connect}
                    width="300px"
                    alt="Logo"
                    src={logo}
                  />
                </Section>
              </div>
              <div className="col-xl-1" />
            </div>
          )}
        </PortletBody>
      </Portlet>
    )
  }
}

export const mapStateToProps = state => ({
  settings: state.settings,
  taxType: state.taxType,
})

export const mapDispatchToProps = dispatch => ({
  post: function(payload, actionEvent) {
    return dispatch(Actions.post(payload, actionEvent))
  },
  storeGet: function(actionEvent, typeSuccess, typeError) {
    return dispatch(Actions.storeGet(actionEvent, typeSuccess, typeError))
  },
  storePost: function(payload, actionEvent, typeSuccess, typeError) {
    return dispatch(
      Actions.storePost(payload, actionEvent, typeSuccess, typeError),
    )
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Quickbooks)
