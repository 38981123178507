import {
  React,
  Component,
  connect,
  Actions,
  Portlet,
  PortletHeader,
  PortletBody,
  Section,
  Select,
  FormGroup,
  Input,
  Label,
  FormFeedback,
  Utils,
  Notification,
  Routes,
  Link,
  FormValidator,
  // Separator,
  Button,
  ActionTypes,
  UiActions,
  _,
} from '../../../Imports'
import { Helmet } from 'react-helmet'

const entity = 'Product Category'

class CategorieForm extends Component {
  constructor(props) {
    super(props)

    this.validator = new FormValidator([
      {
        field: 'label',
        method: 'isEmpty',
        validWhen: false,
        message: 'Category Name is required.',
      },
    ])

    this.state = {
      category: null,
      submited: false,
      isCreate: false,
      btnLoading: false,
    }
  }

  UNSAFE_componentWillMount = () => {
    let storeId = Utils.search(this.props.location).store

    if (this.props.categories.data.length === 0) {
      this.props.storePost(
        {
          storeId: storeId,
        },
        'list-categories',
        ActionTypes.FETCH_CATEGORIES_SUCCESS,
        ActionTypes.FETCH_CATEGORIES_FAILURE,
      )
    }

    this.setState(
      { isCreate: Utils.isCreate(this.props.location.pathname) },
      () => {
        if (this.state.isCreate) {
          this.setState({ category: { type: 'CATEGORY' } })
        } else {
          this.props
            .post(
              {
                storeId: storeId,
                id: this.props.match.params.id,
              },
              'get-category',
            )
            .then(resp => {
              this.setState({
                category: resp.data,
              })
            })
        }
      },
    )
  }

  onChangeInput = e => {
    let category = this.state.category
    category[e.target.name] = e.target.value

    this.setState({ category })
  }

  onChangeSelect = (e, name) => {
    let category = this.state.category
    category[name] = e.value

    this.setState({ category })
  }

  onChangeCheckbox = e => {
    let category = this.state.category
    category[e.target.name] = !_.get(category, e.target.name, false)

    this.setState({ category })
  }

  response = resp => {
    this.props.onSetLoading(false)
    this.setState({ btnLoading: false })

    let msn = _.replace(
      _.replace(process.env.REACT_APP_SUCCESS_ACTION, '%entity%', entity),
      '%action%',
      this.state.isCreate ? 'Created' : 'Updated',
    )

    if (resp.status === 'success') {
      Notification.success(msn)

      if (this.state.isCreate) {
        let url = _.replace(
          Routes.PRODUCT_CATEGORY_DETAIL,
          ':id',
          resp.data.id,
        )

        this.props.history.push(url)
      }
    } else {
      Notification.error(process.env.REACT_APP_ERROR_ACTION)
    }
  }

  action = () => {
    this.setState({
      submited: !this.state.submited,
    })
    let isValid = this.validator.validate(this.state.category).isValid
    let action = this.state.isCreate ? 'create-category' : 'update-category'

    if (isValid) {
      this.props.onSetLoading(true)
      this.setState({ btnLoading: true })
      this.props
        .post(
          {
            storeId: Utils.search(this.props.location).store,
            ...this.state.category,
          },
          action,
        )
        .then(resp => {
          this.response(resp)
        })
    }
  }

  render = () => {
    const validator = this.state.submited
      ? this.validator.validate(this.state.category)
      : this.validator.valid()

    let categoryOptions = _.map(this.props.categories.data, function(p) {
      return { value: p.id, label: p.label }
    })

    categoryOptions.push({ value: '', label: 'No Parent Category' })

    return (
      <Portlet>
        <Helmet>
          <title>Category | Admin Panel - Max Ecommerce</title>
        </Helmet>
        <PortletHeader
          title={this.state.isCreate ? 'New Category' : 'Update Category'}
          sticky
        >
          <Link
            to={`${Routes.PRODUCT_CATEGORY_LIST}?store=${
              Utils.search(this.props.location).store
            }`}
            className="btn btn-secondary mr-3"
          >
            <i className="la la-arrow-left" />
            Back
          </Link>
          <Button
            label={this.state.isCreate ? 'Create' : 'Update'}
            color="primary"
            icon={`la ${this.state.btnLoading ? '' : ' la-check'}`}
            isLoading={this.state.btnLoading}
            onClick={this.action}
          />
        </PortletHeader>
        <PortletBody>
          {this.state.category === null && (
            <div className="row">
              <div className="col-12">
                <div>Loading...</div>
              </div>
            </div>
          )}
          {this.state.category !== null && (
            <div className="row">
              <div className="col-xl-2" />
              <div className="col-xl-8">
                <Section title="Category Information" size="lg">
                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">
                      Category active
                    </Label>

                    <div className="col-9">
                      <span className="k-switch k-switch--md k-switch--icon">
                        <label>
                          <Input
                            checked={
                              this.state.category.active ? 'checked' : ''
                            }
                            onChange={this.onChangeCheckbox}
                            name="active"
                            type="checkbox"
                          />
                          <span />
                        </label>
                      </span>
                    </div>
                  </FormGroup>

                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">
                      Category name
                    </Label>
                    <div className="col-9">
                      <Input
                        value={this.state.category.label}
                        onChange={this.onChangeInput}
                        name="label"
                        invalid={validator.label.isInvalid}
                      />
                      <FormFeedback>{validator.label.message}</FormFeedback>
                    </div>
                  </FormGroup>

                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">
                      Parent Category
                    </Label>
                    <div className="col-9">
                      <Select
                        options={categoryOptions}
                        value={Utils.object(
                          categoryOptions,
                          this.state.category.parentId,
                        )}
                        onChange={e => this.onChangeSelect(e, 'parentId')}
                        isSearchable={true}
                      />
                    </div>
                  </FormGroup>
                </Section>

                {/*
                                <Separator size="lg" border={"dashed"} />
                                <Section title="Search Engine" size="lg">
                                    <FormGroup className="row">
                                        <Label className="col-3 col-form-label">
                                            URL
                                        </Label>
                                        <div className="col-9">
                                            <Input
                                                value={this.state.category.url}
                                                onChange={this.onChangeInput}
                                                name="url"
                                            />
                                        </div>
                                    </FormGroup>
                                    <FormGroup className="row">
                                        <Label className="col-3 col-form-label">
                                            Title
                                        </Label>
                                        <div className="col-9">
                                            <Input
                                                value={
                                                    this.state.category.title
                                                }
                                                onChange={this.onChangeInput}
                                                name="title"
                                            />
                                        </div>
                                    </FormGroup>
                                    <FormGroup className="row">
                                        <Label className="col-3 col-form-label">
                                            Meta Keywords
                                        </Label>
                                        <div className="col-9">
                                            <Input
                                                value={
                                                    this.state.category
                                                        .metaKeywords
                                                }
                                                onChange={this.onChangeInput}
                                                name="metaKeywords"
                                            />
                                        </div>
                                    </FormGroup>
                                    <FormGroup className="row">
                                        <Label className="col-3 col-form-label">
                                            Meta Description
                                        </Label>
                                        <div className="col-9">
                                            <Input
                                                value={
                                                    this.state.category
                                                        .metaDescription
                                                }
                                                onChange={this.onChangeInput}
                                                name="metaDescription"
                                            />
                                        </div>
                                    </FormGroup>
                                </Section>
                                */}
              </div>
              <div className="col-xl-2" />
            </div>
          )}
        </PortletBody>
      </Portlet>
    )
  }
}

export const mapStateToProps = state => ({
  categories: state.productCategories,
})

export const mapDispatchToProps = dispatch => ({
  post: function(payload, actionEvent) {
    return dispatch(Actions.post(payload, actionEvent))
  },
  storePost: function(payload, actionEvent, typeSuccess, typeError) {
    return dispatch(
      Actions.storePost(payload, actionEvent, typeSuccess, typeError),
    )
  },
  onSetLoading: payload => dispatch(UiActions.onSetLoading(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CategorieForm)
