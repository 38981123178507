import React, { Component } from 'react'
import classNames from 'classnames'

class Section extends Component {
  render = () => (
    <div className={`k-section ${this.props.className || ''}`}>
      {this.props.title && (
        <h3
          className={classNames('k-section__title', {
            [`k-section__title-${this.props.size}`]: this.props.size,
          })}
        >
          {this.props.title}
        </h3>
      )}
      {this.props.info && (
        <div className="k-section__info">{this.props.info}</div>
      )}
      <div
        className={classNames('k-section__content', {
          'k-section__content--border': this.props.contentBorder,
        })}
      >
        {this.props.children}
      </div>
    </div>
  )
}

export default Section
