import {
  React,
  Component,
  connect,
  Actions,
  ActionTypes,
  Portlet,
  PortletHeader,
  PortletBody,
  Select,
  FormGroup,
  Modal,
  Input,
  Label,
  FormFeedback,
  Button,
  Utils,
  Notification,
  // Routes,
  // Link,
  FormValidator,
  _,
} from '../../../Imports'
import { Helmet } from 'react-helmet'
import classNames from 'classnames'
import Loader from '../../base/loader'

const entity = 'T14Products'

class T14Products extends Component {
  constructor(props) {
    super(props)

    this.validator = new FormValidator([
      {
        field: 'account',
        method: 'isEmpty',
        validWhen: false,
        message: 'The Account is required.',
      },
      {
        field: 'password',
        method: 'isEmpty',
        validWhen: false,
        message: 'The Password is required.',
      },
    ])

    this.state = {
      setting: null,
      form: {
        account: '',
        password: '',
      },
      brand: null,
      submited: false,
      btnLoading: false,
      isOpen: false,
      selectedAll: false,
      selectedProducts: {},
      totalPages: 1,
      loading: false,
      products: [],
      brands: [],
      page: 0,
    }
  }

  componentDidMount = async () => {
    let storeId = Utils.search(this.props.location).store
    this.fetchBrands()
    if (!this.props.settings.data) {
      this.props.storePost(
        { id: storeId },
        'get-store',
        ActionTypes.FETCH_SETTINGS_SUCCESS,
        ActionTypes.FETCH_SETTINGS_FAILURE,
      ).then(resp => {
        this.setState({
          setting: resp.data,
        })
      })
    } else {
      this.setState({
        setting: this.props.settings.data,
      })
    }
  }

  update = () => {
    this.setState({
      submited: !this.state.submited,
    })
    let isValid = this.validator.validate(this.state.setting).isValid
    if (isValid) {
      this.setState({ btnLoading: true })
      this.props.post(this.state.setting, 'update-store').then(resp => {
        this.response(resp)
      })
    }
  }

  onChangeBrandInput = option => {
    this.setState({ brand: option })
    this.clearAll()
  }

  onChangeInput = event => {
    const { target: { value, name } } = event
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        [name]: value,
      },
    }))
  }

  response = resp => {
    this.setState({
      btnLoading: false,
    })

    let msn = _.replace(
      _.replace(process.env.REACT_APP_SUCCESS_ACTION, '%entity%', entity),
      '%action%',
      'Updated',
    )

    if (resp.status === 'success') {
      Notification.success(msn)
    } else {
      Notification.error(process.env.REACT_APP_ERROR_ACTION)
    }
  }

  onLogin = () => {
    // after login
    this.setState({ isOpen: true })
    const [html] = document.getElementsByTagName('html')
    html.style.overflow = 'hidden'
  }

  onCloseModal = () => {
    this.setState({ isOpen: false, brand: null })
    this.clearAll()
    const [html] = document.getElementsByTagName('html')
    html.style.overflow = 'initial'
  }

  clearAll = event => {
    this.setState({
      selectedProducts: {},
      selectedAll: false,
    })
    if (!event) {
      this.setState({
        products: [],
        page: 0,
        totalPages: 1,
      })
    }
  }

  selectAll = () => {
    this.setState(({ selectedAll }) => ({
      selectedAll: !selectedAll,
    }))
  }

  onSyncProducts = () => {
    const storeId = Utils.search(this.props.location).store
    const { page, brand: { value: brandId }, selectedAll, selectedProducts, products } = this.state
    const items = !selectedAll
      ? products.filter(({ id }) => Boolean(selectedProducts[id])).map(({ item }) => item)
      : products.map(({ item }) => item)
    this.setState({ loading: true, products: [] }, () => {
      this.props.post({ storeId, page, brandId, items }, 't14-select-items')
        .then(res => {
          this.clearAll()
          this.setState({
            brand: null,
            loading: false,
          })
        })
        .catch(() => this.setState({ loading: false }))
    })
  }

  onAddProduct = product => event => {
    const { id } = product
    if (this.state.selectedProducts[id]) {
      this.setState(prevState => {
        const selectedProducts = { ...prevState.selectedProducts }
        delete selectedProducts[id]
        return { selectedProducts }
      })
    } else {
      this.setState(prevState => ({
        selectedProducts: {
          ...prevState.selectedProducts,
          [id]: product
        }
      }))
    }
  }

  fetchBrands = () => {
    const storeId = Utils.search(this.props.location).store
    this.setState({ loading: true }, () => {
      this.props.post({ storeId }, 't14-get-brands')
        .then(res => this.setState({
          brands: (Array.isArray(res?.data) ? res.data : []).map(({ id, name }) => ({
            value: id,
            label: name,
          })),
          loading: false,
        }))
        .catch(() => this.setState({ loading: false }))
    })
  }

  fetchProducts = () => {
    const storeId = Utils.search(this.props.location).store
    const { page: prevPage, brand: { value: id }, totalPages } = this.state
    const page = prevPage + 1
    if (page <= totalPages) {
      this.setState({ loading: true }, () => {
        this.props.post({ page, storeId, id }, 't14-list-items-by-brand')
          .then(res => {
            const hasProducts = Array.isArray(res?.data?.data) && res?.data?.data?.length
            const products = hasProducts ? res.data.data : []
            console.log(res)
            console.log(products)
            this.setState(prevState => ({
              products: [...prevState.products, ...products],
              totalPages: res?.data?.totalPages || 1,
              loading: false,
              page,
            }))
          })
          .catch(() => this.setState({ loading: false }))
      })
    }
  }

  render = () => {
    return (
      <>
        <Portlet className="T14Products">
          <Helmet>
            <title>T14Products Online | Admin Panel - Max Ecommerce</title>
          </Helmet>
          <PortletHeader
            title="T14 Products"
            sticky
          >
          </PortletHeader>
          <PortletBody>
            {this.state.setting === null && (
              <div className="row">
                <div className="col-12">
                  <div>Loading...</div>
                </div>
              </div>
            )}

            {this.state.setting !== null && (
              <>
                {/* <FormGroup className="row">
                  <Label className="col-3 col-form-label">Account</Label>
                  <div className="col-9">
                    <Input
                      value={this.state.form.name}
                      onChange={this.onChangeInput}
                      name="account"
                      invalid={validator.name?.isInvalid}
                    />
                    <FormFeedback>{validator.name?.message}</FormFeedback>
                  </div>
                </FormGroup>
                <FormGroup className="row">
                  <Label className="col-3 col-form-label">Password</Label>
                  <div className="col-9">
                    <Input
                      value={this.state.form.password}
                      onChange={this.onChangeInput}
                      name="password"
                      invalid={validator.password?.isInvalid}
                    />
                    <FormFeedback>{validator.password?.message}</FormFeedback>
                  </div>
                </FormGroup> */}
                <FormGroup className="row">
                  <Button
                    label="Fetch Products"
                    color="primary"
                    isLoading={this.state.btnLoading}
                    onClick={this.onLogin}
                  />
                </FormGroup>
              </>
            )}
          </PortletBody>
        </Portlet>
        {this.state.isOpen && (
          <Modal
            className="T14Products-Modal"
            isOpen={this.state.isOpen}
            onClose={this.onCloseModal}
          >
            <i onClick={this.onCloseModal} />
            <div className="form-buttons">
              <p className="title">T14 Products</p>
              <FormGroup className="row">
                <Select
                  value={this.state.brand}
                  options={this.state.brands}
                  onChange={this.onChangeBrandInput}
                  name="password"
                />
                <Button
                  label="Fetch Now"
                  color="primary"
                  isLoading={this.state.btnLoading}
                  onClick={this.fetchProducts}
                  disabled={!this.state.brand}
                />
              </FormGroup>
            </div>
            <div className="products">
              <div className="header">
                <div>
                  <Input
                    checked={this.state.selectedAll ? 'checked' : ''}
                    onChange={this.selectAll}
                    type="checkbox"
                  />
                  <span onClick={this.selectAll}>
                    Select All
                  </span>
                  <p onClick={this.clearAll}>
                    Clear All
                  </p>
                </div>
                <Button
                  label="Sync"
                  color="primary"
                  isLoading={this.state.btnLoading}
                  onClick={this.onSyncProducts}
                  disabled={!this.state.selectedAll && !Object.keys(this.state.selectedProducts).length}
                />
              </div>
              <div className="body">
                  {this.state.products.map((product, index) => {
                    const { item: { productName, category, qty, id }, price, mapped } = product
                    return (
                      <div key={id || index} className={classNames({ disabled: mapped })}>
                        <Input
                          checked={this.state.selectedProducts[id] || this.state.selectedAll ? 'checked' : ''}
                          onChange={this.onAddProduct(product)}
                          disabled={mapped}
                          type="checkbox"
                        />
                        <p>
                          {productName}
                          <br/>
                          {`${category}, ${qty ? `qty (${qty})` : ''}, $${price}`}
                        </p>
                      </div>
                    )
                  })}
              </div>
              <div className="footer">
                {!!this.state.products.length && (
                  <p
                    className={classNames({
                      disabled: this.state.page + 1 > this.state.totalPages
                    })}
                    onClick={this.fetchProducts}
                  >
                    show next
                  </p>
                )}
              </div>
            </div>
            <Loader active={this.state.loading} />
          </Modal>
        )}
      </>
    )
  }
}

export const mapStateToProps = state => ({
  settings: state.settings,
  taxType: state.taxType,
})

export const mapDispatchToProps = dispatch => ({
  get: function (actionEvent) {
    return dispatch(Actions.get(actionEvent))
  },
  post: function (payload, actionEvent) {
    return dispatch(Actions.post(payload, actionEvent))
  },
  storeGet: function (actionEvent, typeSuccess, typeError) {
    return dispatch(Actions.storeGet(actionEvent, typeSuccess, typeError))
  },
  storePost: function (payload, actionEvent, typeSuccess, typeError) {
    return dispatch(
      Actions.storePost(payload, actionEvent, typeSuccess, typeError),
    )
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(T14Products)
