import {
  React,
  Component,
  connect,
  Actions,
  ActionTypes,
  Portlet,
  PortletHeader,
  PortletBody,
  Section,
  BootstrapTable,
  paginationFactory,
  Select,
  FormGroup,
  Input,
  Label,
  FormFeedback,
  Utils,
  Notification,
  Routes,
  Link,
  FormValidator,
  Separator,
  Button,
  UiActions,
  _,
} from '../../../Imports'
import validator from 'validator'
import moment from 'moment-timezone'
import { Helmet } from 'react-helmet'

const entity = 'Customer'

class ProductForm extends Component {
  constructor(props) {
    super(props)

    this.validator = new FormValidator([
      // Conatat information
      {
        field: 'firstName',
        method: 'isEmpty',
        validWhen: false,
        message: 'First Name is required.',
      },
      {
        field: 'lastName',
        method: 'isEmpty',
        validWhen: false,
        message: 'Last Name is required.',
      },
      {
        field: 'email',
        method: 'isEmail',
        validWhen: true,
        message: 'Invalid email or already exist.',
      },
      {
        field: 'phoneNumber',
        method: 'isMobilePhone',
        args: ['en-US'],
        validWhen: true,
        message: 'This is not a valid phone number.',
      },

      // Address
      {
        field: 'address',
        method: 'isEmpty',
        validWhen: false,
        message: 'Address is required.',
      },
      {
        field: 'city',
        method: 'isEmpty',
        validWhen: false,
        message: 'City is required.',
      },
      {
        field: 'country',
        method: 'isEmpty',
        validWhen: false,
        message: 'Country is required.',
      },
      {
        field: 'state',
        method: 'isEmpty',
        validWhen: false,
        message: 'State is required.',
      },
      {
        field: 'zip',
        method: 'isPostalCode',
        args: ['US'],
        validWhen: true,
        message: 'That is not a valid zipcode.',
      },
    ])

    this.storeId = Utils.search(this.props.location).store

    this.state = {
      customer: null,
      submited: false,
      isCreate: false,
      btnLoading: false,
      settigns: null,
      orderHistory: [],
    }
  }

  UNSAFE_componentWillMount = () => {
    let storeId = Utils.search(this.props.location).store

    if (this.props.settings.data === null) {
      this.props.storePost(
        { id: storeId },
        'get-store',
        ActionTypes.FETCH_SETTINGS_SUCCESS,
        ActionTypes.FETCH_SETTINGS_FAILURE,
      )
    }

    this.setState(
      { isCreate: Utils.isCreate(this.props.location.pathname) },
      () => {
        if (this.state.isCreate) {
          this.setState({ customer: {} })
        } else {
          const email = this.props.match.params.email
          const payload = { storeId, email }

          this.props.post(payload, 'get-customer').then(resp => {
            this.setState({
              customer: resp.data,
            })
          })

          // Loading Order History
          console.log(payload)
          this.props.post(payload, 'admin-order-history').then(resp => {
            console.log('resp', resp)
            this.setState({
              orderHistory: resp.data || [],
              settings: _.get(this.props.settings, 'data', {}),
            })
          })
        }
      },
    )
  }

  onChangeInput = e => {
    let customer = this.state.customer

    if (e.target.name === 'totalSalesAmount') {
      customer[e.target.name] = _.trimStart(e.target.value, '$')
    } else {
      customer[e.target.name] = e.target.value
    }

    this.setState({ customer })
  }

  onChangeCheckbox = e => {
    let customer = this.state.customer
    customer[e.target.name] = !_.get(customer, e.target.name, false)

    this.setState({ customer })
  }

  onChangeSelect = (e, name) => {
    let customer = this.state.customer
    customer[name] = e.value

    this.setState({ customer })
  }

  isValidEmail = async email => {
    let exist = false

    if (this.state.isCreate) {
      exist = await this.props.post(
        {
          storeId: this.storeId,
          email,
        },
        'get-customer',
      )

      exist = exist.data
    }

    return validator.isEmail(email) && !exist
  }

  action = async () => {
    this.props.onSetLoading(true)
    this.setState({
      submited: true,
      btnLoading: true,
    })

    let isValid = this.validator.validate(this.state.customer).isValid
    let action = this.state.isCreate ? 'create-customer' : 'update-customer'

    if (isValid) {
      if (this.state.isCreate) {
        let rsp = await this.props.post(
          {
            storeId: this.storeId,
            email: this.state.customer.email,
          },
          'get-customer',
        )

        if (rsp.data != null) {
          this.props.onSetLoading(false)
          // Notification
          this.setState({ btnLoading: false })

          Notification.error(
            'Sorry the Customer with the same email already exist.',
          )
          return
        }
      }
    }

    if (isValid) {
      this.props.onSetLoading(true)
      this.setState({ btnLoading: true })
      this.props
        .post(
          {
            ...this.state.customer,
            storeId: this.storeId,
          },
          action,
        )
        .then(resp => {
          this.response(resp)
        })
    } else {
      this.props.onSetLoading(false)
      this.setState({ btnLoading: false })
    }
  }

  response = resp => {
    this.props.onSetLoading(false)
    this.setState({ btnLoading: false })

    let msn = _.replace(
      _.replace(process.env.REACT_APP_SUCCESS_ACTION, '%entity%', entity),
      '%action%',
      this.state.isCreate ? 'Created' : 'Updated',
    )

    if (resp.status !== 'error') {
      Notification.success(msn)

      if (this.state.isCreate) {
        this.props.history.push(
          `/customer/detail/${resp.data.email}?store=${this.storeId}`,
        )
      }
    } else {
      Notification.error(process.env.REACT_APP_ERROR_ACTION)
    }
  }

  render = () => {
    const validator = this.state.submited
      ? this.validator.validate(this.state.customer)
      : this.validator.valid()

    console.log(this.state.orderHistory)

    const defaultSorted = [{ dataField: 'email', order: 'asc' }]
    const timeZone = this.state.settings?.timeZone?.value || 'America/New_York'

    const columns = [
      {
        dataField: 'id',
        hidden: true,
      },
      {
        dataField: 'invoiceNumber',
        text: 'Invoice #',
        sort: true,
        headerStyle: (colum, colIndex) => ({ width: '100px' }),
      },
      {
        dataField: 'shippToAddress',
        text: 'Shipping Address',
        sort: true,
        formatter: (cell, row, rowIndex, formatExtraData) => (
          <span>
            {`${row.shippToAddress} ${row.shippToCity}, ${row.shippToCountry} ${row.shippToState} ${row.shippToZipcode}`}
          </span>
        ),
      },
      {
        dataField: 'invoiceDate',
        text: 'Invoice Date',
        sort: true,
        headerStyle: (colum, colIndex) => ({ width: '150px' }),
        formatter: (cell, row, rowIndex, formatExtraData) =>
          moment(cell, 'YYYY-MM-DD').tz(timeZone).format('MM/DD/YYYY'),
      },
      {
        dataField: 'total',
        text: 'Total',
        sort: true,
        align: 'right',
        headerStyle: (colum, colIndex) => ({ width: '80px' }),
        formatter: (cell, row, rowIndex, formatExtraData) => {
          let subtotal = Utils.subtotal(row.products)
          let shipping = row.shippingPrice
          let tax = _.get(row, 'tax', 0)

          let total = subtotal + tax + shipping

          return <strong>${total.toFixed(2)}</strong>
        },
      },
      {
        dataField: 'status',
        text: 'Status',
        sort: true,
        headerStyle: (colum, colIndex) => ({ width: '100px' }),
        formatter: (cell, row, rowIndex, formatExtraData) =>
          _.capitalize(row.status),
      },
      {
        dataField: 'actions',
        text: 'Actions',
        headerAlign: 'right',
        headerClasses: 'align-top',
        headerStyle: (colum, colIndex) => ({ width: '100px' }),
        align: 'right',
        formatter: (cell, row, rowIndex, formatExtraData) => {
          let url = _.replace(Routes.ORDER_DETAIL, ':id', row.id)
          url = url + '?store=' + this.storeId
          return (
            <div>
              <Link
                to={url}
                className="btn btn-outline-secondary btn-sm btn-icon"
                title="Detail"
              >
                <i className="la la-search" />
              </Link>
            </div>
          )
        },
      },
    ]

    const options = {
      showTotal: true,
      sizePerPageList: [
        {
          text: '20',
          value: 20,
        },
        {
          text: '100',
          value: 100,
        },
        {
          text: '200',
          value: 200,
        },
        {
          text: '300',
          value: 300,
        },
        {
          text: 'All',
          value: this.state.orderHistory.length,
        },
      ],
    }

    return (
      <Portlet>
        <Helmet>
          <title>Customer | Admin Panel - Max Ecommerce</title>
        </Helmet>

        <PortletHeader
          title={this.state.isCreate ? 'New Customer' : 'Update Customer'}
          sticky
        >
          <Link
            to={`/customers?store=${this.storeId}`}
            className="btn btn-secondary mr-3"
          >
            <i className="la la-arrow-left" />
            Back
          </Link>
          <Button
            label={this.state.isCreate ? 'Create' : 'Update'}
            color="primary"
            icon={`la ${this.state.btnLoading ? '' : ' la-check'}`}
            isLoading={this.state.btnLoading}
            onClick={this.action}
          />
        </PortletHeader>
        <PortletBody>
          {this.state.customer === null && (
            <div className="row">
              <div className="col-12">
                <div>Loading...</div>
              </div>
            </div>
          )}
          {this.state.customer !== null && (
            <div className="row">
              <div className="col-xl-2" />
              <div className="col-xl-8">
                <Section title="Customer Information" size="lg">
                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">Active</Label>
                    <div className="col-9">
                      <span className="k-switch k-switch--md k-switch--icon">
                        <label>
                          <Input
                            checked={
                              this.state.customer.status ? 'checked' : ''
                            }
                            onChange={this.onChangeCheckbox}
                            name="status"
                            type="checkbox"
                          />
                          <span />
                        </label>
                      </span>
                    </div>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">First Name </Label>
                    <div className="col-9">
                      <Input
                        value={this.state.customer.firstName}
                        onChange={this.onChangeInput}
                        name="firstName"
                        invalid={validator.firstName.isInvalid}
                      />
                      <FormFeedback>{validator.firstName.message}</FormFeedback>
                    </div>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">Last Name </Label>
                    <div className="col-9">
                      <Input
                        value={this.state.customer.lastName}
                        onChange={this.onChangeInput}
                        name="lastName"
                        invalid={validator.lastName.isInvalid}
                      />
                      <FormFeedback>{validator.lastName.message}</FormFeedback>
                    </div>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">Email </Label>
                    <div className="col-9">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <i className="la la-at input-group-text" />
                        </div>
                        <Input
                          value={this.state.customer.email}
                          onChange={this.onChangeInput}
                          name="email"
                          invalid={validator.email.isInvalid}
                        />
                        <FormFeedback>{validator.email.message}</FormFeedback>
                      </div>
                    </div>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">Phone </Label>
                    <div className="col-9">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <i className="la la-phone input-group-text" />
                        </div>
                        <Input
                          value={this.state.customer.phoneNumber}
                          onChange={this.onChangeInput}
                          name="phoneNumber"
                          invalid={validator.phoneNumber.isInvalid}
                        />
                        <FormFeedback>
                          {validator.phoneNumber.message}
                        </FormFeedback>
                      </div>
                    </div>
                  </FormGroup>

                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">Company </Label>
                    <div className="col-9">
                      <Input
                        value={this.state.customer.company}
                        onChange={this.onChangeInput}
                        name="company"
                      />
                    </div>
                  </FormGroup>
                </Section>

                <Separator size="lg" border={'dashed'} />

                <Section title="Address" size="lg">
                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">Address </Label>
                    <div className="col-9">
                      <Input
                        value={this.state.customer.address}
                        onChange={this.onChangeInput}
                        name="address"
                        invalid={validator.address.isInvalid}
                      />
                      <FormFeedback>{validator.address.message}</FormFeedback>
                    </div>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">City </Label>
                    <div className="col-9">
                      <Input
                        value={this.state.customer.city}
                        onChange={this.onChangeInput}
                        name="city"
                        invalid={validator.city.isInvalid}
                      />
                      <FormFeedback>{validator.city.message}</FormFeedback>
                    </div>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">Country </Label>
                    <div className="col-9">
                      <Select
                        options={this.props.country}
                        value={Utils.object(
                          this.props.country,
                          this.state.customer.country,
                        )}
                        onChange={e => this.onChangeSelect(e, 'country')}
                        className={
                          validator.country.isInvalid ? 'is-invalid' : ''
                        }
                      />
                      <FormFeedback>{validator.country.message}</FormFeedback>
                    </div>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">State </Label>
                    <div className="col-9">
                      <Select
                        options={this.props.states}
                        value={Utils.object(
                          this.props.states,
                          this.state.customer.state,
                        )}
                        onChange={e => this.onChangeSelect(e, 'state')}
                        className={
                          validator.state.isInvalid ? 'is-invalid' : ''
                        }
                      />
                      <FormFeedback>{validator.state.message}</FormFeedback>
                    </div>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label className="col-3 col-form-label">Zip </Label>
                    <div className="col-9">
                      <Input
                        value={this.state.customer.zip}
                        onChange={this.onChangeInput}
                        name="zip"
                        invalid={validator.zip.isInvalid}
                      />
                      <FormFeedback>{validator.zip.message}</FormFeedback>
                    </div>
                  </FormGroup>
                </Section>

                {!this.state.isCreate && (
                  <React.Fragment>
                    <Separator size="lg" border={'dashed'} />
                    <Section title="Statistics" size="lg">
                      <FormGroup className="row">
                        <Label className="col-3 col-form-label">
                          Total Orders{' '}
                        </Label>
                        <div className="col-9">
                          <Input
                            value={this.state.customer.totalOrders}
                            name="totalOrders"
                            readOnly
                            disabled
                            className="bg-light"
                          />
                        </div>
                      </FormGroup>
                      <FormGroup className="row">
                        <Label className="col-3 col-form-label">
                          Total Sales{' '}
                        </Label>
                        <div className="col-9">
                          <Input
                            value={`$${
                              this.state.customer.totalSalesAmount.toFixed(2) ||
                              0
                            }`}
                            name="totalSalesAmount"
                            readOnly
                            disabled
                            className="bg-light"
                          />
                        </div>
                      </FormGroup>
                    </Section>

                    <Separator size="lg" border={'dashed'} />

                    <Section title="Order History" size="lg">
                      <FormGroup className="row">
                        <div className="col-12">
                          <BootstrapTable
                            bootstrap4
                            keyField="id"
                            data={this.state.orderHistory}
                            columns={columns}
                            // hover
                            noDataIndication={() => (
                              <div className="mt-2">No History</div>
                            )}
                            pagination={
                              this.state.orderHistory.length
                                ? paginationFactory(options)
                                : null
                            }
                            defaultSorted={defaultSorted}
                          />
                        </div>
                      </FormGroup>
                    </Section>
                  </React.Fragment>
                )}
              </div>
              <div className="col-xl-2" />
            </div>
          )}
        </PortletBody>
      </Portlet>
    )
  }
}

export const mapStateToProps = state => ({
  settings: state.settings,
  productType: state.productType,
  customers: state.customers,
  country: state.country,
  states: state.states,
})

export const mapDispatchToProps = dispatch => ({
  post: function(path, payload, actionEvent) {
    return dispatch(Actions.post(path, payload, actionEvent))
  },
  storeGet: function(path, actionEvent, typeSuccess, typeError) {
    return dispatch(
      Actions.storeGet(path, actionEvent, typeSuccess, typeError),
    )
  },
  storePost: function(payload, actionEvent, typeSuccess, typeError) {
    return dispatch(
      Actions.storePost(payload, actionEvent, typeSuccess, typeError),
    )
  },
  onSetLoading: payload => dispatch(UiActions.onSetLoading(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductForm)
