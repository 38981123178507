import React, { useMemo, useState } from 'react'
import ReactDropZone from 'react-dropzone'
import moment from 'moment-timezone'
import axios from 'axios'
import {
  FormGroup,
  Input,
  Label,
  classNames,
  Actions,
  connect,
  Notification,
  _
} from '../../../Imports'

const allowedFiles = 'image/jpeg, image/png'

const DropZone = ({ images, selected, image, submited, validator, onChange, isMultiple, post, onLoading }) => {
  const [loading, setLoading] = useState(false)
  const previewImages = useMemo(
    () => (selected ? [selected] : []).concat(images).sort(),
    [images, selected],
  )

  const getImagePreview = value => (
    <div className="product-img row align-items-center mb-2" key={value}>
      <div>
        <img src={value} alt="Product Image" />
        <i
          className="la la-close"
          onClick={onRemoveImage(value)}
          title="Remove"
        />
      </div>
      {isMultiple && (
        <FormGroup>
          <Label
            className={classNames(
              { 'k-radio--brand': selected == value },
              {
                'k-radio--danger': validator?.thumbnail.isInvalid,
              },
              { 'text-danger': validator?.thumbnail.isInvalid },
            )}
          >
            <Input
              id={`thumb-${value}`}
              type="radio"
              name="thumbnail"
              checked={selected == value}
              onChange={onChangeThumbnail(value)}
            />
            Thumbnail
            <span />
          </Label>
        </FormGroup>
      )}
    </div>
  )

  const filterImages = url => images.filter(oldUrl => !(oldUrl === url))

  const onRemoveImage = url => () => {
    if (isMultiple) {
      const filteredImages = filterImages(url)
      onChange(filteredImages, url !== selected ? selected : '')
    } else {
      onChange('')
    }
  }

  const onChangeThumbnail = url => () => {
    const filteredImages = filterImages(url)
    onChange((selected ? [selected] : []).concat(filteredImages).sort(), url)
  }

  const onDrop = async (accepted, rejected) => {
    if (rejected.length > 0) return
    if (typeof onLoading === 'function') onLoading(true)
    setLoading(true)

    if (accepted.length > 0) {
      const [file] = accepted
      const fileType = file.type
      const fileName = `${moment().valueOf()}-${_.toLower(file.name)}`
      const config = { headers: { 'Content-Type': fileType }}
      const payload = { fileName: `images/products-images/${fileName}` }
      const { data: url } = await post(payload, 'generate-url')

      axios.put(url, file, config)
        .then(async () => {
          if (typeof onLoading === 'function') onLoading(false)
          setLoading(false)
          const imageUrl = process.env.REACT_APP_IMAGE_BASE_URL + fileName
          if (isMultiple) {
            onChange([...images, imageUrl], selected)
          } else {
            onChange(imageUrl)
          }
        })
        .catch(error => {
          if (typeof onLoading === 'function') onLoading(false)
          setLoading(false)
          console.log('ERROR: ', error)
          Notification.error('File failed to upload.')
        })
    }
  }

  return (
    <>
      <FormGroup className="row">
        <Label className="col-3 col-form-label">{isMultiple ? 'Images' : 'Image'}</Label>
        <div className="col-9 media-list">
          {isMultiple ? (
            previewImages.map(image => getImagePreview(image))
          ) : (
            image && getImagePreview(image)
          )}
          {loading && (
            <i className="product-img-placeholder" />
          )}
        </div>
      </FormGroup>
      {(isMultiple || !image) && (
        <div
          className={classNames('dropZone', {
            'dropZone-invalid': (isMultiple ? !previewImages.length : !image) && submited,
          })}
        >
          <ReactDropZone
            disabled={loading}
            onDrop={onDrop}
            accept={allowedFiles}
            multiple={false}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <p className="dropZone-msg">
                    {allowedFiles} (BROWSE)
                  </p>
                </div>
              </section>
            )}
          </ReactDropZone>
          {!previewImages.length && submited && isMultiple && (
            <span className="invalid-feedback d-block">
              You need to upload one image at least.
            </span>
          )}
        </div>
      )}
    </>
  )
}

DropZone.defaultProps = {
  images: [],
}

const mapDispatchToProps = dispatch => ({
  post: (payload, actionEvent) => dispatch(Actions.post(payload, actionEvent)),
})

export default connect(null, mapDispatchToProps)(DropZone)
