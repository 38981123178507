import ActionTypes from '../actions/ActionTypes'
import _ from 'lodash'

export const Year = (
  state = {
    data: [],
    error: '',
    status: '',
  },
  action,
) => {
  let success = {
    ...state,
    data: _.get(action, 'payload.data', []),
    status: _.get(action, 'payload.status', ''),
  }

  let error = {
    ...state,
    data: [],
    error: action.error,
    status: 'error',
  }

  switch (action.type) {
  case ActionTypes.FETCH_YEARS_SUCCESS: {
    return success
  }
  case ActionTypes.FETCH_YEARS_FAILURE: {
    return error
  }
  }

  return state
}

export const Product = (
  state = {
    data: [],
    page: 0,
    totalPages: 0,
    totalProducts: 0,
    error: '',
    status: '',
    loading: false,
  },
  action,
) => {

  let loading = {...state, loading: true}
  let success = {
    ...state,
    data: _.get(action, 'payload.data', []),
    page: _.get(action, 'payload.page', 0),
    totalPages: _.get(action, 'payload.totalPages', 0),
    totalProducts: _.get(action, 'payload.totalProducts', 0),
    status: _.get(action, 'payload.status', ''),
    loading: false,
  }

  let error = {
    ...state,
    data: [],
    error: action.error,
    status: 'error',
    loading: false,
  }

  switch (action.type) {
  case ActionTypes.FETCH_PRODUCTS_LOADING: {
    return loading
  }
  case ActionTypes.FETCH_PRODUCTS_SUCCESS: {
    return success
  }
  case ActionTypes.FETCH_PRODUCTS_FAILURE: {
    return error
  }
  }

  return state
}

export const Option = (
  state = {
    data: [],
    error: '',
    status: '',
  },
  action,
) => {
  let success = {
    ...state,
    data: _.get(action, 'payload.data', []),
    status: _.get(action, 'payload.status', ''),
  }

  let error = {
    ...state,
    data: [],
    error: action.error,
    status: 'error',
  }

  switch (action.type) {
  case ActionTypes.FETCH_OPTIONS_SUCCESS: {
    return success
  }
  case ActionTypes.FETCH_OPTIONS_FAILURE: {
    return error
  }
  }

  return state
}

export const Page = (
  state = {
    data: [],
    error: '',
    status: '',
  },
  action,
) => {
  let success = {
    ...state,
    data: _.get(action, 'payload.data', []),
    status: _.get(action, 'payload.status', ''),
  }

  let error = {
    ...state,
    data: [],
    error: action.error,
    status: 'error',
  }

  switch (action.type) {
  case ActionTypes.FETCH_PAGES_SUCCESS: {
    return success
  }
  case ActionTypes.FETCH_PAGES_FAILURE: {
    return error
  }
  }

  return state
}

export const Customer = (
  state = {
    data: [],
    error: '',
    status: '',
  },
  action,
) => {
  let success = {
    ...state,
    data: _.get(action, 'payload.data', []),
    status: _.get(action, 'payload.status', ''),
  }

  let error = {
    ...state,
    data: [],
    error: action.error,
    status: 'error',
  }

  switch (action.type) {
  case ActionTypes.FETCH_CUSTOMERS_SUCCESS: {
    return success
  }
  case ActionTypes.FETCH_CUSTOMERS_FAILURE: {
    return error
  }
  }

  return state
}

export const Reports = (
  state = {
    data: [],
    error: '',
    status: '',
  },
  action,
) => {
  let success = {
    ...state,
    data: _.get(action, 'payload.data', []),
    status: _.get(action, 'payload.status', ''),
  }

  let error = {
    ...state,
    data: [],
    error: action.error,
    status: 'error',
  }

  switch (action.type) {
  case ActionTypes.SHIPPING_REPORT_SUCCESS: {
    return success
  }
  case ActionTypes.SHIPPING_REPORT_FAILURE: {
    return error
  }
  }

  return state
}

export const Promotion = (
  state = {
    data: [],
    error: '',
    status: '',
  },
  action,
) => {
  let success = {
    ...state,
    data: _.get(action, 'payload.data', []),
    status: _.get(action, 'payload.status', ''),
  }

  let error = {
    ...state,
    data: [],
    error: action.error,
    status: 'error',
  }

  switch (action.type) {
  case ActionTypes.FETCH_PROMOTIONS_SUCCESS: {
    return success
  }
  case ActionTypes.FETCH_PROMOTIONS_FAILURE: {
    return error
  }
  }

  return state
}

export const User = (
  state = {
    data: [],
    error: '',
    status: '',
  },
  action,
) => {
  let success = {
    ...state,
    data: _.get(action, 'payload.data', []),
    status: _.get(action, 'payload.status', ''),
  }

  let error = {
    ...state,
    data: [],
    error: action.error,
    status: 'error',
  }

  switch (action.type) {
  case ActionTypes.FETCH_USERS_SUCCESS: {
    return success
  }
  case ActionTypes.FETCH_USERS_FAILURE: {
    return error
  }
  }

  return state
}

export const productCategories = (
  state = {
    data: [],
    error: '',
    status: '',
  },
  action,
) => {
  let success = {
    ...state,
    data: _.get(action, 'payload.data', []),
    status: _.get(action, 'payload.status', ''),
  }

  let error = {
    ...state,
    data: [],
    error: action.error,
    status: 'error',
  }

  switch (action.type) {
  case ActionTypes.FETCH_CATEGORIES_SUCCESS: {
    return success
  }
  case ActionTypes.FETCH_CATEGORIES_FAILURE: {
    return error
  }
  }

  return state
}

export const Settings = (
  state = {
    data: null,
    error: '',
    status: '',
  },
  action,
) => {
  let success = {
    ...state,
    data: _.get(action, 'payload.data', []),
    status: _.get(action, 'payload.status', ''),
  }

  let error = {
    ...state,
    data: [],
    error: action.error,
    status: 'error',
  }

  switch (action.type) {
  case ActionTypes.FETCH_SETTINGS_SUCCESS: {
    return success
  }
  case ActionTypes.FETCH_SETTINGS_FAILURE: {
    return error
  }
  }

  return state
}

export const Order = (
  state = {
    data: [],
    error: '',
    status: '',
  },
  action,
) => {
  let success = {
    ...state,
    data: _.get(action, 'payload.data', []),
    status: _.get(action, 'payload.status', ''),
  }

  let error = {
    ...state,
    data: [],
    error: action.error,
    status: 'error',
  }

  switch (action.type) {
  case ActionTypes.FETCH_ORDERS_SUCCESS: {
    return success
  }
  case ActionTypes.FETCH_ORDERS_FAILURE: {
    return error
  }
  }

  return state
}

export const Stores = (
  state = {
    data: [],
    error: '',
    status: '',
  },
  action,
) => {
  let success = {
    ...state,
    data: _.get(action, 'payload.data', []),
    status: _.get(action, 'payload.status', ''),
  }

  let error = {
    ...state,
    data: [],
    error: action.error,
    status: 'error',
  }

  switch (action.type) {
  case ActionTypes.FETCH_STORES_SUCCESS: {
    return success
  }
  case ActionTypes.FETCH_STORES_FAILURE: {
    return error
  }
  }

  return state
}

export const Attributes = (state = '', { type, payload }) => {
  switch (type) {
  case ActionTypes.FETCH_ATTRIBUTES_SUCCESS: {
    return payload.data
  }
  }

  return state
}

export const Variants = (state = [], { type, payload }) => {
  switch (type) {
  case ActionTypes.FETCH_VARIANTS_SUCCESS: {
    return payload.data
  }
  case ActionTypes.CLEAR_VARIANTS:
    return []
  }

  return state
}

export const currentStore = (state = '', action) => {
  switch (action.type) {
  case ActionTypes.CHANGE_CURRENT_STORE: {
    return action.payload
  }
  }

  return state
}

export const PageCategories = () => [
  {
    value: 'HOME',
    label: 'Home',
  },
  {
    value: 'SPECIALS',
    label: 'Specials',
  },
  {
    value: 'SITE_MAP',
    label: 'Site Map',
  },
  {
    value: 'CONTACT',
    label: 'Contact',
  },
  {
    value: 'TEXT',
    label: 'Text',
  },
  {
    value: 'SUCCESS',
    label: 'Success',
  },
  {
    value: 'CATALOG',
    label: 'Catalog',
  },
]

export const TaxType = () => [
  {
    value: 'PERCENTAGE',
    label: 'Percentage',
  },
  {
    value: 'FLAT_RATE',
    label: 'Flat Rate',
  },
]

export const FedexServiceType = () => [
  {
    value: 'EUROPE_FIRST_INTERNATIONAL_PRIORITY',
    label: 'Europe First International Priority',
  },
  {
    value: 'FEDEX_1_DAY_FREIGHT',
    label: 'Fedex 1 Day Freight',
  },
  {
    value: 'FEDEX_2_DAY',
    label: 'Fedex 2 Day',
  },
  {
    value: 'FEDEX_2_DAY_AM',
    label: 'Fedex 2 Day AM',
  },
  {
    value: 'FEDEX_2_DAY_FREIGHT',
    label: 'Fedex 2 Day Freight',
  },
  {
    value: 'FEDEX_3_DAY_FREIGHT',
    label: 'Fedex 3 Day Freight',
  },
  {
    value: 'FEDEX_DISTANCE_DEFERRED',
    label: 'Fedex Distance Deferred',
  },
  {
    value: 'FEDEX_EXPRESS_SAVER',
    label: 'Fedex Express Saver',
  },
  {
    value: 'FEDEX_FIRST_FREIGHT',
    label: 'Fedex First Freight',
  },
  {
    value: 'FEDEX_FREIGHT_ECONOMY',
    label: 'Fedex Freight Economy',
  },
  {
    value: 'FEDEX_FREIGHT_PRIORITY',
    label: 'Fedex Freight Priority',
  },
  {
    value: 'FEDEX_GROUND',
    label: 'Fedex Ground',
  },
  {
    value: 'FEDEX_NEXT_DAY_AFTERNOON',
    label: 'Fedex Next Day Afternoon',
  },
  {
    value: 'FEDEX_NEXT_DAY_EARLY_MORNING',
    label: 'Fedex Next Day Early Morning',
  },
  {
    value: 'FEDEX_NEXT_DAY_END_OF_DAY',
    label: 'Fedex Next Day End of Day',
  },
  {
    value: 'FEDEX_NEXT_DAY_FREIGHT',
    label: 'Fedex Next Day Freight',
  },
  {
    value: 'FEDEX_NEXT_DAY_MID_MORNING',
    label: 'Fedex Next Day Mid Morning',
  },
  {
    value: 'FIRST_OVERNIGHT',
    label: 'First Overnight',
  },
  {
    value: 'GROUND_HOME_DELIVERY',
    label: 'Ground Home Delivery',
  },
  {
    value: 'INTERNATIONAL_ECONOMY',
    label: 'International Economy',
  },
  {
    value: 'INTERNATIONAL_ECONOMY_FREIGHT',
    label: 'International Economy Freight',
  },
  {
    value: 'INTERNATIONAL_FIRST',
    label: 'International First',
  },
  {
    value: 'INTERNATIONAL_PRIORITY',
    label: 'International Priority',
  },
  {
    value: 'INTERNATIONAL_PRIORITY_EXPRESS',
    label: 'International Priority Express',
  },
  {
    value: 'INTERNATIONAL_PRIORITY_FREIGHT',
    label: 'International Priority Freight',
  },
  {
    value: 'PRIORITY_OVERNIGHT',
    label: 'Priority Overnight',
  },
  {
    value: 'SAME_DAY',
    label: 'Same Day',
  },
  {
    value: 'SAME_DAY_CITY',
    label: 'Same Day City',
  },
  {
    value: 'SMART_POST',
    label: 'Smart Post',
  },
  {
    value: 'STANDARD_OVERNIGHT',
    label: 'Standard Overnight',
  },
]

export const FedexPackageType = () => [
  {
    value: {
      id: 'FEDEX1',
      pk: 'FEDEX_PAK',
      length: 0,
      width: 10.25,
      height: 12.75,
    },
    label: 'Fedex Pak (10-1/4 x 12-3/4)',
  },
  {
    value: {
      id: 'FEDEX2',
      pk: 'FEDEX_PAK',
      length: 0,
      width: 12,
      height: 15.5,
    },
    label: 'Fedex Pak (12 x 15-1/2)',
  },
  {
    value: {
      id: 'FEDEX3',
      pk: 'FEDEX_PAK',
      length: 0,
      width: 10.25,
      height: 12.75,
    },
    label: 'Fedex Pak (10-1/4 x 12-3/4)',
  },
  {
    value: {
      id: 'FEDEX4',
      pk: 'FEDEX_ENVELOPE',
      length: 0,
      width: 9.5,
      height: 12.5,
    },
    label: 'Fedex Envelope (9-1/2 x 12-1/2)',
  },
  {
    value: {
      id: 'FEDEX5',
      pk: 'FEDEX_ENVELOPE',
      length: 0,
      width: 9.5,
      height: 15.5,
    },
    label: 'Fedex Envelope (9-1/2 x 15-1/2)',
  },
  {
    value: {
      id: 'FEDEX6',
      pk: 'FEDEX_TUBE',
      length: 6,
      width: 6,
      height: 38,
    },
    label: 'Fedex Tube',
  },
  {
    value: {
      id: 'FEDEX7',
      pk: 'FEDEX_SMALL_BOX',
      length: 10.87,
      width: 1.5,
      height: 12.37,
    },

    label: 'Fedex Small Box (10-7/8)',
  },
  {
    value: {
      id: 'FEDEX8',
      pk: 'FEDEX_SMALL_BOX',
      length: 8.75,
      width: 2.62,
      height: 11.25,
    },

    label: 'Fedex Small Box (8-3/4)',
  },

  {
    value: {
      id: 'FEDEX9',
      pk: 'FEDEX_MEDIUM_BOX',
      length: 11.5,
      width: 2.37,
      height: 13.25,
    },
    label: 'Fedex Medium Box (11-1/2)',
  },
  {
    value: {
      id: 'FEDEX10',
      pk: 'FEDEX_MEDIUM_BOX',
      length: 8.75,
      width: 4.37,
      height: 11.25,
    },
    label: 'Fedex Medium Box (8-3/4)',
  },

  {
    value: {
      id: 'FEDEX11',
      pk: 'FEDEX_LARGE_BOX',
      length: 12.37,
      width: 3,
      height: 17.5,
    },
    label: 'Fedex Large Box (12-3/8)',
  },

  {
    value: {
      id: 'FEDEX12',
      pk: 'FEDEX_LARGE_BOX',
      length: 8.75,
      width: 7.75,
      height: 11.25,
    },
    label: 'Fedex Large Box (8-3/4)',
  },

  {
    value: {
      id: 'FEDEX13',
      pk: 'FEDEX_EXTRA_LARGE_BOX',
      length: 11.87,
      width: 10.75,
      height: 11,
    },

    label: 'Fedex Extra Large Box (11-7/8)',
  },

  {
    value: {
      id: 'FEDEX14',
      pk: 'FEDEX_EXTRA_LARGE_BOX',
      length: 15.75,
      width: 14.12,
      height: 6,
    },

    label: 'Fedex Extra Large Box (15-3/4)',
  },
  // {
  //     value: {
  //         pk: "YOUR_PACKAGING",
  //         length: 0,
  //         width: 0,
  //         height: 0
  //     },
  //     label: "Your Packaging"
  // }

  // We need to find the sizes for
  // the package below
  //{ value: "FEDEX_10KG_BOX", label: "Fedex 10kg Box" },
  //{ value: "FEDEX_25KG_BOX", label: "Fedex 25kg Box" },
  //{ value: "FEDEX_BOX", label: "Fedex Box" },

  // Package sizes pdf
  // https://finance.princeton.edu/how-to/buying-paying/other-buy-pay/new-shipping-tool-eship-g/All_FedEx_UPS.pdf
]

export const UspsPackageType = () =>
// https://postcalc.usps.com/?country=0&ccode=US&oz=91205&omil=False&dz=91209&dmil=False&mdt=9%2F11%2F2019&mdz=15%3A29&m=12

  [
    {
      value: {
        id: 'USPS1',
        pk: 'SMFLATRATEBO',
        length: 8.62,
        width: 5.37,
        height: 1.62,
      },
      label: 'USPS Small Flat Rate Box',
    },

    {
      value: {
        id: 'USPS2',
        pk: 'MDFLATRATEBOX',
        length: 13.62,
        width: 11.87,
        height: 3.37,
      },

      label: 'USPS Medium Flat Rate Box (13.5/8)',
    },
    {
      value: {
        id: 'USPS3',
        pk: 'MDFLATRATEBOX',
        length: 11,
        width: 8.5,
        height: 5.5,
      },

      label: 'USPS Medium Flat Rate Box',
    },
    {
      value: {
        id: 'USPS4',
        pk: 'LGFLATRATEBO',
        length: 23.69,
        width: 11.75,
        height: 3,
      },
      label: 'USPS Large Flat Rate Box (23.69)',
    },
    {
      value: {
        id: 'USPS5',
        pk: 'LGFLATRATEBO',
        length: 12,
        width: 12,
        height: 5.5,
      },
      label: 'USPS Large Flat Rate Box',
    },
    {
      value: {
        id: 'USPS6',
        pk: 'GIFTCARDFLATRATEENVELOPE',
        length: 0,
        width: 10,
        height: 7,
      },
      label: 'USPS Gift Card Flat Rate Envelope',
    },
    {
      value: {
        id: 'USPS7',
        pk: 'FLATRATEENVELOPE',
        length: 0,
        width: 12.5,
        height: 9.5,
      },
      label: 'USPS Flat Rate Envelope',
    },
    {
      value: {
        id: 'USPS7',
        pk: 'PADDEDFLATRATEENVELOPE',
        length: 0,
        width: 12.5,
        height: 9.5,
      },
      label: 'USPS Padded Flat Rate Envelope',
    },
    {
      value: {
        id: 'USPS8',
        pk: 'LEGALFLATRATEENVELOPE',
        length: 0,
        width: 15,
        height: 9.5,
      },
      label: 'USPS Legal Flat Rate Envelope',
    },
    {
      value: {
        id: 'USPS9',
        pk: 'SMFLATRATEENVELOP',
        length: 0,
        width: 10,
        height: 6,
      },
      label: 'USPS Small Flat Rate Envelope',
    },
    {
      value: {
        id: 'USPS10',
        pk: 'WINDOWFLATRATEENVELOPE',
        length: 0,
        width: 10,
        height: 5,
      },
      label: 'USPS Window Flat Rate Envelope (10)',
    },
    {
      value: {
        id: 'USPS11',
        pk: 'WINDOWFLATRATEENVELOPE',
        length: 0,
        width: 12.5,
        height: 9.5,
      },
      label: 'USPS Window Flat Rate Envelope (12.5)',
    },
    {
      value: {
        id: 'USPS12',
        pk: 'REGIONALRATEBOXA',
        length: 10,
        width: 7,
        height: 4.75,
      },
      label: 'USPS Regional Rate Box A Top Loading',
    },
    {
      value: {
        id: 'USPS13',
        pk: 'REGIONALRATEBOXA',
        length: 10.94,
        width: 2.37,
        height: 12.81,
      },
      label: 'USPS Regional Rate Box A Side Loading',
    },
    {
      value: {
        id: 'USPS14',
        pk: 'REGIONALRATEBOXB',
        length: 12,
        width: 10.25,
        height: 5,
      },
      label: 'USPS Regional Rate Box B Top Loading',
    },
    {
      value: {
        id: 'USPS15',
        pk: 'REGIONALRATEBOXB',
        length: 14.37,
        width: 2.87,
        height: 15.87,
      },
      label: 'USPS Regional Rate Box B Side Loading',
    },
    {
      value: {
        id: 'USPS16',
        pk: 'REGIONALRATEBOXC',
        length: 14.25,
        width: 11.25,
        height: 11.5,
      },
      label: 'USPS Regional Rate Box C',
    },

    // { value: "VARIABLE", label: "Variable" },
    // { value: "FLATRATEBOX", label: "Flat Rate Box" },
    // { value: "RECTANGULAR", label: "Rectangular" },
    // { value: "NONRECTANGUL", label: "Non Rectangular" }

    // {
    //     value: {
    //         pk: "SMFLATRATEBO",
    //         length: 0,
    //         width: 0,
    //         height: 0
    //     },
    //     label: "Small Flat Rate Box"
    // }, //8-5/8" x 5-3/8" x 1-5/8"
    //
    // { value: "MDFLATRATEBOX", label: "Medium Flat Rate Box" }, //13-5/8" x 11-7/8" x 3-3/8" or 11" x 8-1/2" x 5-1/2"
    // { value: "LGFLATRATEBO", label: "Large Flat Rate Box" }, //23-11/16" x 11-3/4" x 3" or 12" x 12" x 5-1/2"
    // {
    //     value: "GIFTCARDFLATRATEENVELOPE",
    //     label: "Gift Card Flat Rate Envelope"
    // }, //10" x 7"
    // { value: "FLATRATEENVELOPE", label: "Flat Rate Envelope" }, //12-1/2" x 9-1/2"
    // { value: "PADDEDFLATRATEENVELOPE", label: "Padded Flat Rate Envelope" }, //12-1/2" x 9-1/2"
    // { value: "LEGALFLATRATEENVELOPE", label: "Legal Flat Rate Envelope" }, //15" x 9-1/2"
    // { value: "SMFLATRATEENVELOP", label: "Small Flat Rate Envelope" }, //10" x 6"
    // { value: "WINDOWFLATRATEENVELOPE", label: "Window Flat Rate Envelope" }, //10" x 5" or 12-1/2" x 9-1/2"
    // { value: "REGIONALRATEBOXA", label: "Regional Rate Box A Top Loading" }, //10 in x 7 in x 4-3/4 in
    // {
    //     value: "REGIONALRATEBOXA",
    //     label: "Regional Rate Box A Side Loading"
    // }, //10-15/16” x 2-3/8” x 12-13/16”
    // { value: "REGIONALRATEBOXB", label: "Regional Rate Box B Top Loading" }, // 12” x 10-1/4” x 5”
    // {
    //     value: "REGIONALRATEBOXB",
    //     label: "Regional Rate Box B Side Loading"
    // }, // 14-3/8” x 2-7/8” x 15-7/8”
    // { value: "REGIONALRATEBOXC", label: "Regional Rate Box C" } //14-3/4” x 11-3/4” x 11-1/2”

    // { value: "VARIABLE", label: "Variable" },
    // { value: "FLATRATEBOX", label: "Flat Rate Box" },
    // { value: "RECTANGULAR", label: "Rectangular" },
    // { value: "NONRECTANGUL", label: "Non Rectangular" }
  ]

export const FedexDropoffType = () => [
  { value: 'BUSINESS_SERVICE_CENTER', label: 'Business Service Center' },
  { value: 'DROP_BOX', label: 'Drop Box' },
  { value: 'REGULAR_PICKUP', label: 'Regular Pickup' },
  { value: 'REQUEST_COURIER', label: 'Request Courier' },
  { value: 'STATION', label: 'Station' },
]

export const FedexWeightType = () => [
  { value: 'LB', label: 'LB' },
  { value: 'OZ', label: 'OZ' },
]

export const FedexDimensionType = () => [
  { value: 'FT', label: 'FT' },
  { value: 'IN', label: 'IN' },
]

export const FedexLabelStockType = () => [
  { value: 'PAPER_4X6', label: 'Paper 4x6' },
  { value: 'PAPER_4X6.75', label: 'Paper 4x6.75' },
  { value: 'PAPER_4X8', label: 'Paper 4x8' },
  { value: 'PAPER_4X9', label: 'Paper 4x9' },
  { value: 'PAPER_7X4.75', label: 'Paper 7x4.75' },
  {
    value: 'PAPER_8.5X11_BOTTOM_HALF_LABEL',
    label: 'Paper 8.5x11 Bottom Half Label',
  },
  {
    value: 'PAPER_8.5X11_TOP_HALF_LABEL',
    label: 'Paper 8.5x11 Top Half Label',
  },
  { value: 'PAPER_LETTER', label: 'Paper Letter' },
  { value: 'STOCK_4X6', label: 'Stock 4x6' },
  { value: 'STOCK_4X6.75', label: 'Stock 4x6.75' },
  {
    value: 'STOCK_4X6.75_LEADING_DOC_TAB',
    label: 'Stock 4x6.75 Leading Doc Tab',
  },
  {
    value: 'STOCK_4X6.75_TRAILING_DOC_TAB',
    label: 'Stock 4x6.75 Trailing Doc Tab',
  },
  { value: 'STOCK_4X8', label: 'Stock 4x8' },
  { value: 'STOCK_4X9', label: 'Stock 4x9' },
  {
    value: 'STOCK_4X9_LEADING_DOC_TAB',
    label: 'Stock 4x9 Leading Doc Tab',
  },
  {
    value: 'STOCK_4X9_TRAILING_DOC_TAB',
    label: 'Stock 4x9 Trailing Doc Tab',
  },
]

export const UspsServiceType = () => [
  {
    value: '0',
    label: 'First-Class Mail',
    type: 'dom',
  },
  {
    value: '1',
    label: 'Priority Mail',
    type: 'dom',
  },
  {
    value: '2',
    label: 'Priority Mail Express - Hold For Pickup',
    type: 'dom',
  },
  {
    value: '3',
    label: 'Priority Mail Express',
    type: 'dom',
  },
  {
    value: '4',
    label: 'Standard Post',
    type: 'dom',
  },
  {
    value: '6',
    label: 'Media Mail',
    type: 'dom',
  },
  {
    value: '7',
    label: 'Library Mail',
    type: 'dom',
  },
  {
    value: '13',
    label: 'Priority Mail Express - Flat Rate Envelope',
    type: 'dom',
  },
  {
    value: '15',
    label: 'First-Class Mail Large Postcards',
    type: 'dom',
  },
  {
    value: '16',
    label: 'Priority Mail - Flat Rate Envelope',
    type: 'dom',
  },
  {
    value: '17',
    label: 'Priority Mail - Medium Flat Rate Box',
    type: 'dom',
  },
  {
    value: '22',
    label: 'Priority Mail - Large Flat Rate Box',
    type: 'dom',
  },
  {
    value: '23',
    label: 'Priority Mail Express - Sunday/Holiday Delivery',
    type: 'dom',
  },
  {
    value: '25',
    label: 'Priority Mail Express - Sunday/Holiday Delivery Flat Rate Envelope',
    type: 'dom',
  },
  {
    value: '27',
    label: 'Priority Mail Express - Flat Rate Envelope Hold For Pickup',
    type: 'dom',
  },
  {
    value: '28',
    label: 'Priority Mail - Small Flat Rate Box',
    type: 'dom',
  },
  {
    value: '29',
    label: 'Priority Mail - Padded Flat Rate Envelope',
    type: 'dom',
  },
  {
    value: '30',
    label: 'Priority Mail Express - Legal Flat Rate Envelope',
    type: 'dom',
  },
  {
    value: '31',
    label: 'Priority Mail Express - Legal Flat Rate Envelope Hold For Pickup',
    type: 'dom',
  },
  {
    value: '32',
    label:
      'Priority Mail Express - Sunday/Holiday Delivery Legal Flat Rate Envelope',
    type: 'dom',
  },
  {
    value: '33',
    label: 'Priority Mail - Hold For Pickup',
    type: 'dom',
  },
  {
    value: '34',
    label: 'Priority Mail - Large Flat Rate Box Hold For Pickup',
    type: 'dom',
  },
  {
    value: '35',
    label: 'Priority Mail - Medium Flat Rate Box Hold For Pickup',
    type: 'dom',
  },
  {
    value: '36',
    label: 'Priority Mail - Small Flat Rate Box Hold For Pickup',
    type: 'dom',
  },
  {
    value: '37',
    label: 'Priority Mail - Flat Rate Envelope Hold For Pickup',
    type: 'dom',
  },
  {
    value: '38',
    label: 'Priority Mail - Gift Card Flat Rate Envelope',
    type: 'dom',
  },
  {
    value: '39',
    label: 'Priority Mail - Gift Card Flat Rate Envelope Hold For Pickup',
    type: 'dom',
  },
  {
    value: '40',
    label: 'Priority Mail - Window Flat Rate Envelope',
    type: 'dom',
  },
  {
    value: '41',
    label: 'Priority Mail - Window Flat Rate Envelope Hold For Pickup',
    type: 'dom',
  },
  {
    value: '42',
    label: 'Priority Mail - Small Flat Rate Envelope',
    type: 'dom',
  },
  {
    value: '43',
    label: 'Priority Mail - Small Flat Rate Envelope Hold For Pickup',
    type: 'dom',
  },
  {
    value: '44',
    label: 'Priority Mail - Legal Flat Rate Envelope',
    type: 'dom',
  },
  {
    value: '45',
    label: 'Priority Mail - Legal Flat Rate Envelope Hold For Pickup',
    type: 'dom',
  },
  {
    value: '46',
    label: 'Priority Mail - Padded Flat Rate Envelope Hold For Pickup',
    type: 'dom',
  },
  {
    value: '47',
    label: 'Priority Mail - Regional Rate Box A',
    type: 'dom',
  },
  {
    value: '48',
    label: 'Priority Mail - Regional Rate Box A Hold For Pickup',
    type: 'dom',
  },
  {
    value: '49',
    label: 'Priority Mail - Regional Rate Box B',
    type: 'dom',
  },
  {
    value: '50',
    label: 'Priority Mail - Regional Rate Box B Hold For Pickup',
    type: 'dom',
  },
  {
    value: '53',
    label: 'First-Class/ Package Service Hold For Pickup',
    type: 'dom',
  },
  {
    value: '55',
    label: 'Priority Mail Express - Flat Rate Boxes',
    type: 'dom',
  },
  {
    value: '56',
    label: 'Priority Mail Express - Flat Rate Boxes Hold For Pickup',
    type: 'dom',
  },
  {
    value: '57',
    label: 'Priority Mail Express - Sunday/Holiday Delivery Flat Rate Boxes',
    type: 'dom',
  },
  {
    value: '58',
    label: 'Priority Mail - Regional Rate Box C',
    type: 'dom',
  },
  {
    value: '59',
    label: 'Priority Mail - Regional Rate Box C Hold For Pickup',
    type: 'dom',
  },
  {
    value: '61',
    label: 'First-Class/ Package Service',
    type: 'dom',
  },
  {
    value: '62',
    label: 'Priority Mail Express - Padded Flat Rate Envelope',
    type: 'dom',
  },
  {
    value: '63',
    label: 'Priority Mail Express - Padded Flat Rate Envelope Hold For Pickup',
    type: 'dom',
  },
  {
    value: '64',
    label:
      'Priority Mail Express - Sunday/Holiday Delivery Padded Flat Rate Envelope',
    type: 'dom',
  },
  {
    value: '1',
    label: 'Priority Mail Express International',
    type: 'int',
  },
  {
    value: '2',
    label: 'Priority Mail International',
    type: 'int',
  },
  {
    value: '4',
    label: 'Global Express Guaranteed (GXG)',
    type: 'int',
  },
  {
    value: '5',
    label: 'Global Express Guaranteed Document',
    type: 'int',
  },
  {
    value: '6',
    label: 'Global Express Guaranteed Non-Document Rectangular',
    type: 'int',
  },
  {
    value: '7',
    label: 'Global Express Guaranteed Non-Document Non-Rectangular',
    type: 'int',
  },
  {
    value: '8',
    label: 'Priority Mail International Flat Rate Envelope',
    type: 'int',
  },
  {
    value: '9',
    label: 'Priority Mail International Medium Flat Rate Box',
    type: 'int',
  },
  {
    value: '10',
    label: 'Priority Mail Express International Flat Rate Envelope',
    type: 'int',
  },
  {
    value: '11',
    label: 'Priority Mail International Large Flat Rate Box',
    type: 'int',
  },
  {
    value: '12',
    label: 'USPS GXG Envelopes',
    type: 'int',
  },
  {
    value: '13',
    label: 'First-Class Mail International Letter',
    type: 'int',
  },
  {
    value: '14',
    label: 'First-Class Mail International Large Envelope',
    type: 'int',
  },
  {
    value: '15',
    label: 'First-Class Package International Service',
    type: 'int',
  },
  {
    value: '16',
    label: 'Priority Mail International Small Flat Rate Box',
    type: 'int',
  },
  {
    value: '17',
    label: 'Priority Mail Express International Legal Flat Rate Envelope',
    type: 'int',
  },
  {
    value: '18',
    label: 'Priority Mail International Gift Card Flat Rate Envelope',
    type: 'int',
  },
  {
    value: '19',
    label: 'Priority Mail International Window Flat Rate Envelope',
    type: 'int',
  },
  {
    value: '20',
    label: 'Priority Mail International Small Flat Rate Envelope',
    type: 'int',
  },
  {
    value: '21',
    label: 'First-Class Mail International Postcard',
    type: 'int',
  },
  {
    value: '22',
    label: 'Priority Mail International Legal Flat Rate Envelope',
    type: 'int',
  },
  {
    value: '23',
    label: 'Priority Mail International Padded Flat Rate Envelope',
    type: 'int',
  },
  {
    value: '24',
    label: 'Priority Mail International DVD Flat Rate priced box',
    type: 'int',
  },
  {
    value: '25',
    label: 'Priority Mail International Large Video Flat Rate priced box',
    type: 'int',
  },
  {
    value: '26',
    label: 'Priority Mail Express International Flat Rate Boxes',
    type: 'int',
  },
  {
    value: '27',
    label: 'Priority Mail Express International Padded Flat Rate Envelope',
    type: 'int',
  },
  //{ value: "ALL", label: "All" },
]

export const OrderType = () => [
  //ABANDONED_SHOPPING_CART
  {
    value: 'PENDING',
    label: 'Pending',
  },
  {
    value: 'PROCESSING',
    label: 'Processing',
  },
  {
    value: 'SHIPPED',
    label: 'Shipped',
  },
  {
    value: 'DECLINED',
    label: 'Declined',
  },
  {
    value: 'VOID',
    label: 'Void',
  },
  {
    value: 'ABANDONED_SHOPPING_CART',
    label: 'Abandoned',
  },
  {
    value: 'PUBLISHED',
    label: 'Published',
  },
  {
    value: 'REQUESTED',
    label: 'Requested',
  },
]

export const BroadcastType = () => [
  {
    value: 'HEADER_BAR_HOME_PAGE',
    label: 'Header Bar at Home Page',
  },
  { value: 'HEADER_BAR_HOME_ALL_PAGE', label: 'Header Bar at All Pages' },
  { value: 'EMAIL_POPUP', label: 'Email Popup' },
]

export const AssociateToType = () => [
  { value: 'PRODUCTS', label: 'Products' },
  { value: 'CATEGORIES', label: 'Products Categories' },
]

export const DiscountType = () => [
  {
    value: 'AMOUNT_OFF_PRODUCT_PRICE',
    label: 'Amount off Product Price',
  },
  { value: 'AMOUNT_OFF_ORDER', label: 'Amount off Order' },
  { value: 'QUANTITY_DISCOUNT', label: 'Quantity Discount' },
  { value: 'FREE_SHIPPING_DISCOUNT', label: 'Free Shipping Discount' },
  { value: 'BUY_ONE_GET_ONE_FREE', label: 'Buy One, Get One Free' },
  {
    value: 'BUY_ONE_GET_ONE_AMOUNT_OFF',
    label: 'Buy One, Get One Amount Off',
  },
  {
    value: 'BUY_ONE_GET_ONE_PERCENT_OFF',
    label: 'Buy One, Get One Percent Off',
  },
  // {
  //     value: "BUY_ONE_GET_ANOTHER_FREE",
  //     label: "Buy One, Get Another Free"
  // },
  // {
  //     value: "BUY_ONE_GET_ANOTHER_AMOUNT_OFF",
  //     label: "Buy One, Get Another Amount Off"
  // },
  // {
  //     value: "BUY_ONE_GET_ANOTHER_PERCENT_OFF",
  //     label: "Buy One, Get Another Percent Off"
  // },
  { value: 'FREE_PRODUCT', label: 'Free Product' },
]

export const DiscountRateType = () => [
  { value: 'AMOUNT', label: 'Amount' },
  { value: 'PERCENTAGE', label: 'Percentage' },
]

export const DiscountApplyType = () => [
  { value: 'AUTOMATICALLY', label: 'Automatically' },
  { value: 'PROMOTION_CODE', label: 'Promotion Code' },
]

export const DiscountQuantityApplyType = () => [
  { value: 'ALL_PRODUCTS', label: 'All Products' },
  { value: 'EACH_PRODUCT', label: 'Each Product' },
]

export const NoRate = () => [
  'FREE_SHIPPING_DISCOUNT',
  'BUY_ONE_GET_ONE_FREE',
  'BUY_ONE_GET_ANOTHER_FREE',
  'FREE_PRODUCT',
]

export const DiscountRateReadonlyType = () => [
  'BUY_ONE_GET_ONE_AMOUNT_OFF',
  'BUY_ONE_GET_ONE_PERCENT_OFF',
  'BUY_ONE_GET_ANOTHER_AMOUNT_OFF',
  'BUY_ONE_GET_ANOTHER_PERCENT_OFF',
]

export const Country = () => [
  {
    label: 'Afghanistan',
    value: 'AF',
  },
  {
    label: 'Albania',
    value: 'AL',
  },
  {
    label: 'Algeria',
    value: 'DZ',
  },
  {
    label: 'American Samoa',
    value: 'AS',
  },
  {
    label: 'Andorra',
    value: 'AD',
  },
  {
    label: 'Angola',
    value: 'AO',
  },
  {
    label: 'Anguilla',
    value: 'AI',
  },
  {
    label: 'Antarctica',
    value: 'AQ',
  },
  {
    label: 'Antigua and Barbuda',
    value: 'AG',
  },
  {
    label: 'Argentina',
    value: 'AR',
  },
  {
    label: 'Armenia',
    value: 'AM',
  },
  {
    label: 'Aruba',
    value: 'AW',
  },
  {
    label: 'Australia',
    value: 'AU',
  },
  {
    label: 'Austria',
    value: 'AT',
  },
  {
    label: 'Azerbaijan',
    value: 'AZ',
  },
  {
    label: 'Bahamas',
    value: 'BS',
  },
  {
    label: 'Bahrain',
    value: 'BH',
  },
  {
    label: 'Bangladesh',
    value: 'BD',
  },
  {
    label: 'Barbados',
    value: 'BB',
  },
  {
    label: 'Belarus',
    value: 'BY',
  },
  {
    label: 'Belgium',
    value: 'BE',
  },
  {
    label: 'Belize',
    value: 'BZ',
  },
  {
    label: 'Benin',
    value: 'BJ',
  },
  {
    label: 'Bermuda',
    value: 'BM',
  },
  {
    label: 'Bhutan',
    value: 'BT',
  },
  {
    label: 'Bolivia Plurinational State of',
    value: 'BO',
  },

  {
    label: 'Bonaire Sint Eustatius and Saba',
    value: 'BQ',
  },

  {
    label: 'Bosnia and Herzegovina',
    value: 'BA',
  },
  {
    label: 'Botswana',
    value: 'BW',
  },
  {
    label: 'Bouvet Island',
    value: 'BV',
  },
  {
    label: 'Brazil',
    value: 'BR',
  },
  {
    label: 'British Indian Ocean Territory',
    value: 'IO',
  },
  {
    label: 'Brunei Darussalam',
    value: 'BN',
  },
  {
    label: 'Bulgaria',
    value: 'BG',
  },
  {
    label: 'Burkina Faso',
    value: 'BF',
  },
  {
    label: 'Burundi',
    value: 'BI',
  },
  {
    label: 'Cambodia',
    value: 'KH',
  },
  {
    label: 'Cameroon',
    value: 'CM',
  },
  {
    label: 'Canada',
    value: 'CA',
  },
  {
    label: 'Cape Verde',
    value: 'CV',
  },
  {
    label: 'Cayman Islands',
    value: 'KY',
  },
  {
    label: 'Central African Republic',
    value: 'CF',
  },
  {
    label: 'Chad',
    value: 'TD',
  },
  {
    label: 'Chile',
    value: 'CL',
  },
  {
    label: 'China',
    value: 'CN',
  },
  {
    label: 'Christmas Island',
    value: 'CX',
  },
  {
    label: 'Cocos (Keeling) Islands',
    value: 'CC',
  },
  {
    label: 'Colombia',
    value: 'CO',
  },
  {
    label: 'Comoros',
    value: 'KM',
  },
  {
    label: 'Congo',
    value: 'CG',
  },
  {
    label: 'Congo the Democratic Republic of the',
    value: 'CD',
  },
  {
    label: 'Cook Islands',
    value: 'CK',
  },
  {
    label: 'Costa Rica',
    value: 'CR',
  },
  {
    label: 'CÃ´te d\'Ivoire',
    value: 'CI',
  },
  {
    label: 'Croatia',
    value: 'HR',
  },
  {
    label: 'Cuba',
    value: 'CU',
  },
  {
    label: 'CuraÃ§ao',
    value: 'CW',
  },
  {
    label: 'Cyprus',
    value: 'CY',
  },
  {
    label: 'Czech Republic',
    value: 'CZ',
  },
  {
    label: 'Denmark',
    value: 'DK',
  },
  {
    label: 'Djibouti',
    value: 'DJ',
  },
  {
    label: 'Dominica',
    value: 'DM',
  },
  {
    label: 'Dominican Republic',
    value: 'DO',
  },
  {
    label: 'Ecuador',
    value: 'EC',
  },
  {
    label: 'Egypt',
    value: 'EG',
  },
  {
    label: 'El Salvador',
    value: 'SV',
  },
  {
    label: 'Equatorial Guinea',
    value: 'GQ',
  },
  {
    label: 'Eritrea',
    value: 'ER',
  },
  {
    label: 'Estonia',
    value: 'EE',
  },
  {
    label: 'Ethiopia',
    value: 'ET',
  },
  {
    label: 'Falkland Islands (Malvinas)',
    value: 'FK',
  },
  {
    label: 'Faroe Islands',
    value: 'FO',
  },
  {
    label: 'Fiji',
    value: 'FJ',
  },
  {
    label: 'Finland',
    value: 'FI',
  },
  {
    label: 'France',
    value: 'FR',
  },
  {
    label: 'French Guiana',
    value: 'GF',
  },
  {
    label: 'French Polynesia',
    value: 'PF',
  },
  {
    label: 'French Southern Territories',
    value: 'TF',
  },
  {
    label: 'Gabon',
    value: 'GA',
  },
  {
    label: 'Gambia',
    value: 'GM',
  },
  {
    label: 'Georgia',
    value: 'GE',
  },
  {
    label: 'Germany',
    value: 'DE',
  },
  {
    label: 'Ghana',
    value: 'GH',
  },
  {
    label: 'Gibraltar',
    value: 'GI',
  },
  {
    label: 'Greece',
    value: 'GR',
  },
  {
    label: 'Greenland',
    value: 'GL',
  },
  {
    label: 'Grenada',
    value: 'GD',
  },
  {
    label: 'Guadeloupe',
    value: 'GP',
  },
  {
    label: 'Guam',
    value: 'GU',
  },
  {
    label: 'Guatemala',
    value: 'GT',
  },
  {
    label: 'Guernsey',
    value: 'GG',
  },
  {
    label: 'Guinea',
    value: 'GN',
  },
  {
    label: 'Guinea-Bissau',
    value: 'GW',
  },
  {
    label: 'Guyana',
    value: 'GY',
  },
  {
    label: 'Haiti',
    value: 'HT',
  },
  {
    label: 'Heard Island and McDonald Islands',
    value: 'HM',
  },
  {
    label: 'Holy See (Vatican City State)',
    value: 'VA',
  },
  {
    label: 'Honduras',
    value: 'HN',
  },
  {
    label: 'Hong Kong',
    value: 'HK',
  },
  {
    label: 'Hungary',
    value: 'HU',
  },
  {
    label: 'Iceland',
    value: 'IS',
  },
  {
    label: 'India',
    value: 'IN',
  },
  {
    label: 'Indonesia',
    value: 'ID',
  },
  {
    label: 'Iran Islamic Republic of',
    value: 'IR',
  },
  {
    label: 'Iraq',
    value: 'IQ',
  },
  {
    label: 'Ireland',
    value: 'IE',
  },
  {
    label: 'Isle of Man',
    value: 'IM',
  },
  {
    label: 'Israel',
    value: 'IL',
  },
  {
    label: 'Italy',
    value: 'IT',
  },
  {
    label: 'Jamaica',
    value: 'JM',
  },
  {
    label: 'Japan',
    value: 'JP',
  },
  {
    label: 'Jersey',
    value: 'JE',
  },
  {
    label: 'Jordan',
    value: 'JO',
  },
  {
    label: 'Kazakhstan',
    value: 'KZ',
  },
  {
    label: 'Kenya',
    value: 'KE',
  },
  {
    label: 'Kiribati',
    value: 'KI',
  },
  {
    label: 'Korea Democratic People\'s Republic of',
    value: 'KP',
  },
  {
    label: 'Korea Republic of',
    value: 'KR',
  },
  {
    label: 'Kuwait',
    value: 'KW',
  },
  {
    label: 'Kyrgyzstan',
    value: 'KG',
  },
  {
    label: 'Lao People\'s Democratic Republic',
    value: 'LA',
  },
  {
    label: 'Latvia',
    value: 'LV',
  },
  {
    label: 'Lebanon',
    value: 'LB',
  },
  {
    label: 'Lesotho',
    value: 'LS',
  },
  {
    label: 'Liberia',
    value: 'LR',
  },
  {
    label: 'Libya',
    value: 'LY',
  },
  {
    label: 'Liechtenstein',
    value: 'LI',
  },
  {
    label: 'Lithuania',
    value: 'LT',
  },
  {
    label: 'Luxembourg',
    value: 'LU',
  },
  {
    label: 'Macao',
    value: 'MO',
  },
  {
    label: 'Macedonia the Former Yugoslav Republic of',
    value: 'MK',
  },
  {
    label: 'Madagascar',
    value: 'MG',
  },
  {
    label: 'Malawi',
    value: 'MW',
  },
  {
    label: 'Malaysia',
    value: 'MY',
  },
  {
    label: 'Maldives',
    value: 'MV',
  },
  {
    label: 'Mali',
    value: 'ML',
  },
  {
    label: 'Malta',
    value: 'MT',
  },
  {
    label: 'Marshall Islands',
    value: 'MH',
  },
  {
    label: 'Martinique',
    value: 'MQ',
  },
  {
    label: 'Mauritania',
    value: 'MR',
  },
  {
    label: 'Mauritius',
    value: 'MU',
  },
  {
    label: 'Mayotte',
    value: 'YT',
  },
  {
    label: 'Mexico',
    value: 'MX',
  },
  {
    label: 'Micronesia Federated States of',
    value: 'FM',
  },
  {
    label: 'Moldova Republic of',
    value: 'MD',
  },
  {
    label: 'Monaco',
    value: 'MC',
  },
  {
    label: 'Mongolia',
    value: 'MN',
  },
  {
    label: 'Montenegro',
    value: 'ME',
  },
  {
    label: 'Montserrat',
    value: 'MS',
  },
  {
    label: 'Morocco',
    value: 'MA',
  },
  {
    label: 'Mozambique',
    value: 'MZ',
  },
  {
    label: 'Myanmar',
    value: 'MM',
  },
  {
    label: 'Namibia',
    value: 'NA',
  },
  {
    label: 'Nauru',
    value: 'NR',
  },
  {
    label: 'Nepal',
    value: 'NP',
  },
  {
    label: 'Netherlands',
    value: 'NL',
  },
  {
    label: 'New Caledonia',
    value: 'NC',
  },
  {
    label: 'New Zealand',
    value: 'NZ',
  },
  {
    label: 'Nicaragua',
    value: 'NI',
  },
  {
    label: 'Niger',
    value: 'NE',
  },
  {
    label: 'Nigeria',
    value: 'NG',
  },
  {
    label: 'Niue',
    value: 'NU',
  },
  {
    label: 'Norfolk Island',
    value: 'NF',
  },
  {
    label: 'Northern Mariana Islands',
    value: 'MP',
  },
  {
    label: 'Norway',
    value: 'NO',
  },
  {
    label: 'Oman',
    value: 'OM',
  },
  {
    label: 'Pakistan',
    value: 'PK',
  },
  {
    label: 'Palau',
    value: 'PW',
  },
  {
    label: 'Palestine State of',
    value: 'PS',
  },
  {
    label: 'Panama',
    value: 'PA',
  },
  {
    label: 'Papua New Guinea',
    value: 'PG',
  },
  {
    label: 'Paraguay',
    value: 'PY',
  },
  {
    label: 'Peru',
    value: 'PE',
  },
  {
    label: 'Philippines',
    value: 'PH',
  },
  {
    label: 'Pitcairn',
    value: 'PN',
  },
  {
    label: 'Poland',
    value: 'PL',
  },
  {
    label: 'Portugal',
    value: 'PT',
  },
  {
    label: 'Puerto Rico',
    value: 'PR',
  },
  {
    label: 'Qatar',
    value: 'QA',
  },
  {
    label: 'RÃ©union',
    value: 'RE',
  },
  {
    label: 'Romania',
    value: 'RO',
  },
  {
    label: 'Russian Federation',
    value: 'RU',
  },
  {
    label: 'Rwanda',
    value: 'RW',
  },
  {
    label: 'Saint BarthÃ©lemy',
    value: 'BL',
  },
  {
    label: 'Saint Helena Ascension and Tristan da Cunha',
    value: 'SH',
  },
  {
    label: 'Saint Kitts and Nevis',
    value: 'KN',
  },
  {
    label: 'Saint Lucia',
    value: 'LC',
  },
  {
    label: 'Saint Martin (French part)',
    value: 'MF',
  },
  {
    label: 'Saint Pierre and Miquelon',
    value: 'PM',
  },
  {
    label: 'Saint Vincent and the Grenadines',
    value: 'VC',
  },
  {
    label: 'Samoa',
    value: 'WS',
  },
  {
    label: 'San Marino',
    value: 'SM',
  },
  {
    label: 'Sao Tome and Principe',
    value: 'ST',
  },
  {
    label: 'Saudi Arabia',
    value: 'SA',
  },
  {
    label: 'Senegal',
    value: 'SN',
  },
  {
    label: 'Serbia',
    value: 'RS',
  },
  {
    label: 'Seychelles',
    value: 'SC',
  },
  {
    label: 'Sierra Leone',
    value: 'SL',
  },
  {
    label: 'Singapore',
    value: 'SG',
  },
  {
    label: 'Sint Maarten (Dutch part)',
    value: 'SX',
  },
  {
    label: 'Slovakia',
    value: 'SK',
  },
  {
    label: 'Slovenia',
    value: 'SI',
  },
  {
    label: 'Solomon Islands',
    value: 'SB',
  },
  {
    label: 'Somalia',
    value: 'SO',
  },
  {
    label: 'South Africa',
    value: 'ZA',
  },
  {
    label: 'South Georgia and the South Sandwich Islands',
    value: 'GS',
  },
  {
    label: 'South Sudan',
    value: 'SS',
  },
  {
    label: 'Spain',
    value: 'ES',
  },
  {
    label: 'Sri Lanka',
    value: 'LK',
  },
  {
    label: 'Sudan',
    value: 'SD',
  },
  {
    label: 'Suriname',
    value: 'SR',
  },
  {
    label: 'Svalbard and Jan Mayen',
    value: 'SJ',
  },
  {
    label: 'Swaziland',
    value: 'SZ',
  },
  {
    label: 'Sweden',
    value: 'SE',
  },
  {
    label: 'Switzerland',
    value: 'CH',
  },
  {
    label: 'Syrian Arab Republic',
    value: 'SY',
  },
  {
    label: 'Taiwan Province of China',
    value: 'TW',
  },
  {
    label: 'Tajikistan',
    value: 'TJ',
  },
  {
    label: 'Tanzania United Republic of',
    value: 'TZ',
  },
  {
    label: 'Thailand',
    value: 'TH',
  },
  {
    label: 'Timor-Leste',
    value: 'TL',
  },
  {
    label: 'Togo',
    value: 'TG',
  },
  {
    label: 'Tokelau',
    value: 'TK',
  },
  {
    label: 'Tonga',
    value: 'TO',
  },
  {
    label: 'Trinidad and Tobago',
    value: 'TT',
  },
  {
    label: 'Tunisia',
    value: 'TN',
  },
  {
    label: 'Turkey',
    value: 'TR',
  },
  {
    label: 'Turkmenistan',
    value: 'TM',
  },
  {
    label: 'Turks and Caicos Islands',
    value: 'TC',
  },
  {
    label: 'Tuvalu',
    value: 'TV',
  },
  {
    label: 'Uganda',
    value: 'UG',
  },
  {
    label: 'Ukraine',
    value: 'UA',
  },
  {
    label: 'United Arab Emirates',
    value: 'AE',
  },
  {
    label: 'United Kingdom',
    value: 'GB',
  },
  {
    label: 'United States',
    value: 'US',
  },
  {
    label: 'United States Minor Outlying Islands',
    value: 'UM',
  },
  {
    label: 'Uruguay',
    value: 'UY',
  },
  {
    label: 'Uzbekistan',
    value: 'UZ',
  },
  {
    label: 'Vanuatu',
    value: 'VU',
  },
  {
    label: 'Venezuela Bolivarian Republic of',
    value: 'VE',
  },
  {
    label: 'Viet Nam',
    value: 'VN',
  },
  {
    label: 'Virgin Islands British',
    value: 'VG',
  },
  {
    label: 'Virgin Islands U.S.',
    value: 'VI',
  },
  {
    label: 'Wallis and Futuna',
    value: 'WF',
  },
  {
    label: 'Western Sahara',
    value: 'EH',
  },
  {
    label: 'Yemen',
    value: 'YE',
  },
  {
    label: 'Zambia',
    value: 'ZM',
  },
  {
    label: 'Zimbabwe',
    value: 'ZW',
  },
]

export const States = () => [
  {
    label: 'Alabama',
    value: 'AL',
  },
  {
    label: 'Alaska',
    value: 'AK',
  },
  {
    label: 'American Samoa',
    value: 'AS',
  },
  {
    label: 'Arizona',
    value: 'AZ',
  },
  {
    label: 'Arkansas',
    value: 'AR',
  },
  {
    label: 'California',
    value: 'CA',
  },
  {
    label: 'Colorado',
    value: 'CO',
  },
  {
    label: 'Connecticut',
    value: 'CT',
  },
  {
    label: 'Delaware',
    value: 'DE',
  },
  {
    label: 'District Of Columbia',
    value: 'DC',
  },
  {
    label: 'Federated States Of Micronesia',
    value: 'FM',
  },
  {
    label: 'Florida',
    value: 'FL',
  },
  {
    label: 'Georgia',
    value: 'GA',
  },
  {
    label: 'Guam',
    value: 'GU',
  },
  {
    label: 'Hawaii',
    value: 'HI',
  },
  {
    label: 'Idaho',
    value: 'ID',
  },
  {
    label: 'Illinois',
    value: 'IL',
  },
  {
    label: 'Indiana',
    value: 'IN',
  },
  {
    label: 'Iowa',
    value: 'IA',
  },
  {
    label: 'Kansas',
    value: 'KS',
  },
  {
    label: 'Kentucky',
    value: 'KY',
  },
  {
    label: 'Louisiana',
    value: 'LA',
  },
  {
    label: 'Maine',
    value: 'ME',
  },
  {
    label: 'Marshall Islands',
    value: 'MH',
  },
  {
    label: 'Maryland',
    value: 'MD',
  },
  {
    label: 'Massachusetts',
    value: 'MA',
  },
  {
    label: 'Michigan',
    value: 'MI',
  },
  {
    label: 'Minnesota',
    value: 'MN',
  },
  {
    label: 'Mississippi',
    value: 'MS',
  },
  {
    label: 'Missouri',
    value: 'MO',
  },
  {
    label: 'Montana',
    value: 'MT',
  },
  {
    label: 'Nebraska',
    value: 'NE',
  },
  {
    label: 'Nevada',
    value: 'NV',
  },
  {
    label: 'New Hampshire',
    value: 'NH',
  },
  {
    label: 'New Jersey',
    value: 'NJ',
  },
  {
    label: 'New Mexico',
    value: 'NM',
  },
  {
    label: 'New York',
    value: 'NY',
  },
  {
    label: 'North Carolina',
    value: 'NC',
  },
  {
    label: 'North Dakota',
    value: 'ND',
  },
  {
    label: 'Northern Mariana Islands',
    value: 'MP',
  },
  {
    label: 'Ohio',
    value: 'OH',
  },
  {
    label: 'Oklahoma',
    value: 'OK',
  },
  {
    label: 'Oregon',
    value: 'OR',
  },
  {
    label: 'Palau',
    value: 'PW',
  },
  {
    label: 'Pennsylvania',
    value: 'PA',
  },
  {
    label: 'Puerto Rico',
    value: 'PR',
  },
  {
    label: 'Rhode Island',
    value: 'RI',
  },
  {
    label: 'South Carolina',
    value: 'SC',
  },
  {
    label: 'South Dakota',
    value: 'SD',
  },
  {
    label: 'Tennessee',
    value: 'TN',
  },
  {
    label: 'Texas',
    value: 'TX',
  },
  {
    label: 'Utah',
    value: 'UT',
  },
  {
    label: 'Vermont',
    value: 'VT',
  },
  {
    label: 'Virgin Islands',
    value: 'VI',
  },
  {
    label: 'Virginia',
    value: 'VA',
  },
  {
    label: 'Washington',
    value: 'WA',
  },
  {
    label: 'West Virginia',
    value: 'WV',
  },
  {
    label: 'Wisconsin',
    value: 'WI',
  },
  {
    label: 'Wyoming',
    value: 'WY',
  },
]
