import { React, Component, Routes, Route } from '../../../Imports'

import Profile from './Profile'
import Tax from './Tax'
// import FetTax from "./FetTax";
import OrderSettings from './OrderSettings'
import Social from './Social'
import Integration from './Integration'
import T14Products from './T14Products'
import SettingsAside from './SettingsAside'
import Quickbooks from './Quickbooks'
import FulfilmentCenter from './FulfilmentCenter'
import ServiceArea from './ServiceArea'
import Payment from './Payment'
import ShippingMethod from './ShippingMethod'
import Timezone from './Timezone'

class Settings extends Component {
  render = () => (
    <div className="row">
      <div className="col-lg-3 col-xl-3">
        <SettingsAside />
      </div>
      <div className="col-lg-9 col-xl-9">
        <div className="tab-pane">
          <Route path={Routes.SETTINGS_PROFILE} component={Profile} />
          <Route path={Routes.SETTINGS_TAX} component={Tax} />
          <Route
            path={Routes.SETTINGS_ORDER_SETTINGS}
            component={OrderSettings}
          />
          <Route path={Routes.SETTINGS_TIMEZONE} component={Timezone} />
          {/*
                        <Route
                            path={Routes.SETTINGS_FET_TAX}
                            component={FetTax}
                        />
                        */}
          <Route path={Routes.SETTINGS_PAYMENT} component={Payment} />
          <Route path={Routes.SETTINGS_SOCIAL_LINKS} component={Social} />
          <Route path={Routes.SETTINGS_INTEGRATION} component={Integration} />
          <Route path={Routes.T14_PRODUCTS} component={T14Products} />
          <Route
            path={Routes.SETTINGS_FULFILMENT}
            component={FulfilmentCenter}
          />
          <Route path={Routes.SETTINGS_SERVICE_AREA} component={ServiceArea} />
          <Route
            path={Routes.SETTINGS_SHIPPING_METHOD}
            component={ShippingMethod}
          />
          <Route path={Routes.QUICKBOOK_ONLINE} component={Quickbooks} />
        </div>
      </div>
    </div>
  )
}

export default Settings
