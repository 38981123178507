import React from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import CheckoutForm from './CheckoutForm'

const stripePromise = process.env.REACT_APP_STRIPE_KEY
  ? loadStripe(process.env.REACT_APP_STRIPE_KEY)
  : null

const StripePayment = ({ ...restProps }) => stripePromise && (
  <Elements stripe={stripePromise}>
    <CheckoutForm {...restProps} />
  </Elements>
)

export default StripePayment
